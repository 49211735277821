import React, { useState, useRef, useEffect, } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { useFormik } from 'formik';
import * as yup from 'yup';
import { Typography, Link, Stack, Toolbar, Box, Alert, FormControl, TextField } from '@mui/material';
import Container from '@mui/material/Container';
import { Paper } from '@mui/material';
import OverlaySpinner from './Controls/OverlaySpinner';
import Button from '@mui/material/Button';
import { Footer } from './Footer';
import Grid from '@mui/material/Grid';
import { ErrorMessages } from '../common/ErrorMessages';
import { PreLoginMenu } from './PreLoginMenu';
//import { ReactComponent as ReactLogo } from '../assets/images/ssb-horizontal-new.svg';
import AlertControl from './Controls/AlertControl';
import { GetUserCurrentDate, GetOSPlatform, GetBrowserName, GetCountryOfUser } from '../global/Helpers';
import { APICall, AddToSession } from '../global/Api';
import { IsError } from '../global/APIUtils';
//import clientlogo from '../assets/images/ssb-logo.png';
//import pwdimg from '../assets/images/864687.jpg';

const OTPVerification = (props) => {

    const location = useLocation();
    let navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [blurEmail, setBlurEmail] = useState('');
    const [counter, setCounter] = useState(120);
    const [runTimer, setRunTimer] = useState(true);
    const [resendTextDisp, setResendTextDisp] = useState('block');
    const [resendButtonDisp, setResendButtonDisp] = useState('none');
    const [disSubmitBtn, setDisSubmitBtn] = useState(false);

    const emailAddress = useRef(location.state.emailAddress);
    const [userId, setUserId] = useState(location.state.userId);
    const [sessionId, setSessionId] = useState(location.state.sessionId);
    const [otpRef, setOtpRef] = useState(location.state.otpRef);
    const atoken = useRef(location.state.atoken);
    const rtoken = useRef(location.state.rtoken);

    const OTPVerificationForm = useFormik({
        initialValues: {
            verificationCode: ''
        },
        validationSchema: yup.object({
            verificationCode: yup.string()
                .max(30, "Verification code - must be a string with a maximum length of 10")
                .required('Verification code - required.'),
        }),
        onSubmit: values => {
            //setEmail(values.verificationCode)
            handleSubmit(values);
        }
    });

    const handleSubmit = async (resetValues) => {

        let inputJson = {
            EmailAddress: emailAddress.current,
            LoginOTP: resetValues.verificationCode,
            LoginOTPRef: otpRef
        };


        setErrorMsg('');

        try {

            setIsLoading(true);

            const apiUrl = new URL(
                process.env.REACT_APP_VERIFY_LOGIN_OTP,
                process.env.REACT_APP_BASE_URL,
            );

            let response = await APICall({ url: apiUrl.href, method: 'POST', payload: inputJson, contentType: 'application/json', responseType: '' }, userId, sessionId, atoken.current);

            if (response != null && response != undefined) {

                let error = IsError(response);

                if (error != 0) {
                    setErrorMsg(response.Errors[0].Message);
                    return;
                }

                if (response.meta.Success == '1') {

                    await navigateToHome();
                }
                else {
                    setErrorMsg(response.Errors[0].Message);
                }
            }
        }
        catch (err) {
            setErrorMsg(ErrorMessages.UnknownError);
        }
        finally {
            setIsLoading(false);
        }
    }

    const navigateToHome = async () => {

        const apiUrl = new URL(
            process.env.REACT_APP_GET_SESSION_PARAMETERS,
            process.env.REACT_APP_BASE_URL,
        );

        try {

            setIsLoading(true);

            let response = await APICall({ url: apiUrl.href, method: 'GET', contentType: 'application/json', responseType: '' }, userId, sessionId, atoken.current);

            if (response !== null && response !== undefined) {
                if (response.data.length > 0) {
                    AddToSession(response, response.data[0].EmailAddress, atoken.current, rtoken.current);
                    navigate('/UserHome', { state: { userId: userId, sessionId: sessionId }, replace: true });
                }
            }
        }
        catch (err) {
            setErrorMsg(ErrorMessages.UnknownError);
        }
        finally {
            setIsLoading(false);
        }

    }

    const login = (event) => {
        event.preventDefault();
        navigate('/AppLogin', { state: {}, replace: true });
    };

    useEffect(() => {

        if (emailAddress.current) {
            let arr = emailAddress.current.split("@");
            let len = arr[0].length;
            setBlurEmail(arr[0][0] + '*****' + (len > 1 ? arr[0][len - 2] : '') + arr[0][len - 1] + '@' + arr[1]);
        }

    }, []);

    useEffect(() => {
        const id = setInterval(() => {
            setResetCounter();
        }, 1000);

        return () => {
            clearInterval(id);
        };
    });

    function setResetCounter() {
        if (runTimer) {
            if (parseInt(counter) == 0) {
                setRunTimer(false);
                setResendTextDisp('none');
                setResendButtonDisp('block');
                setDisSubmitBtn(true);
                return;
            }

            setCounter(counter - 1);
        }
    }

    const ResendCode = async (event) => {

        event.preventDefault();

        setErrorMsg('');

        try {

            setIsLoading(true);

            let browserName = GetBrowserName();
            let country = GetCountryOfUser();
            let os = GetOSPlatform();
            let currentDate = GetUserCurrentDate();

            let inputJson = {
                EmailAddress: emailAddress.current,
                UserBrowserName: browserName,
                UserOS: os,
                UserLocationCountry: country,
                UserLocationDate: currentDate
            };

            setErrorMsg('');

            const apiUrl = new URL(
                process.env.REACT_APP_SEND_LOGIN_OTP,
                process.env.REACT_APP_BASE_URL,
            );

            let response = await APICall({ url: apiUrl.href, method: 'POST', payload: inputJson, contentType: 'application/json', responseType: '' }, userId, sessionId, atoken.current);

            if (response != null && response != undefined) {

                let error = IsError(response);

                if (error != 0) {
                    setErrorMsg(response.Errors[0].Message);
                }
                else {
                    if (response.meta.EmailSent === "Yes") {
                        let otpRef = response.data[0];
                        setOtpRef(otpRef);
                        setCounter(120);
                        setRunTimer(true);
                        setResendTextDisp('block');
                        setResendButtonDisp('none');
                        setDisSubmitBtn(false);
                    }
                    else {
                        setErrorMsg(ErrorMessages.UnknownError);
                    }
                }
            }
        }
        catch (err) {
            setErrorMsg(ErrorMessages.UnknownError);
        }
        finally {
            setIsLoading(false);
        }
    }

    return (
        <React.Fragment>
            <Container maxWidth="xl">
                <OverlaySpinner disappear={isLoading} />
                <PreLoginMenu />
                <Paper
                    sx={{
                        position: 'relative',
                        color: '#fff',
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        pb: { xs: 7, sm: 0 },
                    }}
                >
                    <Grid sx={{ pb: 5, pt: 5, }} container>
                        <Grid item xs={6} sx={{}}>
                            <Box sx={{ border: 1, borderColor: '#dadce0', boxShadow: '0 2px 4px 0 rgba(0,0,0,.3)', borderRadius: '4px', letterSpacing: '-1px', pl: 5, pt: 2, pb: 6, ml: 15, mt: 2, mb: 2, pr: 10 }}>
                                <AlertControl Message={errorMsg} severity="error" color="error" icon={true} />
                                <Typography variant="h5" color="text.primary" sx={{ fontWeight: 400, mb: 0, lineHeight: 1.5 }}>Let's verify your account</Typography>
                                <Typography variant="body1" color="text.primary" sx={{ fontWeight: 400, mb: 4, pb: 2, borderBottom: '1px solid rgba(0,0,0,.1)' }}>Why? You've enabled 2-step verification for your account.</Typography>
                                <Typography variant="h6" color="text.primary" sx={{ fontWeight: 400, mb: 0, lineHeight: 1.5 }}>Enter verification code (Ref: {otpRef})</Typography>
                                <Typography variant="body1" color="text.primary" sx={{ pt: 0, fontWeight: 400 }}>
                                    sent to your email {blurEmail}
                                </Typography>
                                <Box sx={{ pt: 4, pr: 5 }}>
                                    <form onSubmit={OTPVerificationForm.handleSubmit} id="OTPVerificationForm" >
                                        <Paper elevation={0}>
                                            <label htmlFor="verificationCode" className="form-label">Verification Code*</label>
                                            <input type="text" id="verificationCode" name="verificationCode" autoComplete="off" placeholder="Enter code here" className="form-control" value={OTPVerificationForm.values.verificationCode} onChange={OTPVerificationForm.handleChange} />
                                            {
                                                OTPVerificationForm.touched.verificationCode && OTPVerificationForm.errors.verificationCode ? (<p className="text-danger">{OTPVerificationForm.errors.verificationCode}</p>) : null
                                            }
                                        </Paper>
                                        <Typography variant="body1" color="text.primary" sx={{ pt: 1, display: resendTextDisp }}>
                                            Resend code in {counter}
                                        </Typography>
                                        <Box sx={{ pt: 1, display: resendButtonDisp }}>
                                            <Button sx={{ textTransform: 'none' }} onClick={(e) => ResendCode(e)}>
                                                <Typography variant="body1" sx={{ color: '#002d72' }}>
                                                    Resend code
                                                </Typography>
                                            </Button>
                                        </Box>
                                        <Box sx={{ mt: 4 }}>
                                            <Button type="submit" disabled={disSubmitBtn} variant="outlined" size="large" style={{ boxShadow: 'none', borderRadius: '40px', padding: '0.4rem 2.5rem', textTransform: 'none', }}>
                                                <Typography variant="body2" sx={{ fontWeight: 600 }} >Verify</Typography>
                                            </Button>

                                        {/*    <Button type="submit" variant="outlined" size="small" sx={{ padding: '0.4rem 3rem', border: 2, borderColor: '#616161' }}>*/}
                                        {/*        <Typography variant="body2" color="#212121" sx={{ textTransform: 'none', fontWeight: 500 }} >Verify</Typography>*/}
                                        {/*    </Button>*/}
                                        </Box>
                                    </form>
                                </Box>
                                <Box sx={{ pt: 3 }}>
                                    <Button onClick={login} sx={{ textTransform: 'none', color: '#002d72' }}>Back to Login</Button>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Paper>
                <Footer />
            </Container>
        </React.Fragment>
    );
}

export default OTPVerification;