import React, { useState, useEffect, useRef } from 'react';
import Container from '@mui/material/Container';
import { Box, Typography, Stack, Button, Badge, Paper, Alert, AlertTitle, Divider, Grid, Toolbar } from '@mui/material';
import OverlaySpinner from './Controls/OverlaySpinner';
import PostLoginMenu from './PostLoginMenu'
import { Footer } from './Footer';
import EnrollmentHeader from './EnrollmentHeader'
import { DrawerAppBar } from './AppNavbar'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
//import pwdimg from '../assets/images/Online protection_Monochromatic.png';
//import Image from 'material-ui-image'
import { useFormik } from 'formik';
import * as yup from 'yup';
import AlertControl from './Controls/AlertControl';
import { FormControl, TextField } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import { RedirectToHome } from '../global/Helpers';
import { useNavigate } from "react-router-dom";
//import { useSelector, useDispatch } from 'react-redux';
import { ErrorMessages } from '../common/ErrorMessages';
import { CallAPI, IsError, IsLoggedInUserProspectUser, GetAccessControl, GetLoggedInUserId, GetLoggedInUserEmail } from '../global/APIUtils';
//import { ReactComponent as ReactLogo } from '../assets/images/ssb-horizontal-new.svg';
import { useLocation } from "react-router-dom";
import { APICall } from '../global/Api';

const CreatePassword = () => {

    const location = useLocation();

    const [isLoading, setIsLoading] = useState(false);
    const [expanded, setExpanded] = useState(true);
    const [errorMsg, setErrorMsg] = useState('');
    const [successMsg, setSuccessMsg] = useState('');
    const [checked, setChecked] = React.useState(true);
    const [isProspect, setProspect] = useState(true);
    const [role, setRole] = useState('');
    const [CntCtId, setCntCtId] = useState(-1);
    const emailAdrs = useRef(null);
    /*const dispatch = useDispatch();*/

    const [userId, setUserId] = useState(location.state.userId || -1);
    const [sessionId, setSessionId] = useState(location.state.sessionId || '');


    const pwdRegExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/;
    let navigate = useNavigate();
    /*const sessions = useSelector((state) => state.sessionMgmnt);*/

    const handleAccordianChange = () => {
        if (expanded) {
            setExpanded(false);
        }
        else {
            setExpanded(true);
        }
    };

    const handleChange = (event) => {
        setChecked(event.target.checked);
        showPassword();
    };

    const createPwdForm = useFormik({

        enableReinitialize: true,
        initialValues: {
            NewPassword: "",
            ConfirmPassword: ""
        },

        validationSchema: yup.object({
            NewPassword: yup.string()
                .max(20, "New Password - weak")
                .min(10, "New Password - weak")
                .matches(pwdRegExp,
                    "New Password - weak"
                )
                .required('New Password - required.'),
            ConfirmPassword: yup.string()
                .max(20, "Confirm Password - weak")
                .min(10, "Confirm Password - weak")
                .matches(pwdRegExp,
                    "Confirm Password - weak"
                )
                .when("NewPassword", {
                    is: psswrd => (psswrd && psswrd.length > 0 ? true : false),
                    then: yup.string().oneOf(
                        [yup.ref("NewPassword")],
                        "Both password need to be the same.")
                })
                .required('Confirm Password - required.')
        }),
        onSubmit: values => {
            submitClick(values);
        },
    });

    const submitClick = async (values) => {

        setErrorMsg('');

        let chngModel = {
            EmailAddress: emailAdrs.current,
            NPassword: values.NewPassword,
            CPassword: values.ConfirmPassword,
            UserId: parseInt(userId)
        };

        //let headers = {
        //    'Content-Type': 'application/json'
        //};

        //let endpoint = process.env.REACT_APP_BASE_URL + process.env.REACT_APP_CREATE_PASSWORD;

        try {
            setIsLoading(true);

            const apiUrl = new URL(
                process.env.REACT_APP_CREATE_PASSWORD,
                process.env.REACT_APP_BASE_URL,
            );

            //let response = await CallAPI({ url: apiUrl.href, method: 'POST', headers: headers, body: chngModel }, userId, sessionId);

            let response = await APICall({ url: apiUrl.href, method: 'POST', payload: chngModel, contentType: 'application/json', responseType: '' }, userId, sessionId);

            if (response != null && response != undefined) {
                let error = IsError(response);

                if (error != 0) {
                    setErrorMsg(response.Errors[0].Message);
                }
                else {
                    navigate('/UserHome', { state: { userId: userId, sessionId: sessionId }, replace: true });
                }
            }
        }
        catch (err) {
            setErrorMsg(ErrorMessages.UnknownError);
        }
        finally {
            setIsLoading(false);
        }
    }

    function showPassword() {

        var np = document.getElementById("NewPassword");
        var cp = document.getElementById("ConfirmPassword");

        if (np.type === "password") {
            np.type = "text";
        } else {
            np.type = "password";
        }

        if (cp.type === "password") {
            cp.type = "text";
        } else {
            cp.type = "password";
        }
    }

    const ContinueHome = (event) => {
        event.preventDefault();
        navigate('/UserHome', { state: { userId: userId, sessionId: sessionId }, replace: true });
    };

    useEffect(() => {

        //let isProspectUser = IsLoggedInUserProspectUser(userId, sessionId);
        //let accessControl = GetAccessControl(userId, sessionId);

        let email = GetLoggedInUserEmail(userId, sessionId);
        emailAdrs.current = email;

        //setRole(accessControl);
        //setCntCtId(userId);
        //
        //setProspect(isProspectUser);

    }, []);

    return (
        <React.Fragment>
            <Container maxWidth="xl" sx={{}}>
                <OverlaySpinner disappear={isLoading} />
                <PostLoginMenu />
                <Paper className=""
                    sx={{
                        position: 'relative',
                        backgroundColor: '#fff',
                        color: '#fff',
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        pb: { xs: 7, sm: 10 },
                    }}
                >
                    {/*<Toolbar sx={{ borderBottom: 1, borderColor: 'divider', justifyContent: 'space-between', backgroundColor: '#fff', pt: 0, pb: 0 }}>*/}

                    {/*    <Box sx={{ display: 'flex', alignItems: 'center', ml: 1, mt: 4, mb: 1 }}>*/}
                    {/*        <ReactLogo />*/}
                    {/*    </Box>*/}
                    {/*</Toolbar>*/}
                    <Grid sx={12} container justifyContent="center" spacing={0} sx={{ pt: 5, }}>
                        <Grid item md={3}>
                        </Grid>
                        <Grid item md={6} sx={{ pr: 2, pt: 4, pb: 2 }}>
                            <Box sx={{ mx: 'auto', border: 1, borderColor: '#bdbdbd', borderRadius: '6px', pt: 1, pl: 10, pr: 8}}>
                                <Box sx={{ pt: 3 }}>
                                <Typography variant="h4" gutterBottom align="center" sx={{ pt: 0, fontWeight: 500, textAlign: 'center' }} color="text.primary">Change password</Typography>
                                <Typography variant="body1" align="center" sx={{}} color="text.primary">ashoke@fi-tek.co.in</Typography>
                                <Typography sx={{ mb: 1, mt: 4, fontWeight: 500 }} variant="h6" color="text.primary">
                                    Create a strong password
                                </Typography>
                                    <Typography sx={{ mb: 4, mt: 1, fontWeight: 500}} variant="body1" color="text.secondary">
                                    Use a new, strong password that you don't use for other websites. Don't use a variation of an old password or any personal information.
                                </Typography>
                                <form className="w-100" onSubmit={createPwdForm.handleSubmit} id="frmChangePwd">
                                    <Grid item sx={{ mb: 1 }} >
                                        <AlertControl Message={errorMsg} severity="error" color="error" icon={true} />
                                        <AlertControl Message={successMsg} severity="success" color="success" icon={true} />
                                    </Grid>
                                    <Grid item sx={{ mb: 1 }} >
                                        <Paper elevation={0}>
                                            <label htmlFor="NewPassword" className="form-label">New Password*</label>
                                            <input type="password" id="NewPassword" autoComplete="off" name="NewPassword" placeholder="Enter your password here" className="form-control" value={createPwdForm.values.NewPassword} onChange={createPwdForm.handleChange} />
                                            {
                                                createPwdForm.touched.NewPassword && createPwdForm.errors.NewPassword ? (<p className="text-danger">{createPwdForm.errors.NewPassword}</p>) : null
                                            }
                                        </Paper>
                                        {/*<FormControl variant="outlined" sx={{ width: '100%' }}>*/}
                                        {/*<TextField className="" sx={{}} type="password"*/}
                                        {/*        id="NewPassword"*/}
                                        {/*        name="NewPassword"*/}
                                        {/*        label="New password"*/}
                                        {/*        variant="outlined"*/}
                                        {/*        value={createPwdForm.values.NewPassword}*/}
                                        {/*        onChange={createPwdForm.handleChange}*/}
                                        {/*        autoComplete="off"*/}
                                        {/*        margin="dense"*/}
                                        {/*    />*/}
                                        {/*    {*/}
                                        {/*        createPwdForm.touched.NewPassword && createPwdForm.errors.NewPassword*/}
                                        {/*            ? (<p className="text-danger">{createPwdForm.errors.NewPassword}</p>)*/}
                                        {/*            : null*/}
                                        {/*    }*/}
                                        {/*</FormControl>*/}
                                    </Grid>
                                    <Grid item sx={{ mb: 1, pt: 1 }} >
                                        <Paper elevation={0}>
                                            <label htmlFor="ConfirmPassword" className="form-label">Confirm Password*</label>
                                            <input type="password" id="ConfirmPassword" autoComplete="off" name="ConfirmPassword" placeholder="Re-enter your password here" className="form-control" value={createPwdForm.values.ConfirmPassword} onChange={createPwdForm.handleChange} />
                                            {
                                                createPwdForm.touched.ConfirmPassword && createPwdForm.errors.ConfirmPassword ? (<p className="text-danger">{createPwdForm.errors.ConfirmPassword}</p>) : null
                                            }
                                        </Paper>
                                        {/*<FormControl variant="outlined" sx={{ width: '100%' }}>*/}
                                        {/*    <TextField className="" sx={{}} type="password"*/}
                                        {/*        id="ConfirmPassword"*/}
                                        {/*        name="ConfirmPassword"*/}
                                        {/*        label="Confirm password"*/}
                                        {/*        variant="outlined"*/}
                                        {/*        value={createPwdForm.values.ConfirmPassword}*/}
                                        {/*        onChange={createPwdForm.handleChange}*/}
                                        {/*        autoComplete="off"*/}
                                        {/*        margin="dense"*/}
                                        {/*    />*/}
                                        {/*    {*/}
                                        {/*        createPwdForm.touched.ConfirmPassword && createPwdForm.errors.ConfirmPassword*/}
                                        {/*            ? (<p className="text-danger">{createPwdForm.errors.ConfirmPassword}</p>)*/}
                                        {/*            : null*/}
                                        {/*    }*/}
                                        {/*</FormControl>*/}
                                    </Grid>
                                    {/*<Grid item sx={{ pb: 1 }} >*/}
                                    {/*    <Typography sx={{ pl: 2 }} variant="body2" color="text.primary">*/}
                                    {/*        At least 10 characters*/}
                                    {/*    </Typography>*/}
                                    {/*</Grid>*/}
                                    <Grid item sx={{}} >
                                        <FormGroup>
                                            <FormControlLabel control={<Checkbox onChange={handleChange} inputProps={{ 'aria-label': 'controlled' }} />} label={<Typography variant="body2" color="text.primary">Show password</Typography>} />
                                        </FormGroup>
                                    </Grid>
                                    <Grid item sm={12} sx={{}} >
                                        <Box
                                            display="flex"
                                            justifyContent="space-between"
                                            sx={{ pl: 0, pr: 0, pb: 6, pt: 2 }}
                                        >
                                            <Button sx={{ height: 40, textTransform: 'none' }} onClick={ContinueHome} >
                                                <Typography variant="subtitle1" sx={{color: '#002d72'}}>Skip</Typography>
                                            </Button>
                                            <Box sx={{ }}>
                                                <Button type="submit" variant="outlined" size="small" sx={{ padding: '0.4rem 1.5rem', border: 2, borderColor: '#616161' }}>
                                                    <Typography variant="body2" color="#212121" sx={{ textTransform: 'none', fontWeight: 500 }} >Save password</Typography>
                                                </Button>
                                            </Box>
                                            {/*<Button type="submit" variant="contained" size="large" style={{ padding: '0.5rem 2rem', textTransform: 'none', outline: 'none', }}>*/}
                                            {/*    <Typography variant="subtitle1"><strong>Save password</strong></Typography>*/}
                                            {/*</Button>*/}
                                            {/*<Button color="primary" sx={{ height: 40, }}>*/}
                                            {/*    Continue*/}
                                            {/*</Button>*/}
                                        </Box>
                                    </Grid>
                                    </form>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item md={3}>
                        </Grid>
                    </Grid>
                </Paper>
                <Footer />
            </Container>
        </React.Fragment>
    );
}

export default CreatePassword;