import React, { useCallback, useState, useEffect, useMemo, useRef, } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import PostLoginMenu from './PostLoginMenu'
import OverlaySpinner from './Controls/OverlaySpinner';
import { Footer } from './Footer';
import { QueryClient, QueryClientProvider, useInfiniteQuery, } from '@tanstack/react-query';
//import { useSelector, useDispatch } from 'react-redux';
import { CallAPI } from '../global/APIUtils';
import { Virtualizer } from '@tanstack/react-virtual';
import { Grid, Stack, Paper, IconButton, Box, Tooltip, Menu, Typography, MenuItem, InputBase, Card, CardContent, CardMedia, bull, CardActions, Button } from '@mui/material';
import Container from '@mui/material/Container';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import EnrollmentHeader from './EnrollmentHeader'
import { DrawerAppBar } from './AppNavbar'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MaterialReactTable, { MRT_ShowHideColumnsButton, MRT_ToggleGlobalFilterButton, MRT_ToggleFiltersButton, } from 'material-react-table';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import NoteAltIcon from '@mui/icons-material/NoteAlt';
import { IsError } from '../global/APIUtils';
import { ErrorMessages } from '../common/ErrorMessages';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { AlertDialog } from '../global/AlertDialog';
import AlertWithTitle from './Controls/AlertWithTitle';
import AlertControl from './Controls/AlertControl';
import { APICall } from '../global/Api';

const ManageRole = (props) => {

    const location = useLocation();

    const [userCount, setUserCount] = useState('');
    const [errorMsg, setErrorMsg] = useState(null);
    const [successMsg, setSuccessMsg] = useState(null);
    const [successMsgTitle, setSuccessMsgTitle] = useState(null);
    const [rowSelection, setRowSelection] = useState({});
    const [disEditApp, setDisEditApp] = useState(true);
    const [roles, setRoles] = useState(null);
    const [enableDelBtn, setEnableDelBtn] = useState(true);
    const [enableEditBtn, setEnableEditBtn] = useState(true);
    //const dispatch = useDispatch();
    //let sessions = useSelector((state) => state.sessionMgmnt);
    const tableInstanceRef = useRef(null);
    const [expanded, setExpanded] = useState(true);

    const [userId, setUserId] = useState(props.userId);
    const [sessionId, setSessionId] = useState(props.sessionId);

    const [openConfirmDlg, setOpenConfirmDlg] = useState(false);

    const confirmDlgHdr = useRef('');
    const confirmDlgBLine1 = useRef('');
    const confirmDlgBLine2 = useRef('');
    const confirmDlgBLine3 = useRef('');
    const confirmDlgBLine4 = useRef('');
    const confirmDlgBLine5 = useRef('');
    const selectedRoleTypId = useRef(0);

    let navigate = useNavigate();

    const handleAccordianChange = () => {
        if (expanded) {
            setExpanded(false);
        }
        else {
            setExpanded(true);
        }
    };

    const columns = [
        {
            accessorFn: (row) => row.RoleName,
            header: 'Role name',
        },
        {
            accessorFn: (row) => row.RoleDesc,
            header: 'Role description',
        },
        {
            accessorFn: (row) => row.IsAllPrcs,
            header: 'All process',
            Cell: ({ cell, row }) =>
                <React.Fragment>
                    {row.original.IsAllPrcs ? 'Yes' : 'No'}
                </React.Fragment>
        ,
        },
        {
            accessorFn: (row) => row.IsProspect,
            header: 'Guest only',
            Cell: ({ cell, row }) =>
                <React.Fragment>
                    {row.original.IsProspect ? 'Yes' : 'No'}
                </React.Fragment>
        },
        {
            accessorFn: (row) => row.IsSystem,
            header: 'System controlled',
            Cell: ({ cell, row }) =>
                <React.Fragment>
                    {row.original.IsSystem ? 'Yes' : 'No'}
                </React.Fragment>
        },
        {
            accessorFn: (row) => row.CreateUser,
            header: 'Created by',
        },
        {
            accessorFn: (row) => row.ModUser,
            header: 'Last modified by',
        },
        {
            accessorFn: (row) => row.CreateDate,
            header: 'Created at',
        },
        {
            accessorFn: (row) => row.ModDate,
            header: 'Modified at',
        },
    ];

    const fetchSize = 25;

    const tableContainerRef = useRef(null); //we can get access to the underlying TableContainer element and react to its scroll events
    const virtualizerInstanceRef = useRef < Virtualizer > null; //we can get access to the underlying Virtualizer instance and call its scrollToIndex method

    const { data, fetchNextPage, isError, isFetching, isLoading, refetch } =
        useInfiniteQuery({
            queryKey: ['table-data'],
            queryFn: async ({ pageParam = 0 }) => {

                const apiUrl = new URL(
                    process.env.REACT_APP_GET_USER_ROLES,
                    process.env.REACT_APP_BASE_URL,
                );

                apiUrl.searchParams.set('start', `${pageParam * fetchSize}`);
                apiUrl.searchParams.set('size', `${fetchSize}`);

                //let response = await CallAPI({ url: apiUrl.href, method: 'GET', headers: {} }, userId, sessionId);
                let response = await APICall({ url: apiUrl.href, method: 'GET', contentType: 'application/json', responseType: '' }, userId, sessionId);

                if (response !== null && response !== undefined) {
                    return response;
                }
                else {
                    throw Error(ErrorMessages.UnknownError);
                }
            },
            getNextPageParam: (_lastGroup, groups) => groups.length,
            keepPreviousData: true,
            refetchOnWindowFocus: false,
        });

    const flatData = useMemo(
        () => data?.pages.flatMap((page) => page.data) ?? [],
        [data],
    );

    const totalDBRowCount = data?.pages?.[0]?.meta?.totalRowCount ?? 0;
    const totalFetched = flatData.length;
    const fetchMoreOnBottomReached = useCallback(
        (containerRefElement) => {
            if (containerRefElement) {
                const { scrollHeight, scrollTop, clientHeight } = containerRefElement;
                if (
                    scrollHeight - scrollTop - clientHeight < 400 &&
                    !isFetching &&
                    totalFetched < totalDBRowCount
                ) {
                    fetchNextPage();
                }
            }
        },
        [fetchNextPage, isFetching, totalFetched, totalDBRowCount],
    );

    useEffect(() => {
        if (virtualizerInstanceRef.current) {
            virtualizerInstanceRef.current.scrollToIndex(0);
        }
    }, []);

    useEffect(() => {
        fetchMoreOnBottomReached(tableContainerRef.current);
    }, [fetchMoreOnBottomReached]);

    const HandleRowClick = async (row) => {

        tableInstanceRef.resetRowSelection = true;
        //selTempltId.current = row.original.TemplateId;

        setUserCount(row.original.UserCount + ' users assigned');
        if (row.original.UserCount > 0 || row.original.IsSystem == 1 )
            setEnableDelBtn(true);
        else
            setEnableDelBtn(false);

        if (row.original.IsSystem == 1)
            setEnableEditBtn(true);
        else
            setEnableEditBtn(false);      
    }

    const userCountClick = (event) => {
        //setSortMnuAnchorEl(event.currentTarget);
    };

    const newRole = (event) => {
        navigate('/RoleDefinition', { state: { roleTypId: 0, userId: userId, sessionId: sessionId }, replace: true })
        //setSortMnuAnchorEl(event.currentTarget);
    };

    const DeleteRole = async (roleTypId) => {

        const apiUrl = new URL(
            process.env.REACT_APP_DELETE_USER_ROLE,
            process.env.REACT_APP_BASE_URL,
        );

        let roleType = {
            "RoleTypeId": roleTypId
        };

        let headers = {
            'Content-Type': 'application/json'
        };

        try {

            //let response = await CallAPI({ url: apiUrl.href, method: 'POST', headers: headers, body: roleType }, userId, sessionId);
            let response = await APICall({ url: apiUrl.href, method: 'POST', payload: roleType, contentType: 'application/json', responseType: '' }, userId, sessionId);

            if (response != null && response != undefined) {
                let error = IsError(response);

                if (error != 0) {
                    setErrorMsg(response.Errors[0].Message);
                }
                else {                   
                    setSuccessMsg("Role deleted successfully.");
                    refetch();
                }
            }
        }
        catch (err) {
            setErrorMsg(ErrorMessages.UnknownError);
        }
        finally {
        }
    };

    const handleDeleteClick = (table) => {

        if (table.getSelectedRowModel().rows.length > 0) {
            let row = table.getSelectedRowModel().rows[0];
            selectedRoleTypId.current = row.original.RoleTypeId;
            confirmDlgBLine1.current = "Do you want to delete '" + row.original.RoleName + "' role?";
            confirmDlgBLine2.current = '';
            confirmDlgBLine3.current = '';
            confirmDlgBLine4.current = '';
        }
        confirmDlgHdr.current = 'Delete Role';
        setOpenConfirmDlg(true);
    };

    const handleConfirmDialogClose = (nvalue, svalue) => {
        setOpenConfirmDlg(false);
        if (nvalue) {
            //DeleteRole(nvalue, null);
            DeleteRole(nvalue);
        }
    };

    const handleEditClick = (table) => {
        if (table.getSelectedRowModel().rows.length > 0) {
            let row = table.getSelectedRowModel().rows[0];
            //navigate('/RoleDefinition', { state: { roleTypId: row.original.RoleTypeId } });
            navigate('/RoleDefinition', { state: { roleTypId: row.original.RoleTypeId, roleName: row.original.RoleName, roleDesc: row.original.RoleDesc, isAllPrcs: row.original.IsAllPrcs, isProspect: row.original.IsProspect, isSystem: row.original.IsSystem, userId: userId, sessionId: sessionId }, replace: true })
        }
    };

    return (
        <React.Fragment>
            <Container maxWidth="xl" sx={{}}>
                <PostLoginMenu userId={userId} sessionId={sessionId} />
                <Paper className=""
                    sx={{
                        position: 'relative',
                        backgroundColor: '#fff',
                        color: '#fff',
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        pb: 12
                    }}
                >
                    <Grid item>
                        <Box sx={{
                            pr: { xs: 3, md: 2 },
                            pl: { xs: 3, md: 2 }
                        }}>
                            <Accordion spacing={0} elevation={0} expanded={expanded} onChange={handleAccordianChange} sx={{ p: 0, m: 0, width: '100%' }}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header" sx={{ mr: 2 }}>
                                </AccordionSummary>
                                <AccordionDetails sx={{ pr: 3, pl: 3, pb: 0 }}>
                                    <EnrollmentHeader />
                                    <DrawerAppBar userId={userId} sessionId={sessionId} />
                                </AccordionDetails>
                                <Box sx={{ pr: 4, pl: 4 }}>
                                    <Grid item xs container direction="row" spacing={2} sx={{ pt: 0, pb: 0 }}>
                                        <Grid item md={9} sx={{ pr: 10 }} justifyContent="center">
                                            <Box sx={{ pt: 2, pr: 2, ml: 2 }}>
                                                <RoleListBreadcrumb />
                                                <Typography sx={{ mt: 1, pb: 2, fontWeight: 500 }} variant="body1" color="text.secondary">
                                                    The application allows you to assign roles to users. Each role has a set of permissions associated with it. By assigning users to different roles you can control what those users are allowed to do.
                                                    In this screen you can create and manage roles in the system.
                                                </Typography>
                                            </Box>
                                            <Box sx={{ pl: 0, mt: 3, ml: 2 }}>
                                                <Button variant="outlined" size="small" sx={{ padding: '0.4rem 1.7rem', border: 2, borderColor: '#616161' }} onClick={(e) => newRole(e)} >
                                                    <Typography variant="body1" color="#000" sx={{ textTransform: 'none' }} >New role</Typography>
                                                </Button>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Accordion>
                        </Box>
                    </Grid>
                    <Grid item>
                        <Grid item xs container direction="row" spacing={2} sx={{ pt: 3, pb: 3, pl: 7, pr: 7 }}>
                            <Grid item md={12} sx={{}} justifyContent="center">                                    
                                    <Box>
                                        <AlertControl Message={errorMsg} severity="error" color="error" icon={true} />
                                        <AlertWithTitle Message={successMsg} variant="body1" severity="success" color="success" icon={true} Title={successMsgTitle} />
                                    </Box>
                                    <Box sx={{ border: '1px solid #e0e0e0', pb: 3, }}>
                                        <MaterialReactTable
                                            columns={columns}
                                            data={flatData}
                                            displayColumnDefOptions={{
                                                'mrt-row-select': {
                                                    maxSize: 12,
                                                    header: '',
                                                },
                                                'mrt-row-actions': {
                                                    header: '',
                                                    maxSize: 30,
                                                },
                                            }}
                                            muiTablePaperProps={{
                                                sx: { border: '1px solid #000' },
                                            }}
                                            enableColumnActions={false}
                                            enableColumnFilters={false}
                                            enableSorting={false}
                                            enablePagination={false}
                                            enableRowNumbers={false}
                                            enableRowVirtualization
                                            enableHiding={false}
                                            enableColumnOrdering={false}
                                            muiTableBodyRowProps={({ row }) => ({
                                                onClick: () => {
                                                    setRowSelection((prev) => ({
                                                        [row.id]: true,
                                                    }));
                                                    HandleRowClick(row);
                                                },
                                                sx: {
                                                    cursor: 'pointer',
                                                },
                                            })}
                                            positionActionsColumn="last"
                                            enableColumnResizing={false}
                                            enableDensityToggle={false}
                                            enableFullScreenToggle={false}
                                            muiTableBodyCellProps={({ column }) => ({
                                                sx: (theme) => ({
                                                    color: theme.palette.text.primary,
                                                    fontSize: theme.typography.body1,
                                                    borderRight: '1px solid #d5dbdb',
                                                    borderLeft: '1px solid #d5dbdb',
                                                    borderBottom: '1px solid #bdbdbd',
                                                    minHeight: '60px',
                                                    pl: 2, pr: 2
                                                }),
                                            })
                                            }

                                            muiTableHeadCellProps={{
                                                sx: (theme) => ({
                                                    color: '#16191f',
                                                    fontSize: theme.typography.body1,
                                                    borderTop: '1px solid #d5dbdb',
                                                    borderLeft: '1px solid #d5dbdb',
                                                    borderBottom: '1px solid #d5dbdb',
                                                    backgroundColor: '#fafafa',
                                                    fontWeight: 700,
                                                    pt: 2,
                                                    pb: 2
                                                }),
                                            }}

                                            muiTableContainerProps={{
                                                ref: tableContainerRef,
                                                sx: { maxHeight: '700px' },
                                                onScroll: (
                                                    event,
                                                ) => fetchMoreOnBottomReached(event.target),
                                            }}
                                            muiToolbarAlertBannerProps={
                                                isError
                                                    ? {
                                                        color: 'error',
                                                        children: ErrorMessages.UnknownError,
                                                    }
                                                    : undefined
                                            }
                                        renderBottomToolbarCustomActions={() => (
                                            <Box sx={{ mt: 2 }}>
                                                <Typography>
                                                    {
                                                        !isError && data
                                                            ? totalFetched + ' of ' + totalDBRowCount + ' role(s).'
                                                            : ''
                                                    }
                                                </Typography>
                                            </Box>
                                            )}
                                            onRowSelectionChange={setRowSelection}
                                            state={{
                                                isLoading,
                                                showAlertBanner: isError,
                                                showProgressBars: isFetching,
                                                rowSelection,
                                            }}
                                            initialState={{
                                            }}
                                            muiTablePaperProps={{
                                                elevation: 0,
                                            }}
                                            rowVirtualizerProps={{ overscan: 1 }}
                                            localization={{
                                                noRecordsToDisplay: 'No roles to display',
                                                selectedCountOfRowCountRowsSelected: '',
                                            }}
                                            renderTopToolbarCustomActions={({ table }) => {
                                                return (
                                                    <Box sx={{
                                                        display: 'flex', alignItems: 'center', flexDirection: 'row'
                                                    }}>
                                                        <Typography variant="h6" color="text.primary" sx={{ pl: 2, pr: 4, fontWeight: 500, letterSpacing: '-1px' }}>Existing roles</Typography>
                                                        <Button type="button" size="small" sx={{ }} onClick={(e) => userCountClick(e)}>
                                                            <Typography variant="body1" color="text.secondary" sx={{ textTransform: 'none' }} >{userCount}</Typography>
                                                        </Button>
                                                    </Box>
                                                );
                                            }}
                                            renderToolbarInternalActions={({ table }) => {
                                                return (
                                                    <Box>
                                                        <Tooltip arrow title="Edit role">
                                                            <span>
                                                                <IconButton sx={{}} disabled={enableEditBtn} onClick={() => handleEditClick(table)}>
                                                                    <EditIcon width="24px" height="24px" color="#ccc" />
                                                                </IconButton>
                                                            </span>
                                                        </Tooltip>
                                                        <Tooltip arrow title="Delete role">
                                                            <span>
                                                                <IconButton disabled={enableDelBtn} onClick={() => handleDeleteClick(table)}>
                                                                    <DeleteIcon />
                                                                </IconButton>
                                                            </span>
                                                        </Tooltip>
                                                    </Box>
                                                );
                                            }}
                                            tableInstanceRef={tableInstanceRef}
                                        />
                                        </Box>
                                {/*</Stack>*/}
                            </Grid>
                            {/*<Grid item md={4} sx={{pl: 5}}>*/}
                            {/*    <RoleListBreadcrumb />*/}
                            {/*    <Typography sx={{ mt: 4, mb: 4, ml: 3 }} variant="body1" color="text.secondary">*/}
                            {/*        The application allows you to assign roles to users. Each role has a set of permissions associated with it. By assigning users to different roles you can control what those users are allowed to do.*/}
                            {/*        In this screen you can create and manage roles in the system.*/}
                            {/*    </Typography>*/}
                            {/*</Grid>*/}
                        </Grid>
                    </Grid>
                    {
                        openConfirmDlg ?
                            <AlertDialog
                                id="ringtone-menu"
                                keepMounted
                                open={openConfirmDlg}
                                onClose={handleConfirmDialogClose}
                                nvalue={selectedRoleTypId.current}
                                alertheader={confirmDlgHdr.current}
                                bmsgline1={confirmDlgBLine1.current}
                                bmsgline2={confirmDlgBLine2.current}
                                bmsgline3={confirmDlgBLine3.current}
                                bmsgline4={confirmDlgBLine4.current}
                            /> : ''
                    }
                </Paper>
                <Footer />
            </Container>
        </React.Fragment>
    );
};

//const queryClient = new QueryClient();

function RoleManagement() {
    //const RoleManagement = () => (

    const location = useLocation();
    const queryClient = new QueryClient();

    const [userId, setUserId] = useState(location.state.userId || -1);
    const [sessionId, setSessionId] = useState(location.state.sessionId || '');

    return (
        <React.Fragment>
            <QueryClientProvider client={queryClient}>
                <ManageRole userId={userId} sessionId={sessionId} />
            </QueryClientProvider>
        </React.Fragment>
    );
}

function RoleListBreadcrumb() {
    return (
        <React.Fragment >

            <Box sx={{ borderLeft: '4px solid #9e9e9e', mt: 0, pt: 0, pb: 2, pl: 1, backgroundColor: '#fff', borderTop: '0px solid #dee2e6' }}>
                <Typography variant="h5" sx={{ fontWeight: 400, lineHeight: 1.1, pl: 2, textTransform: 'none' }} color="text.primary">Role Management</Typography>
            </Box>

            {/*<Box sx={{ borderLeft: '4px solid #42a5f5', mt: 0, pt: 2, pb: 2, pl: 1, ml: 2, backgroundColor: '#f8f9fa', borderTop: '1px solid #dee2e6', boxShadow: '0 0.125rem 0.25rem rgba(0, 0, 0, 0.075)' }}>*/}
            {/*    <Typography variant="h5" sx={{ fontWeight: 300, lineHeight: 1.1, pl: 1, textTransform: 'none' }} color="text.primary">Role Management</Typography>*/}
            {/*</Box>*/}

            {/*<Box sx={{ mt: 3, pt: 3, pb: 3, ml: 3, pl: 1, backgroundColor: '#f7f7fa'}}>*/}
            {/*    <Typography variant="h4" sx={{ fontWeight: 300, lineHeight: 1.1, pl: 1 }} color="text.primary">Role Management</Typography>*/}
            {/*</Box>*/}
            {/*<Box className="" style={{ backgroundColor: '#f8f8f8', padding: '12px 16px' }}>*/}
            {/*    <Typography className="ps-3" variant="h4" sx={{ fontWeight: 400, lineHeight: 1.1 }} color="text.primary">Form Templates</Typography>*/}
            {/*</Box>*/}
        </React.Fragment>
    );
}

export default RoleManagement;