import React, { useEffect, useState, useRef } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, IconButton, Stack, Grid, Paper, Box, label } from '@mui/material';
import LoadingOverlay from 'react-loading-overlay';
import RiseLoader from 'react-spinners/RiseLoader'
import CloseIcon from '@mui/icons-material/Close';
import AlertControl from '../components/Controls/AlertControl';
import { ErrorMessages } from '../common/ErrorMessages';
import { APICall } from '../global/Api';
import { GetUserCurrentDate, GetOSPlatform, GetBrowserName, GetCountryOfUser } from '../global/Helpers';
import { IsError } from '../global/APIUtils';

LoadingOverlay.propTypes = undefined;

const RecoveryEmailDlg = (props) => {

    const { onClose, open, onChange, enrmntid } = props;
    const [isLoading, setIsLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [verificationCode, setVerificationCode] = useState('');
    const [counter, setCounter] = useState(0);
    const [runTimer, setRunTimer] = useState(true);
    const [resendTextDisp, setResendTextDisp] = useState('block');
    const [resendButtonDisp, setResendButtonDisp] = useState('none');
    const [disableEmail, setDisableEmail] = useState(false);
    const [disableVerifyBut, setDisableVerifyBut] = useState(false);
    const [verifyBlockDisp, setVerifyBlockDisp] = useState('none');
    const [addButDisp, setAddButDisp] = useState('block');
    const [verifyButDisp, setVerifyButDisp] = useState('none');
    const [prevRecoveryMail, setPrevRecoveryMail] = useState('');

    const [userId, setUserId] = useState(props.userId);
    const [sessionId, setSessionId] = useState(props.sessionId);
    const [email, setEmail] = useState(props.emailadrs);

    const handleVerificationCodeChange = (evt) => {
        setVerificationCode(evt.target.value);
    };

    const ResendCode = async (event) => {
        let ret = SendVerificationCode();
        if (ret) {
            displayVerifyBlock();
        }
    }

    const recoveryEmailForm = useFormik({
        initialValues: {
            Email: ''
        },
        validationSchema: yup.object({
            Email: yup.string()
                .email('Invalid enmail address.')
                .max(80, "Recovery Email - must be a string with a maximum length of 80")
                .required('Recovery Email - required.'),
        }),
        onSubmit: values => {
            //setEmail(values.EmailAddress)
            handleSubmit(values);
        }
    });

    const SendVerificationCode = async () => {

        if (prevRecoveryMail === recoveryEmailForm.values.Email) {
            onClose();
            return;
        }

        let browserName = GetBrowserName();
        let country = GetCountryOfUser();
        let os = GetOSPlatform();
        let currentDate = GetUserCurrentDate();

        let inputJson = {
            RecoveryEmail: recoveryEmailForm.values.Email,
            UserBrowserName: browserName,
            UserOS: os,
            UserLocationCountry: country,
            UserLocationDate: currentDate
        };

        setErrorMsg('');

        if (recoveryEmailForm.values.Email == email) {
            setErrorMsg('Recovery email must be diffrent from ' + email);
            return;
        }

        try {

            setIsLoading(true);

            const apiUrl = new URL(
                process.env.REACT_APP_VERIFY_RECOVERY_EMAIL,
                process.env.REACT_APP_BASE_URL,
            );

            let response = await APICall({ url: apiUrl.href, method: 'POST', payload: inputJson, contentType: 'application/json', responseType: '' }, userId, sessionId);

            if (response != null && response != undefined) {

                let error = IsError(response);

                if (error != 0) {
                    setErrorMsg(response.Errors[0].Message);
                }
                else {
                    if (response.meta.EmailSent === "Yes") {
                        return true;
                    }
                    else {
                        setErrorMsg(ErrorMessages.UnknownError);
                    }
                }
            }
        }
        catch (err) {
            setErrorMsg(ErrorMessages.UnknownError);
        }
        finally {
            setIsLoading(false);
        }

        return false;
    }

    const handleSubmit = async (values) => {

        let ret = SendVerificationCode();
        if (ret) displayVerifyBlock();
    }

    const handleClose = (event) => {
        event.preventDefault();
        onClose();
    };

    function setResetCounter() {
        if (runTimer) {
            if (parseInt(counter) == 0) {
                setRunTimer(false);
                setResendTextDisp('none');
                setResendButtonDisp('block');
                setDisableVerifyBut(true);
                return;
            }

            setCounter(counter - 1);
        }
    }

    useEffect(() => {
        const id = setInterval(() => {
            setResetCounter();
        }, 1000);

        return () => {
            clearInterval(id);
        };
    });

    useEffect(() => {
        loadForm();
    }, []);

    const loadForm = async () => {

        const apiUrl = new URL(
            process.env.REACT_APP_GET_USER_PREFERENCE,
            process.env.REACT_APP_BASE_URL,
        );

        try {

            setIsLoading(true);

            let response = await APICall({ url: apiUrl.href, method: 'GET', contentType: 'application/json', responseType: '' }, userId, sessionId);

            if (response !== null && response !== undefined) {
                if (response.data.length > 0) {
                    recoveryEmailForm.values.Email = response.data[0].RecoveryEmail;
                    setPrevRecoveryMail(response.data[0].RecoveryEmail);
                }
            }
        }
        catch (err) {
            setErrorMsg(ErrorMessages.UnknownError);
        }
        finally {
            setIsLoading(false);
        }
    }
    const startCounter = () => {
        setCounter(120);
        setRunTimer(true);
        setResendTextDisp('block');
        setResendButtonDisp('none');
        setDisableVerifyBut(false);
    }

    const displayVerifyBlock = () => {

        setVerifyBlockDisp('block');
        setAddButDisp('none');
        setVerifyButDisp('block');
        setDisableEmail(true);
        startCounter();
    }

    const handleEmailChange = (e) => {
        //setInputEmail(e.target.value);
    };

    const handleVerifyCode = async (event) => {

        let inputJson = {
            RecoveryEmail: recoveryEmailForm.values.Email,
            REmailCode: verificationCode
        };


        setErrorMsg('');

        try {

            setIsLoading(true);

            const apiUrl = new URL(
                process.env.REACT_APP_VERIFY_RECOVERY_EMAIL_CODE,
                process.env.REACT_APP_BASE_URL,
            );

            //let endpoint = process.env.REACT_APP_BASE_URL + process.env.REACT_APP_VERIFY_RESET_CODE;

            let response = await APICall({ url: apiUrl.href, method: 'POST', payload: inputJson, contentType: 'application/json', responseType: '' }, userId, sessionId);

            if (response != null && response != undefined) {

                let error = IsError(response);

                if (error != 0) {
                    setErrorMsg(response.Errors[0].Message);
                    return;
                }

                if (response.meta.Verified === "1") {
                    onChange();
                    onClose();
                }
                else {
                    setErrorMsg(ErrorMessages.UnknownError);
                }
            }
        }
        catch (err) {
            setErrorMsg(ErrorMessages.UnknownError);
        }
        finally {
            setIsLoading(false);
        }
    }

    return (
        <React.Fragment>
        <Dialog onClose={handleClose} open={open} keepMounted>
            <LoadingOverlay active={isLoading} spinner={<RiseLoader color={'#1D50AF'} />} >

                <DialogTitle>
                    Add a recovery email
                    <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                    <form id="forgetPassForm" onSubmit={recoveryEmailForm.handleSubmit} >
                    <DialogContent dividers sx={{ ml: 2, mr: 2 }}>
                        <AlertControl Message={errorMsg} severity="error" color="error" icon={true} />
                        <Grid container>
                            <Grid item md={12} sx={{}}>
                                <Typography sx={{ fontWeight: 400 }} gutterBottom variant="body1" color="text.primary">
                                    We'll send a code to this email if you ever trouble signing in.
                                </Typography>
                                <Typography sx={{ fontWeight: 400 }} gutterBottom variant="caption" color="text.primary">
                                    Recovery email must be diffrent from {email}
                                </Typography>
                            </Grid>
                            <Grid item md={10} sx={{ pt: 4, pb: 4 }}>
                                <label htmlFor="Email" className="form-label">Recovery Email*</label>
                                <input type="email" disabled={disableEmail} id="Email" name="Email" autoComplete="off" placeholder="Enter recovery email here" className="form-control" value={recoveryEmailForm.values.Email} onChange={recoveryEmailForm.handleChange} />
                                {
                                    recoveryEmailForm.touched.Email && recoveryEmailForm.errors.Email ? (<p className="text-danger">{recoveryEmailForm.errors.Email}</p>) : null
                                }
                            </Grid>
                                <Grid item md={11} sx={{ pb: 2, display: verifyBlockDisp }}>
                                    <Typography variant="body1" color="text.primary" sx={{ pb: 1, fontWeight: 400 }}>
                                        Enter code that is sent to your above email.
                                    </Typography>
                                <Paper elevation={0}>
                                    <label htmlFor="ResetCode" className="form-label">Verification Code*</label>
                                    <input type="text" id="ResetCode" name="ResetCode" autoComplete="off" placeholder="Enter code here" className="form-control" value={verificationCode} onChange={handleVerificationCodeChange} />
                                </Paper>
                                <Typography variant="body1" color="text.primary" sx={{ pt: 1, display: resendTextDisp }}>
                                    Resend code in {counter}
                                </Typography>
                                <Box sx={{ pt: 1, display: resendButtonDisp }}>
                                    <Button sx={{ textTransform: 'none' }} onClick={(e) => ResendCode(e)}>
                                        <Typography variant="body1" sx={{ color: '#002d72' }}>
                                            Resend code
                                        </Typography>
                                    </Button>
                                </Box>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions sx={{ mr: 2 }}>
                        <Button autoFocus onClick={handleClose}>
                            Cancel
                        </Button>
                        <Button sx={{ display: addButDisp }} type="submit">Add</Button>
                            <Button sx={{ display: verifyButDisp }} disabled={disableVerifyBut} onClick={handleVerifyCode}>Verify</Button>
                    </DialogActions>
                </form>
                </LoadingOverlay>
            </Dialog>
        </React.Fragment>
    );
}

export default RecoveryEmailDlg;
