////import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { CookiesProvider } from "react-cookie";
import App from './App';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
//const rootElement = document.getElementById('root');


const rootElement = ReactDOM.createRoot(document.getElementById('root'));

rootElement.render(
    <CookiesProvider>
        <BrowserRouter basename={baseUrl}>
            <App />
        </BrowserRouter>
    </CookiesProvider>
);


