import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { Logout } from '../global/Logout';
import { Button, Link, Typography, Paper, Box, Stack, CardMedia } from '@mui/material';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import { grey } from '@mui/material/colors';
import productlogo from '../assets/images/Settlemen2.jpg';
import clientlogo from '../assets/images/banklogo.jpg';

export default function EnrollmentHeader() {

    return (
        <React.Fragment>
            <Toolbar disableGutters={true} sx={{ borderBottom: 1, borderColor: '#d5dbdb', justifyContent: 'flex-start', backgroundColor: '#fff', pb: 1 }}>

                {/*<Box sx={{ ml: 1, pt: 1, pb: 1 }}>*/}
                {/*    <img src={productlogo} height="50" loading="lazy" />*/}
                {/*</Box>*/}

                {/*<Box sx={{ pl: 0,  }}>*/}
                {/*    <Stack direction="column" sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block', } }}>*/}
                {/*        <Stack direction="row" sx={{}}>*/}
                {/*            <Typography variant="h2" component="h3" color="text.primary" sx={{ fontWeight: 400, textTransform: 'none', letterSpacing: '-1px', lineheight: 1.2 }}>File a Case</Typography>*/}
                {/*        </Stack>*/}
                {/*    </Stack>*/}
                {/*</Box>*/}
                <Box sx={{ display: 'flex', alignItems: 'center', pr: 0 }}>
                    <img src={productlogo} height="55" loading="lazy" />
                </Box>
            </Toolbar>

        </React.Fragment>
    );
};