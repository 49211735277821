import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { useFormik } from 'formik';
import * as yup from 'yup';
import { PreLoginMenu } from './PreLoginMenu';
//import AlertText from './Controls/AlertText';
//import LoginFormRow1 from './LoginFormRow1';
//import LoginFormRow2 from './LoginFormRow2';
//import LoginFormRow3 from './LoginFormRow3';
//import { ApiHelper } from '../customFunctions/apiHelper';
import { Typography, Link, Stack, Toolbar, Box, Alert, FormControl, TextField } from '@mui/material';
import Container from '@mui/material/Container';
import { Paper } from '@mui/material';
import OverlaySpinner from './Controls/OverlaySpinner';
import Button from '@mui/material/Button';
import { Footer } from './Footer';
import Grid from '@mui/material/Grid';
//import pwdimg from '../assets/images/176432.jpg';
//import Image from 'material-ui-image'
import { CallServerAPI, IsError } from '../global/APIUtils';
import AlertControl from './Controls/AlertControl';
import { ErrorMessages } from '../common/ErrorMessages';
//import { ReactComponent as ReactLogo } from '../assets/images/ssb-horizontal-new.svg';
import { ServerAPICall } from '../global/Api';

const ForgetPassword = () => {

    const [isLoading, setIsLoading] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [email, setEmail] = useState('');

    let navigate = useNavigate();

    const forgetPwdForm = useFormik({
        initialValues: {
            EmailAddress: ''
        },
        validationSchema: yup.object({
            EmailAddress: yup.string()
                .email('Email - invalid expression.')
                .max(80, "Email - must be a string with a maximum length of 80")
                .required('Email Address - required.'),
        }),
        onSubmit: values => {
            setEmail(values.EmailAddress)
            handleSubmit(values);           
        }
    });

    const handleSubmit = async (values) => {
        //API Call

        let resetPwdModel = {
            EmailAddress: values.EmailAddress
        };

        setErrorMsg('');

        const apiUrl = new URL(
            process.env.REACT_APP_FORGET_PASSWORD,
            process.env.REACT_APP_BASE_URL,
        );

        try {
            setIsLoading(true);

            //let endpoint = process.env.REACT_APP_BASE_URL  + process.env.REACT_APP_FORGET_PASSWORD;

            //let response = await CallServerAPI({ url: apiUrl.href, method: 'POST', headers: headers, body: resetPwdModel });

            let response = await ServerAPICall({ url: apiUrl.href, method: 'POST', payload: resetPwdModel, contentType: 'application/json', responseType: '' });

            if (response != null && response != undefined) {

                let error = IsError(response);

                if (error != 0) {
                    setErrorMsg(response.Errors[0].Message);
                }
                else {
                    if (response.meta.EmailSent === "Yes") {
                        navigate('/VerifyResetCode', { state: { emailAddress: values.EmailAddress, }, replace: true  });
                    }
                    else {
                        setErrorMsg(ErrorMessages.UnknownError);
                    }
                }
            }
        }
        catch (err) {
            setErrorMsg(ErrorMessages.UnknownError);
        }
        finally {
            setIsLoading(false);
        }
    }

    //const resendEmail = (event) => {
    //    event.preventDefault();
    //    setSubmitting(false);

    //    let emailModel = {
    //        EmailAddress: email
    //    };

    //    handleSubmit(emailModel);
    //}

    const login = (event) => {
        event.preventDefault();
        navigate('/AppLogin', { state: {}, replace: true });
    }

    //const register = (event) => {
    //    event.preventDefault();
    //    history.push({
    //        pathname: '/Registration'
    //    })
    //}

    return (
        <React.Fragment>

            <Container maxWidth="xl">
                <OverlaySpinner disappear={isLoading} />
                <PreLoginMenu />
                <Paper
                    sx={{
                        position: 'relative',
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        pb: { xs: 7, sm: 10 },
                    }}
                >
                    <Toolbar sx={{ borderBottom: 1, borderColor: 'divider', justifyContent: 'space-between', backgroundColor: '#fff', pt: 0, pb: 0 }}>

                        {/*<Box sx={{ display: 'flex', alignItems: 'center', ml: 1, mt: 4, mb: 1 }}>*/}
                        {/*    <ReactLogo />*/}
                        {/*</Box>*/}
                    </Toolbar>
                    <Grid container justifyContent="center" spacing={0} sx={{ pt: 7,  }}>
                        <Grid item md={6} sx={{ pr: 2, }} justifyContent="center">
                            <Box sx={{ mx: 'auto', border: 0, borderColor: '#16191f', borderRadius: '6px', pt: 4, pb: 4}}>
                                <Typography variant="h4" color="text.primary" sx={{ fontWeight: 400, mb: 3 }}>Forgot your password?</Typography>
                                <AlertControl Message={errorMsg} severity="error" color="error" icon={true} />
                                <Typography variant="body1" color="text.secondary" sx={{ pt: 1, fontWeight: 500}}>
                                    Enter your registered email address and we'll send you an email with instructions to reset your password.
                                </Typography>
                                <Box sx={{ pt: 3, pr: 5 }}>
                                    <form onSubmit={forgetPwdForm.handleSubmit} id="forgetPassForm">
                                        <Paper elevation={0}>
                                            <label htmlFor="EmailAddress" className="form-label">Email Address*</label>
                                            <input type="text" id="EmailAddress" name="EmailAddress" autoComplete="off" placeholder="Enter your email address here" className="form-control" value={forgetPwdForm.values.EmailAddress} onChange={forgetPwdForm.handleChange} />
                                            {
                                                forgetPwdForm.touched.EmailAddress && forgetPwdForm.errors.EmailAddress ? (<p className="text-danger">{forgetPwdForm.errors.EmailAddress}</p>) : null
                                            }
                                        </Paper>
                                        <Box sx={{ mt: 5 }}>
                                            <Button type="submit" variant="outlined" size="small" sx={{ padding: '0.4rem 1.5rem', border: 2, borderColor: '#616161' }}>
                                                <Typography variant="body2" color="#212121" sx={{ textTransform: 'none', fontWeight: 500 }} >Reset Password</Typography>
                                            </Button>
                                        </Box>
                                        <Box sx={{ pt: 3 }}>
                                            <Button onClick={login} sx={{ textTransform: 'none', color: '#002d72'}}>Back to Login</Button>
                                        </Box>
                                    </form>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item md={2}>
                        </Grid>
                    </Grid>
                </Paper>
                <Footer />
            </Container>
        </React.Fragment>
    );
}

export default ForgetPassword;