import React, { useEffect, useState, useRef } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, IconButton, Grid, FormControl } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import AlertControl from '../components/Controls/AlertControl';
import { ErrorMessages } from '../common/ErrorMessages';
import { LoadProfilePic } from '../global/Helpers';
import profimg from '../assets/images/profimg.jpg';
import { CallAPIUsingAxios } from '../global/APIUtils';
import LoadingOverlay from 'react-loading-overlay';
import RiseLoader from 'react-spinners/RiseLoader'
import { GetApplicantImage } from '../global/EnrollmentHelper';
import { APICall } from '../global/Api';

const EnrollmentImageUpldDlg = (props) => {

    const { onClose, open, onChange, enrmntid } = props;
    const [invalidImage, setInvalidImage] = useState(true);
    const [errorMsg, setErrorMsg] = useState('');
    const [imageUrl, setImageUrl] = useState(null);
    const [picUpload, setPicUpload] = useState(null);
    const [isOkBtnEnabled, setIsOkBtnEnabled] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [imageFileName, setImageFileName] = useState(null);

    const [userId, setUserId] = useState(props.userId);
    const [sessionId, setSessionId] = useState(props.sessionId);

    const handleClose = (event) => {
        event.preventDefault();
        onClose();
    };

    const handleOk = async (event) => {
        event.preventDefault();

        if (!invalidImage) {

            try {

                setIsLoading(true);

                const apiUrl = new URL(
                    process.env.REACT_APP_UPLOAD_APPLICANT_IMAGE,
                    process.env.REACT_APP_BASE_URL,
                );

                const imageFormData = new FormData();
                imageFormData.append("enrmntId", parseInt(props.enrmntid));
                imageFormData.append("ImageFile", picUpload);
                imageFormData.append("FileName", imageFileName);
                imageFormData.append("CntrlNm", "ApplicantImage");

                //let response = await CallAPIUsingAxios({ url: apiUrl.href, method: 'POST', formData: imageFormData }, userId, sessionId);

                let response = await APICall({ url: apiUrl.href, method: 'POST', payload: imageFormData, contentType: 'multipart/form-data', responseType: '' }, userId, sessionId);

                if (response !== null && response !== undefined) {
                    onChange();
                }
            }
            catch (err) {

                if (err.response.headers.aoberrorcode == 8 ||
                    err.response.headers.aoberrorcode == 29)
                {
                    setErrorMsg(err.response.headers.aobmessage);
                }
                else {
                    setErrorMsg(ErrorMessages.UnknownError);
                }
            }
            finally {
                setIsLoading(false);
            }
        }
        else if (!imageUrl) {
            setErrorMsg('Please select a valid image file.');
        }
    };

    const onChangeFile = (event) => {
        const imageFile = event.target.files[0];

        const reader = new FileReader();

        setErrorMsg('');
        if (!imageFile) {
            setErrorMsg("Please select an image.");
            setInvalidImage(true);
            return false;
        }

        if (!imageFile.name.match(/\.(jpg|jpeg|png)$/)) {
            setErrorMsg("Please select a valid image file.");
            setInvalidImage(true);
            return false;
        }

        if (imageFile.type !== 'image/jpeg' && imageFile.type !== 'image/png') {
            setErrorMsg("Please select a valid image file.");
            setInvalidImage(true);
            return false;
        }

        if (Math.floor(imageFile.size / 1048576) > 2) {   /* 2MB */
            setErrorMsg("File size exceeds the limit allowed (2MB) and cannot be uploaded.");
            setInvalidImage(true);
            event.preventDefault();
            return false;
        }

        reader.onload = (e) => {
            const img = new Image();
            img.onload = () => {
                setPicUpload(imageFile);
                setInvalidImage(false);
            };
            img.onerror = () => {
                setErrorMsg("Invalid image content.");
                setInvalidImage(true);
                return false;
            };

            setImageUrl(e.target.result);
            img.src = e.target.result;
        };
        reader.readAsDataURL(imageFile);
        setImageFileName(imageFile.name);
        setIsOkBtnEnabled(true);
    }

    return (
        <Dialog onClose={handleClose} open={open} keepMounted>
            <LoadingOverlay active={isLoading} spinner={<RiseLoader color={'#1D50AF'} />} >
                <DialogTitle>
                    Applicant photo
                    <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent dividers sx={{ ml: 2, mr: 2 }}>
                    <AlertControl Message={errorMsg} severity="error" color="error" icon={true} />
                    <Grid container>
                        <Grid item md={11} sx={{}}>
                            <Typography sx={{ fontWeight: 400 }} gutterBottom variant="body1" color="text.primary">
                                Please choose a photo that’s less than 2MB size, and format should be PNG,JPG,JPEG
                            </Typography>
                        </Grid>
                        <Grid item md={11} sx={{ pt: 1 }}>
                            <Typography sx={{ mb: 1 }} variant="body1" color="text.primary"><strong>Preview</strong></Typography>
                        </Grid>
                        <Grid item md={11} sx={{ pt: 1 }}>
                            {imageUrl ?
                                <img src={imageUrl} alt="Prifile Picture" className="prof-pic-prvw" />
                                :
                                <img src={profimg} alt="Prifile Picture" className="prof-pic-prvw" />
                            }
                        </Grid>
                        <Grid item md={11} sx={{ pt: 3, pb: 2 }}>
                            <FormControl variant="outlined">
                                <input type="file" className="prof-pic-upld-ctrl" id="prof-pic-upld" onChange={onChangeFile} accept=".jpg,.jpeg,.png" />
                            </FormControl>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions sx={{ mr: 2 }}>
                    <Button autoFocus onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button onClick={handleOk} disabled={!isOkBtnEnabled}>Upload</Button>
                </DialogActions>
            </LoadingOverlay>
        </Dialog>
    );
}

export default EnrollmentImageUpldDlg;
