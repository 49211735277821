import * as $ from 'jquery';
import React, { Component, createRef, useRef, useEffect, useState, Card, CardMedia } from "react";
//import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from "react-router-dom";
//import 'reactjs-popup/dist/index.css';
import { CallAPI } from '../global/APIUtils';
//import BreadCumControl from './Controls/BreadcumControl';
import OverlaySpinner from './Controls/OverlaySpinner';
import PostLoginMenu from './PostLoginMenu'
//import { MainNavMenu } from './MainNavMenu'
import { Box, Typography, Paper, Grid, Button, Divider, Stack, Tooltip, Link } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Footer } from './Footer';
//import './Modal.css';
//import { NavMenu } from './NavMenu';
//import './TemplateDesignPreview.css';
import Container from '@mui/material/Container';
import EnrollmentHeader from './EnrollmentHeader';
import { DrawerAppBar } from './AppNavbar';
//import pwdimg from '../assets/images/124567.jpg';
//import Image from 'material-ui-image'
import { IsError } from '../global/APIUtils';
import { ErrorMessages } from '../common/ErrorMessages';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import AlertControl from './Controls/AlertControl';
import AlertWithTitle from './Controls/AlertWithTitle';
import { TemplateStatus } from '../common/AOBEnum';
import { goToTop } from '../global/Helpers'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { useNavigate } from "react-router-dom";
import LensIcon from '@mui/icons-material/Lens';
import profimg from '../assets/images/profimg.jpg';
import { APICall } from '../global/Api';

window.jQuery = $;
window.$ = $;

require("jquery-ui-sortable");
require("formBuilder");

require('formBuilder/dist/form-render.min.js')


const TemplateDesigner = (props) => {

    const fb = useRef(null);
    let formBuilder = useRef(null);
    //let sessions = useSelector((state) => state.sessionMgmnt);
    //const dispatch = useDispatch();
    const [errorMsg, setErrorMsg] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [expanded, setExpanded] = useState(true);
    const location = useLocation();
    const [openPreview, setOpenPreview] = React.useState(false);
    const [templateId, setTemplateId] = useState(location.state.templateid || -1);
    const [successMsg, setSuccessMsg] = useState(null);
    const [successMsgTitle, setSuccessMsgTitle] = useState(null);

    const [userId, setUserId] = useState(location.state.userId || -1);
    const [sessionId, setSessionId] = useState(location.state.sessionId || '');

    let navigate = useNavigate();

    const handlePreviewClose = () => {
        setOpenPreview(false);
    };

    const typeUserAttrs = {
        text: {
            //className: colStyleClass,
            maxlength: 20000,
            onclick: {
                label: 'onClick Event',
                value: '',
                type: 'textarea'
            },
            onkeyup: {
                label: 'onKeyup Event',
                value: '',
                type: 'textarea'
            },
            onload: {
                label: 'onLoad Event',
                value: '',
                type: 'textarea'
            },
            disabled: {
                label: 'Disabled',
                value: false,
                type: 'checkbox'
            }
        },
        select: {
            onchange: {
                label: 'onChange Event',
                value: '',
                type: 'textarea'
            }
        },
        file: {
            //className: colStyleClass,
            disabledAttrs: [
                //'multiple',
                'subtype',
                'access'//,            
            ],
            'maxfilesize': {
                'label': 'Max File Size (MB)',
                'value': '4',
                'type': 'number'
            }
        },
        button: {
            //className: colStyleClass,
        },
        'checkbox-group': {
            onclick: {
                label: 'onClick Event',
                value: '',
                type: 'textarea'
            },
            onchange: {
                label: 'onChange Event',
                value: '',
                type: 'textarea'
            },
            onload: {
                label: 'onLoad Event',
                value: '',
                type: 'textarea'
            }
        },
        number: {
            //className: colStyleClass,
        },
        //header: {
        //    className: '',
        //    title: {
        //        label: 'Title',
        //        value: 'Field Title',
        //    }
        //},
        "radio-group": {
            onchange: {
                label: 'onChange Event',
                value: '',
                type: 'textarea'
            },
        },
        textarea: {
            //className: colStyleClass,
        },
        date: {
            //className: colStyleClass,
        }
    };
    const typeUserDisabledAttrs = {
        'file': [
            'subtype',
            'type',
            'access'
        ]
    }

    const stickyControl = {
        stickyControls: {
            enable: true,
            offset: {
                top: 20,
                right: 20,
                left: 'auto'
            }
        }
    }

    const loadDesigner = async () => {

        try {

            setIsLoading(true);

            goToTop();

            const apiUrl = new URL(
                process.env.REACT_APP_GET_TEMPLATE_DESIGNER_DETAIL,
                process.env.REACT_APP_BASE_URL,
            );
            apiUrl.searchParams.set('templateId', templateId);

            //let response = await CallAPI({ url: apiUrl.href, method: 'GET', headers: {} }, userId, sessionId);

            let response = await APICall({ url: apiUrl.href, method: 'GET', contentType: 'application/json', responseType: '' }, userId, sessionId);

            if (response !== null && response !== undefined) {

                PopulateDesigner(response, templateId);
                setIsLoading(false);
            }
        }
        catch (err) {
            setErrorMsg(ErrorMessages.UnknownError);
            setIsLoading(false);
        }
        finally {
            
        }
        return;
    }

    const publishTemplate = async (templateStsId) => {

        let result = await saveData(templateStsId);

        if (result == true) {
            let templateNm = document.getElementById('txtTemplateName').value;
            setSuccessMsgTitle('Template published');
            setSuccessMsg('The template named as \'' + templateNm + '\' published successfully');
        }

    }

    const saveTemplate = async (templateStsId) => {

        let result = await saveData(templateStsId);

        if (result == true) {
            let templateNm = document.getElementById('txtTemplateName').value;
            setSuccessMsgTitle('Template saved');
            setSuccessMsg('The template named as \'' + templateNm + '\' saved successfully');
        }

    }

    const saveData = async (templateStsId) => {

        setErrorMsg(null);
        setSuccessMsgTitle(null);
        setSuccessMsg(null);

        const templateNmContent = document.getElementById('txtTemplateName');
        const templateDescContent = document.getElementById('txtTemplateDesc');
        const isPhotoReqContent = document.getElementById('chkIsPhotoReq');

        let templateNm = templateNmContent.value;
        let templateDesc = templateDescContent.value;
        let isPhotoReq = isPhotoReqContent.checked;

        if (templateNm === '' || templateNm === null) {
            setErrorMsg('You need to give a name to this template');
            templateNmContent.focus();
            return false;
        }

        let headers = {
            'Content-Type': 'application/json'
        };

        try {

        const result = formBuilder.current.actions.save();
        const inputJson = {
            TemplateId: parseInt(templateId),
            TemplateName: templateNm,
            TemplateDesc: templateDesc,
            TemplateValue: formBuilder.current.formData,
            TemplateStatusID: templateStsId,
            IsPhotoReq: isPhotoReq
        };

        const apiUrl = new URL(
            process.env.REACT_APP_SAVE_TEMPLATE,
            process.env.REACT_APP_BASE_URL,
        );


            setIsLoading(true);
            //let response = await CallAPI({ url: apiUrl.href, method: 'POST', headers: {}, body: inputJson }, userId, sessionId);

            let response = await APICall({ url: apiUrl.href, method: 'POST', payload: inputJson, contentType: 'application/json', responseType: '' }, userId, sessionId);

            if (response !== null && response !== undefined) {

                setIsLoading(false);

                let error = IsError(response);

                if (error != 0) {
                    setErrorMsg(response.Errors[0].Message);
                    return false;
                }

                let templateID = response["data"][0].TemplateID;
                setTemplateId(templateID);

                return true;
            }
        }
        catch (err) {
            setErrorMsg(ErrorMessages.UnknownError);
            setIsLoading(false);
        }
        finally {
            
        }

        return false;
    }

    const previewForm = () => {
        setOpenPreview(true);
    }

    async function PopulateDesigner(response) {

        let hasSavedData = parseInt(templateId) > 0;

        let controlOrder = response.data[0].ControlOrder;
        let hideControl = response.data[0].HideControlList;
        let formData = hasSavedData ? response.data[0].TemplateDetails[0].TemplateValue : response.data[0].DefaultControls;
        let templateData = response.data[0].InputSet;
        let templateNm = hasSavedData ? response.data[0].TemplateDetails[0].TemplateName : '';
        let templateDesc = hasSavedData ? response.data[0].TemplateDetails[0].TemplateDesc : '';
        let isPhotoReq = hasSavedData ? response.data[0].TemplateDetails[0].IsPhotoReq : false;
        let isChecked = isPhotoReq ? 'checked' : '';

        let templateCntrlDivStrt = '<div class="template-design">'
        let templateLabelCntrl = '<label class="template-name">Template Name<span class="required-asterisk" style="display:inline"> *</span></label>'
        let templateNameCntrl = '<input type="text" id="txtTemplateName" required class="form-control" placeholder="Template Name" autoComplete="off" value="' + templateNm + '" />'
        let templateDescLabelCntrl = '<label class="template-desc">Description</label>'
        let templateDescCntrl = '<textarea id="txtTemplateDesc" class = "form-control" rows = "2" placeholder = "Description" autoComplete = "off" >' + templateDesc + '</textarea>'
        //let isPhotoReqCntrl = '<input type="checkbox" id="chkIsPhotoReq" class = "form-control form-check" rows = "3" placeholder = "Description" value="' + isPhotoReq + '">Is Applicant Photo Required?</checkbox>'
        let isPhotoReqCntrl = '<div class="form-check" style="padding-top: 8px;"><input class="form-check-input" type="checkbox" value="" id="chkIsPhotoReq" ' + isChecked +'><label class="form-check-label" for="chkIsPhotoReq"> Requires Applicant Photo </label></div>';

        let options = {
            inputSets: templateData,
            controlOrder: controlOrder,
            disableFields: hideControl,
            prepend: templateCntrlDivStrt + templateLabelCntrl + templateNameCntrl + templateDescLabelCntrl + templateDescCntrl + isPhotoReqCntrl,// + templateCntrlDivEnd,
            stickyControls: stickyControl.stickyControls,
            typeUserAttrs: typeUserAttrs,
            typeUserDisabledAttrs: typeUserDisabledAttrs,
            disableHTMLLabels: true,
            fieldRemoveWarn: true,
            scrollToFieldOnAdd: true,
            stickyControls: {
                enable: true
            },
            disabledAttrs: [
                'access'//,
            ],
            i18n: {
                location: process.env.REACT_APP_I18N_LANG
            },
            formData: formData,
            disabledActionButtons: ['clear', 'data', 'save'],
        };

        formBuilder.current = $(fb.current).formBuilder(options);

    }
    useEffect(() => {
        loadDesigner();
    }, []);

    const handleAccordianChange = () => {
        if (expanded) {
            setExpanded(false);
        }
        else {
            setExpanded(true);
        }
    }

    const handleBackClick = () => {

        navigate('/TemplateList', { state: { userId: userId, sessionId: sessionId }, replace: true });
    }

    const GetTemplateName = () => {

        const templateNmContent = document.getElementById('txtTemplateName');

        let templateNm = templateNmContent ? 'APPLICATION FORM TEMPLATE - ' + templateNmContent.value : 'APPLICATION FORM TEMPLATE';

        return templateNm;
    }

    return (
        <React.Fragment>
            <Container maxWidth="xl" sx={{}}>
                <OverlaySpinner disappear={isLoading} />
                <PostLoginMenu userId={userId} sessionId={sessionId} />
                <Paper elevation={0} className=""
                    sx={{
                        position: 'relative',
                        backgroundColor: '#fff',
                        color: '#fff',
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        pb: 12
                    }}
                >
                    <Grid item>
                        <Box sx={{
                            pr: { xs: 3, md: 2 },
                            pl: { xs: 3, md: 2 }
                        }}>
                            <Accordion spacing={0} elevation={0} expanded={expanded} onChange={handleAccordianChange} sx={{ p: 0, m: 0, width: '100%' }}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header" sx={{ mr: 2 }}>
                                </AccordionSummary>
                                <AccordionDetails sx={{ pr: 3, pl: 3, pb: 0 }}>
                                    <EnrollmentHeader />
                                    <DrawerAppBar userId={userId} sessionId={sessionId} />
                                </AccordionDetails>
                                <Box sx={{ pr: 4, pl: 4 }}>
                                    <Grid item xs container direction="row" spacing={2} sx={{ pt: 0, pb: 0 }}>
                                        <Grid item md={9} sx={{ pr: 10, }} justifyContent="center">
                                            <Box sx={{ pt: 2, pr: 2, ml: 2 }}>
                                                <TemplateDesignerBreadcrumb text={(props.templateid !== null && props.templateid !== undefined) ? "Edit Template" : "New Template"} />
                                                <Typography sx={{ mt: 1, pb: 2, fontWeight: 500 }} variant="body1" color="text.secondary">
                                                    Create Account Form by using this account form designer. Use it to design, create,
                                                    and update account openning forms. This utility gives access to a drawing canvas
                                                    upon which you construct your forms, a design toolbar from which you access
                                                    controls and layout objects, and a properties view that you use to set
                                                    attributes for each control or layout object.
                                                </Typography>
                                            </Box>
                                            {/*<Box>*/}
                                            {/*    <Tooltip arrow title="Back" onClick={() => handleBackClick()}>*/}
                                            {/*        <span>*/}
                                            {/*            <IconButton>*/}
                                            {/*                <KeyboardBackspaceIcon sx={{ fontSize: 36 }} color="#ccc" />*/}
                                            {/*            </IconButton>*/}
                                            {/*        </span>*/}
                                            {/*    </Tooltip>*/}
                                            {/*</Box>*/}
                                        </Grid>
                                        {/*<Grid item md={5}>*/}
                                        {/*    <Grid container>*/}
                                        {/*        <Grid item md={12} sx={{ pl: 2 }} >*/}
                                        {/*            <Image src={pwdimg} aspectRatio={1.9} />*/}
                                        {/*        </Grid>*/}
                                        {/*    </Grid>*/}
                                        {/*</Grid>*/}
                                    </Grid>
                                </Box>
                            </Accordion>
                        </Box>
                    </Grid>
                    {/*<Box sx={{ pl: { xs: 3, md: 5 }, pr: { xs: 3, md: 6 }, pt: 0,}}>*/}
                    {/*    <Divider variant="div" sx={{ background: 'rgba(0, 0, 0, 0.5)', }} />*/}
                    {/*</Box>*/}
                    <Grid item>
                        <Box alignItems="center" sx={{ mr: { xs: 3, md: 7 }, pb: 0, pt: 4, pl: 7, display: 'flex', justifyContent: 'space-between' }}>
                            <Stack direction="row" spacing={2} alignItems="center">
                                <Box>
                                    <Tooltip arrow title="Back" onClick={() => handleBackClick()}>
                                        <span>
                                            <IconButton>
                                                <KeyboardBackspaceIcon sx={{ fontSize: 36 }} color="#ccc" />
                                            </IconButton>
                                        </span>
                                    </Tooltip>
                                </Box>
                                <Box>
                                    <Stack direction="row" spacing={2} alignItems="center">
                                        <Button variant="outlined" size="small" sx={{ padding: '0.3rem 2.5rem', border: 1, borderColor: '#545b64' }} onClick={() => saveTemplate(TemplateStatus.InProgress)}>
                                        <Typography variant="body1" color="#000" sx={{ textTransform: 'none' }} >Save</Typography>
                                    </Button>
                                        <Button variant="outlined" size="small" sx={{ padding: '0.3rem 2rem', border: 1, borderColor: '#545b64' }} onClick={() => publishTemplate(TemplateStatus.Published)}>
                                            <Typography variant="body1" color="#000" sx={{ textTransform: 'none' }} >Publish</Typography>
                                        </Button>
                                        
                                    </Stack>
                                </Box>
                            </Stack>
                            <Box alignItems="center">
                                <Button variant="outlined" size="small" sx={{ padding: '0.3rem 2rem', border: 1, borderColor: '#545b64' }} onClick={() => previewForm()}>
                                    <Typography variant="body1" color="#000" sx={{ textTransform: 'none' }} >Preview</Typography>
                                </Button>
                                
                            </Box>
                        </Box>
                        <Box sx={{ ml: { xs: 3, md: 5 }, mr: { xs: 3, md: 5 }, }}>
                            <AlertControl Message={errorMsg} severity="error" color="error" icon={true} />
                            <AlertWithTitle Message={successMsg} variant="body1" severity="success" color="success" icon={true} Title={successMsgTitle} />
                        </Box>
                    </Grid>
                    <Grid item>
                        <Paper elevation={1} sx={{
                            mr: { xs: 3, md: 7 },
                            ml: { xs: 3, md: 7 },
                            //pr: { xs: 3, md: 6 },
                            //pl: { xs: 3, md: 5 },
                            pt: 4, pb: 8,
                            borderTop: '1px solid #d5dbdb', borderRadius: '6px', boxShadow: '0 1px 3px 0 rgba(0,0,0,.3), 0 0 0 1px rgba(0,0,0,.04)'
                        }}>
                            <Box sx={{ borderBottom: '1px solid #d5dbdb', pb: 2, pl: 2, pr: 2, display: 'flex', justifyContent: 'space-between'}}>
                                <Typography variant="h6" color="text.primary" sx={{ textTransform: 'none', fontWeight: 500, pl: 4 }} >APPLICATION FORM TEMPLATE</Typography>
                                {/*<Stack direction="column">*/}
                                    {/*<Typography variant="body1" color="text.secondary" sx={{ textTransform: 'none', textAlign: 'right' }} >Control panel</Typography>*/}
                                    <Typography variant="caption" color="text.secondary" sx={{ textTransform: 'none', fontStyle: 'italic', pt: 2 }} >Drag a control from control panel to the left design panel</Typography>
                               {/* </Stack>*/}
                            </Box>
                            <Typography variant="body1" color="text.secondary" sx={{ textTransform: 'none', textAlign: 'right', pt: 3, fontWeight: 500, pr: 5 }} >Control panel</Typography>
                            <Box sx={{pl: 7, pt: 1, pr: 7}} id="fb-editor" ref={fb} />
                        </Paper>
                    </Grid>

                    <Dialog
                        fullScreen
                        open={openPreview}
                        onClose={handlePreviewClose}
                    >
                        <AppBar sx={{ position: 'relative' }}>
                            <Toolbar>
                                <IconButton
                                    edge="start"
                                    color="inherit"
                                    onClick={handlePreviewClose}
                                    aria-label="close"
                                >
                                    <CloseIcon />
                                </IconButton>
                                <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                                    {GetTemplateName()}
                                </Typography>
                                <Button autoFocus color="inherit">
                                    Preview
                                </Button>
                            </Toolbar>
                        </AppBar>
                        <DispalyApplicationForm data={formBuilder.current ? formBuilder.current.formData : null} />
                    </Dialog>
                </Paper>
                <Footer />
            </Container>

        {/*    <PostLoginMenu />*/}
        {/*    <OverlaySpinner disappear={isLoading} />*/}
        {/*    <div className="container-fluid">*/}
        {/*        <div className="row justify-content-center">*/}
        {/*            <div className="col-12" style={{ maxWidth: '100rem' }}>*/}
        {/*                <Accordion elevation={0} expanded={expanded} onChange={handleAccordianChange}>*/}
        {/*                    <AccordionSummary className="mt-3" expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">                                */}
        {/*                    </AccordionSummary>*/}
        {/*                    <AccordionDetails>*/}
        {/*                        <MainNavMenu />*/}
        {/*                        <div className="row mb-2 mt-1">*/}
        {/*                            <TemplateDesignerBreadcrumb text={(props.templateid !== null && props.templateid !== undefined) ? "Edit Template" : "New Template"} />*/}
        {/*                        </div>*/}
        {/*                        <div className="row heading m-t-10">*/}
        {/*                            <h5 className="text-center p-t-0 p-b-1" style={{ fontSize: '30px', fontWeight: 400 }}>Form Designer</h5>*/}
        {/*                            <p className="text-center p-b-1" style={{ fontSize: '16px' }}>Design your Account Form using this designer</p>                                */}
        {/*                        </div>*/}
        {/*                    </AccordionDetails>*/}
        {/*                </Accordion>*/}
        {/*            </div>*/}
        {/*        </div>*/}
        {/*    </div>*/}
        {/*    <div className="container-fluid">*/}
        {/*        <div className="row justify-content-center">*/}
        {/*            <div className="col-12" style={{ maxWidth: '100rem' }}>*/}
        {/*                <div className="m-t-0" id="fb-editor" ref={fb} />*/}
        {/*            </div>*/}
        {/*        </div>*/}
        {/*    </div>*/}
        {/*    <Footer />*/}
        </React.Fragment>
    );
}

const DispalyApplicationForm = (props) => {

    const sectionHeaders = useRef([]);
    const [curSecHdrIdx, setCurSecHdrIdx] = useState(0);
    const [nextId, setNextId] = useState('');
    const [curId, setCurId] = useState('');
    const [prevId, setPrevId] = useState('');
    const sections = useRef([]);

    const templateDetail = useRef('');

    const handleScrollIntoView = (event) => {
        event.preventDefault();
        let id = event.target.id.replace('btn_', '');
        const myArray = id.split("_");
        let nmPrfx = myArray[1];

        sectionDivider(sectionHeaders.current, templateDetail.current, false, nmPrfx);
    }

    const sectionDivider = (headerTypes, actualData, isInital, sectionId) => {

        let nmPrfx = '';
        let curRowIndex = 0;

        if (!isInital) {
            //onChangeFormData();
            curRowIndex = headerTypes.findIndex(item => item.name.toString().endsWith(sectionId));
        }

        setCurSecHdrIdx(curRowIndex);

        //setAppEventSts(AppEventStatus.InComplete);
        //setsavedMessage('');

        if (headerTypes[curRowIndex].type.toString() === "header" && headerTypes[curRowIndex].className == "section-group") {
            const myArray = headerTypes[curRowIndex].name.split("_");
            nmPrfx = myArray[1];

            if (curRowIndex === 0)
                setPrevId('');
            else {
                const myArray = headerTypes[curRowIndex - 1].name.split("_");
                let prevNmPrfx = myArray[1];
                setPrevId(prevNmPrfx);
            }

            let cardObj = {
                id: null,
                visibile: true,
                values: []
            };

            let data = [];
            try {
                data = JSON.parse(actualData).filter((section) => {
                    return section.sectionIdentifier.toString().endsWith(nmPrfx);
                });
            } catch (e) {
                data = (actualData).filter((section) => {
                    return section.sectionIdentifier.toString().endsWith(nmPrfx)
                });
            }

            cardObj.id = nmPrfx;
            cardObj.visibile = true;
            cardObj.values = data;

            setCurId(nmPrfx);
            sections.current = [cardObj];
            if (curRowIndex === headerTypes.length - 1) {
                setNextId('');
            }
            else {
                const myArray = headerTypes[curRowIndex + 1].name.split("_");
                let nextNmPrfx = myArray[1];
                setNextId(nextNmPrfx);
            }

            $('.fb-render-preview').formRender({
                dataType: 'json',
                formData: JSON.stringify(sections.current[0]["values"]),
                i18n: {
                    location: process.env.REACT_APP_I18N_LANG
                }
            });
        }
    };

    useEffect(() => {

        let templateData = JSON.parse(props.data).slice();

        let cur_ref = '';
        let count = 0;

        for (let i = 0; i < templateData.length; i++) {

            if (templateData[i].type == "header" && templateData[i].className == "section-group") {
                cur_ref = 'sec_ref' + count;
                templateData[i].name = cur_ref;
                templateData[i].sectionIdentifier = cur_ref;
                count++;
            }
            else {
                templateData[i].sectionIdentifier = cur_ref;
            }
        }

        templateDetail.current = templateData;

        const headerTypes = templateDetail.current.filter((headerType) => {
            return headerType.type === "header" && headerType.className == "section-group";

        });

        sectionDivider(headerTypes, templateDetail.current, true, '')

        if (headerTypes.length > 0) {
            sectionHeaders.current = headerTypes;
        }



    }, []);

    return (
        <React.Fragment>
            <Container maxWidth="xl" sx={{pt: 8}}>
                <Paper elevation={2} sx={{}}>
                    <Grid container spacing={0} sx={{ pt: 10, pb: 10 }}>
                        <Grid item xs={12} md={1} sx={{}}>
                        </Grid>
                        <Grid item xs={12} md={3} sx={{}}>
                            <ApplicationSections sectionHeaders={sectionHeaders.current} CurrentIndex={curSecHdrIdx} handleClick={(event) => handleScrollIntoView(event)} />
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <Paper elevation={0}>
                                <Box sx={{borderLeft: 1, borderColor: 'rgba(0,0,0,0.15)', pl: 15, pr: 5}}>
                                    <div className="fb-render-preview" id='fb-render-preview-id'>
                                    </div>
                                </Box>
                            </Paper>
                        </Grid>
                        {/*<Grid item xs={12} md={1} sx={{}}>*/}
                        {/*</Grid>*/}
                    </Grid>
                </Paper>
            </Container>
        </React.Fragment>
    );
}

const ApplicationSections = (props) => {

    return (
        <>
            <Box className="app-sec-nav" sx={{ pt: 1 }} >
                {
                    props.sectionHeaders.map((elem, i) => (
                        <Stack key={i} direction="row" spacing={2}>
                            <Box className="icon-box">
                                <span className={props.CurrentIndex === i ? 'step-number-active display-19' : 'step-number display-19'}><strong>{i + 1}</strong></span>
                            </Box>
                            <Box sx={{ pl: 4 }} className={props.CurrentIndex === i ? 'text-box-active' : 'text-box'}>

                                <Link color={props.CurrentIndex === i ? 'text.primary' : '#bdbdbd'} component="button" href="#" underline="hover" variant="h4" sx={{ mb: 0, fontWeight: 500 }} onClick={(e) => props.handleClick(e)} id={"btn_" + elem.name} >{elem.label}</Link>
                                {/*<Stack direction="row" alignItems="center" spacing={1} sx={{ ml: 1 }}>*/}
                                {/*    <Typography variant="body1" color="text.secondary" sx={{ mt: 1 }}>*/}
                                {/*        {elem.sectionStatus}*/}
                                {/*    </Typography>*/}
                                {/*    {elem.sectionStatus === 'Complete' ?*/}
                                {/*        <DoneIcon sx={{ fontSize: 24, color: '#4caf50' }} />*/}
                                {/*        :*/}
                                {/*        <LensIcon sx={{ fontSize: 12, color: elem.sectionStatus === 'Required' ? '#ef5350' : '#4caf50' }} />*/}
                                {/*    }*/}
                                {/*</Stack>*/}
                            </Box>
                        </Stack>
                    ))
                }
                <Stack direction="row" spacing={2}>
                    <Box className="icon-box">
                        <span className="step-number display-19"><strong>&nbsp;</strong></span>
                    </Box>
                    <Box sx={{ pl: 4 }} className="">

                        <Link color="#bdbdbd" component="button" href="#" underline="hover" variant="h4" sx={{ mb: 0, fontWeight: 500 }}>End</Link>
                    </Box>
                </Stack>

            </Box>
        </>
    );
};

const TemplateDesignerBreadcrumb = (props) => {

    return (
        <React.Fragment >
            <Box sx={{ borderLeft: '4px solid #9e9e9e', mt: 0, pt: 0, pb: 2, pl: 1, backgroundColor: '#fff', borderTop: '0px solid #dee2e6' }}>
                <Typography variant="h5" sx={{ fontWeight: 400, lineHeight: 1.1, pl: 2, textTransform: 'none' }} color="text.primary">{props.text}</Typography>
            </Box>
        </React.Fragment>
    );
}

export default TemplateDesigner;