import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { Container } from '@mui/material';
import OverlaySpinner from './Controls/OverlaySpinner';
import PostLoginMenu from './PostLoginMenu';
import { GetLoggedInUserId, IsLoggedInUserProspectUser, GetAccessControl } from '../global/APIUtils';
import { RedirectToHome } from '../global/Helpers';
import { AOBSystemProcess } from '../common/AOBEnum';
import { useLocation } from "react-router-dom";

const UserHome = () => {

    const location = useLocation();

    const [userId, setUserId] = useState(location.state ? location.state.userId : (GetLoggedInUserId() != null && GetLoggedInUserId() != undefined) ? GetLoggedInUserId() : -1);
    const [sessionId, setSessionId] = useState(location.state ? location.state.sessionId : '');

    let navigate = useNavigate();

    useEffect(() => {

        if (!userId || userId == -1) {
            navigate('/AppLogin', { state: {}, replace: true });
            return;
        }

        let prospect = IsLoggedInUserProspectUser(userId);
        let accessControl = GetAccessControl(userId);

        if (prospect === true) {
            navigate('/ProspectDashboard', { state: { userId: userId, sessionId: sessionId }, replace: true });
            return;
        }

        let accessControlArray = accessControl.split("|");

        var found = accessControlArray.find(function (element) {
            return element == AOBSystemProcess.ApplicationMaintenance
                || element == AOBSystemProcess.ViewApplication || element == AOBSystemProcess.ExportApplication
                || element == AOBSystemProcess.ApproveApplication || element == AOBSystemProcess.PublishApplication
                || element == AOBSystemProcess.DocuSignDocUpload || element == AOBSystemProcess.AllProcess;
        });

        if (found !== undefined && found.length > 0)
            navigate('/ApplicationDashboard', { state: { userId: userId, sessionId: sessionId }, replace: true });
        else
            navigate('/UserProfileSetting', { replace: true }, { state: { userId: userId, sessionId: sessionId } });

    }, []);

    return (
        <React.Fragment>
            <Container maxWidth="xl" sx={{}}>
                <OverlaySpinner disappear={true} />
                <PostLoginMenu userId={userId} sessionId={sessionId} />
            </Container>
        </React.Fragment>
    );
}
export default UserHome;