import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import { Typography } from '@mui/material';

export function AlertDialog(props) {
    const { onClose, value: valueProp, open, ...other } = props;

    const handleCancel = () => {
        onClose();
    };

    const handleOk = () => {
        onClose(props.nvalue, props.svalue);
    };

    return (
        <Dialog
            sx={{ '& .MuiDialog-paper': { width: '100%', maxHeight: 435 } }}
            maxWidth="xs"
            open={open}
            {...other}
        >
            <DialogTitle className="ms-2 me-2">
                <Typography variant="body1" color="text.primary" sx={{fontWeight: 600}}>{props.alertheader}</Typography>
            </DialogTitle>
            <DialogContent dividers className="ms-2 me-2">
                <Typography variant="body2" color="text.primary" sx={{}}>{props.bmsgline1}</Typography>
                <Typography variant="body2" color="text.primary" sx={{}}>{props.bmsgline2}</Typography>
                <Typography variant="body2" color="text.primary" sx={{}}>{props.bmsgline3}</Typography>
                <Typography variant="body2" color="text.primary" sx={{}}>{props.bmsgline4}</Typography>
                <Typography variant="body2" color="text.primary" sx={{}}>{props.bmsgline5}</Typography>
            </DialogContent>
            <DialogActions sx={{ pt: 2, pb: 2, pr: 2}}>
                <Button autoFocus variant="outlined" onClick={handleCancel} size="small" sx={{ margin: '4px 8px', padding: '5px 40px', border: '1px solid rgba(0,0,0,0.34)'}}>
                    <Typography variant="body1" color="text.primary" sx={{ textTransform: 'none' }} >No</Typography>
                </Button>
                <Button variant="outlined" onClick={handleOk} size="small" sx={{ margin: '4px 10px 4px 0px', padding: '5px 40px', border: '1px solid rgba(0,0,0,0.34)' }}>
                    <Typography variant="body1" color="text.primary" sx={{ textTransform: 'none' }} >Yes</Typography>
                </Button>
            </DialogActions>
        </Dialog>
    );
}

AlertDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    nvalue: PropTypes.number,
    svalue: PropTypes.string,
    alertheader: PropTypes.string.isRequired,
    bmsgline1: PropTypes.string.isRequired,
    bmsgline2: PropTypes.string,
    bmsgline3: PropTypes.string,
    bmsgline4: PropTypes.string,
    bmsgline5: PropTypes.string,
};