import React, { useEffect, useState, useRef, useMemo } from 'react';
import { Container, Dialog, Divider, DialogTitle, DialogContent, Box, DialogActions, Button, Typography, IconButton, Grid, Toolbar } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import AppBar from '@mui/material/AppBar';
//import { useSelector, useDispatch } from 'react-redux';
import AlertControl from '../components/Controls/AlertControl';
import MaterialReactTable, { MRT_ShowHideColumnsButton, MRT_ToggleGlobalFilterButton, MRT_ToggleFiltersButton, } from 'material-react-table';
import { CallAPI, IsLoggedInUserProspectUser } from '../global/APIUtils';
import DownloadIcon from '@mui/icons-material/Download';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { GetAttachedFile } from '../global/EnrollmentHelper';
import LoadingOverlay from 'react-loading-overlay';
import RiseLoader from 'react-spinners/RiseLoader'
import { ErrorMessages } from '../common/ErrorMessages';
import { APICall } from '../global/Api';

const DocListDlg = (props) => {

    const { onClose, open, enrmntid, ownerName, enrolNm, templateNm } = props;
    const [isLoading, setIsLoading] = useState(false);

    return (
        <Dialog
            fullScreen
            open={open}
            onClose={onClose}
            scroll="paper"
        >
            {/*<LoadingOverlay active={isLoading} spinner={<RiseLoader color={'#1D50AF'} />} >*/}
            <AppBar sx={{ position: 'relative' }}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={onClose}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                    <Typography sx={{ ml: 2, flex: 1, fontWeight: 300 }} variant="h6" component="div">
                        Uploaded documents
                    </Typography>
                    <Typography sx={{fontWeight: 300 }} variant="h6" component="div">
                        Applicant : {ownerName}
                    </Typography>
                </Toolbar>
            </AppBar>
            <DocListControl enrmntid={enrmntid} acctType={props.acctType} enrolNm={enrolNm} templateNm={templateNm} userId={props.userId} sessionId={props.sessionId} />
            {/*</LoadingOverlay>*/}
       </Dialog>
    );
};

const DocListControl = (props) => {

    const [enrmntid, setEnrmntid] = useState(props.enrmntid || -1);
    const [enrolNm, setEnrolNm] = useState(props.enrolNm || -1);
    const [errorMsg, setErrorMsg] = useState(null);
    const [isDataLoading, setIsDataLoading] = useState(false);
    const [data, setData] = useState([]);
    const [successMsg, setSuccessMsg] = useState(null);
    const isProspectUser = useRef(false);

    const [userId, setUserId] = useState(props.userId);
    const [sessionId, setSessionId] = useState(props.sessionId);

    //const dispatch = useDispatch();
    //let sessions = useSelector((state) => state.sessionMgmnt);

    const loadAttachments = async () => {

        try {

            setIsDataLoading(true);

            const apiUrl = new URL(
                process.env.REACT_APP_GET_ATTACHMENTS,
                process.env.REACT_APP_BASE_URL,
            );

            apiUrl.searchParams.set('enrmntId', `${parseInt(enrmntid)}`);

            let response = await APICall({ url: apiUrl.href, method: 'GET', contentType: 'application/json', responseType: '' }, userId, sessionId);

            if (response !== null && response !== undefined) {
                setIsDataLoading(false);
                setData(response.data[0])
            }
        }
        catch (err) {
            setErrorMsg(ErrorMessages.UnknownError);
            setIsDataLoading(false);
        }
        finally {
            
        }
    }

    const downloadAttachment = async (attachmentId, fileNm) => {


        try {

            setIsDataLoading(true);

            const apiUrl = new URL(
                process.env.REACT_APP_DOWNLOAD_ENROLLMENT_DOCUMENT,
                process.env.REACT_APP_BASE_URL,
            );
            apiUrl.searchParams.set('enrmntId', `${parseInt(enrmntid)}`);
            apiUrl.searchParams.set('attachmentId', `${parseInt(attachmentId)}`);
            apiUrl.searchParams.set('fname', fileNm);
            apiUrl.searchParams.set('tag', 'None');

            await GetAttachedFile(apiUrl.href, fileNm, userId, sessionId);
        }
        catch (ex) {
            setErrorMsg(ErrorMessages.UnknownError);
        }
        finally {
            setIsDataLoading(false);
        }
    }

    const downloadSignedAttachment = async (attachmentId, fileNm) => {

        try {

            setIsDataLoading(true);

            const apiUrl = new URL(
                process.env.REACT_APP_DOWNLOAD_SIGNED_DOCUMENT,
                process.env.REACT_APP_BASE_URL,
            );
            apiUrl.searchParams.set('enrmntId', `${parseInt(enrmntid)}`);
            apiUrl.searchParams.set('attachmentId', `${parseInt(attachmentId)}`);
            apiUrl.searchParams.set('fname', fileNm);
            apiUrl.searchParams.set('tag', 'None');

            await GetAttachedFile(apiUrl.href, fileNm, userId, sessionId);
        }
        catch (ex) {
            setErrorMsg(ErrorMessages.UnknownError);
        }
        finally {
            setIsDataLoading(false);
        }
    }

    const deleteAttachment = async (enrmntId, attachmentId, fileNm) => {

        const inputJson = {
            EnrmntId: enrmntId,
            AttchmntId: attachmentId
        };

        const apiUrl = new URL(
            process.env.REACT_APP_DELETE_ENROLLMENT_DOC,
            process.env.REACT_APP_BASE_URL,
        );
        try {

            setIsDataLoading(true);

            let response = await APICall({ url: apiUrl.href, method: 'POST', payload: inputJson, contentType: 'application/json', responseType: '' }, userId, sessionId);

            if (response !== null && response !== undefined) {
                setSuccessMsg('File \'' + fileNm + '\' deleted successfully' );
                loadAttachments();
                setIsDataLoading(false);
            }
        }
        catch (err) {
            setErrorMsg(ErrorMessages.UnknownError);
            setIsDataLoading(false);
        }
        finally {
        }
    }

    const canDeleteAttachment = async (CntrlNm) => {

        if (CntrlNm === '') {
            if (isProspectUser.current) {
                return true;
            }
        }

        return false;
    }

    useEffect(() => {
        isProspectUser.current = IsLoggedInUserProspectUser();
        loadAttachments();
    }, []);

    const columns = [
        {
            accessorKey: 'AttchmntId',
            header: '',
            Cell: ({ cell }) => (
                <IconButton title="Download" onClick={() => downloadAttachment(cell.row.original.AttchmntId, cell.row.original.FileName)}>
                    <DownloadIcon width="8px" height="8px" color="#0f69ff" />
                </IconButton>
            ),
            maxSize: 10,
            minSize: 10,
        },
        {
            accessorKey: 'CntrlNm',
            header: '',
            Cell: ({ cell }) => (
                <IconButton title="Delete" disabled={cell.getValue() == '' ? (cell.row.original.IsSignCompleted == true ? true : false) : true} onClick={() => deleteAttachment(enrmntid, cell.row.original.AttchmntId, cell.row.original.FileName)}>
                    <DeleteForeverIcon sx={{ fontSize: 24 }} color="#0f69ff" />
                </IconButton>
            ),
            maxSize: 10,
            minSize: 10,
        },
        {
            accessorKey: 'FileName',
            header: 'FILE NAME',
        },
        {
            accessorKey: 'SignRequire',
            header: 'REQUIRES DOCUSIGN',
        },
        {
            accessorKey: 'SignStatus',
            header: 'SIGNING STATUS',
            Cell: ({ cell }) => (
                <React.Fragment >
                    {/*<Typography color="#616161" sx={{}}>*/}
                    {cell.row.original.SignRequire == 'Yes' ? cell.getValue() : ''}
                    {/*</Typography>*/}
                {/*    <Typography color="#616161" sx={{}} variant="body2" component="body2">*/}
                {/*        {cell.row.original.DeclieReason}*/}
                {/*    </Typography>*/}
                </React.Fragment >
            ),

        },
        {
            accessorKey: 'AttchmntGUID',
            header: 'SIGNED ATTACHMENT',
            Cell: ({ cell }) => (
                cell.row.original.IsSignCompleted ? 
                <IconButton title="Download" onClick={() => downloadSignedAttachment(cell.row.original.AttchmntId, cell.row.original.FileName)}>
                    <DownloadIcon width="8px" height="8px" color="#0f69ff" />
                </IconButton>
                    : ''
            ),
            maxSize: 10,
            minSize: 10,
        },
        {
            accessorKey: 'DeclineReason',
            header: 'DECLINE REASON',
        },
        {
            accessorKey: 'CreatedBy',
            header: 'UPLOADED BY',
        },
    ];

    return (
        <React.Fragment >
            <Container maxWidth="lg" sx={{ pt: 5 }}>
                <Grid item>
                    <Box alignItems="center" sx={{ display: 'flex', justifyContent: 'space-between', }}>
                        <Typography color="#616161" sx={{}} variant="h5" component="div">
                            Application name : &nbsp;{enrolNm}
                        </Typography>
                        <Typography color="#616161" sx={{}} variant="h6" component="div">
                            Applied for : &nbsp;{props.templateNm}
                        </Typography>
                    </Box>
                </Grid>
                <Grid item>
                    <LoadingOverlay active={isDataLoading} spinner={<RiseLoader color={'#1D50AF'} />} >
                        <Box sx={{ pt: 3 }}>
                            <AlertControl Message={errorMsg} severity="error" color="error" icon={true} />
                            <AlertControl Message={successMsg} isSuccess="1" />
                        </Box>
                        <Box sx={{pt: 3}}>
                            <MaterialReactTable
                                columns={columns}
                                data={data}
                                enableColumnActions={false}
                                enableColumnFilters={false}
                                enablePagination={false}
                                enableSorting={false}
                                enableBottomToolbar={false}
                                enableTopToolbar={false}
                                muiTableBodyCellProps={({ column }) => ({
                                    sx: (theme) => ({
                                        color: theme.palette.text.secondary,
                                        fontSize: theme.typography.body2,
                                        borderBottom: '1px solid #eeeeee',
                                        minHeight: '50px',
                                    }),
                                })
                                }
                                muiTableHeadCellProps={{
                                    sx: (theme) => ({
                                        color: '#22303e',
                                        fontSize: theme.typography.body2,
                                        //borderTop: '1px solid rgba(224, 224, 224, 1)',
                                        //borderLeft: '1px solid rgba(224, 224, 224, 1)',
                                        //backgroundColor: '#00477b',
                                        fontWeight: 500,
                                        pt: 2,
                                        pb: 2
                                    }),
                                }}
                            />
                        </Box>
                    </LoadingOverlay>
                </Grid>
            </Container>
        </React.Fragment>
    );
};

export default DocListDlg;
