import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import CheckIcon from '@mui/icons-material/Check';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import DescriptionIcon from '@mui/icons-material/Description';
import DownloadIcon from '@mui/icons-material/Download';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import NoteAltIcon from '@mui/icons-material/NoteAlt';
import UploadIcon from '@mui/icons-material/Upload';
import { Box, Card, CardMedia, Grid, IconButton, Menu, MenuItem, Paper, Stack, Typography, Tooltip } from '@mui/material';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Avatar from '@mui/material/Avatar';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import ListItemIcon from '@mui/material/ListItemIcon';
//import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { QueryClient, QueryClientProvider, useInfiniteQuery } from '@tanstack/react-query';
import { Virtualizer } from '@tanstack/react-virtual';
import MaterialReactTable, { MRT_ToggleGlobalFilterButton } from 'material-react-table';
//import Image from 'material-ui-image';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { renderToString } from 'react-dom/server';
//import { useDispatch, useSelector } from 'react-redux';
//import pwdimg from '../assets/images/12543.jpg';
//import BLogo from '../assets/images/BLogo.jpg';
import BLogo from '../assets/images/ssb-logo.png';
import profimg from '../assets/images/profimg.jpg';
import { ApplicationSortAction, EnrollmentStatus, AOBSystemProcess, APIResponseStatus } from '../common/AOBEnum';
import { ErrorMessages } from '../common/ErrorMessages';
import AppViewDlg from '../dialogs/ApplViewDlg';
import DocListDlg from '../dialogs/DocListDlg';
import DocUpldDlg from '../dialogs/DocUpldDlg';
import ExportToGWESLoaderDlg from '../dialogs/ExportToGWESLoaderDlg';
import { AlertDialog } from '../global/AlertDialog';
import { CallAPI, IsError, IsInRole, GetLoggedInUserId, GetAPIErrorAction, GetAxiosErrorAction, CallAPIUsingAxios, GetLoggedInUserEmail } from '../global/APIUtils';
import { ConfirmDialog } from '../global/ConfirmDialog';
import { GetApplicantImage } from '../global/EnrollmentHelper';
import { FormDialog } from '../global/FormDialog';
import { ApplicationAllDocumentDownload } from './ApplicationDocumentDownload';
//import { ApplicationEnrollmentPrint } from './ApplicationEnrollmentPrint';
import { DrawerAppBar } from './AppNavbar';
import AlertControl from './Controls/AlertControl';
import AlertWithTitle from './Controls/AlertWithTitle';
import OverlaySpinner from './Controls/OverlaySpinner';
import { AddEditEnrollment } from '../dialogs/AddEditEnrollment';
import EnrollmentHeader from './EnrollmentHeader';
import { Footer } from './Footer';
import PostLoginMenu from './PostLoginMenu';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { withRouter } from '../global/Helpers';
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { APICall } from '../global/Api';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import { styled } from '@mui/material/styles';

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: 0,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
}));

const UserEnrollments = () => {

    const location = useLocation();
    let navigate = useNavigate();

    const queryClient = new QueryClient();
    //const dispatch = useDispatch();
    //let sessions = useSelector((state) => state.sessionMgmnt);
    const [openConfirmDlg, setOpenConfirmDlg] = useState(false);
    const [openFormDlg, setOpenFormDlg] = useState(false);
    const [enrolModalShow, setEnrolModalShow] = useState(false);
    const [expanded, setExpanded] = useState(true);
    const [errorMsg, setErrorMsg] = useState(null);
    const [successMsg, setSuccessMsg] = useState(null);
    const [successMsgTitle, setSuccessMsgTitle] = useState(null);
    const [appStatusErrorMsg, setAppStatusErrorMsg] = useState('');
    const [isDataLoading, setIsDataLoading] = useState(false);
    const [enrollmentCount, setEnrollmentCount] = useState(0);
    const [attachmentCount, setAttachmentCount] = useState(0);
    const [docuSignRequiresCount, setDocuSignRequiresCount] = useState(0);
    const [docuSignCompletedCount, setDocuSignCompletedCount] = useState(0);
    const [docuSignDeclineCount, setDocuSignDeclineCount] = useState(0);
    const [submittedDate, setSubmittedDate] = useState(null);
    const [openDelConfirmDlg, setOpenDelConfirmDlg] = useState(false);
    const [statusExpanded, setStatusExpanded] = useState(false);

    const [userId, setUserId] = useState(location.state ? location.state.userId : (GetLoggedInUserId() != null && GetLoggedInUserId() != undefined) ? GetLoggedInUserId() : -1);
    const [sessionId, setSessionId] = useState(location.state ? location.state.sessionId : '');

    const confirmDlgHdr = useRef('');
    const confirmDlgBLine1 = useRef('');
    const confirmDlgBLine2 = useRef('');
    const confirmDlgBLine3 = useRef('');
    const confirmDlgBLine4 = useRef('');
    const confirmDlgBLine5 = useRef('');

    const confirmDelDlgHdr = useRef('');
    const confirmDelDlgBLine1 = useRef('');


    const newEnrolStatus = useRef(0);
    const selectedEnrolId = useRef(0);
    const templateId = useRef(0);
    const enrolNm = useRef(null);
    const templateNm = useRef(null);

    const emailAdrs = useRef(null);

    const selectedEnrolmntValue = useRef('');
    const selectedTemplateNm = useRef('');

    const [imageUrl, setImageUrl] = useState(null);
    const [optMnuAnchorEl, setOptMnuAnchorEl] = useState(null);
    const open = Boolean(optMnuAnchorEl);

    const [moreMnuAnchorEl, setMoreMnuAnchorEl] = useState(null);
    const openMoreMenu = Boolean(moreMnuAnchorEl);

    const [userEnrolmlSts, setUserEnrolmlSts] = useState(null);
    const [rowSelection, setRowSelection] = useState({});
    const [rowId, setRowId] = useState({});
    const [isFormLoading, setIsFormLoading] = useState(false);
    const [openAppView, setOpenAppView] = useState(false);
    const [printApp, setPrintApp] = useState(false);

    const [disRevOpt, setDisRevOpt] = useState(true);
    const [disRevPassOpt, setDisRevPassOpt] = useState(true);
    const [disRevFailOpt, setDisRevFailOpt] = useState(true);
    const [disKYCAMLCheckOpt, setDisKYCAMLCheckOpt] = useState(true);
    const [disKYCAMLPassOpt, setDisKYCAMLPassOpt] = useState(true);
    const [disKYCAMLFailOpt, setDisKYCAMLFailOpt] = useState(true);
    const [disDocuSignOpt, setDisDocuSignOpt] = useState(true);
    const [disDocuSignCompletedOpt, setDisDocuSignCompletedOpt] = useState(true);
    const [disSubmitForEnrollOpt, setDisSubmitForEnrollOpt] = useState(true);
    const [disAccountActivatedOpt, setDisAccountActivatedOpt] = useState(true);
    const [disRejectOpt, setDisRejectOpt] = useState(true);
    const [disCancelOpt, setDisCancelOpt] = useState(true);
    const [applicantsName, setApplicantsName] = useState('');
    const [applicantEmail, setApplicantEmail] = useState('');
    const [ownerAge, setOwnerAge] = useState('');
    const [ownerMobileNbr, setOwnerMobileNbr] = useState('');

    const [disViewApp, setDisViewApp] = useState(true);
    const [disUploadDoc, setDisUploadDoc] = useState(true);
    const [disDownloadApp, setDisDownloadApp] = useState(true);
    const [disPrintApp, setDisPrintApp] = useState(true);
    const [disViewDoc, setDisViewDoc] = useState(true);
    const [disEditApp, setDisEditApp] = useState(true);
    const [disDeleteApp, setDisDeleteApp] = useState(true);

    const tickSrtDtNewDisp = useRef('block');
    const tickSrtDtOldDisp = useRef('none');
    const tickSrtOwnerDisp = useRef('none');
    const tickSrtAccTypeDisp = useRef('none');
    const tickSrtApplNameDisp = useRef('none');
    const tickSrtStatusDisp = useRef('none');

    const sortMenuAction = useRef(ApplicationSortAction.Newest);

    const [showAppViewDlg, setShowAppViewDlg] = useState(false);
    const [showDocUpldDlg, setShowDocUpldDlg] = useState(false);
    const [showDocListDlg, setShowDocListDlg] = useState(false);
    const [disStatusDiv, setDisStatusDiv] = useState(true);
    const [showExportToGWESDlg, setShowExportToGWESDlg] = useState(false);

    const handleStatusAccordianChange = () => {
        if (statusExpanded) {
            setStatusExpanded(false);
        }
        else {
            //if (userEnrolmlSts == null) {
            //    getEnrollmentStatus();
            //}
            setStatusExpanded(true);
        }
    };

    const closeAppViewDlg = () => {
        setShowAppViewDlg(false);
    }

    const closeDocUpldDlg = () => {
        setShowDocUpldDlg(false);
    }

    const closeDocListDlg = () => {
        setRowSelection({});
        refetch();
        setApplicantsName('Applicant');
        setApplicantEmail(null);
        setOwnerAge(null);
        setOwnerMobileNbr(null);
        setAttachmentCount(null);
        setDocuSignRequiresCount(null);
        setDocuSignCompletedCount(null);
        setSubmittedDate(null);
        setImageUrl(null);
        setShowDocListDlg(false);
        disableAlltoolbarButton();
        DisableAllActions();
    }

    const disableAlltoolbarButton = () => {
        setDisViewDoc(true);
        setDisUploadDoc(true);
        setDisEditApp(true);
        setDisViewApp(true);
        setDisDownloadApp(true);
        setDisDeleteApp(true);
    }

    const onFileChangeHandle = async () => {
        setShowDocUpldDlg(false);
        setRowSelection({});
        refetch();
        setApplicantsName('Applicant');
        setApplicantEmail(null);
        setOwnerAge(null);
        setOwnerMobileNbr(null);
        setAttachmentCount(0);
        setDocuSignRequiresCount(0);
        setDocuSignCompletedCount(0);
    }

    const handleOptMenuClick = (event) => {
        setOptMnuAnchorEl(event.currentTarget);
    };
    const handleOptMenuClose = () => {
        setOptMnuAnchorEl(null);
    };

    const handleMoreMenuClick = (event) => {
        setMoreMnuAnchorEl(event.currentTarget);
    };
    const handleMoreMenuClose = () => {
        setMoreMnuAnchorEl(null);
    };

    const [sortMnuAnchorEl, setSortMnuAnchorEl] = useState(null);
    const openSort = Boolean(sortMnuAnchorEl);

    const handleSortMnuClick = (event) => {
        setSortMnuAnchorEl(event.currentTarget);
    };
    const handleSortMnuClose = () => {
        setSortMnuAnchorEl(null);
    };
    const handleReviewClick = (table) => {
        setOptMnuAnchorEl(null);
        newEnrolStatus.current = EnrollmentStatus.UnderReview;
        
        if (table.getSelectedRowModel().rows.length > 0) {
            let row = table.getSelectedRowModel().rows[0];
            selectedEnrolId.current = row.original.enrmntId;
            confirmDlgBLine1.current = 'Application Owner: ' + row.original.ownerName;
            confirmDlgBLine2.current = 'Application Name: ' + row.original.name;
            confirmDlgBLine3.current = 'Applied For: ' + row.original.templateNm;
            confirmDlgBLine4.current = 'Applied: ' + row.original.crtDate;
        }
        confirmDlgHdr.current = 'Change the status from \'Submitted\' to \'Under Review\' of the following application';
        setOpenConfirmDlg(true);
    };

    const handleReviewPassClick = (table) => {
        setOptMnuAnchorEl(null);
        newEnrolStatus.current = EnrollmentStatus.ReviewPass;

        if (table.getSelectedRowModel().rows.length > 0) {
            let row = table.getSelectedRowModel().rows[0];
            selectedEnrolId.current = row.original.enrmntId;
            confirmDlgBLine1.current = 'Application Owner: ' + row.original.ownerName;
            confirmDlgBLine2.current = 'Application Name: ' + row.original.name;
            confirmDlgBLine3.current = 'Applied For: ' + row.original.templateNm;
            confirmDlgBLine4.current = 'Applied: ' + row.original.crtDate;
        }
        confirmDlgHdr.current = 'Change the status from \'Under Review\' to \'Review Passed\' of the following application';
        setOpenConfirmDlg(true);
    };

    const handleKYCAMLClick = (table) => {
        setOptMnuAnchorEl(null);
        newEnrolStatus.current = EnrollmentStatus.KYCAMLInitiated;

        if (table.getSelectedRowModel().rows.length > 0) {
            let row = table.getSelectedRowModel().rows[0];
            selectedEnrolId.current = row.original.enrmntId;
            confirmDlgBLine1.current = 'Application Owner: ' + row.original.ownerName;
            confirmDlgBLine2.current = 'Application Name: ' + row.original.name;
            confirmDlgBLine3.current = 'Applied For: ' + row.original.templateNm;
            confirmDlgBLine4.current = 'Applied: ' + row.original.crtDate;
        }
        confirmDlgHdr.current = 'Change the status from \'Review passed\' to \'KYC/AML Initiated\' of the following application';
        setOpenConfirmDlg(true);
    };

    const handleKYCAMLPassClick = (table) => {
        setOptMnuAnchorEl(null);
        newEnrolStatus.current = EnrollmentStatus.KYCAMLPassed;

        if (table.getSelectedRowModel().rows.length > 0) {
            let row = table.getSelectedRowModel().rows[0];
            selectedEnrolId.current = row.original.enrmntId;
            confirmDlgBLine1.current = 'Application Owner: ' + row.original.ownerName;
            confirmDlgBLine2.current = 'Application Name: ' + row.original.name;
            confirmDlgBLine3.current = 'Applied For: ' + row.original.templateNm;
            confirmDlgBLine4.current = 'Applied: ' + row.original.crtDate;
        }
        confirmDlgHdr.current = 'Change the status from \'KYC/AML Initiated\' to \'KYC/AML Passed\' of the following application';
        setOpenConfirmDlg(true);
    };

    const handleDocuSignReqClick = (table) => {
        setOptMnuAnchorEl(null);

        if (table.getSelectedRowModel().rows.length > 0) {
            let row = table.getSelectedRowModel().rows[0];

            if (row.original.DocuSignCount == 0) {
                setErrorMsg('No documents uploaded for signing.');
                return;
            }
            selectedEnrolId.current = row.original.enrmntId;
            confirmDlgBLine1.current = 'Application Owner: ' + row.original.ownerName;
            confirmDlgBLine2.current = 'Application Name: ' + row.original.name;
            confirmDlgBLine3.current = 'Applied For: ' + row.original.templateNm;
            confirmDlgBLine4.current = 'Applied: ' + row.original.crtDate;
        }
        confirmDlgHdr.current = 'Change the status from \'KYC/AML Passed\' to \'Docusign Required\' of the following application';
        newEnrolStatus.current = EnrollmentStatus.DocuSignRequired;
        setOpenConfirmDlg(true);
    };

    const handleDocuSignCompletedClick = (table) => {
        setOptMnuAnchorEl(null);

        if (table.getSelectedRowModel().rows.length > 0) {
            let row = table.getSelectedRowModel().rows[0];
            if (row.original.DocuSignCount > 0) {
                setErrorMsg('Document signing is not completed.');
                return;
            }
            selectedEnrolId.current = row.original.enrmntId;
            confirmDlgBLine1.current = 'Application Owner: ' + row.original.ownerName;
            confirmDlgBLine2.current = 'Application Name: ' + row.original.name;
            confirmDlgBLine3.current = 'Applied For: ' + row.original.templateNm;
            confirmDlgBLine4.current = 'Applied: ' + row.original.crtDate;
        }
        confirmDlgHdr.current = 'Change the status from \'Docusign Required\' to \'Docusign Completed\' of the following application';
        newEnrolStatus.current = EnrollmentStatus.DocuSignCompleted;
        setOpenConfirmDlg(true);
    };

    const handleSubmitForEnrollClick = (table) => {
        setOptMnuAnchorEl(null);
        newEnrolStatus.current = EnrollmentStatus.SubmittedEnrollment;
        let curEnrollSts = 'docusign complete';
        if (table.getSelectedRowModel().rows.length > 0) {
            let row = table.getSelectedRowModel().rows[0];
            selectedEnrolId.current = row.original.enrmntId;
            confirmDlgBLine1.current = 'Application Owner: ' + row.original.ownerName;
            confirmDlgBLine2.current = 'Application Name: ' + row.original.name;
            confirmDlgBLine3.current = 'Applied For: ' + row.original.templateNm;
            confirmDlgBLine4.current = 'Applied: ' + row.original.crtDate;
            curEnrollSts = row.original.enrmntStatus;
        }
        confirmDlgHdr.current = 'Change the status from \'' + curEnrollSts + '\' to \'Submitted for Enrollment\' of the following application';
        setOpenConfirmDlg(true);
    };

    const handleAccountActivateClick = (table) => {
        setOptMnuAnchorEl(null);
        newEnrolStatus.current = EnrollmentStatus.AccountActivated;
        
        if (table.getSelectedRowModel().rows.length > 0) {
            let row = table.getSelectedRowModel().rows[0];
            selectedEnrolId.current = row.original.enrmntId;
            confirmDlgBLine1.current = 'Application Owner: ' + row.original.ownerName;
            confirmDlgBLine2.current = 'Application Name: ' + row.original.name;
            confirmDlgBLine3.current = 'Applied For: ' + row.original.templateNm;
            confirmDlgBLine4.current = 'Applied: ' + row.original.crtDate;
        }
        confirmDlgHdr.current = 'Change the status from \'Submitted for Enrollment\' to \'Account Activated\' of the following application';
        setOpenConfirmDlg(true);
    };

    const handleReviewFailClick = (table) => {
        setOptMnuAnchorEl(null);
        newEnrolStatus.current = EnrollmentStatus.ReviewFail;

        if (table.getSelectedRowModel().rows.length > 0) {
            let row = table.getSelectedRowModel().rows[0];
            selectedEnrolId.current = row.original.enrmntId;
            confirmDlgBLine1.current = 'Application Owner: ' + row.original.ownerName;
            confirmDlgBLine2.current = 'Application Name: ' + row.original.name;
            confirmDlgBLine3.current = 'Applied For: ' + row.original.templateNm;
            confirmDlgBLine4.current = 'Applied: ' + row.original.crtDate;
            //confirmDlgBLine5.current = 'Please enter the reason for failure the review status.';
        }
        confirmDlgHdr.current = 'Change the status from \'Under Review\' to \'Review Failed\' of the following application';
        confirmDlgBLine5.current = 'Please enter the reason:';
        setOpenFormDlg(true);
    };

    const handleKYCAMLFailClick = (table) => {
        setOptMnuAnchorEl(null);
        newEnrolStatus.current = EnrollmentStatus.KYCAMLFailed;

        if (table.getSelectedRowModel().rows.length > 0) {
            let row = table.getSelectedRowModel().rows[0];
            selectedEnrolId.current = row.original.enrmntId;
            confirmDlgBLine1.current = 'Application Owner: ' + row.original.ownerName;
            confirmDlgBLine2.current = 'Application Name: ' + row.original.name;
            confirmDlgBLine3.current = 'Applied For: ' + row.original.templateNm;
            confirmDlgBLine4.current = 'Applied: ' + row.original.crtDate;            
        }
        confirmDlgHdr.current = 'Change the status from \'KYC/AML Initiated\' to \'KYC/AML Failed\' of the following application';
        confirmDlgBLine5.current = 'Please enter the reason for failure of KYC/AML:';
        setOpenFormDlg(true);
    };

    const handleCancelClick = (table) => {
        setOptMnuAnchorEl(null);
        newEnrolStatus.current = EnrollmentStatus.CancelApplication;
        let curEnrollSts = 'current status';
        if (table.getSelectedRowModel().rows.length > 0) {
            let row = table.getSelectedRowModel().rows[0];
            selectedEnrolId.current = row.original.enrmntId;
            confirmDlgBLine1.current = 'Application Owner: ' + row.original.ownerName;
            confirmDlgBLine2.current = 'Application Name: ' + row.original.name;
            confirmDlgBLine3.current = 'Applied For: ' + row.original.templateNm;
            confirmDlgBLine4.current = 'Applied: ' + row.original.crtDate;
            curEnrollSts = row.original.enrmntStatus;
        }
        confirmDlgHdr.current = 'Change the status from \'' + curEnrollSts + '\' to \'Canceled\' of the following application';
        confirmDlgBLine5.current = 'Please enter the reason for cancellation:';
        setOpenFormDlg(true);
    };

    const handleRejectClick = (table) => {

        setOptMnuAnchorEl(null);
        newEnrolStatus.current = EnrollmentStatus.RejectApplication;
        let curEnrollSts = 'current status';
        if (table.getSelectedRowModel().rows.length > 0) {
            let row = table.getSelectedRowModel().rows[0];
            selectedEnrolId.current = row.original.enrmntId;
            confirmDlgBLine1.current = 'Application Owner: ' + row.original.ownerName;
            confirmDlgBLine2.current = 'Application Name: ' + row.original.name;
            confirmDlgBLine3.current = 'Applied For: ' + row.original.templateNm;
            confirmDlgBLine4.current = 'Applied: ' + row.original.crtDate;
            curEnrollSts = row.original.enrmntStatus;
        }
        confirmDlgHdr.current = 'Change the status from \'' + curEnrollSts+ '\' to \'Rejected\' of the following application';
        confirmDlgBLine5.current = 'Please enter the reason for rejection:';
        setOpenFormDlg(true);
    };

    const handleConfirmDialogClose = (nvalue, svalue) => {
        setOpenConfirmDlg(false);
        if (nvalue) {
            updateEnrollmentStatus(nvalue, null);
        }
        setErrorMsg('');
    };
    const handleFormDialogClose = (nvalue, reason) => {
        setOpenFormDlg(false);
        if (nvalue && reason) {
            updateEnrollmentStatus(nvalue, reason);
        }
    };
    const handleEnrollmentDialogClose = () => {
        setOpenAppView(false);
        setPrintApp(false);

        //setSelectedEnrolmntValue(null);
    };

    const handleExportToGWESDlgClose = () => {
        setShowExportToGWESDlg(false);
    };
    const handleAPIError = async (response) => {

        let action = await GetAPIErrorAction(response);
        if (action.redirectUrl != null) {

            const path = {
                pathname: action.redirectUrl,
                search: action.searchParam
            };

            navigate(path, { state: { userId: userId, sessionId: sessionId }, replace: true, search: action.searchParam });

            //navigate({
            //    pathname: action.redirectUrl,
            //    search: action.searchParam,
            //    state: {},
            //    replace: true
            //});
        }
        else {
            setErrorMsg(action.Message);
        }
    }

    const exportToGwes = async () => {

        try {

            setErrorMsg('');
            setSuccessMsg('');

            let approverEmail = GetLoggedInUserEmail(userId, sessionId);

            let inputJson = {
                "enrmntId": selectedEnrolId.current,                
                "approverEmail": approverEmail
            }

            setIsDataLoading(true);

            const apiUrl = new URL(
                process.env.REACT_APP_EXPORT_ENROLLMENT,
                process.env.REACT_APP_BASE_URL,
            );

            let response = await APICall({ url: apiUrl.href, method: 'POST', payload: inputJson, contentType: 'application/json', responseType: '' }, userId, sessionId);

            if (response !== null && response !== undefined) {

                let error = IsError(response);

                if (error != 0) {
                    setErrorMsg(response.Errors[0].Message);
                }
                else {
                    let hasFile = response.data[0].HasFile;
                    let gwesRefId = response.data[0].GWESRefId;
                    let enrollmentAcctNo = response.data[0].EnrollmentAcctNo;
                    if (!hasFile) {

                        refetch();
                        setRowSelection({ rowId: false }); //removing row selection 
                        DisableApplOptCntrl();
                        setSuccessMsgTitle("Export to GWES");
                        setSuccessMsg("Enrollment exported to GWES sucessfully. GWES reference number " + enrollmentAcctNo);
                        return true;
                    }
                    else {
                        //File Transferring to GWES                       
                        await exportFileToGwes(selectedEnrolId.current, gwesRefId, enrollmentAcctNo);
                    }
                }

            }
        }
        catch (err) {
            setErrorMsg(ErrorMessages.UnknownError);
        }
        finally {
            setIsDataLoading(false);
        }

        return false;
    }

    const exportFileToGwes = async (enrollmentId, gwesRefId, enrollmentAcctNo) => {
        try {
            setIsDataLoading(false);
            setShowExportToGWESDlg(true);


            let inputJson = {
                "enrmntId": enrollmentId,
                "gwesRefId": gwesRefId
            }

            const apiUrl = new URL(
                process.env.REACT_APP_EXPORT_ATTACHMENT_ENROLLMENT,
                process.env.REACT_APP_BASE_URL,
            );

            let response = await APICall({ url: apiUrl.href, method: 'POST', payload: inputJson, contentType: 'application/json', responseType: '' }, userId, sessionId);

            if (response !== null && response !== undefined) {

                let error = IsError(response);

                if (error != 0) {                   
                    setErrorMsg(response.Errors[0].Message);
                }
                else {
                    refetch();
                    setRowSelection({ rowId: false }); //removing row selection 
                    DisableApplOptCntrl();
                    setSuccessMsgTitle("Export to GWES");
                    setSuccessMsg("Enrollment exported to GWES sucessfully. GWES reference number " + enrollmentAcctNo);
                    return true;
                }

            }
        
        }
        catch (err) {
            setErrorMsg(ErrorMessages.UnknownError);
        }
        finally {           
            setShowExportToGWESDlg(false);
        }

        return false;

    }


    const handleConfirmDelDialogClose = async (nvalue, svalue) => {

        setOpenDelConfirmDlg(false);

        if (nvalue == null)
            return;

        try {

            setIsDataLoading(true);

            const apiUrl = new URL(
                process.env.REACT_APP_DELETE_ENROLLMENT,
                process.env.REACT_APP_BASE_URL,
            );

            const inputJson = {
                enrmntId: parseInt(nvalue),
            };

            //let response = await CallAPI({ url: apiUrl.href, method: 'POST', body: inputJson }, userId, sessionId);

            let response = await APICall({ url: apiUrl.href, method: 'POST', payload: inputJson, contentType: 'application/json', responseType: '' }, userId, sessionId);

            if (response !== null && response !== undefined) {

                setIsDataLoading(false);

                let error = IsError(response);

                if (error != 0) {
                    setErrorMsg(response.Errors[0].Message);
                    return;
                }
                setSuccessMsgTitle("Application deleted");
                setSuccessMsg('Application named \'' + enrolNm.current + '\' deleted successfully.');
                refetch();
            }
        }
        catch (err) {
            setErrorMsg(ErrorMessages.UnknownError);
            setIsDataLoading(false);
        }
        finally {
            
        }
    };

    const updateEnrollmentStatus = async (enrmntStatusId, reason) => {

        try {

            if (enrmntStatusId == EnrollmentStatus.SubmittedEnrollment) {
                await exportToGwes();
                return;
            }

            const inputJson = {
                enrmntId: selectedEnrolId.current,
                enrmntStatusId: enrmntStatusId,
                stsReason: reason
            };

            setIsDataLoading(true);

            const apiUrl = new URL(
                process.env.REACT_APP_UPDATE_USER_ENROLMENT_STATUS,
                process.env.REACT_APP_BASE_URL,
            );

            //let response = await CallAPI({ url: apiUrl.href, method: 'POST', headers: {}, body: inputJson }, userId, sessionId);

            let response = await APICall({ url: apiUrl.href, method: 'POST', payload: inputJson, contentType: 'application/json', responseType: '' }, userId, sessionId);

            if (response !== null && response !== undefined) {

                if (response.Errors.length > 0) {
                    setErrorMsg(response.Errors[0].Message);
                }
                else if (response.meta.Updated === "1") {
                    refetch();
                    setRowSelection({ rowId: false }); //removing row selection 
                    DisableApplOptCntrl();
                    setSuccessMsgTitle("Application status updated");
                    setSuccessMsg("Application status updated successfully.");
                }

                setIsDataLoading(false);
            }
        }
        catch (err) {
            setErrorMsg(ErrorMessages.UnknownError);
            setIsDataLoading(false);
        }
        finally {
        }
    }

    const getEnrollmentStatus = async () => {

        const apiUrl = new URL(
            process.env.REACT_APP_GET_USER_ENROLMENT_STATUS,
            process.env.REACT_APP_BASE_URL,
        );

        try {

            //setIsDataLoading(true);

            let response = await APICall({ url: apiUrl.href, method: 'GET', contentType: 'application/json', responseType: '' }, userId, sessionId);

            if (response !== null && response !== undefined) {
                setUserEnrolmlSts(response.data)
            }
        }
        catch (err) {
            setAppStatusErrorMsg("ERROR while getting application information");
        }
        finally {
            //setIsDataLoading(false);
        }
    }
    const columns = [
        {
            accessorKey: 'ownerId',
            header: '',
            Cell: ({ renderedCellValue, row }) =>
                row.original.AttachmentCount > 0 ? (
                    <AttachFileIcon sx={{ fontSize: 20, color: '#757575'}} />
                ): (
                        ''
                ),
            muiTableBodyCellProps: ({ cell }) => ({
                sx: (theme) => ({
                    //borderLeft: '1px solid #d5dbdb',
                }),
            }),
            size: 30
        },
        {
            accessorKey: 'ownerName',
            header: 'Claim owner',
            Cell: ({ cell, column }) => (
                <Box>
                    {cell.getValue() && cell.getValue().length > 25 ? cell.getValue().substring(0, 25) + '...' : cell.getValue()}
                </Box>
            ),
            size: 230
        },
        {
            accessorKey: 'ClaimNumber',
            header: 'Claim number',
            muiTableBodyCellProps: ({ cell }) => ({
                sx: (theme) => ({
                    align: 'right'
                }),
            }),
            size: 160
        },
        //{
        //    accessorKey: 'name',
        //    header: 'Name',
        //},
        {
            accessorKey: 'enrmntStatus',
            header: 'Claim status',
            Cell: ({ cell, column }) => (
                <Box>
                    {cell.getValue() && cell.getValue().length > 25 ? cell.getValue().substring(0, 25) + '...' : cell.getValue()}
                </Box>
            ),
            size: 230
        },
        {
            accessorKey: 'CancelRequest',
            header: 'Cancellation Request',
            Cell: ({ cell, column }) => (
                <Box
                    sx={{
                        color: cell.getValue() === true ? '#fff' : '#000', p: 0.5
                    }}
                >
                    {cell.getValue() === true ? 'Yes' : 'No'}
                </Box>
            ),
            size: 230,
            muiTableHeadCellProps: ({ column }) => ({
                sx: (theme) => ({
                    pt: 0, pl: 0, pr: 0, pb: 0, fontSize: theme.typography.h6,
                }),
            }),
            muiTableBodyCellProps: ({ cell }) => ({
                sx: (theme) => ({
                    pt: 0, pl: 0, pr: 0,
                }),
            }),
        },
        {
            accessorKey: 'ownerEmail',
            header: 'Email',
            Cell: ({ cell, column }) => (
                <Box>
                    {cell.getValue() && cell.getValue().length > 25 ? cell.getValue().substring(0, 25) + '...' : cell.getValue()}
                </Box>
            ),
            size: 230,
        },
        {
            accessorKey: 'ModUser',
            header: 'Last Action By',
            Cell: ({ cell, column }) => (
                <Box>
                    {cell.getValue() && cell.getValue().length > 25 ? cell.getValue().substring(0, 25) + '...' : cell.getValue()}
                </Box>
            ),
            size: 230
        },
        {
            accessorKey: 'modDate',
            header: 'Date Modified',
            size: 230
        },
        {
            accessorKey: 'crtDate',
            header: 'Date Created',
            size: 230
        },
    ];

    const fetchSize = 25;

    const tableContainerRef = useRef(null); //we can get access to the underlying TableContainer element and react to its scroll events
    const virtualizerInstanceRef = useRef < Virtualizer > null; //we can get access to the underlying Virtualizer instance and call its scrollToIndex method

    const [columnFilters, setColumnFilters] = useState([]);
    const [globalFilter, setGlobalFilter] = useState();
    const [sorting, setSorting] = useState([]);

    const { data, fetchNextPage, isError, isFetching, isLoading, refetch } =
        useInfiniteQuery(
            ['table-data', columnFilters, globalFilter, sorting],
            async ({ pageParam = 0 }) => {

                const apiUrl = new URL(
                    process.env.REACT_APP_GET_USER_ENROLLMENTS,
                    process.env.REACT_APP_BASE_URL,
                );

                apiUrl.searchParams.set('start', `${pageParam * fetchSize}`);
                apiUrl.searchParams.set('size', `${fetchSize}`);

                apiUrl.searchParams.set('filters', JSON.stringify(columnFilters ?? []));
                apiUrl.searchParams.set('globalFilter', globalFilter ?? '');
                apiUrl.searchParams.set('sorting', sortMenuAction.current);

                let response = await APICall({ url: apiUrl.href, method: 'GET', contentType: 'application/json', responseType: '' }, userId, sessionId);

                if (response !== null && response !== undefined) {

                    if (response.data.length > 0) {
                        setDisStatusDiv(false);
                    }
                    return response;
                }
            //    else {
            //        setErrorMsg(ErrorMessages.UnknownError);
            //        throw Error(ErrorMessages.UnknownError);
            //    }                
            },
            {
                getNextPageParam: (_lastGroup, groups) => groups.length,
                keepPreviousData: true,
                refetchOnWindowFocus: false,
            },
        );

    const flatData = useMemo(
        () => data?.pages.flatMap((page) => page.data) ?? [],
        [data],
    );

    const totalDBRowCount = data?.pages?.[0]?.meta?.totalRowCount ?? 0;
    const totalFetched = flatData.length;
    const fetchMoreOnBottomReached = useCallback(
        (containerRefElement) => {
            if (containerRefElement) {
                const { scrollHeight, scrollTop, clientHeight } = containerRefElement;
                if (
                    scrollHeight - scrollTop - clientHeight < 400 &&
                    !isFetching &&
                    totalFetched < totalDBRowCount
                ) {
                    fetchNextPage();
                }
            }
        },
        [fetchNextPage, isFetching, totalFetched, totalDBRowCount],
    );

    const printAppData = async (enrmntId) => {
        //console.log('print');  
        //return;
        let response = await getEnrollment(enrmntId);

        if (response && response.length > 0) {
            let enrolData = response[0].enrmntData;
            var divText = '<div>'
            divText = divText.concat('<div style="position: absolute; width: 100%;"><h4 style="text-align: center;">Review Application</h4></div>');
            /* divText = divText.concat('<hr />');*/
            //divText = divText.concat(renderToString(<ApplicationEnrollmentPrint id="ringtone-from-menu" value={enrolData} />));  //document.getElementById("printablediv").innerHTML;
            //divText = divText.concat(renderToString(<ApplicationEnrollmentPrint id="ringtone-from-menu" enrollData={response[0].enrmntData} enrmntid={enrmntId} acctType={response[0].templateNm} applicantImage={imageUrl} avtarImage={profimg} session={sessions} dispatch={dispatch} />));  //document.getElementById("printablediv").innerHTML;
            divText = divText.concat('</div>');

            let width = window.screen.width - 10;
            let height = window.screen.height - 10;
            var win = window.open('', '_blank', "toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=" + width + ",height=" + height);

            let printerIcon = '<svg fill="#000" height="36px" width="36px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" ';
            printerIcon += 'viewBox="-23.68 -23.68 111.36 111.36" enable-background="new 0 0 64 64" xml:space="preserve" stroke="#0000" stroke-width="1.6"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_iconCarrier"> <g id="Printer"> <path d="M57.7881012,14.03125H52.5v-8.0625c0-2.2091999-1.7909012-4-4-4h-33c-2.2091999,0-4,1.7908001-4,4v8.0625H6.2119002 C2.7871001,14.03125,0,16.8183498,0,20.2431507V46.513649c0,3.4248009,2.7871001,6.2119026,6.2119002,6.2119026h2.3798995 c0.5527,0,1-0.4472008,1-1c0-0.5527-0.4473-1-1-1H6.2119002C3.8896,50.7255516,2,48.8359489,2,46.513649V20.2431507 c0-2.3223,1.8896-4.2119007,4.2119002-4.2119007h51.5762024C60.1102982,16.03125,62,17.9208508,62,20.2431507V46.513649 c0,2.3223-1.8897018,4.2119026-4.2118988,4.2119026H56c-0.5527992,0-1,0.4473-1,1c0,0.5527992,0.4472008,1,1,1h1.7881012 C61.2128983,52.7255516,64,49.9384499,64,46.513649V20.2431507C64,16.8183498,61.2128983,14.03125,57.7881012,14.03125z M13.5,5.96875c0-1.1027999,0.8971996-2,2-2h33c1.1027985,0,2,0.8972001,2,2v8h-37V5.96875z"></path> <path d="M44,45.0322495H20c-0.5517998,0-0.9990005,0.4472008-0.9990005,0.9990005S19.4482002,47.0302505,20,47.0302505h24 c0.5517006,0,0.9990005-0.4472008,0.9990005-0.9990005S44.5517006,45.0322495,44,45.0322495z"></path> <path d="M44,52.0322495H20c-0.5517998,0-0.9990005,0.4472008-0.9990005,0.9990005S19.4482002,54.0302505,20,54.0302505h24 c0.5517006,0,0.9990005-0.4472008,0.9990005-0.9990005S44.5517006,52.0322495,44,52.0322495z"></path> <circle cx="7.9590998" cy="21.8405495" r="2"></circle> <circle cx="14.2856998" cy="21.8405495" r="2"></circle> <circle cx="20.6121998" cy="21.8405495" r="2"></circle> <path d="M11,62.03125h42v-26H11V62.03125z M13.4036999,38.4349518h37.1925964v21.1925964H13.4036999V38.4349518z"></path> </g> </g></svg>';

            //let closeIcon = '<svg fill="#000000" height="36px" width="36px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 36 36" xml:space="preserve">';
            //closeIcon += '<g><g><polygon points="512,59.076 452.922,0 256,196.922 59.076,0 0,59.076 196.922,256 0,452.922 59.076,512 256,315.076 452.922,512 512,452.922 315.076,256 		"/></g></g></svg>';

            win.document.open();
            win.document.write('<html>');
            win.document.write('<head>');
            win.document.write('<title>Application Review</title>');
            //win.document.write('<link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossorigin="anonymous">');
            //win.document.write('<script src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/js/bootstrap.bundle.min.js" integrity="sha384-MrcW6ZMFYlzcLA8Nl+NtUVF0sA7MsXsP1UyJoMp4YLEuNSfAP+JcXn/tWtIaxVXM" crossorigin="anonymous"></script>');
            win.document.write('<style type="text/css" media="print">.no-print {display: none !important; }</style>');

            win.document.write('</head>');
            win.document.write('<body onload="window.print()">');
            win.document.write('<p style="display: flex;justify-content: right; text-decoration-style: solid;font-weight: bold; margin-top:20px;margin-right:50px;" class="no-print">');
            win.document.write('<span class="no-print" style="cursor: pointer;" onclick="{window.print();}" title="Print">' + printerIcon + '</span>');
            win.document.write('<span class="no-print" style="cursor: pointer;margin-top:5px;margin-left:5px;" onclick="{window.close();}" title="Close">&#10006;</span>');
            win.document.write('</p>');

            win.document.write(divText);
            win.document.write('</body></html>');
            win.document.close();
            win.open();

            setPrintApp(false);
        }
        //setTimeout(() => {
        //    win.print();
        //}, 1000);
    }

    useEffect(() => {

        if (virtualizerInstanceRef.current) {
            virtualizerInstanceRef.current.scrollToIndex(0);
        }
    }, [sorting, columnFilters, globalFilter]);

    useEffect(() => {

        fetchMoreOnBottomReached(tableContainerRef.current);        
    }, [fetchMoreOnBottomReached]);

    useEffect(() => {
        //getEnrollmentStatus();
    }, []);

    const handleCreateNewUser = () => {

    };
    const handleRemoveUsers = () => {
    };

    const handleChange = (event) => {
        setRowSelection('500', true);
    };

    const handleAxiosError = async (ex) => {

        let action = await GetAxiosErrorAction(ex);
        if (action.redirectUrl != null) {

            navigate({
                pathname: action.redirectUrl,
                search: action.searchParam,
                state: {},
                replace: true
            });
        }
        else if (action.Message != ''){
            setErrorMsg(action.Message);
        }
    }

    const HandleRowClick = async (row) => {
        setRowId(row.id);
        selectedEnrolId.current = row.original.enrmntId;
        setApplicantsName(row.original.ownerName ? (row.original.ownerTitle ? row.original.ownerTitle + ' ' + row.original.ownerName : row.original.ownerName) : 'Applicant');
        setApplicantEmail(row.original.ownerEmail);
        setOwnerAge(row.original.ownerAge ? 'Age: ' + row.original.ownerAge + ' Yrs.' : '');
        setOwnerMobileNbr(row.original.mobileNbr ? 'M: ' + row.original.mobileNbr : '');
        setAttachmentCount(row.original.AttachmentCount);
        setDocuSignRequiresCount(row.original.DocuSignCount);
        setDocuSignCompletedCount(row.original.DocuSignCompletedCount);
        setDocuSignDeclineCount(row.original.DocuSignDeclineCount);
        setSubmittedDate(row.original.SubmittedDate);
        enrolNm.current = row.original.name;

        try {

            setIsDataLoading(true);

            const apiUrl = new URL(
                process.env.REACT_APP_GET_APPLICANT_IMAGE,
                process.env.REACT_APP_BASE_URL,
            );

            apiUrl.searchParams.set('enrmntId', `${parseInt(row.original.enrmntId)}`);
            apiUrl.searchParams.set('tag', 'ApplicantImage');

            //let imageURL = await GetApplicantImage(apiUrl.href, userId, sessionId);

            let response = await APICall({ url: apiUrl.href, method: 'GET', responseType: 'blob' }, userId, sessionId);

            if (response) {
                if (response.type == 'image/jpeg') {
                    setImageUrl(response);
                }
                else {
                    setImageUrl(null);
                }
                setIsDataLoading(false);
            }           
        }
        catch (err) {
            setImageUrl(null);
            setErrorMsg(ErrorMessages.UnknownError);
            setIsDataLoading(false);
        }
        finally {
        }

        await EnableDisableApplOpt(row);
        await EnableDisableApplCntrl(row);
    }
    const DisableAllActions = async () => {
        setDisRevOpt(true);
        setDisRevPassOpt(true);
        setDisRevFailOpt(true);
        setDisKYCAMLCheckOpt(true);
        setDisKYCAMLPassOpt(true);
        setDisKYCAMLFailOpt(true);
        setDisDocuSignOpt(true);
        setDisDocuSignCompletedOpt(true);
        setDisSubmitForEnrollOpt(true);
        setDisAccountActivatedOpt(true);
        setDisRejectOpt(true);
        setDisCancelOpt(true);
    }
    const EnableDisableApplOpt = async (row) => {
        
        DisableAllActions();

        if (row.original.CancelRequest == true) {
            setDisCancelOpt(false);
        }

        switch (row.original.enrmntStatusId) {
            case EnrollmentStatus.SubmitApplication:
                setDisRevOpt(false);
                break;
            case EnrollmentStatus.UnderReview:
                setDisRevPassOpt(false);
                setDisRevFailOpt(false);
                setDisRejectOpt(false);
                break;
            case EnrollmentStatus.ReviewPass:
                setDisKYCAMLCheckOpt(false);
                break;
            case EnrollmentStatus.KYCAMLInitiated:
                setDisKYCAMLPassOpt(false);
                setDisKYCAMLFailOpt(false);
                setDisRejectOpt(false);
                break;
            case EnrollmentStatus.KYCAMLPassed:
                setDisSubmitForEnrollOpt(false);
                setDisRejectOpt(false);
                break;
            case EnrollmentStatus.SubmittedEnrollment:
                setDisDocuSignOpt(false);
                setDisRejectOpt(false);
                break;
            case EnrollmentStatus.DocuSignRequired:
                setDisDocuSignCompletedOpt(false);
                setDisRejectOpt(false);
                break;
            case EnrollmentStatus.DocuSignCompleted:
                setDisDocuSignOpt(false);
                setDisAccountActivatedOpt(false);
                setDisRejectOpt(false);
                break;
            default:
            // code block
        }
    }

    const EnableDisableApplCntrl = async (row) => {
        setDisViewApp(true);
        setDisUploadDoc(true);
        setDisDownloadApp(true);
        setDisPrintApp(true);
        setDisViewDoc(true);
        setDisEditApp(true);
        setDisDeleteApp(true);

        //let userID = 0;

        //let userID = GetLoggedInUserId();

        if (row.original.enrmntStatusId !== EnrollmentStatus.InComplete && row.original.enrmntStatusId !== EnrollmentStatus.Complete
            && row.original.enrmntStatusId !== EnrollmentStatus.CancelApplication && row.original.enrmntStatusId !== EnrollmentStatus.RejectApplication) {
            setDisUploadDoc(false);
        }

        if ((row.original.enrmntStatusId === EnrollmentStatus.InComplete || row.original.enrmntStatusId === EnrollmentStatus.Complete)
            && row.original.IsProspectCrtUser === false)
        {
            setDisEditApp(false);
        }

        let canDelete = IsInRole(AOBSystemProcess.DeleteApplication, userId, sessionId);
        if ((row.original.IsProspectCrtUser === false && (parseInt(userId) == parseInt(row.original.crtUsrId))) || canDelete) {
            setDisDeleteApp(false);
        }

        if (row.original.enrmntStatusId !== EnrollmentStatus.InComplete && row.original.enrmntStatusId !== EnrollmentStatus.Complete
            && row.original.enrmntStatusId !== EnrollmentStatus.CancelApplication && row.original.enrmntStatusId !== EnrollmentStatus.RejectApplication) {
            setDisViewDoc(false);
            setDisDownloadApp(false);
        }
        
        if (row.original.enrmntStatusId !== EnrollmentStatus.InComplete && row.original.enrmntStatusId !== EnrollmentStatus.Complete) {
            setDisViewApp(false);
        }
    }

    const DisableApplOptCntrl = () => {
        setDisRevOpt(true);
        setDisRevPassOpt(true);
        setDisRevFailOpt(true);
        setDisKYCAMLCheckOpt(true);
        setDisKYCAMLPassOpt(true);
        setDisKYCAMLFailOpt(true);
        setDisDocuSignOpt(true);
        setDisSubmitForEnrollOpt(true);
        setDisRejectOpt(true);
        setDisAccountActivatedOpt(true);

        setDisViewApp(true);
        setDisUploadDoc(true);
        setDisDownloadApp(true);
        setDisPrintApp(true);
        setDisViewDoc(true);
        setDisEditApp(true);
    }

    function createData(
        name,
        calories,
        fat,
        carbs,
        protein,
    ) {
        return { name, calories, fat, carbs, protein };
    }

    const rows = [
        createData('Submitted', 159, 6.0, 24, 4.0),
        createData('Cancelled', 237, 9.0, 37, 4.3),
        createData('Under Review', 262, 16.0, 24, 6.0),
        createData('Rejected', 305, 3.7, 67, 4.3),
        createData('Request for Cancellation', 356, 16.0, 49, 3.9),
        createData('Review Passed', 356, 16.0, 49, 3.9),
        createData('Review Failed', 356, 16.0, 49, 3.9),
        createData('KYC/AML Initiated', 356, 16.0, 49, 3.9),
        createData('KYC/AML Passed', 356, 16.0, 49, 3.9),
        createData('KYC/AML Failed', 356, 16.0, 49, 3.9),
        createData('Submitted for Enrollment', 356, 16.0, 49, 3.9),
        createData('DocuSign Required', 356, 16.0, 49, 3.9),
        createData('DocuSign Completed', 356, 16.0, 49, 3.9),
        createData('Account Activated', 356, 16.0, 49, 3.9),

    ];

    const handleEditAppClick = async (table) => {
        if (table.getSelectedRowModel().rows.length > 0) {
            let enrmntId = table.getSelectedRowModel().rows[0].original.enrmntId;
            selectedEnrolId.current = enrmntId;
            var resposne = await getEnrollment(enrmntId);
            if (resposne && resposne.length > 0) {
                templateId.current = resposne[0]["templateId"];
                enrolNm.current = resposne[0]["name"];
                templateNm.current = resposne[0]["templateNm"];
                setEnrolModalShow(true);
            }
        }
    }

    const handleAddAppClick = () => {
        setMoreMnuAnchorEl(null);
        selectedEnrolId.current = 0;
        templateId.current = -1;
        enrolNm.current = null;
        templateNm.current = null;
        setEnrolModalShow(true);
    }

    const handleReviewAppClick = async (table) => {
        setMoreMnuAnchorEl(null);
        if (table.getSelectedRowModel().rows.length > 0) {
            let enrmntId = table.getSelectedRowModel().rows[0].original.enrmntId;
            await reviewApp(enrmntId);
        }
    }

    const handlePrintAppClick = async (table) => {

        setMoreMnuAnchorEl(null);
        if (table.getSelectedRowModel().rows.length > 0) {
            let enrmntId = table.getSelectedRowModel().rows[0].original.enrmntId;
            await printAppData(enrmntId);
        }
    }

    const handleViewDocClick = async (table) => {
        if (table.getSelectedRowModel().rows.length > 0) {
            templateNm.current = table.getSelectedRowModel().rows[0].original.templateNm;
            enrolNm.current = table.getSelectedRowModel().rows[0].original.name;
            setShowDocListDlg(true);
        }
    };
    const handleUploadDoc = async (table) => {

        
        if (table.getSelectedRowModel().rows.length > 0) {
            let enrmntId = table.getSelectedRowModel().rows[0].original.enrmntId;
            selectedEnrolId.current = enrmntId;
            templateNm.current = table.getSelectedRowModel().rows[0].original.templateNm;
            enrolNm.current = table.getSelectedRowModel().rows[0].original.name;
            setShowDocUpldDlg(true); 
        }
    }

    const handleDeleteAppClick = async (table) => {
        setMoreMnuAnchorEl(null);

        confirmDelDlgHdr.current = 'Delete file';
        confirmDelDlgBLine1.current = "Do you want to delete the application named as '" + enrolNm.current + "' that is created by '" + applicantsName + "' ?";
        setOpenDelConfirmDlg(true);
    }

    const handleDownloadAppClick = async (table) => {

        setMoreMnuAnchorEl(null);
        if (table.getSelectedRowModel().rows.length < 1)
            return;

        let enrmntId = table.getSelectedRowModel().rows[0].original.enrmntId;

        let userInfo = {
            ApplicantsName: applicantsName,
            ApplicantEmail: emailAdrs.current,
            ApplicantsImg: imageUrl,
            AvtarImage: profimg,
            BankLogo: BLogo
        }

        try {
            let errorMsg = await ApplicationAllDocumentDownload(enrmntId, userId, sessionId, userInfo);
            if (errorMsg !== '')
                setErrorMsg(errorMsg);
        }
        catch (err) {
            setErrorMsg(ErrorMessages.UnknownError);
        }
        finally {
            setIsFormLoading(false);
        }
    }

    const reviewApp = async (enrmntId) => {

        setMoreMnuAnchorEl(null);

        let response = await getEnrollment(enrmntId);

        if (response && response.length > 0) {
            selectedEnrolmntValue.current = response[0].enrmntData;
            selectedEnrolId.current = enrmntId;
            selectedTemplateNm.current = enrmntId;
            setShowAppViewDlg(true);
        }
    }

    const getEnrollment = async (enrmntId) => {

        try {

            setIsDataLoading(true);

            const apiUrl = new URL(
                process.env.REACT_APP_GET_ENROLLMENT_BY_ID,
                process.env.REACT_APP_BASE_URL,
            );
            apiUrl.searchParams.set('enrmntId', `${parseInt(enrmntId)}`);

            //let response = await CallAPI({ url: apiUrl.href, method: 'GET', headers: {} }, userId, sessionId);
            let response = await APICall({ url: apiUrl.href, method: 'GET', contentType: 'application/json', responseType: '' }, userId, sessionId);

            if (response !== null && response !== undefined) {
                setIsDataLoading(false);
                if (response["data"] && response["data"].length > 0) {
                    return response["data"]
                }
                else {

                    let apiErrors = response["Errors"];

                    if (apiErrors != undefined && apiErrors.length > 0) {
                        setErrorMsg(apiErrors[0].Message);
                    }

                    setErrorMsg(ErrorMessages.UnknownError);
                }

            }
        }
        catch (err) {
            setErrorMsg(ErrorMessages.UnknownError);
            setIsDataLoading(false);
        }
        finally {
            
        }

        return null;
    }

    const handleAccordianChange = () => {
        if (expanded) {
            setExpanded(false);
        }
        else {
            setExpanded(true);
        }
    }

    const handleSortDtNewMenuClick = (event) => {
        setSortMnuAnchorEl(null);
        sortMenuAction.current = ApplicationSortAction.Newest;
        refetch();

        SetSortMenuTick(ApplicationSortAction.Newest);
    }

    const handleSortDtOldMenuClick = (event) => {
        setSortMnuAnchorEl(null);
        sortMenuAction.current = ApplicationSortAction.Oldest;
        refetch();

        SetSortMenuTick(ApplicationSortAction.Oldest);
    }

    const handleSortOwnerMenuClick = (event) => {
        setSortMnuAnchorEl(null);
        sortMenuAction.current = ApplicationSortAction.Owner;
        refetch();

        SetSortMenuTick(ApplicationSortAction.Owner);
    }

    const handleSortAcctTypeMenuClick = (event) => {
        setSortMnuAnchorEl(null);
        sortMenuAction.current = ApplicationSortAction.AccountType;
        refetch();

        SetSortMenuTick(ApplicationSortAction.AccountType);
    }

    const handleSortLastActionByMenuClick = (event) => {
        setSortMnuAnchorEl(null);
        sortMenuAction.current = ApplicationSortAction.ModUser;
        refetch();

        SetSortMenuTick(ApplicationSortAction.ApplName);
    }

    const handleSortStatusMenuClick = (event) => {
        setSortMnuAnchorEl(null);
        sortMenuAction.current = ApplicationSortAction.Status;
        refetch();

        SetSortMenuTick(ApplicationSortAction.Status);
    }

    function SetSortMenuTick(item) {
        tickSrtDtNewDisp.current = 'none';
        tickSrtDtOldDisp.current = 'none';
        tickSrtOwnerDisp.current = 'none';
        tickSrtAccTypeDisp.current = 'none';
        tickSrtApplNameDisp.current = 'none';
        tickSrtStatusDisp.current = 'none';

        if (item == ApplicationSortAction.Newest)
            tickSrtDtNewDisp.current = 'block';

        if (item == ApplicationSortAction.Oldest)
            tickSrtDtOldDisp.current = 'block';

        if (item == ApplicationSortAction.Owner)
            tickSrtOwnerDisp.current = 'block';

        if (item == ApplicationSortAction.AccountType)
            tickSrtAccTypeDisp.current = 'block';

        if (item == ApplicationSortAction.ApplName)
            tickSrtApplNameDisp.current = 'block';

        if (item == ApplicationSortAction.Status)
            tickSrtStatusDisp.current = 'block';
    }

    //const Accordion = styled((props) => (
    //    <MuiAccordion disableGutters elevation={0} square {...props} />
    //))(({ theme }) => ({
    //    border: `0px solid ${theme.palette.divider}`,
    //    '&:not(:last-child)': {
    //        borderBottom: 1,
    //    },
    //    '&:before': {
    //        display: 'none',
    //    },
    //    '&:after': {
    //        display: 'none',
    //    },
    //}));

    //const AccordionSummary = styled((props) => (
    //    <MuiAccordionSummary disableGutters
    //        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    //        {...props}
    //    />
    //))(({ theme }) => ({
    //    backgroundColor:
    //        theme.palette.mode === 'dark'
    //            ? 'rgba(255, 255, 255, .05)'
    //            : 'rgba(0, 0, 0, .03)',
    //    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    //        transform: 'rotate(90deg)',
    //    },
    //    '& .MuiAccordionSummary-content': {
    //        marginLeft: theme.spacing(1),
    //    },
    //}));

    //const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    //    padding: 0, margin: 0,
    //    borderTop: '1px solid rgba(0, 0, 0, .125)',
    //}));

    return (
        <React.Fragment>
            <Container maxWidth="xl" sx={{}}>
                <OverlaySpinner disappear={isDataLoading} />
                <PostLoginMenu userId={userId} sessionId={sessionId} />
                <Paper className=""
                    sx={{
                        position: 'relative',
                        backgroundColor: '#fff',
                        color: '#fff',
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        pb: 12
                    }}
                >
                    <Grid item>
                        <Box sx={{
                            pr: { xs: 3, md: 2 },
                            pl: { xs: 3, md: 2 }
                        }}>
                            <Accordion spacing={0} elevation={0} expanded={expanded} onChange={handleAccordianChange} sx={{ m: 0, p: 0, width: '100%' }}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header" sx={{ mr: 2 }}>
                                </AccordionSummary>
                                <AccordionDetails sx={{ pr: 2, pl: 0, pb: 0 }}>
                                    <DrawerAppBar userId={userId} sessionId={sessionId} />
                                </AccordionDetails>
                            </Accordion>
                        </Box>
                    </Grid>
                    <Grid item>
                        <Box sx={{ ml: 6, mr: 6, border: '0px solid #000', pt: 1 }}>
                            <Typography variant="h3" color="text.primary" sx={{ borderBottom: '1px solid rgba(0,0,0,0.1)', lineHeight: 2.2, fontWeight: 500, letterSpacing: '-1px', textAlign: 'center' }} >Claim Settlement</Typography>
                        </Box>
                    </Grid>
                    <Grid item>
                        <Box sx={{ ml: 14, mr: 14, borderBottom: '1px solid rgba(0,0,0,0.1)', pt: 1 }}>
                            {/*<Typography variant="h3" color="text.primary" sx={{ borderBottom: '1px solid rgba(0,0,0,0.1)', lineHeight: 2.2, fontWeight: 500, letterSpacing: '-1px', textAlign: 'center' }} >Claim Settlement</Typography>*/}
                            <Typography sx={{ mt: 2, pb: 3, fontWeight: 400, lineHeight: 1.3 }} variant="h6" color="text.secondary">
                                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur unde suscipit, quam beatae rerum inventore consectetur, neque doloribus, cupiditate numquam dignissimos laborum fugiat deleniti? Eum quasi quidem quibusdam.
                            </Typography>
                            <Typography sx={{ mb: 2, fontWeight: 400, lineHeight: 1.3 }} variant="h6" color="text.secondary">
                                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur unde suscipit, quam beatae rerum inventore consectetur, neque doloribus, cupiditate numquam dignissimos laborum fugiat deleniti? Eum quasi quidem quibusdam.
                            </Typography>
                            <Typography sx={{ mb: 4, fontWeight: 400, lineHeight: 1.3 }} variant="h6" color="text.secondary">
                                In this page, view and manage all the claims registered by clients.
                                You can take different actions to these claims depending on the status of the claims.
                            </Typography>
                        </Box>
                        <Box sx={{ pl: 14, pr: 14, pt: 0 }}>
                            <Paper elevation={0} sx={{ }}>
                                <AlertControl Message={errorMsg} severity="error" color="error" icon={true} />
                            </Paper>
                            <Paper elevation={0}>
                                <AlertWithTitle Message={successMsg} variant="body1" severity="success" color="success" icon={true} Title={successMsgTitle} />
                            </Paper>
                            <MaterialReactTable
                                columns={columns}
                                data={flatData}
                                displayColumnDefOptions={{
                                    'mrt-row-select': {
                                        maxSize: 12,
                                        header: '',
                                    },
                                }}
                                enableColumnActions={false}
                                enableSorting={false}
                                enableExpandAll={false}
                                enablePagination={false}
                                enableRowNumbers={false}
                                enableRowVirtualization
                                enableHiding={false}
                                enableColumnOrdering={false}
                                muiTableBodyRowProps={({ row }) => ({
                                    onClick: () => {
                                        setRowSelection((prev) => ({
                                            [row.id]: true,
                                        }));
                                        HandleRowClick(row);
                                    },
                                    sx: {
                                        cursor: 'pointer',
                                    },
                                })}
                                positionActionsColumn="last"
                                enableColumnResizing={false}
                                enableDensityToggle={false}
                                enableFullScreenToggle={false}
                                muiTableBodyCellProps={({ column }) => ({
                                    sx: (theme) => ({
                                        color: theme.palette.text.secondary,
                                        fontSize: theme.typography.body1,
                                        lineHeight: 1,
                                        borderBottom: '1px solid rgba(0,0,0,0.1)',
                                        pl: 0, pr: 0, pt: 0, pb: 0
                                    }),
                                })
                                }

                                muiTableHeadCellProps={{
                                    sx: (theme) => ({
                                        color: '#383d3f',
                                        fontSize: theme.typography.h6,
                                        borderBottom: '1px solid rgba(0,0,0,0.1)',
                                        fontWeight: 700, elevation: 0,
                                        pt: 0, pl: 0, pr: 0,
                                        pb: 0, textDecoration: 'underline'
                                    }),
                                }}

                                muiTableContainerProps={{
                                    ref: tableContainerRef,
                                    sx: { maxHeight: '550px' },
                                    onScroll: (
                                        event,
                                    ) => fetchMoreOnBottomReached(event.target),
                                }}
                                muiToolbarAlertBannerProps={
                                    isError
                                        ? {
                                            color: 'error',
                                            children: ErrorMessages.UnknownError,
                                        }
                                        : undefined
                                }
                                onColumnFiltersChange={setColumnFilters}
                                onGlobalFilterChange={setGlobalFilter}
                                onSortingChange={setSorting}
                                renderBottomToolbarCustomActions={() => (
                                    <Box sx={{ mt: 2 }}>
                                        <Typography>
                                            {
                                                !isError && data
                                                    ? totalFetched + ' of ' + totalDBRowCount + ' application(s)'
                                                    : ''
                                            }
                                        </Typography>
                                    </Box>
                                )}
                                onRowSelectionChange={setRowSelection}
                                state={{
                                    columnFilters,
                                    globalFilter,
                                    isLoading,
                                    showAlertBanner: isError,
                                    showProgressBars: isFetching,
                                    rowSelection,
                                    sorting,
                                }}
                                initialState={{
                                }}
                                muiTablePaperProps={{
                                    elevation: 0,
                                }}
                                rowVirtualizerProps={{ overscan: 2 }}
                                localization={{
                                    noRecordsToDisplay: 'No application to display',
                                    selectedCountOfRowCountRowsSelected: '',
                                }}
                                renderTopToolbarCustomActions={({ table }) => {
                                    return (
                                        <Box sx={{
                                            display: 'flex', alignItems: 'center', flexDirection: 'row'
                                        }}>
                                            <Typography variant="h6" color="text.primary" sx={{ pr: 4, fontWeight: 600, letterSpacing: '-1px' }}>Claims</Typography>
                                            <Typography variant="body1" color="text.primary" sx={{ pr: 0.5, }}>Sort</Typography>
                                            <IconButton sx={{ border: 0, borderColor: '#235ec9', p: 0, pr: 2 }} onClick={handleSortMnuClick}>
                                                <ExpandMoreIcon sx={{fontSize: 24}} />
                                            </IconButton>
                                            <Menu
                                                id="basic-menu"
                                                anchorEl={sortMnuAnchorEl}
                                                open={openSort}
                                                onClose={handleSortMnuClose}
                                                MenuListProps={{
                                                    'aria-labelledby': 'basic-button',
                                                }}
                                            >
                                                <MenuItem key={0} sx={{mt: 1}} onClick={(event) => handleSortDtNewMenuClick(event)}>
                                                    <ListItemIcon >
                                                        <CheckIcon sx={{ display: tickSrtDtNewDisp.current, fontSize: 24, color: '#0070CC' }} />
                                                    </ListItemIcon>
                                                    <Typography variant="body2" sx={{ textTransform: 'none', mr: 2 }} color="text.primary">Date: Newest on top</Typography>
                                                </MenuItem>
                                                <MenuItem key={1} onClick={(event) => handleSortDtOldMenuClick(event)}>
                                                    <ListItemIcon>
                                                        <CheckIcon sx={{ display: tickSrtDtOldDisp.current, fontSize: 24, color: '#0070CC' }} />
                                                    </ListItemIcon>
                                                    <Typography variant="body2" sx={{ textTransform: 'none', mr: 2 }} color="text.primary">Date: Oldtest on top</Typography>
                                                </MenuItem>
                                                <MenuItem key={2} onClick={(event) => handleSortOwnerMenuClick(event)}>
                                                    <ListItemIcon>
                                                        <CheckIcon sx={{ display: tickSrtOwnerDisp.current, fontSize: 24, color: '#0070CC' }}  />
                                                    </ListItemIcon>
                                                    <Typography variant="body2" sx={{ textTransform: 'none', mr: 2 }} color="text.primary">Claim Owner</Typography>
                                                </MenuItem>
                                                {/*<MenuItem key={3} onClick={(event) => handleSortAcctTypeMenuClick(event)}>*/}
                                                {/*    <ListItemIcon className="ms-3 mt-1 mb-1">*/}
                                                {/*        <CheckIcon sx={{ display: tickSrtAccTypeDisp.current, fontSize: 24, color: '#0070CC' }} />*/}
                                                {/*    </ListItemIcon>*/}
                                                {/*    <Typography className="me-5" variant="body2" sx={{ textTransform: 'none' }} color="text.primary">Account type</Typography>*/}
                                                {/*</MenuItem>*/}
                                                <MenuItem key={5} onClick={(event) => handleSortStatusMenuClick(event)}>
                                                    <ListItemIcon>
                                                        <CheckIcon sx={{ display: tickSrtStatusDisp.current, fontSize: 24, color: '#0070CC' }} />
                                                    </ListItemIcon>
                                                    <Typography variant="body2" sx={{ textTransform: 'none', mr: 2 }} color="text.primary">Case Status</Typography>
                                                </MenuItem>
                                                <MenuItem key={4} onClick={(event) => handleSortLastActionByMenuClick(event)}>
                                                    <ListItemIcon>
                                                        <CheckIcon sx={{ display: tickSrtApplNameDisp.current, fontSize: 24, color: '#0070CC' }} />
                                                    </ListItemIcon>
                                                    <Typography variant="body2" sx={{ textTransform: 'none', mr: 2, mb: 2 }} color="text.primary">Last Action By</Typography>
                                                </MenuItem>
                                            </Menu>

                                            <Typography variant="body1" color="text.primary" sx={{ pr: 0.5 }}>Action</Typography>
                                            <IconButton sx={{ border: 0, borderColor: '#235ec9', padding: '0px' }} onClick={handleOptMenuClick}>
                                                <ExpandMoreIcon sx={{ fontSize: 24 }} />
                                            </IconButton>
                                            <Menu
                                                id="action-menu"
                                                anchorEl={optMnuAnchorEl}
                                                open={open}
                                                onClose={handleOptMenuClose}
                                            >
                                                <MenuItem sx={{ml: 3, mt: 1, mr: 3}} key={0} disabled={disRevOpt} onClick={() => handleReviewClick(table)}>
                                                    <Typography sx={{}} variant="body2" sx={{ textTransform: 'none' }} color="text.primary">Review</Typography>
                                                </MenuItem>
                                                <MenuItem sx={{ ml: 3, mr: 3 }} key={1} disabled={disRevPassOpt} onClick={() => handleReviewPassClick(table)}>
                                                    <Typography sx={{}} variant="body2" sx={{ textTransform: 'none' }} color="text.primary">Review passed</Typography>
                                                </MenuItem>
                                                <MenuItem sx={{ ml: 3, mb: 1, mr: 3 }} key={2} disabled={disRevFailOpt} onClick={() => handleReviewFailClick(table)}>
                                                    <Typography sx={{}} variant="body2" sx={{ textTransform: 'none' }} color="text.primary">Review failed</Typography>
                                                </MenuItem>
                                                <Divider />
                                                <MenuItem sx={{ ml: 3, mt: 1, mr: 3 }} key={3} disabled={disKYCAMLCheckOpt} onClick={() => handleKYCAMLClick(table)}>
                                                    <Typography sx={{}} variant="body2" sx={{ textTransform: 'none' }} color="text.primary">Initiated KYC/AML</Typography>
                                                </MenuItem>
                                                <MenuItem sx={{ ml: 3, mr: 3 }} key={4} disabled={disKYCAMLPassOpt} onClick={() => handleKYCAMLPassClick(table)}>
                                                    <Typography sx={{}} variant="body2" sx={{ textTransform: 'none' }} color="text.primary">KYC/AML passed</Typography>
                                                </MenuItem>
                                                <MenuItem sx={{ ml: 3, mb: 1, mr: 3 }} key={5} disabled={disKYCAMLFailOpt} onClick={() => handleKYCAMLFailClick(table)}>
                                                    <Typography sx={{}} variant="body2" sx={{ textTransform: 'none' }} color="text.primary">KYC/AML failed</Typography>
                                                </MenuItem>
                                                <Divider />
                                                <MenuItem sx={{ ml: 3, mb: 1, mr: 3 }} key={6} disabled={disDocuSignOpt} onClick={() => handleDocuSignReqClick(table)}>
                                                    <Typography sx={{}} variant="body2" sx={{ textTransform: 'none' }} color="text.primary">DocuSign required</Typography>
                                                </MenuItem>
                                                <MenuItem sx={{ ml: 3, mb: 1, mr: 3 }} key={6} disabled={disDocuSignCompletedOpt} onClick={() => handleDocuSignCompletedClick(table)}>
                                                    <Typography sx={{}} variant="body2" sx={{ textTransform: 'none' }} color="text.primary">DocuSign completed</Typography>
                                                </MenuItem>
                                                <Divider />
                                                <MenuItem sx={{ ml: 3, mb: 1, mr: 3 }} key={7} disabled={disSubmitForEnrollOpt} onClick={() => handleSubmitForEnrollClick(table)}>
                                                    <Typography sx={{}} variant="body2" sx={{ textTransform: 'none' }} color="text.primary">Submit for enrollment</Typography>
                                                </MenuItem>
                                                <Divider />
                                                <MenuItem sx={{ ml: 3, mb: 1, mr: 3 }} key={8} disabled={disAccountActivatedOpt} onClick={() => handleAccountActivateClick(table)}>
                                                    <Typography sx={{}} variant="body2" sx={{ textTransform: 'none' }} color="text.primary">Account activated</Typography>
                                                </MenuItem>
                                                <Divider />
                                                <MenuItem sx={{ ml: 3, mr: 3 }} key={9} disabled={disCancelOpt} onClick={() => handleCancelClick(table)}>
                                                    <Typography sx={{}} variant="body2" sx={{ textTransform: 'none' }} color="text.primary">Cancel</Typography>
                                                </MenuItem>
                                                <MenuItem sx={{ ml: 3, mb: 1, mr: 3 }} key={9} disabled={disRejectOpt} onClick={() => handleRejectClick(table)}>
                                                    <Typography sx={{}} variant="body2" sx={{ textTransform: 'none' }} color="text.primary">Reject</Typography>
                                                </MenuItem>
                                            </Menu>
                                        </Box>
                                    );
                                }}
                                renderToolbarInternalActions={({ table }) => {
                                    return (
                                        <Box>                                        
                                            <MRT_ToggleGlobalFilterButton table={table} color="#ccc" />
                                            <Tooltip arrow title="Edit claim">
                                                <span>
                                                    <IconButton size="small" sx={{ border: 0, borderColor: '#9e9e9e', borderRadius: '5px', ml: 1, mr: 1, mb: 1 }} disabled={disEditApp} onClick={() => handleEditAppClick(table)}>
                                                        <NoteAltIcon sx={{ fontSize: 28}} />
                                                    </IconButton>
                                                </span>
                                            </Tooltip>
                                            <Tooltip arrow title="View documents">
                                                <span>
                                                <IconButton size="small" sx={{ border: 0, borderColor: '#9e9e9e', borderRadius: '5px', ml: 1, mr: 1, mb: 1 }} disabled={disViewDoc} title="Attached document(s)" onClick={() => handleViewDocClick(table)} >
                                                        <FileCopyOutlinedIcon sx={{ fontSize: 26 }} />
                                                </IconButton>
                                                </span>
                                            </Tooltip>
                                            <Tooltip arrow title="View payment details">
                                                <span>
                                                    <IconButton size="small" sx={{ border: 0, borderColor: '#9e9e9e', borderRadius: '5px', ml: 1, mr: 1, mb: 1 }} disabled={disViewDoc} title="Attached document(s)" onClick={() => handleViewDocClick(table)} >
                                                        <DescriptionOutlinedIcon sx={{ fontSize: 26 }} />
                                                    </IconButton>
                                                </span>
                                            </Tooltip>
                                            <Tooltip arrow title="Upload document">
                                                <span>
                                                    <IconButton size="small" sx={{ border: 0, borderColor: '#9e9e9e', borderRadius: '5px', ml: 1, mr: 1, mb: 1 }} disabled={disUploadDoc} title="Upload document(s)" onClick={() => handleUploadDoc(table)}  >
                                                        <UploadIcon sx={{ fontSize: 28 }} />
                                                    </IconButton>
                                                </span>
                                            </Tooltip>
                                            <Tooltip arrow title="More">
                                                <span>
                                                <IconButton size="small" sx={{ border: 0, borderColor: '#9e9e9e', borderRadius: '5px', ml: 1, mr: 1, mb: 1 }} title="More options" onClick={handleMoreMenuClick}>
                                                    <MoreVertIcon sx={{ fontSize: 24 }} />
                                                </IconButton>
                                                </span>
                                            </Tooltip>
                                            <Menu
                                                id="more-menu"
                                                anchorEl={moreMnuAnchorEl}
                                                open={openMoreMenu}
                                                onClose={handleMoreMenuClose}
                                            >
                                                <MenuItem sx={{ml: 2, mt: 1, mb: 1}} key={0} onClick={() => handleAddAppClick()}>
                                                    <ListItemIcon>
                                                        <NoteAddIcon width="24px" height="24px" color="#ccc" />
                                                    </ListItemIcon>
                                                    <Typography className="me-5" variant="body2" sx={{ textTransform: 'none' }} color="text.primary">Register A Claim</Typography>
                                                </MenuItem>
                                                <Divider />
                                                <MenuItem sx={{ ml: 2, mt: 1 }} key={1} disabled={disViewApp} onClick={() => handleReviewAppClick(table)}>
                                                    <ListItemIcon>
                                                        <DescriptionIcon width="24px" height="24px" color="#ccc" />
                                                    </ListItemIcon>
                                                    <Typography className="me-5" variant="body2" sx={{ textTransform: 'none' }} color="text.primary">View Claim</Typography>
                                                </MenuItem>
                                                <MenuItem sx={{ ml: 2, mt: 1 }} key={2} disabled={disDownloadApp} onClick={() => handleDownloadAppClick(table)}>
                                                    <ListItemIcon>
                                                        <DownloadIcon width="24px" height="24px" color="#ccc" />
                                                    </ListItemIcon>
                                                    <Typography className="me-5" variant="body2" sx={{ textTransform: 'none' }} color="text.primary">Download Claim</Typography>
                                                </MenuItem>
                                                {/*<MenuItem sx={{ ml: 2, mt: 1, mb: 1 }} key={3} disabled={disPrintApp} onClick={() => handlePrintAppClick(table)}>*/}
                                                {/*    <ListItemIcon>*/}
                                                {/*        <PrintIcon width="24px" height="24px" color="#ccc" />*/}
                                                {/*    </ListItemIcon>*/}
                                                {/*    <Typography className="me-5" variant="body2" sx={{ textTransform: 'none' }} color="text.primary">Print application</Typography>*/}
                                                {/*</MenuItem>*/}
                                                <Divider />
                                                <MenuItem sx={{ ml: 2, mt: 1, mb: 1 }} key={4} disabled={disDeleteApp} onClick={() => handleDeleteAppClick(table)}>
                                                    <ListItemIcon>
                                                        <DeleteForeverIcon width="24px" height="24px" color="#ccc" />
                                                    </ListItemIcon>
                                                    <Typography className="me-5" variant="body2" sx={{ textTransform: 'none' }} color="text.primary">Delete Claim</Typography>
                                                </MenuItem>
                                                {/*<Divider />*/}
                                                {/*<MenuItem sx={{ ml: 2, mt: 1, mb: 1 }} key={5} disabled={true} onClick={() => handleKYCAMLFailClick(table)}>*/}
                                                {/*    <ListItemIcon>*/}
                                                {/*        <ImportExportIcon width="24px" height="24px" color="#ccc" />*/}
                                                {/*    </ListItemIcon>*/}
                                                {/*    <Typography className="me-5" variant="body2" sx={{ textTransform: 'none' }} color="text.primary">Export to file</Typography>*/}
                                                {/*</MenuItem>*/}
                                            </Menu>
                                        </Box>
                                    );
                                }}
                            />
                        </Box>
                    </Grid>
                </Paper>
                <Footer />
            </Container>
            {
                enrolModalShow ?
                    <AddEditEnrollment
                        key={selectedEnrolId.current}
                        show={enrolModalShow}
                        backdrop="static"
                        keyboard={false}
                        onHide={() => setEnrolModalShow(false)}
                        title={selectedEnrolId.current > 0 ? 'Edit your saved application' : 'Application for opening an account'}
                        templateid={templateId.current}
                        enrmntid={selectedEnrolId.current}
                        name={enrolNm.current}
                        templatenm={templateNm.current}
                        userId={userId}
                        sessionId={sessionId}
                    />
                    : <div />
            }
            <ConfirmDialog
                keepMounted
                open={openDelConfirmDlg}
                onClose={handleConfirmDelDialogClose}
                alertheader={confirmDelDlgHdr.current}
                bmsgline1={confirmDelDlgBLine1.current}
                nvalue={selectedEnrolId.current}
                svalue={selectedEnrolId.current}
            />
            <AlertDialog
                id="ringtone-menu"
                keepMounted
                open={openConfirmDlg}
                onClose={handleConfirmDialogClose}
                enrolmentid={newEnrolStatus.current}
                nvalue={newEnrolStatus.current}
                alertheader={confirmDlgHdr.current}
                bmsgline1={confirmDlgBLine1.current}
                bmsgline2={confirmDlgBLine2.current}
                bmsgline3={confirmDlgBLine3.current}
                bmsgline4={confirmDlgBLine4.current}
            />
            <FormDialog
                id="ringtone-from-menu"
                keepMounted
                open={openFormDlg}
                onClose={handleFormDialogClose}
                enrolmentid={selectedEnrolId.current}
                nvalue={newEnrolStatus.current}
                alertheader={confirmDlgHdr.current}
                bmsgline1={confirmDlgBLine1.current}
                bmsgline2={confirmDlgBLine2.current}
                bmsgline3={confirmDlgBLine3.current}
                bmsgline4={confirmDlgBLine4.current}
                bmsgline5={confirmDlgBLine5.current}
            />
            {showAppViewDlg ?
                <AppViewDlg open={showAppViewDlg} onClose={closeAppViewDlg} enrollData={selectedEnrolmntValue.current} enrmntid={selectedEnrolId.current} acctType={selectedTemplateNm.current} applicantImage={imageUrl} avtarImage={profimg} applicantName={applicantsName} applicantEmail={applicantEmail} applicantMobile={ownerMobileNbr} userId={userId} sessionId={sessionId} />
                : null
            }
            {showDocUpldDlg ?
                <DocUpldDlg open={showDocUpldDlg} onClose={closeDocUpldDlg} onChange={onFileChangeHandle} enrmntid={selectedEnrolId.current} ownerName={applicantsName} enrolNm={enrolNm.current} templateNm={templateNm.current} userId={userId} sessionId={sessionId} />
                : null
            }
            {showDocListDlg ?
                <DocListDlg open={showDocListDlg} onClose={closeDocListDlg} enrmntid={selectedEnrolId.current} ownerName={applicantsName} enrolNm={enrolNm.current} templateNm={templateNm.current} userId={userId} sessionId={sessionId} />
                : null
            }
            {
                showExportToGWESDlg ?
                    <ExportToGWESLoaderDlg id="exportTofWES" open={showExportToGWESDlg} onClose={handleExportToGWESDlgClose} keepMounted/>: null

            }
        </React.Fragment>
    );
};

const queryClient = new QueryClient();

const ApplicationDashboard = () => (
    <QueryClientProvider client={queryClient}>
        <UserEnrollments />
    </QueryClientProvider>
);

function ApplicationDashboardBreadcrumb() {
    return (
        <React.Fragment >
            <Box sx={{ borderLeft: '4px solid #9e9e9e', mt: 0, pt: 0, pb: 2, pl: 1, backgroundColor: '#fff', borderTop: '0px solid #dee2e6'}}>
                <Typography variant="h5" sx={{ fontWeight: 400, lineHeight: 1.1, pl: 2, textTransform: 'none' }} color="text.primary">Applications From Prospects</Typography>
            </Box>
        </React.Fragment>
    );
}
export default ApplicationDashboard;