import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { Logout } from '../global/Logout';
import { Button, Link, Typography, Paper, Box, Stack } from '@mui/material';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import CallIcon from '@mui/icons-material/Call';
import { GetLoggedInUserName, GetLoggedInUserEmail, GetLoggedInUserId } from '../global/APIUtils';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import clientlogo from '../assets/images/banklogo.jpg';

export default function PostLoginMenu(props) {

    let navigate = useNavigate();

    const [userInfo, setUserInfo] = useState('');

    const [userId, setUserId] = useState(props.userId);
    const [sessionId, setSessionId] = useState(props.sessionId);

    const sections = [
        { title: 'Logout', url: '#' },
    ];

    const contactUsClick = async () => {

        //await Logout(userId, null);

        navigate('/ContactUs', { state: {userId: userId, sessionId: sessionId}, replace: true });
    }

    useEffect(() => {

        let uname = GetLoggedInUserName(userId, sessionId);
        let umail = GetLoggedInUserEmail(userId, sessionId);

        setUserInfo(uname + ' (' + umail + ')');

    }, []);

    return (
        <React.Fragment>
            <Toolbar 
                component="nav"
                variant="dense"
                sx={{ p: 0, m: 0, justifyContent: 'space-between', overflowX: 'auto', backgroundColor: '#4C4C4C', border: 0, minHeight: '40px' }}
            >
                <Stack direction="row" alignItems="center" sx={{ml: -1}}>
                    <PersonOutlineIcon sx={{ color: '#fff', fontSize: 24 }} /><Typography sx={{pl: 1}} variant="body2" color="#fff">{userInfo}</Typography>
                </Stack>
                <Box>
                    {/*<Box sx={{ ml: 1, pt: 1, pb: 1 }}>*/}
                    {/*    <img src={clientlogo} height="40" loading="lazy" />*/}
                    {/*</Box>*/}

                    {/*<Link color="#fff" noWrap key={2} variant="body1" href="#" sx={{ pl: 4, pr: 0, flexShrink: 0 }}>*/}
                    {/*    Help*/}
                    {/*</Link>*/}
                    {/*<Link color="#fff" noWrap key={2} variant="body1" href="#" sx={{ pl: 4, pr: 0, flexShrink: 0 }}>*/}
                    {/*    Locations*/}
                    {/*</Link>*/}
                    {/*<Link color="#fff" noWrap key={3} variant="body2" href="#" sx={{ pl: 2, pr: 0, flexShrink: 0 }}>*/}
                    {/*    Chat*/}
                    {/*</Link>*/}
                    <Link color="#fff" noWrap key={0} variant="body1" sx={{ pl: 4, pr: 2, flexShrink: 0, fontWeight: 400 }} onClick={contactUsClick}>
                        Contact Us
                    </Link>
                </Box>
            </Toolbar>
        </React.Fragment>
    );
};