import React, { useEffect, useState } from 'react';
import { PreLoginMenu } from './PreLoginMenu';
import { Footer } from './Footer';
import Button from '@mui/material/Button';
import { Typography, Box, Paper } from '@mui/material';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import KeyDate from './KeyDate'


export default function KeyDates() {

    return (
        <React.Fragment>
            <Container maxWidth="xl" sx={{}} >
                <PreLoginMenu />
                <Paper elevation={0}
                    sx={{
                        position: 'relative',
                        color: '#fff',
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        pb: { xs: 7, sm: 0 },
                    }}
                >
                    <Box
                        sx={{
                            position: 'absolute',
                            top: 0,
                            bottom: 0,
                            right: 0,
                            left: 0,
                            backgroundColor: 'rgba(238, 238, 238, 0.08)',
                        }}
                    />
                    <Grid sx={{ pb: 5, pt: 2 }} container>
                        <Grid item xs={12}>
                            <KeyDate />
                        </Grid>
                    </Grid>
                </Paper>
                <Footer />
            </Container>
        </React.Fragment>
    );
}