import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { PreLoginMenu } from './PreLoginMenu';
import { Footer } from './Footer';
import Button from '@mui/material/Button';
import { Typography, Link, card, CardContent, Box, FormControl, Toolbar, Stack, Paper, InputBase, IconButton } from '@mui/material';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import FAQS from './FAQS';

export default function FAQ() {

    let navigate = useNavigate();
    const [expanded, setExpanded] = React.useState('');

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    const handleSearchChange = (event) => {
        //setSearchText(event.target.value);
    };

    const handleSearchClick = (event) => {
        //refetch();
    };

    return (
        <React.Fragment>
            <Container maxWidth="xl" sx={{}} >
                <PreLoginMenu />
                <Paper elevation={0}
                    sx={{
                        position: 'relative',
                        color: '#fff',
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        pb: { xs: 10, sm: 0 },
                    }}
                >
                    <Box
                        sx={{
                            position: 'absolute',
                            top: 0,
                            bottom: 0,
                            right: 0,
                            left: 0,
                            backgroundColor: 'rgba(238, 238, 238, 0.08)',
                        }}
                    />
                    <Grid sx={{ pb: 5, pt: 2 }} container>
                        <Grid item xs={12}>
                            <FAQS />
                        </Grid>
                    </Grid>
                </Paper>
                <Footer />
            </Container>
        </React.Fragment>
    );
}