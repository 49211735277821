import React from 'react';
import Alert from '@mui/material/Alert';
import { Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';

const AlertControl = (props) => {

    const [open, setOpen] = React.useState(true);

    return (
        <>
            {
                (props.Message) ?
                    props.icon ?
                        (
                            <Alert severity={props.severity} color={props.color} sx={{ mb: 2, mt: 1 }}>
                                <Typography variant="body2">{props.Message}</Typography>
                            </Alert>
                        )
                        :
                        (
 
                            <Alert severity={props.severity} color={props.color} icon={false} sx={{ mb: 2, mt: 1 }}>
                                <Typography variant="body2">{props.Message}</Typography>
                            </Alert>
                        )

                    : <p></p>
            }
        </>
    );
}

export default AlertControl;