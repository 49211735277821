import React, { useCallback, useState, useEffect, useMemo, useRef, } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import PostLoginMenu from './PostLoginMenu';
import OverlaySpinner from './Controls/OverlaySpinner';
//import { MainNavMenu } from './MainNavMenu';
import { Footer } from './Footer';
import { QueryClient, QueryClientProvider, useInfiniteQuery, } from '@tanstack/react-query';
//import { useSelector, useDispatch } from 'react-redux';
import { CallAPI } from '../global/APIUtils';
import { Virtualizer } from '@tanstack/react-virtual';
import { Grid, Stack, Divider, Paper, IconButton, Box, Tooltip, Menu, Typography, MenuItem, InputBase, Card, CardContent, bull, CardActions, Button } from '@mui/material';
import { CardMedia, CardHeader } from '@mui/material';
import { Search, EnvelopeOpen, ArchiveFill, ChevronDown, TrashFill, ThreeDotsVertical, ArrowClockwise, AlignCenter } from 'react-bootstrap-icons';
import MaterialReactTable, { MRT_ShowHideColumnsButton, MRT_ToggleGlobalFilterButton, MRT_ToggleFiltersButton, } from 'material-react-table';
import { ErrorMessages } from '../common/ErrorMessages';
import { LoadProfilePic } from '../global/Helpers';
import PrintIcon from '@mui/icons-material/Print';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import FileOpenIcon from '@mui/icons-material/FileOpen';
import Avatar from '@mui/material/Avatar';
import { deepOrange, pink, blue, grey } from '@mui/material/colors';
import DownloadIcon from '@mui/icons-material/Download';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { AlertDialog } from '../global/AlertDialog';
import { EnrollmentStatus } from '../common/AOBEnum';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import DescriptionIcon from '@mui/icons-material/Description';
import PublishIcon from '@mui/icons-material/Publish';
import { ListItemIcon, ListItemText } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import { TemplateSortAction, AOBSystemProcess } from '../common/AOBEnum';
import SouthIcon from '@mui/icons-material/South';
import NorthIcon from '@mui/icons-material/North';
import AlertControl from './Controls/AlertControl';
import Container from '@mui/material/Container';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import EnrollmentHeader from './EnrollmentHeader';
import { DrawerAppBar } from './AppNavbar';
//import pwdimg from '../assets/images/23512.jpg';
//import Image from 'material-ui-image'
import { IsError, IsInRole, GetLoggedInUserId } from '../global/APIUtils';
import AlertWithTitle from './Controls/AlertWithTitle';
import { APICall } from '../global/Api';

const Templates = () => {

    const location = useLocation();
    let navigate = useNavigate();
    const queryClient = new QueryClient();
    //const dispatch = useDispatch();
    const tableInstanceRef = useRef(null);
    const selTempltId = useRef(0);
    const selTempltNm = useRef('');

    const [templateSts, setTemplateSts] = useState(null);
    const [errorMsg, setErrorMsg] = useState('');
    const [successMsg, setSuccessMsg] = useState(null);
    const [successMsgTitle, setSuccessMsgTitle] = useState(null);
    const [isDataLoading, setIsDataLoading] = useState('');
    const [templateErrorMsg, setTemplateErrorMsg] = useState('');
    const [templateStsErrorMsg, setTemplateStsErrorMsg] = useState('');

    const [userId, setUserId] = useState(location.state.userId || -1);
    const [sessionId, setSessionId] = useState(location.state.sessionId || '');

    //const [isOuterLoading, setIsOuterLoading] = useState(false);

    const [disPublishTmpltBtn, setDisPublishTmpltBtn] = useState(true);
    const [disDeleteTmpltBtn, setDisDeleteTmpltBtn] = useState(true);
    const [disEditTmpltBtn, setDisEditTmpltBtn] = useState(true);

    //let sessions = useSelector((state) => state.sessionMgmnt);

    const [openConfirmDlg, setOpenConfirmDlg] = useState(false);
    const [openPubCnfrmDlg, setOpenPubCnfrmDlg] = useState(false);
    const [openDelCnfrmDlg, setOpenDelCnfrmDlg] = useState(false);
    const [templateCount, setTemplateCount] = useState(0);

    const confirmDlgHdr = useRef('');
    const confirmDlgBLine1 = useRef('');
    const confirmDlgBLine2 = useRef('');
    const confirmDlgBLine3 = useRef('');
    const confirmDlgBLine4 = useRef('');

    const cnfrmDelDlgHdr = useRef('');
    const cnfrmDelDlgBLine1 = useRef('');
    const cnfrmDelDlgBLine2 = useRef('');
    const cnfrmDelDlgBLine3 = useRef('');
    const cnfrmDelDlgBLine4 = useRef('');

    const cnfrmPubDlgHdr = useRef('');
    const cnfrmPubDlgBLine1 = useRef('');
    const cnfrmPubDlgBLine2 = useRef('');
    const cnfrmPubDlgBLine3 = useRef('');
    const cnfrmPubDlgBLine4 = useRef('');
    const newEnrolStatus = useRef(0);
    const [publishedCount, setPublishedCount] = useState(0);
    const [notPublishedCount, setNotPublishedCount] = useState(0);
    const [expanded, setExpanded] = useState(true);

    const tickDtNewSrtMnuDisp = useRef('block');
    const tickDtOldSrtMnuDisp = useRef('none');
    const tickTmpltNameSrtMnuDisp = useRef('none');
    const tickTmpltDescSrtMnuDisp = useRef('none');
    const tickTmpltOwnrSrtMnuDisp = useRef('none');
    const tickTmpltStsSrtMnuDisp = useRef('none');

    const sortMenuAction = useRef(TemplateSortAction.Newest);

    const [sortMnuAnchorEl, setSortMnuAnchorEl] = useState(null);
    const openSort = Boolean(sortMnuAnchorEl);

    const handleSortMnuClick = (event) => {
        setSortMnuAnchorEl(event.currentTarget);
    };
    const handleSortMnuClose = () => {
        setSortMnuAnchorEl(null);
    };

    const handlePubCnfrmDlgClose = async (nvalue, svalue) => {

        setOpenPubCnfrmDlg(false);

        try {

            if (nvalue == null || nvalue === undefined) {
                return;
            }

            setIsDataLoading(true);

            const apiUrl = new URL(
                process.env.REACT_APP_PUBLISH_TEMPLATE,
                process.env.REACT_APP_BASE_URL,
            );

            const inputJson = {
                TemplateId: selTempltId.current,
            };

            //let response = await CallAPI({ url: apiUrl.href, method: 'POST', headers: {}, body: { TemplateId: selTempltId.current } }, userId, sessionId);

            let response = await APICall({ url: apiUrl.href, method: 'POST', payload: inputJson, contentType: 'application/json', responseType: '' }, userId, sessionId);

            if (response !== null && response !== undefined) {

                setIsDataLoading(false);

                if (response.Errors !== undefined && response.Errors.length > 0) {
                    setErrorMsg(response.Errors[0].Message);
                }
                else {
                    setSuccessMsgTitle("Template published");
                    setSuccessMsg('Template named as \'' + selTempltNm.current + '\' published successfully');
                    setRowSelection({});
                    setDisPublishTmpltBtn(true);
                    refetch();
                }
            }
        }
        catch (err) {
            setErrorMsg(ErrorMessages.UnknownError);
            setIsDataLoading(false);
        }
        finally {
        }
    };

    const handleDelCnfrmDlgClose = async (nvalue, svalue) => {

        setOpenDelCnfrmDlg(false);

        try {

            if (nvalue == null || nvalue === undefined) {
                return;
            }

            setIsDataLoading(true);

            const apiUrl = new URL(
                process.env.REACT_APP_DELETE_TEMPLATE,
                process.env.REACT_APP_BASE_URL,
            );

            const inputJson = {
                TemplateId: selTempltId.current,
            };

            //let apiUrl = process.env.REACT_APP_BASE_URL + process.env.REACT_APP_DELETE_TEMPLATE;
            //let response = await CallAPI({ url: apiUrl.href, method: 'POST', headers: {}, body: { TemplateId: selTempltId.current } }, userId, sessionId);
            let response = await APICall({ url: apiUrl.href, method: 'POST', payload: inputJson, contentType: 'application/json', responseType: '' }, userId, sessionId);

            if (response !== null && response !== undefined) {

                setIsDataLoading(false);

                if (response.Errors !== undefined && response.Errors.length > 0) {
                    setErrorMsg(response.Errors[0].Message);
                }
                else {
                    setSuccessMsgTitle("Template deleted");
                    setSuccessMsg('Template named as \'' + selTempltNm.current + '\' deleted successfully');
                    setRowSelection({});
                    setDisPublishTmpltBtn(true);
                    refetch();
                }
            }
        }
        catch (err) {
            setErrorMsg(ErrorMessages.UnknownError);
            setIsDataLoading(false);
        }
        finally {
        }
    };

    const columns = [
        {
            accessorFn: (row) => row.TemplateName,
            header: 'Template name',
        },
        {
            accessorFn: (row) => row.TemplateDesc,
            header: 'Template description',
        },
        {
            accessorFn: (row) => row.TemplateOwner,
            header: 'Template owner',
        },
        {
            accessorFn: (row) => row.LastModifiedUser,
            header: 'Last modified by',
        },
        {
            accessorFn: (row) => row.LastModifiedOn,
            header: 'Last modified',
        },
        {
            accessorFn: (row) => row.TemplateStatus,
            header: 'Status',
        },
    ];

    const fetchSize = 25;

    const tableContainerRef = useRef(null); //we can get access to the underlying TableContainer element and react to its scroll events
    const virtualizerInstanceRef = useRef < Virtualizer > null; //we can get access to the underlying Virtualizer instance and call its scrollToIndex method

    const [globalFilter, setGlobalFilter] = useState();
 
    const { data, fetchNextPage, isError, isFetching, isLoading, refetch } =
        useInfiniteQuery({
            queryKey: ['table-data', globalFilter],
            queryFn: async ({ pageParam = 0 }) => {

                setTemplateErrorMsg(ErrorMessages.UnknownError);

                const apiUrl = new URL(
                    process.env.REACT_APP_GET_RESTRICTED_TEMPLATES,
                    process.env.REACT_APP_BASE_URL,
                );
                apiUrl.searchParams.set('start', `${pageParam * fetchSize}`);
                apiUrl.searchParams.set('size', `${fetchSize}`);
                apiUrl.searchParams.set('globalFilter', globalFilter ?? '');
                apiUrl.searchParams.set('sorting', sortMenuAction.current);

                //let response = await CallAPI({ url: apiUrl.href, method: 'GET', headers: {} }, userId, sessionId);
                let response = await APICall({ url: apiUrl.href, method: 'GET', contentType: 'application/json', responseType: '' }, userId, sessionId);

                if (response !== null && response !== undefined) {
                    let error = IsError(response);
                    if (error != 0) {
                        setErrorMsg(response.Errors[0].Message);
                    }

                    return response;
                }
                else {
                    throw Error(ErrorMessages.UnknownError);
                }
            },
            getNextPageParam: (_lastGroup, groups) => groups.length,
            keepPreviousData: true,
            refetchOnWindowFocus: false,
        });

    const flatData = useMemo(
        () => data?.pages.flatMap((page) => page.data) ?? [],
        [data],
    );

    const totalDBRowCount = data?.pages?.[0]?.meta?.totalRowCount ?? 0;
    const totalFetched = flatData.length;
    const fetchMoreOnBottomReached = useCallback(
        (containerRefElement) => {
            if (containerRefElement) {
                const { scrollHeight, scrollTop, clientHeight } = containerRefElement;
                if (
                    scrollHeight - scrollTop - clientHeight < 400 &&
                    !isFetching &&
                    totalFetched < totalDBRowCount
                ) {
                    fetchNextPage();
                }
            }
        },
        [fetchNextPage, isFetching, totalFetched, totalDBRowCount],
    );

    useEffect(() => {
        if (virtualizerInstanceRef.current) {
            virtualizerInstanceRef.current.scrollToIndex(0);
        }
    }, [globalFilter]);

    useEffect(() => {
        fetchMoreOnBottomReached(tableContainerRef.current);
    }, [fetchMoreOnBottomReached]);

    useEffect(() => {
        loadTemplateStatusSummary();
    }, []);

    const loadTemplateStatusSummary = async () => {

        let baseUrl = process.env.REACT_APP_BASE_URL;

        const apiUrl = new URL(
            process.env.REACT_APP_GET_TEMPLATE_STASTUS_SUMM,
            baseUrl
        );
        try {

            //setIsDataLoading(true);

            //let response = await CallAPI({ url: apiUrl.href, method: 'GET', headers: {} }, userId, sessionId);
            let response = await APICall({ url: apiUrl.href, method: 'GET', contentType: 'application/json', responseType: '' }, userId, sessionId);

            if (response !== null && response !== undefined) {

                let count = 0;
                if (response.data) {
                    for (const x of response.data) {
                        count += x.TemplateCount;
                    }
                }
                setTemplateCount(count);
                setTemplateSts(response.data);
            }
        }
        catch (err) {
            //setTemplateStsErrorMsg("ERROR while getting template information");
        }
        finally {
            //setIsDataLoading(false);
        }
    };

    const handleCreateNewUser = () => {

    };
    const handleRemoveUsers = () => {
    };

    const [rowSelection, setRowSelection] = useState({});

    const handleChange = (event) => {
        setRowSelection('500', true);
    };

    const HandleRowClick = async (row) => {

        tableInstanceRef.resetRowSelection = true;
        selTempltId.current = row.original.TemplateId;
        selTempltNm.current = row.original.TemplateName;

        if (row.original.TemplateStatusID == 34)
            setDisPublishTmpltBtn(true);
        else
            setDisPublishTmpltBtn(false);

        //let userID = GetLoggedInUserId();

        let canDelete = IsInRole(AOBSystemProcess.DeleteTemplate, userId, sessionId);
        if (parseInt(row.original.CrtUsrId) == parseInt(userId) || canDelete)
            setDisDeleteTmpltBtn(false);
        else
            setDisDeleteTmpltBtn(true);

    }

    const handleDtNewSortMenuClick = (event) => {
        setSortMnuAnchorEl(null);
        sortMenuAction.current = TemplateSortAction.Newest;
        refetch();

        SetSortMenuTick(TemplateSortAction.Newest);
    }
    const handleDtOldSortMenuClick = (event) => {
        setSortMnuAnchorEl(null);
        sortMenuAction.current = TemplateSortAction.Oldest;
        refetch();

        SetSortMenuTick(TemplateSortAction.Oldest);
    }
    const handleTmpltNameSortMenuClick = (event) => {
        setSortMnuAnchorEl(null);
        sortMenuAction.current = TemplateSortAction.TemplateName;
        refetch();

        SetSortMenuTick(TemplateSortAction.TemplateName);
    }
    const handleTmpltDescSortMenuClick = (event) => {
        setSortMnuAnchorEl(null);
        sortMenuAction.current = TemplateSortAction.TemplateDesc;
        refetch();

        SetSortMenuTick(TemplateSortAction.TemplateDesc);
    }
    const handleTmpltOwnrSortMenuClick = (event) => {
        setSortMnuAnchorEl(null);
        sortMenuAction.current = TemplateSortAction.TemplateOwner;
        refetch();

        SetSortMenuTick(TemplateSortAction.TemplateOwner);
    }
    const handleTmpltStatusSortMenuClick = (event) => {
        setSortMnuAnchorEl(null);
        sortMenuAction.current = TemplateSortAction.TemplateStatus;
        refetch();

        SetSortMenuTick(TemplateSortAction.TemplateStatus);
    }
    function SetSortMenuTick(item) {
        tickDtNewSrtMnuDisp.current = 'none';
        tickDtOldSrtMnuDisp.current = 'none';
        tickTmpltNameSrtMnuDisp.current = 'none';
        tickTmpltDescSrtMnuDisp.current = 'none';
        tickTmpltOwnrSrtMnuDisp.current = 'none';
        tickTmpltStsSrtMnuDisp.current = 'none';

        if (item == TemplateSortAction.Newest)
            tickDtNewSrtMnuDisp.current = 'block';

        if (item == TemplateSortAction.Oldest)
            tickDtOldSrtMnuDisp.current = 'block';

        if (item == TemplateSortAction.TemplateName)
            tickTmpltNameSrtMnuDisp.current = 'block';

        if (item == TemplateSortAction.TemplateDesc)
            tickTmpltDescSrtMnuDisp.current = 'block';

        if (item == TemplateSortAction.TemplateOwner)
            tickTmpltOwnrSrtMnuDisp.current = 'block';

        if (item == TemplateSortAction.TemplateStatus)
            tickTmpltStsSrtMnuDisp.current = 'block';
    }

    function createData(
        name,
        calories,
        fat,
        carbs,
        protein,
    ) {
        return { name, calories, fat, carbs, protein };
    }

    const rows = [
        createData('Published', 159, 6.0, 24, 4.0),
        createData('Not published', 237, 9.0, 37, 4.3),
    ];

    const handleEditClick = (table) => {

        if (table.getSelectedRowModel().rows.length > 0) {
            let row = table.getSelectedRowModel().rows[0];
            navigate('/TemplateDesigner', { state: { templateid: parseInt(selTempltId.current), userId: userId, sessionId: sessionId }, replace: true });
        }
    };
    const handlePublishClick = (table) => {

        if (table.getSelectedRowModel().rows.length > 0) {
            let row = table.getSelectedRowModel().rows[0];
            cnfrmPubDlgBLine1.current = 'Template Name: ' + row.original.TemplateName;
            cnfrmPubDlgBLine2.current = 'Template Description: ' + row.original.TemplateDesc;
            cnfrmPubDlgBLine3.current = 'Template Owner: ' + row.original.TemplateOwner;
        }

        cnfrmPubDlgHdr.current = 'Are you sure you want to publish the following template?';
        setOpenPubCnfrmDlg(true);
    };
    const handleDeleteClick = (table) => {

        if (table.getSelectedRowModel().rows.length > 0) {
            let row = table.getSelectedRowModel().rows[0];
            cnfrmDelDlgBLine1.current = 'Template Name: ' + row.original.TemplateName;
            cnfrmDelDlgBLine2.current = 'Template Description: ' + row.original.TemplateDesc;
            cnfrmDelDlgBLine3.current = 'Template Owner: ' + row.original.TemplateOwner;
        }
        cnfrmDelDlgHdr.current = 'Are you sure you want to delete the following template?';
        setOpenDelCnfrmDlg(true);
    };
    const handleAccordianChange = () => {
        if (expanded) {
            setExpanded(false);
        }
        else {
            setExpanded(true);
        }
    };
    const NewTemplateClick = (event) => {
        event.preventDefault();

        navigate('/TemplateDesigner', { state: { templateid: -1, userId: userId, sessionId: sessionId } });

        //navigate('/TemplateDesigner');
    };

    return (
        <React.Fragment>
            <Container maxWidth="xl" sx={{}}>
                <OverlaySpinner disappear={isDataLoading} />
                <PostLoginMenu userId={userId} sessionId={sessionId}  />
                <Paper className=""
                    sx={{
                        position: 'relative',
                        backgroundColor: '#fff',
                        color: '#fff',
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        pb: 12
                    }}
                >
                    <Grid item>
                        <Box sx={{
                            pr: { xs: 3, md: 2 },
                            pl: { xs: 3, md: 2 }
                        }}>
                            <Accordion spacing={0} elevation={0} expanded={expanded} onChange={handleAccordianChange} sx={{ p: 0, m: 0, width: '100%' }}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header" sx={{ mr: 2 }}>
                                </AccordionSummary>
                                <AccordionDetails sx={{ pr: 3, pl: 3, pb: 0 }}>
                                    <EnrollmentHeader />
                                    <DrawerAppBar userId={userId} sessionId={sessionId} />
                                </AccordionDetails>
                                <Box sx={{ pr: 4, pl: 4 }}>
                                    <Grid item xs container direction="row" spacing={2} sx={{ pt: 0, pb: 0 }}>
                                        <Grid item md={9} sx={{ pr: 10, }} justifyContent="center">
                                            <Box sx={{ pt: 2, pr: 2, ml: 2 }}>
                                                <TemplateListBreadcrumb />
                                                <Typography sx={{ mt: 1, pb: 2, fontWeight: 500 }} variant="body1" color="text.secondary">
                                                    Create & manage all form templates in the system which are used as application form for opening an account.
                                                    You can custimize existing forms or add new forms as per your needs.
                                                </Typography>
                                            </Box>
                                            <Box sx={{ pl: 0, mt: 3, ml: 2 }}>
                                                <Button variant="outlined" size="small" sx={{ padding: '0.4rem 1.2rem', border: 2, borderColor: '#616161' }} onClick={(e) => NewTemplateClick(e)}>
                                                    <Typography variant="body2" color="#212121" sx={{ textTransform: 'none', fontWeight: 500 }} >New Template</Typography>
                                                </Button>
                                            </Box>
                                        </Grid>
                                        {/*<Grid item md={5}>*/}
                                        {/*    <Grid container>*/}
                                        {/*        <Grid item md={12} sx={{pl: 2}} >*/}
                                        {/*            <Image src={pwdimg} aspectRatio={1.8 } />*/}
                                        {/*        </Grid>*/}
                                        {/*    </Grid>*/}
                                        {/*</Grid>*/}
                                    </Grid>
                                </Box>
                            </Accordion>
                        </Box>
                    </Grid>
                    <Grid item>
                        <Grid item xs container direction="row" spacing={2} sx={{ pt: 3, pb: 3, pl: 7, pr: 7 }}>
                            <Grid item md={10} sx={{ }} justifyContent="center">
                                {/*<div className="row me-2">*/}
                                <Box>
                                    <AlertWithTitle Message={successMsg} variant="body1" severity="success" color="success" icon={true} Title={successMsgTitle} />
                                    <AlertControl Message={errorMsg} severity="error" color="error" icon={true} />
                                </Box>
                                <Box sx={{}}>
                                    <MaterialReactTable sx={{}}
                                        columns={columns}
                                        data={flatData}
                                        displayColumnDefOptions={{
                                            'mrt-row-select': {
                                                maxSize: 12,
                                                header: '',
                                            },
                                            'mrt-row-actions': {
                                                header: '',
                                                maxSize: 30,
                                            },
                                        }}
                                        enableColumnActions={false}
                                        enableSorting={false}
                                        enablePagination={false}
                                        enableRowNumbers={false}
                                        enableRowVirtualization
                                        enableHiding={false}
                                        enableColumnOrdering={false}
                                        muiTableBodyRowProps={({ row }) => ({
                                            onClick: () => {
                                                setRowSelection((prev) => ({
                                                    [row.id]: true,
                                                }));
                                                HandleRowClick(row);
                                            },
                                            //selected: rowSelection[row.id],
                                            sx: {
                                                cursor: 'pointer',
                                            },
                                        })}
                                        positionActionsColumn="last"
                                        enableColumnResizing={false}
                                        enableDensityToggle={false}
                                        enableFullScreenToggle={false}
                                        muiTableBodyCellProps={({ column }) => ({
                                            sx: (theme) => ({
                                                color: theme.palette.text.primary,
                                                fontSize: theme.typography.body1,
                                                borderRight: '1px solid #d5dbdb',
                                                borderLeft: '1px solid #d5dbdb',
                                                borderBottom: '1px solid #bdbdbd',
                                                minHeight: '60px',
                                                pl: 2, pr: 2
                                            }),
                                        })
                                        }
                                        muiTableHeadCellProps={{
                                            sx: (theme) => ({
                                                color: '#16191f',
                                                fontSize: theme.typography.body1,
                                                borderTop: '1px solid #d5dbdb',
                                                borderLeft: '1px solid #d5dbdb',
                                                borderBottom: '1px solid #d5dbdb',
                                                backgroundColor: '#fafafa',
                                                fontWeight: 700,
                                                pt: 2,
                                                pb: 2
                                            }),
                                        }}

                                        muiTableContainerProps={{
                                            ref: tableContainerRef,
                                            sx: { maxHeight: '700px' },
                                            onScroll: (
                                                event,
                                            ) => fetchMoreOnBottomReached(event.target),
                                        }}
                                        muiToolbarAlertBannerProps={
                                            isError
                                                ? {
                                                    color: 'error',
                                                    children: ErrorMessages.UnknownError,
                                                }
                                                : undefined
                                        }
                                        onGlobalFilterChange={setGlobalFilter}
                                        renderBottomToolbarCustomActions={() => (
                                            <Box sx={{ mt: 2 }}>
                                                <Typography>
                                                    {
                                                        !isError && data
                                                            ? totalFetched + ' of ' + totalDBRowCount + ' templates.'
                                                            : ''
                                                    }
                                                </Typography>
                                            </Box>
                                        )}
                                        onRowSelectionChange={setRowSelection}
                                        state={{
                                            globalFilter,
                                            isLoading,
                                            showAlertBanner: isError,
                                            showProgressBars: isFetching,
                                            rowSelection,
                                        }}
                                        initialState={{
                                        }}
                                        muiTablePaperProps={{
                                            elevation: 0,
                                        }}
                                        rowVirtualizerProps={{ overscan: 2 }}
                                        localization={{
                                            noRecordsToDisplay: 'No template to display',
                                            selectedCountOfRowCountRowsSelected: '',
                                        }}
                                        renderTopToolbarCustomActions={({ table }) => {
                                            return (
                                                <Box sx={{
                                                    display: 'flex', alignItems: 'center', flexDirection: 'row', padding: 0,
                                                }}>
                                                    <Typography variant="h6" color="text.primary" sx={{ pr: 4, fontWeight: 500, letterSpacing: '-1px' }}>Existing templates</Typography>
                                                    <Typography variant="body1" color="text.primary" sx={{ pr: 0.5, }}>Sort</Typography>
                                                    <IconButton sx={{ border: 0, borderColor: '#235ec9', p: 0, pr: 2 }} onClick={handleSortMnuClick}>
                                                        <ExpandMoreIcon sx={{ fontSize: 24 }} />
                                                    </IconButton>
                                                    <Menu
                                                        id="basic-menu"
                                                        anchorEl={sortMnuAnchorEl}
                                                        open={openSort}
                                                        onClose={handleSortMnuClose}
                                                        MenuListProps={{
                                                            'aria-labelledby': 'basic-button',
                                                        }}
                                                    >
                                                        <MenuItem key={0} onClick={(event) => handleDtNewSortMenuClick(event)}>
                                                            <ListItemIcon fontSize="small">
                                                                <CheckIcon sx={{ display: tickDtNewSrtMnuDisp.current, fontSize: 24  }} />
                                                            </ListItemIcon>
                                                            <Typography className="me-3" variant="body2" display="block" color="text.primary">Date: Newest on top</Typography>
                                                        </MenuItem>
                                                        <MenuItem key={1} onClick={(event) => handleDtOldSortMenuClick(event)}>
                                                            <ListItemIcon fontSize="small">
                                                                <CheckIcon sx={{ display: tickDtOldSrtMnuDisp.current }} />
                                                            </ListItemIcon>
                                                            <Typography className="me-2" variant="body2" display="block" color="text.primary">Date: Oldtest on top</Typography>
                                                        </MenuItem>
                                                        <MenuItem key={2} onClick={(event) => handleTmpltNameSortMenuClick(event)}>
                                                            <ListItemIcon fontSize="small">
                                                                <CheckIcon sx={{ display: tickTmpltNameSrtMnuDisp.current }} />
                                                            </ListItemIcon>
                                                            <Typography className="me-2" variant="body2" display="block" color="text.primary">Template name</Typography>
                                                        </MenuItem>
                                                        <MenuItem key={3} onClick={(event) => handleTmpltDescSortMenuClick(event)}>
                                                            <ListItemIcon fontSize="small">
                                                                <CheckIcon sx={{ display: tickTmpltDescSrtMnuDisp.current }} />
                                                            </ListItemIcon>
                                                            <Typography className="me-2" variant="body2" display="block" color="text.primary">Template description</Typography>
                                                        </MenuItem>
                                                        <MenuItem key={4} onClick={(event) => handleTmpltOwnrSortMenuClick(event)}>
                                                            <ListItemIcon fontSize="small">
                                                                <CheckIcon sx={{ display: tickTmpltOwnrSrtMnuDisp.current }} />
                                                            </ListItemIcon>
                                                            <Typography className="me-2" variant="body2" display="block" color="text.primary">Template owner</Typography>
                                                        </MenuItem>
                                                        <MenuItem key={5} onClick={(event) => handleTmpltStatusSortMenuClick(event)}>
                                                            <ListItemIcon fontSize="small">
                                                                <CheckIcon sx={{ display: tickTmpltStsSrtMnuDisp.current }} />
                                                            </ListItemIcon>
                                                            <Typography className="me-2" variant="body2" display="block" color="text.primary">Status</Typography>
                                                        </MenuItem>
                                                    </Menu>
                                                </Box>
                                            );
                                        }}
                                        renderToolbarInternalActions={({ table }) => {
                                            return (
                                                <Box>
                                                    <MRT_ToggleGlobalFilterButton table={table} color="#ccc" />
                                                    <Tooltip arrow title="Edit template">
                                                        <span>
                                                            <IconButton sx={{ ml: 1, mr: 1, mb: 1 }} disabled={table.getSelectedRowModel().flatRows.length <= 0} onClick={() => handleEditClick(table)}>
                                                                <EditIcon sx={{ fontSize: 32 }} />
                                                            </IconButton>
                                                        </span>
                                                    </Tooltip>
                                                    <Tooltip arrow title="Publish template">
                                                        <span>
                                                            <IconButton sx={{ ml: 1, mr: 1, mb: 1 }} disabled={disPublishTmpltBtn} onClick={() => handlePublishClick(table)}>
                                                                <PublishIcon sx={{ fontSize: 32 }} />
                                                            </IconButton>
                                                        </span>
                                                    </Tooltip>
                                                    <Tooltip arrow title="Delete template">
                                                        <span>
                                                            <IconButton sx={{ ml: 1, mr: 1, mb: 1 }} disabled={disDeleteTmpltBtn} onClick={() => handleDeleteClick(table)}>
                                                                <DeleteIcon sx={{ fontSize: 32 }} />
                                                            </IconButton>
                                                        </span>
                                                    </Tooltip>
                                                </Box>
                                            );
                                        }}
                                        tableInstanceRef={tableInstanceRef}
                                    />
                                </Box>
                            {/*</div>*/}
                            </Grid>
                            <Grid item md={2} sx={{}} justifyContent="center">
                                {/*<Paper elevation={0}>*/}
                                {/*    <AlertControl Message={templateStsErrorMsg} severity="error" color="error" icon={true} />*/}
                                {/*</Paper>*/}
                                <Box>
                                    <Typography variant="body2" color="text.secondary" sx={{ pl: 1, pb: 1 }}>{templateCount > 1 ? templateCount + ' templates' : '0 template'} </Typography>
                                    {
                                        templateSts && templateSts.length > 0 ?
                                            <Box sx={{ pl: 1 }}>
                                                <Table aria-label="simple table" sx={{ border: '1px solid #e0e0e0' }}>
                                                    <TableBody>
                                                        {
                                                            templateSts.map((row, index) => (
                                                                <TableRow
                                                                    key={index}
                                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                                >
                                                                    <TableCell component="th" scope="row">
                                                                        <Typography className="" variant="body2" color="text.secondary">
                                                                            {row.TemplateStatus}
                                                                        </Typography>
                                                                    </TableCell>
                                                                    <TableCell align="right" color="text.primary" sx={{ opacity: '0.7' }}>
                                                                        <Avatar sx={{ bgcolor: row.StatusBGColor, width: 22, height: 22 }}>
                                                                            <Typography className="" variant="caption" color="#fff">
                                                                                {row.TemplateCount}
                                                                            </Typography>
                                                                        </Avatar>
                                                                    </TableCell>
                                                                </TableRow>
                                                            ))}
                                                    </TableBody>
                                                </Table>
                                            </Box>
                                            :
                                            <Box sx={{ pl: 1 }}>
                                                <Table aria-label="simple table" sx={{ border: '1px solid #CCCCCC' }}>
                                                    <TableBody>
                                                        <TableRow>
                                                            <TableCell component="th" scope="row">
                                                                <Typography className="" variant="body1" color="text.primary">
                                                                    Published
                                                                </Typography>
                                                            </TableCell>
                                                            <TableCell align="right" color="text.primary" sx={{ opacity: '0.7' }}>
                                                                <Avatar sx={{ bgcolor: '#08415C', width: 22, height: 22 }}>
                                                                    <Typography className="" variant="caption" color="#fff">
                                                                        0
                                                                    </Typography>
                                                                </Avatar>
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </Box>
                                    }

                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                    <AlertDialog
                        id="del-cnfrm-dlg"
                        keepMounted
                        open={openDelCnfrmDlg}
                        onClose={handleDelCnfrmDlgClose}
                        nvalue={selTempltId.current}
                        alertheader={cnfrmDelDlgHdr.current}
                        bmsgline1={cnfrmDelDlgBLine1.current}
                        bmsgline2={cnfrmDelDlgBLine2.current}
                        bmsgline3={cnfrmDelDlgBLine3.current}
                        bmsgline4={cnfrmDelDlgBLine4.current}
                    />
                    <AlertDialog
                        id="pub-cnfrm-dlg"
                        keepMounted
                        open={openPubCnfrmDlg}
                        onClose={handlePubCnfrmDlgClose}
                        nvalue={selTempltId.current}
                        alertheader={cnfrmPubDlgHdr.current}
                        bmsgline1={cnfrmPubDlgBLine1.current}
                        bmsgline2={cnfrmPubDlgBLine2.current}
                        bmsgline3={cnfrmPubDlgBLine3.current}
                        bmsgline4={cnfrmPubDlgBLine4.current}
                    />
                </Paper>
                <Footer />
            </Container>
            {/*<PostLoginMenu />*/}
            {/*<OverlaySpinner disappear={isOuterLoading} />*/}
            {/*<MainNavMenu />*/}
            {/*<section className="app-dbrd-sec">*/}
            {/*    <div className="container-fluid">*/}
            {/*        <div className="row justify-content-center">*/}
            {/*            <div className="col-12" style={{ maxWidth: '100rem' }}>*/}
            {/*                <div className="row mb-2 mt-1">*/}
            {/*                    <TemplateListBreadcrumb />*/}
            {/*                </div>*/}
            {/*                <div className="row mb-4">*/}
            {/*                    <Paper elevation={0}>*/}
            {/*                        <Typography variant="body1" color="text.secondary" className="mt-4 w-75 mb-2" sx={{}}>*/}
            {/*                        Create Account Form by using account form designer to design, create,*/}
            {/*                        and update account openning forms. This utility gives access to a drawing canvas*/}
            {/*                        upon which you construct your forms, a design toolbar from which you access*/}
            {/*                        controls and layout objects, and a properties view that you use to set*/}
            {/*                        attributes for each control or layout object. */}
            {/*                        </Typography>*/}
            {/*                    </Paper>*/}
            {/*                </div>*/}
            {/*                <div className="row mb-5 g-0">*/}
            {/*                    <Box>*/}
            {/*                        <Button className="m-0" variant="contained" size="small" style={{ backgroundColor: '#0099f6', padding: '0.8rem 2.5rem' }} onClick={(e) => NewTemplateClick(e)}>*/}
            {/*                            New Account Form*/}
            {/*                        </Button>    */}
            {/*                    </Box>*/}
            {/*                </div>*/}
            {/*                <AlertDialog*/}
            {/*                    id="pub-cnfrm-dlg"*/}
            {/*                    keepMounted*/}
            {/*                    open={openPubCnfrmDlg}*/}
            {/*                    onClose={handlePubCnfrmDlgClose}*/}
            {/*                    nvalue={selTempltId.current}*/}
            {/*                    alertheader={cnfrmPubDlgHdr.current}*/}
            {/*                    bmsgline1={cnfrmPubDlgBLine1.current}*/}
            {/*                    bmsgline2={cnfrmPubDlgBLine2.current}*/}
            {/*                    bmsgline3={cnfrmPubDlgBLine3.current}*/}
            {/*                    bmsgline4={cnfrmPubDlgBLine4.current}*/}
            {/*                />*/}
            {/*                <AlertDialog*/}
            {/*                    id="del-cnfrm-dlg"*/}
            {/*                    keepMounted*/}
            {/*                    open={openDelCnfrmDlg}*/}
            {/*                    onClose={handleDelCnfrmDlgClose}*/}
            {/*                    nvalue={selTempltId.current}*/}
            {/*                    alertheader={cnfrmDelDlgHdr.current}*/}
            {/*                    bmsgline1={cnfrmDelDlgBLine1.current}*/}
            {/*                    bmsgline2={cnfrmDelDlgBLine2.current}*/}
            {/*                    bmsgline3={cnfrmDelDlgBLine3.current}*/}
            {/*                    bmsgline4={cnfrmDelDlgBLine4.current}*/}
            {/*                />*/}
            {/*                <Stack direction="row" spacing={2} className="mb-4 ">*/}
            {/*                    <span className="m-e-6">*/}
            {/*                        <svg viewBox="0 0 24 24" width="48" height="48" stroke="#235ec9" fill="#235ec9"><path fill="#235ec9" d="M0 4.75C0 3.784.784 3 1.75 3h20.5c.966 0 1.75.784 1.75 1.75v14.5A1.75 1.75 0 0 1 22.25 21H1.75A1.75 1.75 0 0 1 0 19.25Zm1.75-.25a.25.25 0 0 0-.25.25v14.5c0 .138.112.25.25.25h20.5a.25.25 0 0 0 .25-.25V4.75a.25.25 0 0 0-.25-.25Z"></path><path d="M5 8.75A.75.75 0 0 1 5.75 8h11.5a.75.75 0 0 1 0 1.5H5.75A.75.75 0 0 1 5 8.75Zm0 4a.75.75 0 0 1 .75-.75h5.5a.75.75 0 0 1 0 1.5h-5.5a.75.75 0 0 1-.75-.75Z"></path>*/}
            {/*                        </svg>*/}
            {/*                    </span>*/}
            {/*                    <Typography className="ms-3 mt-2" variant="h5" color="text.primary" sx={{ fontWeight: 600 }}>Existing Forms</Typography>*/}
            {/*                                </Stack>*/}
            {/*                <hr className="hr-block bg-blue-500 " />*/}
            {/*                <div className="mt-4 mb-2">*/}
            {/*                    <AlertControl Message={errorMsg} />*/}
            {/*                </div>*/}
            {/*                <div className="mt-4 mb-4">*/}
            {/*                    <AlertControl Message={infoMsg} isSuccess="1" />*/}
            {/*                </div>*/}
            {/*                <div className="row mb-2 mt-4">*/}
            {/*                    <main className="col-md-3 col-lg-10 d-md-block" style={{}}>*/}
            {/*                        <div className="row me-2">*/}
            {/*                            <Box>*/}
            {/*                                <MaterialReactTable*/}
            {/*                                    columns={columns}*/}
            {/*                                    data={flatData}*/}
            {/*                                    displayColumnDefOptions={{*/}
            {/*                                        'mrt-row-select': {*/}
            {/*                                            maxSize: 12,*/}
            {/*                                            header: '',*/}
            {/*                                        },*/}
            {/*                                        'mrt-row-actions': {*/}
            {/*                                            header: '',*/}
            {/*                                            maxSize: 30,*/}
            {/*                                        },*/}
            {/*                                    }}*/}
            {/*                                    enableColumnActions={false}*/}
            {/*                                    enableSorting={false}*/}
            {/*                                    enablePagination={false}*/}
            {/*                                    enableRowNumbers={false}*/}
            {/*                                    enableRowVirtualization*/}
            {/*                                    enableHiding={false}*/}
            {/*                                    enableColumnOrdering={false}*/}
            {/*                                    muiTableBodyRowProps={({ row }) => ({*/}
            {/*                                        onClick: () => {*/}
            {/*                                            setRowSelection((prev) => ({*/}
            {/*                                                [row.id]: true,*/}
            {/*                                            }));*/}
            {/*                                            HandleRowClick(row);*/}
            {/*                                        },*/}
            {/*                                        sx: {*/}
            {/*                                            cursor: 'pointer',*/}
            {/*                                        },*/}
            {/*                                    })}*/}
            {/*                                    positionActionsColumn="last"*/}
            {/*                                    enableColumnResizing={false}*/}
            {/*                                    enableDensityToggle={false}*/}
            {/*                                    enableFullScreenToggle={false}*/}
            {/*                                    muiTableBodyCellProps={({ column }) => ({*/}
            {/*                                        sx: (theme) => ({*/}
            {/*                                            color: theme.palette.text.primary,*/}
            {/*                                            fontSize: theme.typography.body2,*/}
            {/*                                            minHeight: '120px',*/}
            {/*                                            borderRight: '1px solid rgba(224,224,224,1)',*/}
            {/*                                        }),*/}
            {/*                                    })*/}
            {/*                                    }*/}

            {/*                                    muiTableHeadCellProps={{*/}
            {/*                                        sx: (theme) => ({*/}
            {/*                                            color: theme.palette.text.primary,*/}
            {/*                                            borderRight: '1px solid rgba(224,224,224,1)',*/}
            {/*                                            borderTop: '1px solid rgba(224,224,224,1)',*/}
            {/*                                        }),*/}
            {/*                                    }}*/}

            {/*                                    muiTableContainerProps={{*/}
            {/*                                        ref: tableContainerRef,*/}
            {/*                                        sx: { maxHeight: '800px' },*/}
            {/*                                        onScroll: (*/}
            {/*                                            event,*/}
            {/*                                        ) => fetchMoreOnBottomReached(event.target),*/}
            {/*                                    }}*/}
            {/*                                    muiToolbarAlertBannerProps={*/}
            {/*                                        isError*/}
            {/*                                            ? {*/}
            {/*                                                color: 'error',*/}
            {/*                                                children: ErrorMessages.UnknownError,*/}
            {/*                                            }*/}
            {/*                                            : undefined*/}
            {/*                                    }*/}
            {/*                                    onGlobalFilterChange={setGlobalFilter}*/}
            {/*                                    renderBottomToolbarCustomActions={() => (*/}
            {/*                                        <Typography className="mt-3 text-end w-100">*/}
            {/*                                            {*/}
            {/*                                                !isError && data*/}
            {/*                                                    ? totalFetched + ' of ' + totalDBRowCount + ' templates.'*/}
            {/*                                                    : ''*/}
            {/*                                            }*/}
            {/*                                        </Typography>*/}
            {/*                                    )}*/}
            {/*                                    onRowSelectionChange={setRowSelection}*/}
            {/*                                    state={{*/}
            {/*                                        globalFilter,*/}
            {/*                                        isLoading,*/}
            {/*                                        showAlertBanner: isError,*/}
            {/*                                        showProgressBars: isFetching,*/}
            {/*                                        rowSelection,*/}
            {/*                                    }}*/}
            {/*                                    initialState={{*/}
            {/*                                    }}*/}
            {/*                                    muiTablePaperProps={{*/}
            {/*                                        elevation: 0,*/}
            {/*                                    }}*/}
            {/*                                    rowVirtualizerInstanceRef={virtualizerInstanceRef} //get access to the virtualizer instance*/}
            {/*                                    rowVirtualizerProps={{ overscan: 4 }}*/}
            {/*                                    localization={{*/}
            {/*                                        noRecordsToDisplay: 'No templates to display',*/}
            {/*                                        selectedCountOfRowCountRowsSelected: '',*/}
            {/*                                    }}*/}
            {/*                                    renderTopToolbarCustomActions={({ table }) => {*/}
            {/*                                        return (*/}
            {/*                                            <Box sx={{*/}
            {/*                                                display: 'flex', alignItems: 'center', flexDirection: 'row', padding: 0,*/}
            {/*                                            }}>*/}
            {/*                                                <Typography variant="body1" color="text.secondary" className="me-0">Sort</Typography>*/}
            {/*                                                <IconButton className="me-4" sx={{ border: 0, borderColor: '#235ec9', padding: '0px' }} onClick={handleSortMnuClick}>*/}
            {/*                                                    <ExpandMoreIcon width="24px" height="24px" />*/}
            {/*                                                </IconButton>*/}
            {/*                                                <Menu*/}
            {/*                                                    id="basic-menu"*/}
            {/*                                                    anchorEl={sortMnuAnchorEl}*/}
            {/*                                                    open={openSort}*/}
            {/*                                                    onClose={handleSortMnuClose}*/}
            {/*                                                    MenuListProps={{*/}
            {/*                                                        'aria-labelledby': 'basic-button',*/}
            {/*                                                    }}*/}
            {/*                                                >*/}
            {/*                                                    <MenuItem key={0} onClick={(event) => handleDtNewSortMenuClick(event)}>*/}
            {/*                                                        <ListItemIcon fontSize="small">*/}
            {/*                                                            <CheckIcon sx={{ display: tickDtNewSrtMnuDisp.current, fontSize: 24  }} />*/}
            {/*                                                        </ListItemIcon>*/}
            {/*                                                        <Typography className="me-3" variant="body2" display="block" color="text.primary">Date: Newest on top</Typography>*/}
            {/*                                                    </MenuItem>*/}
            {/*                                                    <MenuItem key={1} onClick={(event) => handleDtOldSortMenuClick(event)}>*/}
            {/*                                                        <ListItemIcon fontSize="small">*/}
            {/*                                                            <CheckIcon sx={{ display: tickDtOldSrtMnuDisp.current }} />*/}
            {/*                                                        </ListItemIcon>*/}
            {/*                                                        <Typography className="me-2" variant="body2" display="block" color="text.primary">Date: Oldtest on top</Typography>*/}
            {/*                                                    </MenuItem>*/}
            {/*                                                    <MenuItem key={2} onClick={(event) => handleTmpltNameSortMenuClick(event)}>*/}
            {/*                                                        <ListItemIcon fontSize="small">*/}
            {/*                                                            <CheckIcon sx={{ display: tickTmpltNameSrtMnuDisp.current }} />*/}
            {/*                                                        </ListItemIcon>*/}
            {/*                                                        <Typography className="me-2" variant="body2" display="block" color="text.primary">Template name</Typography>*/}
            {/*                                                    </MenuItem>*/}
            {/*                                                    <MenuItem key={3} onClick={(event) => handleTmpltDescSortMenuClick(event)}>*/}
            {/*                                                        <ListItemIcon fontSize="small">*/}
            {/*                                                            <CheckIcon sx={{ display: tickTmpltDescSrtMnuDisp.current }} />*/}
            {/*                                                        </ListItemIcon>*/}
            {/*                                                        <Typography className="me-2" variant="body2" display="block" color="text.primary">Template description</Typography>*/}
            {/*                                                    </MenuItem>*/}
            {/*                                                    <MenuItem key={4} onClick={(event) => handleTmpltOwnrSortMenuClick(event)}>*/}
            {/*                                                        <ListItemIcon fontSize="small">*/}
            {/*                                                            <CheckIcon sx={{ display: tickTmpltOwnrSrtMnuDisp.current }} />*/}
            {/*                                                        </ListItemIcon>*/}
            {/*                                                        <Typography className="me-2" variant="body2" display="block" color="text.primary">Template owner</Typography>*/}
            {/*                                                    </MenuItem>*/}
            {/*                                                    <MenuItem key={5} onClick={(event) => handleTmpltStatusSortMenuClick(event)}>*/}
            {/*                                                        <ListItemIcon fontSize="small">*/}
            {/*                                                            <CheckIcon sx={{ display: tickTmpltStsSrtMnuDisp.current }} />*/}
            {/*                                                        </ListItemIcon>*/}
            {/*                                                        <Typography className="me-2" variant="body2" display="block" color="text.primary">Status</Typography>*/}
            {/*                                                    </MenuItem>*/}
            {/*                                                </Menu>*/}
            {/*                                            </Box>*/}
            {/*                                        );*/}
            {/*                                    }}*/}
            {/*                                    renderToolbarInternalActions={({ table }) => {*/}
            {/*                                        return (*/}
            {/*                                            <Box>*/}
            {/*                                                <MRT_ToggleGlobalFilterButton table={table} color="#ccc" />*/}
            {/*                                                <Tooltip arrow title="Edit template">*/}
            {/*                                                    <span>*/}
            {/*                                                        <IconButton sx={{ }} disabled={!table.getIsSomeRowsSelected()} onClick={() => handleEditClick(table)}>*/}
            {/*                                                            <EditIcon width="24px" height="24px" color="#ccc" />*/}
            {/*                                                        </IconButton>*/}
            {/*                                                    </span>*/}
            {/*                                                </Tooltip>*/}
            {/*                                                <Tooltip arrow title="Publish template">*/}
            {/*                                                    <span>*/}
            {/*                                                        <IconButton disabled={disPublishTmpltBtn} onClick={() => handlePublishClick(table)}>*/}
            {/*                                                            <PublishIcon />*/}
            {/*                                                        </IconButton>*/}
            {/*                                                    </span>*/}
            {/*                                                </Tooltip>*/}
            {/*                                                <Tooltip arrow title="Delete template">*/}
            {/*                                                    <span>*/}
            {/*                                                        <IconButton disabled={!table.getIsSomeRowsSelected()} onClick={() => handleDeleteClick(table)}>*/}
            {/*                                                            <DeleteIcon />*/}
            {/*                                                        </IconButton>*/}
            {/*                                                    </span>*/}
            {/*                                                </Tooltip>*/}
            {/*                                            </Box>*/}
            {/*                                        );*/}
            {/*                                    }}*/}
            {/*                                    tableInstanceRef={tableInstanceRef}*/}
            {/*                                />*/}
            {/*                            </Box>*/}
            {/*                        </div>*/}
            {/*                    </main>*/}
            {/*                    <div className="col-md-3 col-lg-2 d-md-block" style={{}}>*/}
            {/*                        <div className="row me-2">*/}
            {/*                            <Box>*/}
            {/*                                <Table aria-label="simple table" sx={{}}>*/}
            {/*                                    <TableBody>*/}
            {/*                                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>*/}
            {/*                                            <TableCell component="th" scope="row">Published</TableCell>*/}
            {/*                                            <TableCell align="right" color="text.primary" sx={{ opacity: '0.7' }}>{publishedCount}</TableCell>*/}
            {/*                                        </TableRow>*/}
            {/*                                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>*/}
            {/*                                            <TableCell component="th" scope="row">Not published</TableCell>*/}
            {/*                                            <TableCell align="right" color="text.primary" sx={{ opacity: '0.7' }}>{notPublishedCount}</TableCell>*/}
            {/*                                        </TableRow>*/}
            {/*                                    </TableBody>*/}
            {/*                                </Table>*/}
            {/*                            </Box>*/}
            {/*                        </div>*/}
            {/*                    </div>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</section>*/}
            {/*<Footer />*/}
        </React.Fragment>
    );
};

const queryClient = new QueryClient();

const TemplateList = () => (
    <QueryClientProvider client={queryClient}>
        <Templates />
    </QueryClientProvider>
);

function TemplateListBreadcrumb() {
    return (
        <React.Fragment >
            <Box sx={{ borderLeft: '4px solid #9e9e9e', mt: 0, pt: 0, pb: 2, pl: 1, backgroundColor: '#fff', borderTop: '0px solid #dee2e6' }}>
                <Typography variant="h5" sx={{ fontWeight: 400, lineHeight: 1.1, pl: 2, textTransform: 'none' }} color="text.primary">Application templates</Typography>
            </Box>
            {/*<Box sx={{ borderLeft: '4px solid #42a5f5', mt: 0, pt: 2, pb: 2, pl: 1, backgroundColor: '#f8f9fa', borderTop: '1px solid #dee2e6', boxShadow: '0 0.125rem 0.25rem rgba(0, 0, 0, 0.075)' }}>*/}
            {/*    <Typography variant="h5" sx={{ fontWeight: 300, lineHeight: 1.1, pl: 1, textTransform: 'none' }} color="text.primary">Application templates</Typography>*/}
            {/*</Box>*/}
        </React.Fragment>
    );
}

export default TemplateList;