import React, { useCallback, useState, useEffect, useMemo, useRef, } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import PostLoginMenu from './PostLoginMenu'
import OverlaySpinner from './Controls/OverlaySpinner';
import { Footer } from './Footer';
import { QueryClient, QueryClientProvider, useInfiniteQuery, } from '@tanstack/react-query';
//import { useSelector, useDispatch } from 'react-redux';
import { CallAPI, IsInRole } from '../global/APIUtils';
import { Virtualizer } from '@tanstack/react-virtual';
import { Grid, Stack, Paper, IconButton, Box, Tooltip, Menu, Typography, MenuItem, InputBase, Card, CardContent, CardMedia, bull, CardActions, Button, Divider } from '@mui/material';
import Container from '@mui/material/Container';
//import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import EnrollmentHeader from './EnrollmentHeader'
import { DrawerAppBar } from './AppNavbar'
//import pwdimg from '../assets/images/45357.jpg';
//import Image from 'material-ui-image'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MaterialReactTable, { MRT_ShowHideColumnsButton, MRT_ToggleGlobalFilterButton, MRT_ToggleFiltersButton, } from 'material-react-table';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import NoteAltIcon from '@mui/icons-material/NoteAlt';
import profimg from '../assets/images/profimg.jpg';
import { UserListSortAction } from '../common/AOBEnum';
import ListItemIcon from '@mui/material/ListItemIcon';
import CheckIcon from '@mui/icons-material/Check';
import { ErrorMessages } from '../common/ErrorMessages';
import GroupIcon from '@mui/icons-material/Group';
import PrintIcon from '@mui/icons-material/Print';
import { Search } from 'react-bootstrap-icons';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { AlertDialog } from '../global/AlertDialog';
import AlertWithTitle from './Controls/AlertWithTitle';
import AlertControl from './Controls/AlertControl';
import { LoadProfilePic } from '../global/Helpers';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import InvitationDlg from '../dialogs/InvitationDlg';
import { makeStyles } from "@material-ui/core/styles";
import { AOBSystemProcess } from '../common/AOBEnum';
import { APICall } from '../global/Api';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import { styled } from '@mui/material/styles';

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: 0,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
}));


const UserList = (props) => {

    const location = useLocation();
    const [isDataLoading, setIsDataLoading] = useState(false);
    const [rowSelection, setRowSelection] = useState({});
    const [disEditApp, setDisEditApp] = useState(true);
    //const dispatch = useDispatch();
    //let sessions = useSelector((state) => state.sessionMgmnt);
    const [imageUrl, setImageUrl] = useState(null);
    const [applicantsName, setApplicantsName] = useState('Applicant');
    const [openDelCnfrmDlg, setOpenDelCnfrmDlg] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [successMsg, setSuccessMsg] = useState(null);
    const [successMsgTitle, setSuccessMsgTitle] = useState(null);
    const [showInviteDlg, setShowInviteDlg] = useState(false);
    const [disDeleteApp, setDisDeleteApp] = useState(true);

    const [userId, setUserId] = useState(props.userId);
    const [sessionId, setSessionId] = useState(props.sessionId);

    const sortMenuAction = useRef(UserListSortAction.Newest);

    const cnfrmDelDlgHdr = useRef('');
    const cnfrmDelDlgBLine1 = useRef('');
    const cnfrmDelDlgBLine2 = useRef('');
    const cnfrmDelDlgBLine3 = useRef('');
    const cnfrmDelDlgBLine4 = useRef('');

    const tickSrtDtNewDisp = useRef('block');
    const tickSrtDtOldDisp = useRef('none');
    const tickSrtFirstNameDisp = useRef('none');
    const tickSrtLastNameDisp = useRef('none');
    const tickSrtEmailDisp = useRef('none');
    const tickSrtRoleDisp = useRef('none');

    const selUserId = useRef(0);
    const selUserNm = useRef('');
    const selEmail = useRef('');
    const selRoleNm = useRef('');
    const selUserStatusId = useRef(36);

    const closeInviteDlg = () => {
        setShowInviteDlg(false);
        refetch();
    }

    let navigate = useNavigate();

    const [sortMnuAnchorEl, setSortMnuAnchorEl] = useState(null);
    const openSort = Boolean(sortMnuAnchorEl);

    const handleSortMnuClick = (event) => {
        setSortMnuAnchorEl(event.currentTarget);
    };
    const handleSortMnuClose = () => {
        setSortMnuAnchorEl(null);
    };

    const handleSortDtNewMenuClick = (event) => {
        setSortMnuAnchorEl(null);
        sortMenuAction.current = UserListSortAction.Newest;
        refetch();

        SetSortMenuTick(UserListSortAction.Newest);
    }

    const handleSortDtOldMenuClick = (event) => {
        setSortMnuAnchorEl(null);
        sortMenuAction.current = UserListSortAction.Oldest;
        refetch();

        SetSortMenuTick(UserListSortAction.Oldest);
    }

    const firstNameSortMenuClick = (event) => {
        setSortMnuAnchorEl(null);
        sortMenuAction.current = UserListSortAction.FirstName;
        refetch();

        SetSortMenuTick(UserListSortAction.FirstName);
    }

    const lastNameSortMenuClick = (event) => {
        setSortMnuAnchorEl(null);
        sortMenuAction.current = UserListSortAction.LastName;
        refetch();

        SetSortMenuTick(UserListSortAction.LastName);
    }

    const emailSortMenuClick = (event) => {
        setSortMnuAnchorEl(null);
        sortMenuAction.current = UserListSortAction.Email;
        refetch();

        SetSortMenuTick(UserListSortAction.Email);
    }

    const roleSortMenuClick = (event) => {
        setSortMnuAnchorEl(null);
        sortMenuAction.current = UserListSortAction.RoleName;
        refetch();

        SetSortMenuTick(UserListSortAction.RoleName);
    }

    function SetSortMenuTick(item) {

        tickSrtDtNewDisp.current = 'none';
        tickSrtDtOldDisp.current = 'none';
        tickSrtFirstNameDisp.current = 'none';
        tickSrtLastNameDisp.current = 'none';
        tickSrtEmailDisp.current = 'none';
        tickSrtRoleDisp.current = 'none';

        if (item == UserListSortAction.Newest)
            tickSrtDtNewDisp.current = 'block';

        if (item == UserListSortAction.Oldest)
            tickSrtDtOldDisp.current = 'block';

        if (item == UserListSortAction.FirstName)
            tickSrtFirstNameDisp.current = 'block';

        if (item == UserListSortAction.LastName)
            tickSrtLastNameDisp.current = 'block';

        if (item == UserListSortAction.Email)
            tickSrtEmailDisp.current = 'block';

        if (item == UserListSortAction.RoleName)
            tickSrtRoleDisp.current = 'block';

    }

    const columns = [
        {
            accessorKey: 'TitleName',
            header: 'Title',
            size: 90
        },
        {
            accessorKey: 'FirstName',
            header: 'First Name',
            Cell: ({ cell, column }) => (
                <Box>
                    {cell.getValue() && cell.getValue().length > 15 ? cell.getValue().substring(0, 15) + '...' : cell.getValue()}
                </Box>
            ),
            size: 170
        },
        {
            accessorKey: 'LastName',
            header: 'Last Name',
            Cell: ({ cell, column }) => (
                <Box>
                    {cell.getValue() && cell.getValue().length > 15 ? cell.getValue().substring(0, 15) + '...' : cell.getValue()}
                </Box>
            ),
            size: 170
        },
        {
            accessorKey: 'Gender',
            header: 'Gender',
            size: 120
        },
        {
            accessorKey: 'DateOfBirth',
            header: 'Date of birth',
            size: 160
        },
        {
            accessorKey: 'EmailAddress',
            header: 'Email address',
            Cell: ({ cell, column }) => (
                <Box>
                    {cell.getValue() && cell.getValue().length > 20 ? cell.getValue().substring(0, 20) + '...' : cell.getValue()}
                </Box>
            ),
            size: 210
        },
        {
            accessorKey: 'PhoneNbr',
            header: 'Phone number',
            Cell: ({ cell, column }) => (
                <Box>
                    {cell.getValue() && cell.getValue().length > 10 ? cell.getValue().substring(0, 10) + '...' : cell.getValue()}
                </Box>
            ),
            size: 180
        },
        {
            accessorKey: 'MobileNbr',
            header: 'Mobile number',
            Cell: ({ cell, column }) => (
                <Box>
                    {cell.getValue() && cell.getValue().length > 10 ? cell.getValue().substring(0, 10) + '...' : cell.getValue()}
                </Box>
            ),
            size: 180
        },
        {
            accessorKey: 'RoleName',
            header: 'Role',
            Cell: ({ cell, column }) => (
                <Box>
                    {cell.getValue() && cell.getValue().length > 15 ? cell.getValue().substring(0, 15) + '...' : cell.getValue()}
                </Box>
            ),
            size: 190
        },
        {
            accessorKey: 'ActiveUser',
            header: 'Active',
            size: 100
        },
        {
            accessorKey: 'Locked',
            header: 'Locked',
            size: 100
        },
        {
            accessorKey: 'LastLoginDate',
            header: 'Last login',
            size: 250
        },
        {
            accessorKey: 'LastPasswordChanged',
            header: 'Password changed',
            size: 250
        },
        {
            accessorKey: 'CreateDate',
            header: 'Created at',
            size: 250
        },
        {
            accessorKey: 'CreateUser',
            header: 'Created by',
            Cell: ({ cell, column }) => (
                <Box>
                    {cell.getValue() && cell.getValue().length > 25 ? cell.getValue().substring(0, 25) + '...' : cell.getValue()}
                </Box>
            ),
            size: 230
        },
        {
            accessorKey: 'FailedLoginAttempt',
            header: 'Failed login attempt',
        },
        //{
        //    accessorKey: 'modDate',
        //    header: 'Last Modified',
        //    size: 90,
        //},
    ];

    const fetchSize = 25;

    const tableContainerRef = useRef(null); //we can get access to the underlying TableContainer element and react to its scroll events
    const virtualizerInstanceRef = useRef < Virtualizer > null; //we can get access to the underlying Virtualizer instance and call its scrollToIndex method

    const [columnFilters, setColumnFilters] = useState([]);
    const [globalFilter, setGlobalFilter] = useState('');
    const [sorting, setSorting] = useState([]);
    const [searchText, setSearchText] = useState(null);

    const { data, fetchNextPage, isError, isFetching, isLoading, refetch } =
        useInfiniteQuery(
            ['table-data', columnFilters, globalFilter, sorting],
            async ({ pageParam = 0 }) => {

                const apiUrl = new URL(
                    process.env.REACT_APP_GET_USER_LIST,
                    process.env.REACT_APP_BASE_URL,
                );

                apiUrl.searchParams.set('start', `${pageParam * fetchSize}`);
                apiUrl.searchParams.set('size', `${fetchSize}`);

                apiUrl.searchParams.set('globalFilter', searchText ?? '');
                apiUrl.searchParams.set('sorting', sortMenuAction.current);

                let response = await APICall({ url: apiUrl.href, method: 'GET', contentType: 'application/json', responseType: '' }, userId, sessionId);

                if (response !== null && response !== undefined) {
                    return response;
                }
                else {
                    throw Error(ErrorMessages.UnknownError);
                }
            },
            {
                getNextPageParam: (_lastGroup, groups) => groups.length,
                keepPreviousData: true,
                refetchOnWindowFocus: false,
            },
        );

    const flatData = useMemo(
        () => data?.pages.flatMap((page) => page.data) ?? [],
        [data, sortMenuAction],
    );

    const totalDBRowCount = data?.pages?.[0]?.meta?.totalRowCount ?? 0;
    const totalFetched = flatData.length;
    const fetchMoreOnBottomReached = useCallback(
        (containerRefElement) => {
            if (containerRefElement) {
                const { scrollHeight, scrollTop, clientHeight } = containerRefElement;
                if (
                    scrollHeight - scrollTop - clientHeight < 400 &&
                    !isFetching &&
                    totalFetched < totalDBRowCount
                ) {
                    fetchNextPage();
                }
            }
        },
        [fetchNextPage, isFetching, totalFetched, totalDBRowCount],
    );

    useEffect(() => {

        if (virtualizerInstanceRef.current) {
            virtualizerInstanceRef.current.scrollToIndex(0);
        }
    }, [sorting, columnFilters]);

    useEffect(() => {

        fetchMoreOnBottomReached(tableContainerRef.current);
    }, [fetchMoreOnBottomReached]);

    useEffect(() => {

    }, []);

    const inviteUser = (event) => {
        setShowInviteDlg(true);
    };

    const newUser = (event) => {
        navigate('/User', { state: { userid: -1, loggedInUserId: userId, sessionId: sessionId }, replace: true });
    };

    const handleSearchChange = (event) => {
        setSearchText(event.target.value);
    };

    const handleSearchClick = (event) => {
        refetch();
    };

    const handleEditClick = (table) => {

        if (table.getSelectedRowModel().rows.length > 0) {
            let row = table.getSelectedRowModel().rows[0];
            navigate('/User', { state: { userid: parseInt(selUserId.current), userEmail: selEmail.current, userStatusId: selUserStatusId.current, loggedInUserId: userId, sessionId: sessionId }, replace: true });
        }
    };

    const handleDeleteClick = (table) => {

        if (table.getSelectedRowModel().rows.length > 0) {
            let row = table.getSelectedRowModel().rows[0];
            cnfrmDelDlgBLine1.current = 'User Name: ' + row.original.FirstName + ' ' + row.original.LastName;
            cnfrmDelDlgBLine2.current = 'Email address: ' + row.original.EmailAddress;
            cnfrmDelDlgBLine3.current = 'Note: All applications (if any) of this user will also be deleted.';
        }
        cnfrmDelDlgHdr.current = 'Are you sure you want to delete the following user?';
        setOpenDelCnfrmDlg(true);
    };

    const handleDelCnfrmDlgClose = async (nvalue, svalue) => {

        setOpenDelCnfrmDlg(false);

        try {

            if (nvalue === null || nvalue === undefined) {
                return;
            }

            props.loadingChange(true);

            const apiUrl = new URL(
                process.env.REACT_APP_DELETE_USER,
                process.env.REACT_APP_BASE_URL,
            );

            const inputJson = {
                UserId: nvalue
            };

            let response = await APICall({ url: apiUrl.href, method: 'POST', payload: inputJson, contentType: 'application/json', responseType: '' }, userId, sessionId);

            if (response !== null && response !== undefined) {

                if (response.Errors !== undefined && response.Errors.length > 0) {
                    setErrorMsg(response.Errors[0].Message);
                }
                else {
                    setSuccessMsgTitle("User deleted");
                    setSuccessMsg('User named as \'' + selUserNm.current + '\' deleted successfully');
                    setRowSelection({});
                    refetch();
                }

                props.loadingChange(false);
            }
        }
        catch (err) {
            setErrorMsg(ErrorMessages.UnknownError);
            props.loadingChange(false);
        }
        finally {
            setIsDataLoading(false);
        }
    };

    const HandleRowClick = async (row) => {

        selUserId.current = row.original.UserId;
        selUserNm.current = row.original.FirstName + ' ' + row.original.LastName;
        selEmail.current = row.original.EmailAddress;
        selUserStatusId.current = row.original.UserStatus;
        selRoleNm.current = 'Role: ' + row.original.RoleName;

        let canDelete = IsInRole(AOBSystemProcess.DeleteUser, userId, sessionId);
        if (canDelete) {
            setDisDeleteApp(false);
        }
        else {
            setDisDeleteApp(true);
        }

        try {

            props.loadingChange(true);

            const apiUrl = new URL(
                process.env.REACT_APP_GET_PROFILE_IMAGE,
                process.env.REACT_APP_BASE_URL,
            );

            apiUrl.searchParams.set('cntctId', `${parseInt(selUserId.current)}`);

            let response = await APICall({ url: apiUrl.href, method: 'GET', contentType: 'application/json', responseType: 'blob' }, userId, sessionId);

            if (response) {
                if (response.type == 'image/jpeg') {
                    setImageUrl(response);
                }
                else {
                    setImageUrl(null);
                }
                props.loadingChange(false);
            }
        }
        catch (err) {
            setErrorMsg(ErrorMessages.UnknownError);
            props.loadingChange(false);
        }
    }

    return (
        <React.Fragment>
            
                {/*    <Grid item>*/}
                {/*<Box sx={{ pl: 0, mt: 4 }}>*/}
                {/*    */}{/*<Button type="submit" variant="outlined" size="large" style={{ boxShadow: 'none', border: '2px solid #0972d3', borderRadius: '40px', padding: '0.4rem 2rem', textTransform: 'none', }} onClick={(e) => newUser(e)}>*/}
                {/*    */}{/*    <Typography variant="body2" color="#0972d3" sx={{ textTransform: 'none', fontWeight: 600 }} >New Participant</Typography>*/}
                {/*    */}{/*    </Button>*/}

                {/*            */}{/*<Button variant="outlined" size="small" sx={{ padding: '0.4rem 2rem', border: 2, borderColor: '#616161' }} onClick={(e) => newUser(e)}>*/}
                {/*            */}{/*    <Typography variant="body2" color="#212121" sx={{ textTransform: 'none', fontWeight: 500 }} >New Participant</Typography>*/}
                {/*            */}{/*</Button>*/}
                {/*            */}{/*<Button type="submit" variant="contained" size="large" style={{ padding: '0.3rem 2rem', textTransform: 'none' }} onClick={(e) => newUser(e)}>New User</Button>*/}
                {/*        </Box>*/}
                {/*    </Grid>*/}
                    <Grid item>
                        <Grid item xs container direction="row" spacing={2} sx={{ pt: 1, pb: 3,  }}>
                            <Grid item md={10} sx={{}} justifyContent="center">
                                <Box sx={{pt: 1}}>
                                    <AlertWithTitle Message={successMsg} variant="body1" severity="success" color="success" icon={true} Title={successMsgTitle} />
                                    <AlertControl Message={errorMsg} severity="error" color="error" icon={true} />
                                </Box>
                                <Box sx={{ borderBottom: '1px solid rgba(0,0,0,0.1)', pl: 0, pt: 0, pb: 3, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <Paper elevation={0}
                                        component="form"
                                        sx={{ display: 'flex', alignItems: 'center', width: 500, border: 1, borderColor: '#d5dbdb', }}
                                    >
                                        <InputBase className="form-control" sx={{ boxShadow: 'none !important', border: 'none !important', padding: '0px 1rem !important', font: 'unset' }}
                                            placeholder="Search First name, Last name, Email address" onChange={handleSearchChange}
                                        />
                                        <IconButton type="button" sx={{ p: '10px' }} aria-label="search" onClick={(event) => handleSearchClick(event)}>
                                            <Search />
                                        </IconButton>
                                    </Paper>
                                    <Stack direction="row">
                                        <Box sx={{ }}>
                                            <Button type="submit" variant="outlined" size="large" style={{ boxShadow: 'none', border: '2px solid #0972d3', borderRadius: '40px', padding: '0.4rem 2.5rem', textTransform: 'none', }} onClick={(e) => newUser(e)}>
                                                <Typography variant="body2" color="#0972d3" sx={{ textTransform: 'none', fontWeight: 500 }} >New</Typography>
                                            </Button>
                                        </Box>
                                        <Box sx={{ pl: 1 }}>
                                            <Button type="submit" variant="outlined" size="large" style={{ boxShadow: 'none', border: '2px solid #0972d3', borderRadius: '40px', padding: '0.4rem 2.5rem', textTransform: 'none', }} onClick={(e) => inviteUser(e)}>
                                                <Typography variant="body2" color="#0972d3" sx={{ textTransform: 'none', fontWeight: 500 }} >Invite</Typography>
                                            </Button>
                                        </Box>
                                    </Stack>
                                </Box>
                                <Box>
                                    <MaterialReactTable
                                        columns={columns}
                                        data={flatData}
                                        displayColumnDefOptions={{
                                            'mrt-row-select': {
                                                maxSize: 12,
                                                header: '',
                                            },
                                        }}
                                        enableColumnActions={false}
                                        enableSorting={false}
                                        enableExpandAll={false}
                                        enablePagination={false}
                                        enableRowNumbers={false}
                                        enableRowVirtualization
                                        enableHiding={false}
                                        enableColumnFilters={false}
                                        enableColumnOrdering={false}
                                        muiTableBodyRowProps={({ row }) => ({
                                            onClick: () => {
                                                setRowSelection((prev) => ({
                                                    [row.id]: true,
                                                }));
                                                HandleRowClick(row);
                                            },
                                            sx: {
                                                cursor: 'pointer',
                                            },
                                        })}
                                        positionActionsColumn="last"
                                        enableColumnResizing={false}
                                        enableDensityToggle={false}
                                        enableFullScreenToggle={false}
                                        muiTableBodyCellProps={({ column }) => ({
                                            sx: (theme) => ({
                                                color: theme.palette.text.secondary,
                                                fontSize: theme.typography.body1,
                                                lineHeight: 1,
                                                borderBottom: '1px solid rgba(0,0,0,0.1)',
                                                pl: 0, pr: 0, pt: 2, pb: 2
                                            }),
                                        })
                                        }

                                        muiTableHeadCellProps={{
                                            sx: (theme) => ({
                                                color: '#383d3f',
                                                fontSize: theme.typography.h6,
                                                borderBottom: '1px solid rgba(0,0,0,0.1)',
                                                fontWeight: 700, elevation: 0,
                                                pt: 0, pl: 0, pr: 0,
                                                pb: 0, textDecoration: 'underline'
                                            }),
                                        }}

                                        muiTableContainerProps={{
                                            ref: tableContainerRef,
                                            sx: { maxHeight: '550px' },
                                            onScroll: (
                                                event,
                                            ) => fetchMoreOnBottomReached(event.target),
                                        }}
                                        muiToolbarAlertBannerProps={
                                            isError
                                                ? {
                                                    color: 'error',
                                                    children: ErrorMessages.UnknownError,
                                                }
                                                : undefined
                                        }
                                        onColumnFiltersChange={setColumnFilters}
                                        onSortingChange={setSorting}
                                        renderBottomToolbarCustomActions={() => (
                                        <Box sx={{ mt: 2, pl: 0 }}>
                                            <Typography>
                                                {
                                                    !isError && data
                                                        ? totalFetched + ' of ' + totalDBRowCount + ' participant(s)'
                                                        : ''
                                                }
                                            </Typography>
                                        </Box>
                                        )}
                                        onRowSelectionChange={setRowSelection}
                                        state={{
                                            columnFilters,
                                            isLoading,
                                            showAlertBanner: isError,
                                            showProgressBars: isFetching,
                                            rowSelection,
                                            sorting,
                                        }}
                                        initialState={{
                                        }}
                                        muiTablePaperProps={{
                                            elevation: 0,
                                        }}
                                        rowVirtualizerProps={{ overscan: 4 }}
                                        localization={{
                                            noRecordsToDisplay: 'No user to display',
                                            selectedCountOfRowCountRowsSelected: '',
                                        }}
                                        renderTopToolbarCustomActions={({ table }) => {
                                            return (
                                                <Box sx={{
                                                    display: 'flex', alignItems: 'center', flexDirection: 'row'
                                                }}>
                                                    <Typography variant="h6" color="text.primary" sx={{ pl: 0, pr: 4, fontWeight: 500, letterSpacing: '-1px' }}>Active participants</Typography>
                                                    <Typography variant="body1" color="text.secondary" sx={{ pr: 0.5 }}>Sort</Typography>
                                                    <IconButton sx={{ border: 0, borderColor: '#235ec9', p: 0, pr: 2 }} onClick={(event) => handleSortMnuClick(event)}>
                                                        <ExpandMoreIcon sx={{ fontSize: 24 }} />
                                                    </IconButton>
                                                    <Menu
                                                        id="basic-menu"
                                                        anchorEl={sortMnuAnchorEl}
                                                        open={openSort}
                                                        onClose={handleSortMnuClose}
                                                        MenuListProps={{
                                                            'aria-labelledby': 'basic-button',
                                                        }}
                                                    >
                                                        <MenuItem key={0} className="mt-2" onClick={(event) => handleSortDtNewMenuClick(event)}>
                                                            <ListItemIcon className="ms-3 mb-1">
                                                                <CheckIcon sx={{ display: tickSrtDtNewDisp.current, fontSize: 24, color: '#0070CC' }} />
                                                            </ListItemIcon>
                                                            <Typography className="me-5" variant="body2" sx={{ textTransform: 'none' }} color="text.primary">Date: Newest on top</Typography>
                                                        </MenuItem>
                                                        <MenuItem key={1} onClick={(event) => handleSortDtOldMenuClick(event)}>
                                                            <ListItemIcon className="ms-3 mt-1 mb-1">
                                                                <CheckIcon sx={{ display: tickSrtDtOldDisp.current, fontSize: 24, color: '#0070CC' }} />
                                                            </ListItemIcon>
                                                            <Typography className="me-5" variant="body2" sx={{ textTransform: 'none' }} color="text.primary">Date: Oldtest on top</Typography>
                                                        </MenuItem>
                                                        <MenuItem key={0} className="mt-2" onClick={(event) => firstNameSortMenuClick(event)}>
                                                            <ListItemIcon className="ms-3 mb-1">
                                                                <CheckIcon sx={{ display: tickSrtFirstNameDisp.current, fontSize: 24, color: '#0070CC' }} />
                                                            </ListItemIcon>
                                                            <Typography className="me-5" variant="body2" sx={{ textTransform: 'none' }} color="text.primary">Fist name</Typography>
                                                        </MenuItem>
                                                        <MenuItem key={1} onClick={(event) => lastNameSortMenuClick(event)}>
                                                            <ListItemIcon className="ms-3 mt-1 mb-1">
                                                                <CheckIcon sx={{ display: tickSrtLastNameDisp.current, fontSize: 24, color: '#0070CC' }} />
                                                            </ListItemIcon>
                                                            <Typography className="me-5" variant="body2" sx={{ textTransform: 'none' }} color="text.primary">Last name</Typography>
                                                        </MenuItem>
                                                        <MenuItem key={2} onClick={(event) => emailSortMenuClick(event)}>
                                                            <ListItemIcon className="ms-3 mt-1 mb-1">
                                                                <CheckIcon sx={{ display: tickSrtEmailDisp.current, fontSize: 24, color: '#0070CC' }} />
                                                            </ListItemIcon>
                                                            <Typography className="me-5" variant="body2" sx={{ textTransform: 'none' }} color="text.primary">Email address</Typography>
                                                        </MenuItem>
                                                        <MenuItem key={3} onClick={(event) => roleSortMenuClick(event)}>
                                                            <ListItemIcon className="ms-3 mt-1 mb-1">
                                                                <CheckIcon sx={{ display: tickSrtRoleDisp.current, fontSize: 24, color: '#0070CC' }} />
                                                            </ListItemIcon>
                                                            <Typography className="me-5" variant="body2" sx={{ textTransform: 'none' }} color="text.primary">Role</Typography>
                                                        </MenuItem>
                                                    </Menu>
                                                </Box>
                                            );
                                        }}
                                        renderToolbarInternalActions={({ table }) => {
                                            return (
                                                <Box>
                                                    <Tooltip arrow title="Edit user">
                                                        <span>
                                                            <IconButton size="small" sx={{ ml: 1, mr: 1, mb: 1 }} disabled={table.getSelectedRowModel().flatRows.length <= 0} onClick={() => handleEditClick(table)}>
                                                                <EditIcon sx={{ fontSize: 28 }} />
                                                            </IconButton>
                                                        </span>
                                                    </Tooltip>
                                                    <Tooltip arrow title="Delete user">
                                                        <span>
                                                            <IconButton size="small" sx={{ ml: 1, mr: 1, mb: 1 }} disabled={disDeleteApp} onClick={() => handleDeleteClick(table)}>
                                                                <DeleteIcon sx={{ fontSize: 28 }} />
                                                            </IconButton>
                                                        </span>
                                                    </Tooltip>
                                                </Box>
                                            );
                                        }}
                                    />
                                </Box>
                            </Grid>
                            <Grid item md={2} sx={{}} justifyContent="center">
                                <Box sx={{ml: 1, p: 1, mt: 15, border: '2px solid #000'}}>
                                    <Card elevation={0} sx={{ Width: '100%' }}>
                                        {imageUrl ?
                                            <CardMedia component="img" sx={{ maxWidth: 345 }} image={URL.createObjectURL(imageUrl)} />
                                            :
                                            <img src={profimg} alt="Prifile Picture" className="prof-pic-prvw" />
                                        }
                                    </Card>
                                    <Typography variant="h6" color="text.primary" sx={{ fontWeight: 500, mt: 2 }}>
                                        {selUserNm.current}
                                    </Typography>
                                    <Typography variant="body2" color="text.primary" sx={{ pt: 1 }}>
                                        {selEmail.current}
                                    </Typography>
                                    <Typography variant="body2" color="text.primary" sx={{pt: 1}}>
                                        {selRoleNm.current}
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                <AlertDialog
                    id="del-cnfrm-dlg"
                    keepMounted
                    open={openDelCnfrmDlg}
                    onClose={handleDelCnfrmDlgClose}
                    nvalue={selUserId.current}
                    alertheader={cnfrmDelDlgHdr.current}
                    bmsgline1={cnfrmDelDlgBLine1.current}
                    bmsgline2={cnfrmDelDlgBLine2.current}
                    bmsgline3={cnfrmDelDlgBLine3.current}
                />
                {showInviteDlg ?
                <InvitationDlg open={showInviteDlg} onClose={closeInviteDlg} userId={userId} sessionId={sessionId} />
                    : null
                }
        </React.Fragment>
    );
};

const PendingActivationUserList = (props) => {

    const [isDataLoading, setIsDataLoading] = useState(false);
    const [rowSelection, setRowSelection] = useState({});
    const [disEditApp, setDisEditApp] = useState(true);
    //const dispatch = useDispatch();
    //let sessions = useSelector((state) => state.sessionMgmnt);
    const [imageUrl, setImageUrl] = useState(null);
    const [applicantsName, setApplicantsName] = useState('Applicant');
    const [openDelCnfrmDlg, setOpenDelCnfrmDlg] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [successMsg, setSuccessMsg] = useState(null);
    const [successMsgTitle, setSuccessMsgTitle] = useState(null);
    const [showInviteDlg, setShowInviteDlg] = useState(false);
    const sortMenuAction = useRef(UserListSortAction.Newest);

    const [userId, setUserId] = useState(props.userId);
    const [sessionId, setSessionId] = useState(props.sessionId);

    const tickSrtDtNewDisp = useRef('block');
    const tickSrtDtOldDisp = useRef('none');
    const tickSrtFirstNameDisp = useRef('none');
    const tickSrtLastNameDisp = useRef('none');
    const tickSrtEmailDisp = useRef('none');
    const tickSrtRoleDisp = useRef('none');


    const [sortMnuAnchorEl, setSortMnuAnchorEl] = useState(null);
    const openSort = Boolean(sortMnuAnchorEl);

    const handleSortMnuClick = (event) => {
        setSortMnuAnchorEl(event.currentTarget);
    };
    const handleSortMnuClose = () => {
        setSortMnuAnchorEl(null);
    };

    const handleSortDtNewMenuClick = (event) => {
        setSortMnuAnchorEl(null);
        sortMenuAction.current = UserListSortAction.Newest;
        refetch();

        SetSortMenuTick(UserListSortAction.Newest);
    }

    const handleSortDtOldMenuClick = (event) => {
        setSortMnuAnchorEl(null);
        sortMenuAction.current = UserListSortAction.Oldest;
        refetch();

        SetSortMenuTick(UserListSortAction.Oldest);
    }

    const firstNameSortMenuClick = (event) => {
        setSortMnuAnchorEl(null);
        sortMenuAction.current = UserListSortAction.FirstName;
        refetch();

        SetSortMenuTick(UserListSortAction.FirstName);
    }

    const lastNameSortMenuClick = (event) => {
        setSortMnuAnchorEl(null);
        sortMenuAction.current = UserListSortAction.LastName;
        refetch();

        SetSortMenuTick(UserListSortAction.LastName);
    }

    const emailSortMenuClick = (event) => {
        setSortMnuAnchorEl(null);
        sortMenuAction.current = UserListSortAction.Email;
        refetch();

        SetSortMenuTick(UserListSortAction.Email);
    }

    const roleSortMenuClick = (event) => {
        setSortMnuAnchorEl(null);
        sortMenuAction.current = UserListSortAction.RoleName;
        refetch();

        SetSortMenuTick(UserListSortAction.RoleName);
    }

    function SetSortMenuTick(item) {

        tickSrtDtNewDisp.current = 'none';
        tickSrtDtOldDisp.current = 'none';
        tickSrtFirstNameDisp.current = 'none';
        tickSrtLastNameDisp.current = 'none';
        tickSrtEmailDisp.current = 'none';
        tickSrtRoleDisp.current = 'none';

        if (item == UserListSortAction.Newest)
            tickSrtDtNewDisp.current = 'block';

        if (item == UserListSortAction.Oldest)
            tickSrtDtOldDisp.current = 'block';

        if (item == UserListSortAction.FirstName)
            tickSrtFirstNameDisp.current = 'block';

        if (item == UserListSortAction.LastName)
            tickSrtLastNameDisp.current = 'block';

        if (item == UserListSortAction.Email)
            tickSrtEmailDisp.current = 'block';

        if (item == UserListSortAction.RoleName)
            tickSrtRoleDisp.current = 'block';

    }

    const columns = [
        {
            accessorKey: 'TitleName',
            header: 'Title',
            size: 90
        },
        {
            accessorKey: 'FirstName',
            header: 'First name',
            Cell: ({ cell, column }) => (
                <Box>
                    {cell.getValue() && cell.getValue().length > 15 ? cell.getValue().substring(0, 15) + '...' : cell.getValue()}
                </Box>
            ),
            size: 170
        },
        {
            accessorKey: 'LastName',
            header: 'Last name',
            Cell: ({ cell, column }) => (
                <Box>
                    {cell.getValue() && cell.getValue().length > 15 ? cell.getValue().substring(0, 15) + '...' : cell.getValue()}
                </Box>
            ),
            size: 170
        },
        {
            accessorKey: 'Gender',
            header: 'Gender',
            size: 120
        },
        {
            accessorKey: 'DateOfBirth',
            header: 'Date of birth',
            size: 160
        },
        {
            accessorKey: 'EmailAddress',
            header: 'Email address',
            Cell: ({ cell, column }) => (
                <Box>
                    {cell.getValue() && cell.getValue().length > 20 ? cell.getValue().substring(0, 20) + '...' : cell.getValue()}
                </Box>
            ),
            size: 210
        },
        {
            accessorKey: 'PhoneNbr',
            header: 'Phone number',
            Cell: ({ cell, column }) => (
                <Box>
                    {cell.getValue() && cell.getValue().length > 10 ? cell.getValue().substring(0, 10) + '...' : cell.getValue()}
                </Box>
            ),
            size: 180
        },
        {
            accessorKey: 'MobileNbr',
            header: 'Mobile numder',
            Cell: ({ cell, column }) => (
                <Box>
                    {cell.getValue() && cell.getValue().length > 10 ? cell.getValue().substring(0, 10) + '...' : cell.getValue()}
                </Box>
            ),
            size: 180
        },
        {
            accessorKey: 'RoleName',
            header: 'Role',
            Cell: ({ cell, column }) => (
                <Box>
                    {cell.getValue() && cell.getValue().length > 15 ? cell.getValue().substring(0, 15) + '...' : cell.getValue()}
                </Box>
            ),
            size: 190
        },
        {
            accessorKey: 'CreateDate',
            header: 'Created at',
            size: 250
        },
        {
            accessorKey: 'CreateUser',
            header: 'Created by',
            Cell: ({ cell, column }) => (
                <Box>
                    {cell.getValue() && cell.getValue().length > 25 ? cell.getValue().substring(0, 25) + '...' : cell.getValue()}
                </Box>
            ),
            size: 230
        }
    ];

    const fetchSize = 25;

    const tableContainerRef = useRef(null); //we can get access to the underlying TableContainer element and react to its scroll events
    const virtualizerInstanceRef = useRef < Virtualizer > null; //we can get access to the underlying Virtualizer instance and call its scrollToIndex method

    const [columnFilters, setColumnFilters] = useState([]);
    const [globalFilter, setGlobalFilter] = useState('');
    const [sorting, setSorting] = useState([]);
    const [searchText, setSearchText] = useState(null);

    const { data, fetchNextPage, isError, isFetching, isLoading, refetch } =
        useInfiniteQuery(
            ['table-data', columnFilters, globalFilter, sorting],
            async ({ pageParam = 0 }) => {

                const apiUrl = new URL(
                    process.env.REACT_APP_GET_USER_LIST,
                    process.env.REACT_APP_BASE_URL,
                );

                apiUrl.searchParams.set('start', `${pageParam * fetchSize}`);
                apiUrl.searchParams.set('size', `${fetchSize}`);

                apiUrl.searchParams.set('globalFilter', searchText ?? '');
                apiUrl.searchParams.set('sorting', sortMenuAction.current);
                apiUrl.searchParams.set('userstatus', 72);

                let response = await APICall({ url: apiUrl.href, method: 'GET', contentType: 'application/json', responseType: '' }, userId, sessionId);

                if (response !== null && response !== undefined) {
                    return response;
                }
                else {
                    throw Error(ErrorMessages.UnknownError);
                }
            },
            {
                getNextPageParam: (_lastGroup, groups) => groups.length,
                keepPreviousData: true,
                refetchOnWindowFocus: false,
            },
        );

    const flatData = useMemo(
        () => data?.pages.flatMap((page) => page.data) ?? [],
        [data, sortMenuAction],
    );

    const totalDBRowCount = data?.pages?.[0]?.meta?.totalRowCount ?? 0;
    const totalFetched = flatData.length;
    const fetchMoreOnBottomReached = useCallback(
        (containerRefElement) => {
            if (containerRefElement) {
                const { scrollHeight, scrollTop, clientHeight } = containerRefElement;
                if (
                    scrollHeight - scrollTop - clientHeight < 400 &&
                    !isFetching &&
                    totalFetched < totalDBRowCount
                ) {
                    fetchNextPage();
                }
            }
        },
        [fetchNextPage, isFetching, totalFetched, totalDBRowCount],
    );

    useEffect(() => {

        if (virtualizerInstanceRef.current) {
            virtualizerInstanceRef.current.scrollToIndex(0);
        }
    }, [sorting, columnFilters]);

    useEffect(() => {

        fetchMoreOnBottomReached(tableContainerRef.current);
    }, [fetchMoreOnBottomReached]);

    const handleSearchChange = (event) => {
        setSearchText(event.target.value);
    };

    const handleSearchClick = (event) => {
        refetch();
    };

    return (
        <React.Fragment>

            <Grid item>
                <Grid item xs container direction="row" spacing={2} sx={{ pt: 0, pb: 3, }}>
                    <Grid item md={12} sx={{}} justifyContent="center">
                        <Box sx={{ pt: 1 }}>
                            <AlertControl Message={errorMsg} severity="error" color="error" icon={true} />
                        </Box>
                        <Box sx={{ pl: 3, pt: 1, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Paper elevation={0}
                                component="form"
                                sx={{ display: 'flex', alignItems: 'center', width: 500, border: 1, borderColor: '#bdbdbd', }}
                            >
                                <InputBase className="form-control" sx={{ boxShadow: 'none !important', border: 'none !important', padding: '0px 1rem !important', font: 'unset' }}
                                    placeholder="Search First name, Last name, Email address" onChange={handleSearchChange}
                                />
                                <IconButton type="button" sx={{ p: '10px' }} aria-label="search" onClick={(event) => handleSearchClick(event)}>
                                    <Search />
                                </IconButton>
                            </Paper>
                        </Box>

                        <Box sx={{minHeight: '500px'}}>
                            <MaterialReactTable sx={{minHeight: '500px'}}
                                columns={columns}
                                data={flatData}
                                displayColumnDefOptions={{
                                    'mrt-row-select': {
                                        maxSize: 12,
                                        header: '',
                                    },
                                }}
                                enableColumnActions={false}
                                enableSorting={false}
                                enableExpandAll={false}
                                enablePagination={false}
                                enableRowNumbers={false}
                                enableRowVirtualization
                                enableHiding={false}
                                enableColumnFilters={false}
                                enableColumnOrdering={false}
                                muiTableBodyRowProps={({ row }) => ({
                                    onClick: () => {
                                        setRowSelection((prev) => ({
                                            [row.id]: true,
                                        }));
                                        //HandleRowClick(row);
                                    },
                                    sx: {
                                        cursor: 'pointer',
                                    },
                                })}
                                positionActionsColumn="last"
                                enableColumnResizing={false}
                                enableDensityToggle={false}
                                enableFullScreenToggle={false}
                                muiTableBodyCellProps={({ column }) => ({
                                    sx: (theme) => ({
                                        color: theme.palette.text.secondary,
                                        fontSize: theme.typography.body1,
                                        lineHeight: 1,
                                        borderBottom: '1px solid rgba(0,0,0,0.1)',
                                        pl: 0, pr: 0, pt: 2, pb: 2
                                    }),
                                })
                                }

                                muiTableHeadCellProps={{
                                    sx: (theme) => ({
                                        color: '#383d3f',
                                        fontSize: theme.typography.h6,
                                        borderBottom: '1px solid rgba(0,0,0,0.1)',
                                        fontWeight: 700, elevation: 0,
                                        pt: 0, pl: 0, pr: 0,
                                        pb: 0, textDecoration: 'underline'
                                    }),
                                }}

                                muiTableContainerProps={{
                                    ref: tableContainerRef,
                                    sx: { maxHeight: '800px' },
                                    onScroll: (
                                        event,
                                    ) => fetchMoreOnBottomReached(event.target),
                                }}
                                muiToolbarAlertBannerProps={
                                    isError
                                        ? {
                                            color: 'error',
                                            children: ErrorMessages.UnknownError,
                                        }
                                        : undefined
                                }
                                onColumnFiltersChange={setColumnFilters}
                                onSortingChange={setSorting}
                                renderBottomToolbarCustomActions={() => (
                                    <Box sx={{ mt: 2, pl: 0 }}>
                                        <Typography>
                                            {
                                                !isError && data
                                                    ? totalFetched + ' of ' + totalDBRowCount + ' participant(s)'
                                                    : ''
                                            }
                                        </Typography>
                                    </Box>
                                )}
                                onRowSelectionChange={setRowSelection}
                                enableGlobalFilter={false}
                                state={{
                                    columnFilters,
                                    isLoading,
                                    showAlertBanner: isError,
                                    showProgressBars: isFetching,
                                    rowSelection,
                                    sorting,
                                }}
                                initialState={{
                                }}
                                muiTablePaperProps={{
                                    elevation: 0,
                                }}
                                rowVirtualizerProps={{ overscan: 4 }}
                                localization={{
                                    noRecordsToDisplay: 'No participant to display',
                                    selectedCountOfRowCountRowsSelected: '',
                                }}
                                renderTopToolbarCustomActions={({ table }) => {
                                    return (
                                        <Box sx={{
                                            display: 'flex', alignItems: 'center', flexDirection: 'row'
                                        }}>
                                            <Typography variant="h6" color="text.primary" sx={{ pl: 0, pr: 4, fontWeight: 500, letterSpacing: '-1px' }}>Pending participants</Typography>
                                            <Typography variant="body1" color="text.secondary" sx={{ pr: 0.5 }}>Sort</Typography>
                                            <IconButton sx={{ p: 0, pr: 2 }} onClick={(event) => handleSortMnuClick(event)}>
                                                <ExpandMoreIcon sx={{ fontSize: 24 }} />
                                            </IconButton>
                                            <Menu
                                                id="basic-menu"
                                                anchorEl={sortMnuAnchorEl}
                                                open={openSort}
                                                onClose={handleSortMnuClose}
                                                MenuListProps={{
                                                    'aria-labelledby': 'basic-button',
                                                }}
                                            >
                                                <MenuItem key={0} className="mt-2" onClick={(event) => handleSortDtNewMenuClick(event)}>
                                                    <ListItemIcon className="ms-3 mb-1">
                                                        <CheckIcon sx={{ display: tickSrtDtNewDisp.current, fontSize: 24, color: '#0070CC' }} />
                                                    </ListItemIcon>
                                                    <Typography className="me-5" variant="body2" sx={{ textTransform: 'none' }} color="text.primary">Date: Newest on top</Typography>
                                                </MenuItem>
                                                <MenuItem key={1} onClick={(event) => handleSortDtOldMenuClick(event)}>
                                                    <ListItemIcon className="ms-3 mt-1 mb-1">
                                                        <CheckIcon sx={{ display: tickSrtDtOldDisp.current, fontSize: 24, color: '#0070CC' }} />
                                                    </ListItemIcon>
                                                    <Typography className="me-5" variant="body2" sx={{ textTransform: 'none' }} color="text.primary">Date: Oldtest on top</Typography>
                                                </MenuItem>
                                                <MenuItem key={0} className="mt-2" onClick={(event) => firstNameSortMenuClick(event)}>
                                                    <ListItemIcon className="ms-3 mb-1">
                                                        <CheckIcon sx={{ display: tickSrtFirstNameDisp.current, fontSize: 24, color: '#0070CC' }} />
                                                    </ListItemIcon>
                                                    <Typography className="me-5" variant="body2" sx={{ textTransform: 'none' }} color="text.primary">Fist name</Typography>
                                                </MenuItem>
                                                <MenuItem key={1} onClick={(event) => lastNameSortMenuClick(event)}>
                                                    <ListItemIcon className="ms-3 mt-1 mb-1">
                                                        <CheckIcon sx={{ display: tickSrtLastNameDisp.current, fontSize: 24, color: '#0070CC' }} />
                                                    </ListItemIcon>
                                                    <Typography className="me-5" variant="body2" sx={{ textTransform: 'none' }} color="text.primary">Last name</Typography>
                                                </MenuItem>
                                                <MenuItem key={2} onClick={(event) => emailSortMenuClick(event)}>
                                                    <ListItemIcon className="ms-3 mt-1 mb-1">
                                                        <CheckIcon sx={{ display: tickSrtEmailDisp.current, fontSize: 24, color: '#0070CC' }} />
                                                    </ListItemIcon>
                                                    <Typography className="me-5" variant="body2" sx={{ textTransform: 'none' }} color="text.primary">Email address</Typography>
                                                </MenuItem>
                                                <MenuItem key={3} onClick={(event) => roleSortMenuClick(event)}>
                                                    <ListItemIcon className="ms-3 mt-1 mb-1">
                                                        <CheckIcon sx={{ display: tickSrtRoleDisp.current, fontSize: 24, color: '#0070CC' }} />
                                                    </ListItemIcon>
                                                    <Typography className="me-5" variant="body2" sx={{ textTransform: 'none' }} color="text.primary">Role</Typography>
                                                </MenuItem>
                                            </Menu>
                                        </Box>
                                    );
                                }}                                
                            />
                        </Box>
                    </Grid>
                  
                </Grid>
            </Grid>
        </React.Fragment>
    );
};

const DeactivatedUserList = (props) => {

    const [isDataLoading, setIsDataLoading] = useState(false);
    const [rowSelection, setRowSelection] = useState({});
    const [disEditApp, setDisEditApp] = useState(true);
    //const dispatch = useDispatch();
    //let sessions = useSelector((state) => state.sessionMgmnt);
    const [imageUrl, setImageUrl] = useState(null);
    const [applicantsName, setApplicantsName] = useState('Applicant');
    const [openDelCnfrmDlg, setOpenDelCnfrmDlg] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [successMsg, setSuccessMsg] = useState(null);
    const [successMsgTitle, setSuccessMsgTitle] = useState(null);
    const [showInviteDlg, setShowInviteDlg] = useState(false);
    const sortMenuAction = useRef(UserListSortAction.Newest);

    const [userId, setUserId] = useState(props.userId);
    const [sessionId, setSessionId] = useState(props.sessionId);

    const tickSrtDtNewDisp = useRef('block');
    const tickSrtDtOldDisp = useRef('none');
    const tickSrtFirstNameDisp = useRef('none');
    const tickSrtLastNameDisp = useRef('none');
    const tickSrtEmailDisp = useRef('none');
    const tickSrtRoleDisp = useRef('none');


    const [sortMnuAnchorEl, setSortMnuAnchorEl] = useState(null);
    const openSort = Boolean(sortMnuAnchorEl);

    const handleSortMnuClick = (event) => {
        setSortMnuAnchorEl(event.currentTarget);
    };
    const handleSortMnuClose = () => {
        setSortMnuAnchorEl(null);
    };

    const handleSortDtNewMenuClick = (event) => {
        setSortMnuAnchorEl(null);
        sortMenuAction.current = UserListSortAction.Newest;
        refetch();

        SetSortMenuTick(UserListSortAction.Newest);
    }

    const handleSortDtOldMenuClick = (event) => {
        setSortMnuAnchorEl(null);
        sortMenuAction.current = UserListSortAction.Oldest;
        refetch();

        SetSortMenuTick(UserListSortAction.Oldest);
    }

    const firstNameSortMenuClick = (event) => {
        setSortMnuAnchorEl(null);
        sortMenuAction.current = UserListSortAction.FirstName;
        refetch();

        SetSortMenuTick(UserListSortAction.FirstName);
    }

    const lastNameSortMenuClick = (event) => {
        setSortMnuAnchorEl(null);
        sortMenuAction.current = UserListSortAction.LastName;
        refetch();

        SetSortMenuTick(UserListSortAction.LastName);
    }

    const emailSortMenuClick = (event) => {
        setSortMnuAnchorEl(null);
        sortMenuAction.current = UserListSortAction.Email;
        refetch();

        SetSortMenuTick(UserListSortAction.Email);
    }

    const roleSortMenuClick = (event) => {
        setSortMnuAnchorEl(null);
        sortMenuAction.current = UserListSortAction.RoleName;
        refetch();

        SetSortMenuTick(UserListSortAction.RoleName);
    }

    function SetSortMenuTick(item) {

        tickSrtDtNewDisp.current = 'none';
        tickSrtDtOldDisp.current = 'none';
        tickSrtFirstNameDisp.current = 'none';
        tickSrtLastNameDisp.current = 'none';
        tickSrtEmailDisp.current = 'none';
        tickSrtRoleDisp.current = 'none';

        if (item == UserListSortAction.Newest)
            tickSrtDtNewDisp.current = 'block';

        if (item == UserListSortAction.Oldest)
            tickSrtDtOldDisp.current = 'block';

        if (item == UserListSortAction.FirstName)
            tickSrtFirstNameDisp.current = 'block';

        if (item == UserListSortAction.LastName)
            tickSrtLastNameDisp.current = 'block';

        if (item == UserListSortAction.Email)
            tickSrtEmailDisp.current = 'block';

        if (item == UserListSortAction.RoleName)
            tickSrtRoleDisp.current = 'block';

    }

    const columns = [
        {
            accessorKey: 'TitleName',
            header: 'Title',
            size: 90
        },
        {
            accessorKey: 'FirstName',
            header: 'First name',
            Cell: ({ cell, column }) => (
                <Box>
                    {cell.getValue() && cell.getValue().length > 15 ? cell.getValue().substring(0, 15) + '...' : cell.getValue()}
                </Box>
            ),
            size: 170
        },
        {
            accessorKey: 'LastName',
            header: 'Last name',
            Cell: ({ cell, column }) => (
                <Box>
                    {cell.getValue() && cell.getValue().length > 15 ? cell.getValue().substring(0, 15) + '...' : cell.getValue()}
                </Box>
            ),
            size: 170
        },
        {
            accessorKey: 'Gender',
            header: 'Gender',
            size: 120
        },
        {
            accessorKey: 'DateOfBirth',
            header: 'Date of birth',
            size: 160
        },
        {
            accessorKey: 'EmailAddress',
            header: 'Email address',
            Cell: ({ cell, column }) => (
                <Box>
                    {cell.getValue() && cell.getValue().length > 20 ? cell.getValue().substring(0, 20) + '...' : cell.getValue()}
                </Box>
            ),
            size: 210
        },
        {
            accessorKey: 'PhoneNbr',
            header: 'Phone number',
            Cell: ({ cell, column }) => (
                <Box>
                    {cell.getValue() && cell.getValue().length > 10 ? cell.getValue().substring(0, 10) + '...' : cell.getValue()}
                </Box>
            ),
            size: 180
        },
        {
            accessorKey: 'MobileNbr',
            header: 'Mobile number',
            Cell: ({ cell, column }) => (
                <Box>
                    {cell.getValue() && cell.getValue().length > 10 ? cell.getValue().substring(0, 10) + '...' : cell.getValue()}
                </Box>
            ),
            size: 180
        },
        {
            accessorKey: 'RoleName',
            header: 'Role',
            Cell: ({ cell, column }) => (
                <Box>
                    {cell.getValue() && cell.getValue().length > 15 ? cell.getValue().substring(0, 15) + '...' : cell.getValue()}
                </Box>
            ),
            size: 190
        },
        {
            accessorKey: 'CreateDate',
            header: 'Created at',
            size: 250
        },
        {
            accessorKey: 'CreateUser',
            header: 'Created by',
            Cell: ({ cell, column }) => (
                <Box>
                    {cell.getValue() && cell.getValue().length > 25 ? cell.getValue().substring(0, 25) + '...' : cell.getValue()}
                </Box>
            ),
            size: 230
        }
    ];

    const fetchSize = 25;

    const tableContainerRef = useRef(null); //we can get access to the underlying TableContainer element and react to its scroll events
    const virtualizerInstanceRef = useRef < Virtualizer > null; //we can get access to the underlying Virtualizer instance and call its scrollToIndex method

    const [columnFilters, setColumnFilters] = useState([]);
    const [globalFilter, setGlobalFilter] = useState('');
    const [sorting, setSorting] = useState([]);
    const [searchText, setSearchText] = useState(null);

    const { data, fetchNextPage, isError, isFetching, isLoading, refetch } =
        useInfiniteQuery(
            ['table-data', columnFilters, globalFilter, sorting],
            async ({ pageParam = 0 }) => {

                const apiUrl = new URL(
                    process.env.REACT_APP_GET_USER_LIST,
                    process.env.REACT_APP_BASE_URL,
                );

                apiUrl.searchParams.set('start', `${pageParam * fetchSize}`);
                apiUrl.searchParams.set('size', `${fetchSize}`);

                apiUrl.searchParams.set('globalFilter', searchText ?? '');
                apiUrl.searchParams.set('sorting', sortMenuAction.current);
                apiUrl.searchParams.set('isActive', 0);

                let response = await APICall({ url: apiUrl.href, method: 'GET', contentType: 'application/json', responseType: '' }, userId, sessionId);

                if (response !== null && response !== undefined) {
                    return response;
                }
                else {
                    throw Error(ErrorMessages.UnknownError);
                }
            },
            {
                getNextPageParam: (_lastGroup, groups) => groups.length,
                keepPreviousData: true,
                refetchOnWindowFocus: false,
            },
        );

    const flatData = useMemo(
        () => data?.pages.flatMap((page) => page.data) ?? [],
        [data, sortMenuAction],
    );

    const totalDBRowCount = data?.pages?.[0]?.meta?.totalRowCount ?? 0;
    const totalFetched = flatData.length;
    const fetchMoreOnBottomReached = useCallback(
        (containerRefElement) => {
            if (containerRefElement) {
                const { scrollHeight, scrollTop, clientHeight } = containerRefElement;
                if (
                    scrollHeight - scrollTop - clientHeight < 400 &&
                    !isFetching &&
                    totalFetched < totalDBRowCount
                ) {
                    fetchNextPage();
                }
            }
        },
        [fetchNextPage, isFetching, totalFetched, totalDBRowCount],
    );

    useEffect(() => {

        if (virtualizerInstanceRef.current) {
            virtualizerInstanceRef.current.scrollToIndex(0);
        }
    }, [sorting, columnFilters]);

    useEffect(() => {

        fetchMoreOnBottomReached(tableContainerRef.current);
    }, [fetchMoreOnBottomReached]);

    const handleSearchChange = (event) => {
        setSearchText(event.target.value);
    };

    const handleSearchClick = (event) => {
        refetch();
    };

    return (
        <React.Fragment>

            <Grid item>
                <Grid item xs container direction="row" spacing={2} sx={{ pt: 0, pb: 3, }}>
                    <Grid item md={12} sx={{}} justifyContent="center">
                        <Box sx={{ pt: 1 }}>
                            <AlertControl Message={errorMsg} severity="error" color="error" icon={true} />
                        </Box>
                        <Box sx={{ pl: 3, pt: 1, pb: 3, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Paper elevation={0}
                                component="form"
                                sx={{ display: 'flex', alignItems: 'center', width: 500, border: 1, borderColor: '#d5dbdb', }}
                            >
                                <InputBase className="form-control" sx={{ boxShadow: 'none !important', border: 'none !important', padding: '0px 1rem !important', font: 'unset' }}
                                    placeholder="Search First name, Last name, Email address" onChange={handleSearchChange}
                                />
                                <IconButton type="button" sx={{ p: '10px' }} aria-label="search" onClick={(event) => handleSearchClick(event)}>
                                    <Search />
                                </IconButton>
                            </Paper>
                        </Box>

                        <Box sx={{ minHeight: '500px' }}>
                            <MaterialReactTable sx={{ minHeight: '500px' }}
                                columns={columns}
                                data={flatData}
                                displayColumnDefOptions={{
                                    'mrt-row-select': {
                                        maxSize: 12,
                                        header: '',
                                    },
                                }}
                                enableColumnActions={false}
                                enableSorting={false}
                                enableExpandAll={false}
                                enablePagination={false}
                                enableRowNumbers={false}
                                enableRowVirtualization
                                enableHiding={false}
                                enableColumnFilters={false}
                                enableColumnOrdering={false}
                                muiTableBodyRowProps={({ row }) => ({
                                    onClick: () => {
                                        setRowSelection((prev) => ({
                                            [row.id]: true,
                                        }));
                                        //HandleRowClick(row);
                                    },
                                    sx: {
                                        cursor: 'pointer',
                                    },
                                })}
                                positionActionsColumn="last"
                                enableColumnResizing={false}
                                enableDensityToggle={false}
                                enableFullScreenToggle={false}
                                muiTableBodyCellProps={({ column }) => ({
                                    sx: (theme) => ({
                                        color: theme.palette.text.secondary,
                                        fontSize: theme.typography.body1,
                                        lineHeight: 1,
                                        borderBottom: '1px solid rgba(0,0,0,0.1)',
                                        pl: 0, pr: 0, pt: 2, pb: 2
                                    }),
                                })
                                }

                                muiTableHeadCellProps={{
                                    sx: (theme) => ({
                                        color: '#383d3f',
                                        fontSize: theme.typography.h6,
                                        borderBottom: '1px solid rgba(0,0,0,0.1)',
                                        fontWeight: 700, elevation: 0,
                                        pt: 0, pl: 0, pr: 0,
                                        pb: 0, textDecoration: 'underline'
                                    }),
                                }}

                                muiTableContainerProps={{
                                    ref: tableContainerRef,
                                    sx: { maxHeight: '550px' },
                                    onScroll: (
                                        event,
                                    ) => fetchMoreOnBottomReached(event.target),
                                }}
                                muiToolbarAlertBannerProps={
                                    isError
                                        ? {
                                            color: 'error',
                                            children: ErrorMessages.UnknownError,
                                        }
                                        : undefined
                                }
                                onColumnFiltersChange={setColumnFilters}
                                onSortingChange={setSorting}
                                renderBottomToolbarCustomActions={() => (
                                <Box sx={{ mt: 2, pl: 0 }}>
                                    <Typography>
                                        {
                                            !isError && data
                                                ? totalFetched + ' of ' + totalDBRowCount + ' participant(s)'
                                                : ''
                                        }
                                        </Typography>
                                </Box>
                                )}
                                onRowSelectionChange={setRowSelection}
                                enableGlobalFilter={false}
                                state={{
                                    columnFilters,
                                    isLoading,
                                    showAlertBanner: isError,
                                    showProgressBars: isFetching,
                                    rowSelection,
                                    sorting,
                                }}
                                initialState={{
                                }}
                                muiTablePaperProps={{
                                    elevation: 0,
                                }}
                                rowVirtualizerProps={{ overscan: 4 }}
                                localization={{
                                    noRecordsToDisplay: 'No participant to display',
                                    selectedCountOfRowCountRowsSelected: '',
                                }}
                                renderTopToolbarCustomActions={({ table }) => {
                                    return (
                                        <Box sx={{
                                            display: 'flex', alignItems: 'center', flexDirection: 'row'
                                        }}>
                                            <Typography variant="h6" color="text.primary" sx={{ pl: 1, pr: 4, fontWeight: 500, letterSpacing: '-1px' }}>Deactivated users</Typography>
                                            <Typography variant="body1" color="text.primary" sx={{ pr: 0.5 }}>Sort</Typography>
                                            <IconButton sx={{ p: 0, pr: 2 }} onClick={(event) => handleSortMnuClick(event)}>
                                                <ExpandMoreIcon sx={{ fontSize: 24 }} />
                                            </IconButton>
                                            <Menu
                                                id="basic-menu"
                                                anchorEl={sortMnuAnchorEl}
                                                open={openSort}
                                                onClose={handleSortMnuClose}
                                                MenuListProps={{
                                                    'aria-labelledby': 'basic-button',
                                                }}
                                            >
                                                <MenuItem key={0} className="mt-2" onClick={(event) => handleSortDtNewMenuClick(event)}>
                                                    <ListItemIcon className="ms-3 mb-1">
                                                        <CheckIcon sx={{ display: tickSrtDtNewDisp.current, fontSize: 24, color: '#0070CC' }} />
                                                    </ListItemIcon>
                                                    <Typography className="me-5" variant="body2" sx={{ textTransform: 'none' }} color="text.primary">Date: Newest on top</Typography>
                                                </MenuItem>
                                                <MenuItem key={1} onClick={(event) => handleSortDtOldMenuClick(event)}>
                                                    <ListItemIcon className="ms-3 mt-1 mb-1">
                                                        <CheckIcon sx={{ display: tickSrtDtOldDisp.current, fontSize: 24, color: '#0070CC' }} />
                                                    </ListItemIcon>
                                                    <Typography className="me-5" variant="body2" sx={{ textTransform: 'none' }} color="text.primary">Date: Oldtest on top</Typography>
                                                </MenuItem>
                                                <MenuItem key={0} className="mt-2" onClick={(event) => firstNameSortMenuClick(event)}>
                                                    <ListItemIcon className="ms-3 mb-1">
                                                        <CheckIcon sx={{ display: tickSrtFirstNameDisp.current, fontSize: 24, color: '#0070CC' }} />
                                                    </ListItemIcon>
                                                    <Typography className="me-5" variant="body2" sx={{ textTransform: 'none' }} color="text.primary">Fist name</Typography>
                                                </MenuItem>
                                                <MenuItem key={1} onClick={(event) => lastNameSortMenuClick(event)}>
                                                    <ListItemIcon className="ms-3 mt-1 mb-1">
                                                        <CheckIcon sx={{ display: tickSrtLastNameDisp.current, fontSize: 24, color: '#0070CC' }} />
                                                    </ListItemIcon>
                                                    <Typography className="me-5" variant="body2" sx={{ textTransform: 'none' }} color="text.primary">Last name</Typography>
                                                </MenuItem>
                                                <MenuItem key={2} onClick={(event) => emailSortMenuClick(event)}>
                                                    <ListItemIcon className="ms-3 mt-1 mb-1">
                                                        <CheckIcon sx={{ display: tickSrtEmailDisp.current, fontSize: 24, color: '#0070CC' }} />
                                                    </ListItemIcon>
                                                    <Typography className="me-5" variant="body2" sx={{ textTransform: 'none' }} color="text.primary">Email address</Typography>
                                                </MenuItem>
                                                <MenuItem key={3} onClick={(event) => roleSortMenuClick(event)}>
                                                    <ListItemIcon className="ms-3 mt-1 mb-1">
                                                        <CheckIcon sx={{ display: tickSrtRoleDisp.current, fontSize: 24, color: '#0070CC' }} />
                                                    </ListItemIcon>
                                                    <Typography className="me-5" variant="body2" sx={{ textTransform: 'none' }} color="text.primary">Role</Typography>
                                                </MenuItem>
                                            </Menu>
                                        </Box>
                                    );
                                }}
                            />
                        </Box>
                    </Grid>

                </Grid>
            </Grid>
        </React.Fragment>
    );
};
function AllUsers(props) {

    const queryClient = new QueryClient();

    return (
        <React.Fragment>
            <QueryClientProvider client={queryClient}>
                <UserList userId={props.userId} sessionId={props.sessionId} loadingChange={props.loadingChange} />
            </QueryClientProvider>
        </React.Fragment>
    );
}

function AllPendingActivationUsers(props) {

    const queryClient = new QueryClient();

    return (
        <React.Fragment>
            <QueryClientProvider client={queryClient}>
                <PendingActivationUserList userId={props.userId} sessionId={props.sessionId} loadingChange={props.loadingChange} />
            </QueryClientProvider>
        </React.Fragment>
    );
}

function AllDeactivatedUsers(props) {

    const queryClient = new QueryClient();

    return (
        <React.Fragment>
            <QueryClientProvider client={queryClient}>
                <DeactivatedUserList userId={props.userId} sessionId={props.sessionId} loadingChange={props.loadingChange} />
            </QueryClientProvider>
        </React.Fragment>
    );
}

function UserManagement() {

    const location = useLocation();

    const [userId, setUserId] = useState(location.state.userId || -1);
    const [sessionId, setSessionId] = useState(location.state.sessionId || '');
    const [isDataLoading, setIsDataLoading] = useState(false);

    const [expanded, setExpanded] = useState(true);
    const [tabValue, setTabValue] = React.useState(0);

    const tabStyles = makeStyles({
        indicator: {
            border: "1px solid #16191f"
        },
    });

    const tabClasses = tabStyles();

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    function handleLoadingState(isLoading)
    {
        setIsDataLoading(isLoading);
    }

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    function TabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box sx={{ p: 0 }}>
                        {children}
                    </Box>
                )}
            </div>
        );
    }

    const handleAccordianChange = () => {
        if (expanded) {
            setExpanded(false);
        }
        else {
            setExpanded(true);
        }
    }

    return (
        <React.Fragment>
            <Container maxWidth="xl" sx={{}}>
                <OverlaySpinner disappear={isDataLoading} />
                <PostLoginMenu userId={userId} sessionId={sessionId} />
                <Paper className=""
                    sx={{
                        position: 'relative',
                        backgroundColor: '#fff',
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        pb: 12
                    }}
                >
                    <Grid item sx={{}}>
                        <Box sx={{
                            mr: { xs: 3, md: 2 },
                            ml: { xs: 3, md: 2 }
                        }}>
                            <Accordion spacing={0} elevation={0} expanded={expanded} onChange={handleAccordianChange} sx={{ p: 0, m: 0, width: '100%' }}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header" sx={{ mr: 2 }}>
                                </AccordionSummary>
                                <AccordionDetails sx={{ pr: 2, pl: 0, pb: 0}}>
                                    {/*<EnrollmentHeader />*/}
                                    <DrawerAppBar userId={userId} sessionId={sessionId} />
                                </AccordionDetails>
                            </Accordion>
                        </Box>
                    </Grid>
                    <Grid item>
                        <Box sx={{ ml: 6, mr: 6, border: '0px solid #000', pt: 1 }}>
                            <Typography variant="h3" color="text.primary" sx={{ borderBottom: '1px solid rgba(0,0,0,0.1)', lineHeight: 2.2, fontWeight: 500, letterSpacing: '-1px', textAlign: 'center' }} >View Participants</Typography>
                        </Box>
                    </Grid>
                    <Grid item>
                        <Box sx={{ ml: 14, mr: 14, border: '0px solid #000', pt: 1 }}>
                            <Typography sx={{ borderBottom: '1px solid rgba(0,0,0,0.1)', mt: 2, pb: 3, fontWeight: 400, lineHeight: 1.3 }} variant="h6" color="text.secondary">
                                This page will enable settlement adminstrators to control user access and on-board and off-board users/participants to and from this application.
                            </Typography>
                        </Box>
                    </Grid>

                    <Grid item>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', ml: 15, mr: 15, mt: 1, mb: 1 }}>
                            <Stack direction="row" spacing={2} sx={{}}>
                                <Typography variant="h6" color="text.primary" sx={{ fontWeight: 500, pt: 2 }}>Manage Participants</Typography>
                            </Stack>                            
                        </Box>
                    </Grid>

                    <Grid item>
                        <Box sx={{ ml: 15, mr: 15, mb: 5, mt: 2, borderTop: '0px solid #eaeded', }}>
                            <Box sx={{ borderBottom: 0, borderColor: '#d5dbdb' }}>
                                <Tabs value={tabValue} classes={{ indicator: tabClasses.indicator }} onChange={handleTabChange} sx={{ height: '60px', background: '#fafafa', borderBottom: '1px solid #eaeded'}}>
                                    <Tab label="Active" {...a11yProps(0)} sx={{ height: '65px', textTransform: 'none'}} />
                                    <Tab label="Pending" {...a11yProps(1)} sx={{ height: '65px', textTransform: 'none' }} />
                                    <Tab label="Deactivated" {...a11yProps(2)} sx={{ height: '65px', textTransform: 'none' }} />
                                </Tabs>
                            </Box>
                            {tabValue === 0 && (
                                <AllUsers userId={userId} sessionId={sessionId} loadingChange={handleLoadingState} />
                            )}
                            {tabValue === 1 && (
                                <AllPendingActivationUsers userId={userId} sessionId={sessionId} loadingChange={handleLoadingState} />
                            )}
                            {tabValue === 2 && (
                                <AllDeactivatedUsers userId={userId} sessionId={sessionId} loadingChange={handleLoadingState} />
                            )}
                        </Box>
                    </Grid>

                </Paper>
                <Footer />
            </Container>
        </React.Fragment>
    );

    {/*<AllUsers />*/}
}

function UserListBreadcrumb() {
    return (
        <React.Fragment >
            <Box sx={{ borderLeft: '4px solid #9e9e9e', mt: 0, pt: 0, pb: 2, pl: 1, backgroundColor: '#fff', borderTop: '0px solid #dee2e6' }}>
                <Typography variant="h5" sx={{ fontWeight: 400, lineHeight: 1.1, pl: 2, textTransform: 'none' }} color="text.primary">User Management</Typography>
            </Box>
            {/*<Box sx={{ borderLeft: '4px solid #42a5f5', mt: 0, pt: 2, pb: 2, pl: 1, backgroundColor: '#f8f9fa', borderTop: '1px solid #dee2e6', boxShadow: '0 0.125rem 0.25rem rgba(0, 0, 0, 0.075)' }}>*/}
            {/*    <Typography variant="h5" sx={{ fontWeight: 300, lineHeight: 1.1, pl: 1, textTransform: 'none' }} color="text.primary">User Management</Typography>*/}
            {/*</Box>*/}
        </React.Fragment>
    );
}

export default UserManagement;