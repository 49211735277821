import React from 'react';
import axios from 'axios';
import { APIResponseStatus } from '../common/AOBEnum';
import { Logout } from './Logout';
import moment from 'moment';
import { ErrorMessages } from '../common/ErrorMessages';

export async function CallLoginAPI(requestConfig) {

    return await CallServerAPI(requestConfig);
}

export async function CallLogoutAPI(requestConfig) {

    let response = await CallServerAPI(requestConfig);

    return response;
}

export async function CallAPI(requestConfig, userId, sessionId) {

    let LogoutResponse = null;

    //if (userId == null || userId == -1) {
    //    LogoutResponse.meta.Logout = '1';
    //    return LogoutResponse;
    //}

    requestConfig.headers = GetStandardHeaderWithAccessToken(userId, sessionId);

    let response = await CallServerAPI(requestConfig);

    if (response != null && response != undefined) {

        let error = IsError(response);

        if (error != 0) {
            if (error === APIResponseStatus.TokenExpired) {
                response = await GetAccessTokenByRefreshToken(userId, sessionId);
                response = await HandleRefreshTokenResponse(response, requestConfig, userId, sessionId);
            }
        }
    }

    return response;
}

function GetStandardHeaderWithAccessToken(userId, sessionId) {
    let token = '';
    let userID = 0;

    token = localStorage.getItem(userId + '_AOBIDENTITYA');

    let headers = { "Content-Type": "application/json" };
    headers = { ...headers, "Authorization": "Bearer " + token };

    return headers;
}

export function IsError(response) {
    let errorCode = 0;

    let apiErrors = response["Errors"];

    if (apiErrors != null && apiErrors != undefined) {
        if (apiErrors.length > 0) {
            errorCode = apiErrors[0].Code;
        }
    }

    return errorCode;
}

function AOBDelay(delay) {
    return new Promise(res => setTimeout(res, delay));
}

export function IsAxiosError(response) {
    let errorCode = 0;

    let apiErrors = response.data["Errors"];

    if (apiErrors != null && apiErrors != undefined) {
        if (apiErrors.length > 0) {
            errorCode = apiErrors[0].Code;
        }
    }

    return errorCode;
}

async function HandleRefreshTokenResponse(response, requestConfig, userId, sessionId) {

    if (response != null && response != undefined) {

        let error = IsError(response);

        if (error != 0) {
            response.meta.Logout = '1';
            return response;
        }

        if (response["data"][0]["Access_Token"] !== null && response["data"][0]["Access_Token"] !== undefined
            && response["data"][0]["Refresh_Token"] !== null && response["data"][0]["Refresh_Token"] !== undefined)
        {
            AddToSession(response["data"][0]["Access_Token"], response["data"][0]["Refresh_Token"], response["data"][0]["Access_Token_Version"], response["data"][0]["Refresh_Token_Version"], userId, sessionId);
            let newResponse = await CallAPI(requestConfig, userId, sessionId);
            return newResponse;
        }
        else if (response.meta.TokenOld === "1") {
            let curVer = response["data"][0]["Refresh_Token_Version"];
            let tver = localStorage.getItem(userId + '_AOBIDENTITYRV');

            let timeout = false;
            let elapsedTime = 0;
            while (curVer !== tver && !timeout) {
                console.log('message1');
                await AOBDelay(2000);
                elapsedTime += 2000;

                if (elapsedTime >= 180000) {
                    timeout = true;
                }

                tver = localStorage.getItem(userId + '_AOBIDENTITYRV');
            }
            if (timeout) {
                response.meta.Logout = '1';
                return response;
            }
            else {
                //let atoken = localStorage.getItem(userId + '_AOBIDENTITYA')
                let newResponse = await CallAPI(requestConfig, userId, sessionId);
                return newResponse;
            }
        }
        else {
            response.meta.Logout = '1';
            return response;
        }
    }
    else {
        response.meta.Logout = '1';
        return response;
    }
}
function AddToSession(accessToken, refreshToken, accessTokenVersion, refreshTokenVersion, userId, sessionId) {

    localStorage.setItem(userId + '_AOBIDENTITYA', accessToken);
    localStorage.setItem(userId + '_AOBIDENTITYAV', accessTokenVersion);

    localStorage.setItem(userId + '_AOBIDENTITYR', refreshToken);
    localStorage.setItem(userId + '_AOBIDENTITYRV', refreshTokenVersion);
}

//async function RetryApiCall(requestConfig, userID, accessToken) {

//    let headers = { ...requestConfig.headers, "Authorization": "Bearer " + accessToken };
//    requestConfig.headers = headers;

//    let response = await CallServerAPI(requestConfig);

//    if (response != null && response != undefined) {

//        let error = IsError(response);

//        if (error != 0) {
//            let userID = GetLoggedInUserId();
//            Logout(userID, 'session_notfound');
//            return;
//        }
//    }

//    return response;
//}


async function GetAccessTokenByRefreshToken(userId, sessionId) {
    let refreshToken = '';

    refreshToken = localStorage.getItem(userId + '_AOBIDENTITYR');

    let tokenHeaders = {
        'Content-Type': 'application/x-www-form-urlencoded',
        'refresh_token': "Bearer " + refreshToken
    };

    var formData = new URLSearchParams();
    formData.append("grant_type", "refresh_token");

    let apiUrl = new URL(
        process.env.REACT_APP_LOGINUSER,
        process.env.REACT_APP_BASE_URL,
    );

    let response = await CallServerAPI({ url: apiUrl.href, method: 'POST', headers: tokenHeaders, form: formData });

    return response;
}

export async function CallServerAPI(requestConfig) {

    let headers = { ...requestConfig.headers, "User-Agent": "Account-Onboarding" };

    let endPoint = requestConfig.url;
    headers = { ...headers, "x-api-key": process.env.REACT_APP_ABCD };

    const response = await fetch(endPoint, {
        method: requestConfig.method ? requestConfig.method : 'GET',
        headers: headers,
        body: requestConfig.body ? JSON.stringify(requestConfig.body) : requestConfig.form ? requestConfig.form : null
    });

    let jsonResponse = null;

    if (response.status >= 200 && response.status <= 299) {

        if (response != null && response != undefined) {
            jsonResponse = await response.json();
        }

        return jsonResponse;
    } else {
        throw Error("API didn't returned success status. Status: " + response.status + ", Status Text: " + response.statusText + ", URL: " + response.url);
    }
}

export async function CallAPIUsingAxios(requestConfig, userId, sessionId) {

    let response = null;
    let tokenExpired = false;
    requestConfig.headers = GetStandardHeaderWithAccessToken(userId, sessionId);
    requestConfig.headers = { ...requestConfig.headers, "Accept": '*' };
    try {
        response = await CallServerAPIUsingAxios(requestConfig);
    }
    catch (ex) {
        if (ex.response.status == 401 && ex.response.headers.aobauthstatus == 5) {
            tokenExpired = true;
        }
        else {
            throw ex;
        }
    }

    if (tokenExpired) {
        response = await GetAccessTokenByRefreshToken(userId, sessionId);
        response = await HandleAxiosRefreshTokenResponse(response, requestConfig, userId, sessionId);
    }

    return response;
}

async function HandleAxiosRefreshTokenResponse(response, requestConfig, userId, sessionId) {

    //let userID = GetLoggedInUserId();

    if (response != null && response != undefined) {

        let error = IsError(response);

        if (error != 0) {
            response.meta.Logout = '1';
            return response;
        }

        if (response["data"][0]["Access_Token"] !== null && response["data"][0]["Access_Token"] !== undefined
            && response["data"][0]["Refresh_Token"] !== null && response["data"][0]["Refresh_Token"] !== undefined) {
            AddToSession(response["data"][0]["Access_Token"], response["data"][0]["Refresh_Token"], response["data"][0]["Access_Token_Version"], response["data"][0]["Refresh_Token_Version"], userId, sessionId);
            let newResponse = await CallAPIUsingAxios(requestConfig, userId, sessionId);
            return newResponse;
        }
        else if (response.meta.TokenOld === "1") {
            let curVer = response["data"][0]["Refresh_Token_Version"];
            let tver = localStorage.getItem(userId + '_AOBIDENTITYRV');

            let timeout = false;
            let elapsedTime = 0;
            while (curVer !== tver && !timeout) {
                await AOBDelay(2000);
                elapsedTime += 2000;

                if (elapsedTime >= 180000) {
                    timeout = true;
                }
            }
            if (timeout) {
                response.meta.Logout = '1';
                return response;
            }
            else {
                let newResponse = await CallAPIUsingAxios(requestConfig, userId, sessionId);
                return newResponse;
            }
        }
        else {
            response.meta.Logout = '1';
            return response;
        }
    }
    else {
        //response.meta.Logout = '1';
        return response;
    }
}

//async function RetryAxiosApiCall(requestConfig, accessToken) {

//    let userID = GetLoggedInUserId();

//    let headers = { ...requestConfig.headers, "Authorization": "Bearer " + accessToken };
//    requestConfig.headers = headers;

//    let response = await CallServerAPIUsingAxios(requestConfig);

//    if (response != null && response != undefined) {

//        let error = IsError(response);

//        if (error != 0) {
//            if (error === APIResponseStatus.TokenExpired ||
//                error === APIResponseStatus.InvalidToken ||
//                error === APIResponseStatus.TokenMissing)
//            {
//                Logout(userID, 'session_notfound');
//            }
//        }
//    }

//    return response;
//}

export async function CallServerAPIUsingAxios(requestConfig) {

        let response = null;

        let endPoint = requestConfig.url;
        let headers = { ...requestConfig.headers, "x-api-key": process.env.REACT_APP_ABCD };

        if (requestConfig.method === 'POST') {
            headers = { ...headers, "Content-Type": "multipart/form-data" };
            response = await axios.post(requestConfig.url, requestConfig.formData, {
                headers: headers
            });
        }
        else if (requestConfig.method === 'GET') {
            response = await axios.get(requestConfig.url, {
                responseType: requestConfig.responseType,
                headers: headers
            });
        }

        return response;
}

export async function UploadUserFiles(endPoint, formData)
{

    let headers = GetStandardHeaderWithAccessToken();
    headers = { ...headers, "x-api-key": process.env.REACT_APP_ABCD, "Content-Type": "multipart/form-data" };

    try {
        let response = await axios.post(endPoint, formData, {
            headers: headers
        });

        return response;
    } catch (ex) {
        console.log(ex);
        throw ex;
    }
}
export async function DownloadUserFiles(endPoint) {

    let headers = GetStandardHeaderWithAccessToken();
    headers = { ...headers, "x-api-key": process.env.REACT_APP_ABCD };
    headers = { ...headers, "Accept": '*' };

    try {
        let response = await axios.get(endPoint, {
            responseType: 'blob',
            headers: headers
        });

        return response;
    } catch (ex) {
        console.log(ex);
        throw ex;
    }
}
export async function LoadUserFiles(endPoint, formData) {

    let headers = GetStandardHeaderWithAccessToken();
    headers = { ...headers, "x-api-key": process.env.REACT_APP_ABCD };
    headers = { ...headers, "Accept": '*/*' };
    headers = { ...headers, "Content-Type": "multipart/form-data" };

    try {
        let response = await axios.post(endPoint, formData, {
            responseType: 'blob',
            headers: headers
        });

        return response;
    } catch (ex) {
        console.log(ex);
        throw ex;
    }
}

export function AddLoginInfoInSession(responseMsg, email) {
    let newDate = new Date()
    let userInfo = '';

    let userId = parseInt(responseMsg.data.data[0].UserID);
    let sessionId = responseMsg.data.data[0].SessionID;

    //userInfo = jwt_decode(responseMsg.data.data[0].Access_Token);

    let isProspectUser = responseMsg.data.data[0].ProspectUser;

    localStorage.setItem(userId + '_AOBIDENTITYA', responseMsg.data.data[0].Access_Token);
    localStorage.setItem(userId + '_AOBIDENTITYAV', responseMsg.data.data[0].Access_Token_Version);

    localStorage.setItem(userId + '_AOBIDENTITYR', responseMsg.data.data[0].Refresh_Token);
    localStorage.setItem(userId + '_AOBIDENTITYRV', responseMsg.data.data[0].Refresh_Token_Version);

    localStorage.setItem('LoginUserId', responseMsg.data.data[0].UserID);
    localStorage.setItem(userId + '_LoginUserEmail', email);
    localStorage.setItem(userId + '_UserName', responseMsg.data.data[0].UserName);
    localStorage.setItem(userId + '_AccessControl', responseMsg.data.data[0].AccessControl);
    localStorage.setItem(userId + '_SessionID', responseMsg.data.data[0].SessionID);
    localStorage.setItem(userId + '_LoginTime', moment(newDate).format("MM/DD/YYYY HH:mm:ss"));
    localStorage.setItem(userId + '_ProspectUser', isProspectUser);
}

export function GetLoggedInUserId() {
    return localStorage.getItem('LoginUserId');
}
export function GetLoggedInUserEmail(userId) {
    return localStorage.getItem(userId + '_LoginUserEmail');
}
export function GetLoggedInUserName(userId) {
    return localStorage.getItem(userId + '_UserName');
}
export function GetAccessControl(userId) {
    return localStorage.getItem(userId + '_AccessControl');
}
export function IsInRole(ptype, userId) {

    let roles = localStorage.getItem(userId + '_AccessControl');
    const rolesArray = roles.split("|");

    for (let i = 0; i < rolesArray.length; i++) {
        if (parseInt(rolesArray[i]) == parseInt(ptype) || parseInt(rolesArray[i]) == 18)
            return true;
    }

    return false;
}
export function IsLoggedInUserProspectUser(userId) {
    let p = localStorage.getItem(userId + '_ProspectUser');

    if (p === "1")
        return true;
    else
        return false;
}
export const GetAPIErrorAction = async (response, userId) => {

    let ErrorAction = { redirectUrl: null, searchParam: null, Message: '' };

    let errorCode = response.Errors[0].Code;

    if (errorCode == APIResponseStatus.TokenMissing || errorCode == APIResponseStatus.TokenExpired
        || errorCode == APIResponseStatus.InvalidToken ||
        (response.meta != null && response.meta != undefined && response.meta.Logout == '1'))
    {
        await Logout(userId);
        ErrorAction.redirectUrl = '/AppLogin';
        ErrorAction.searchParam = '?reason=session_notfound';
    }
    else if (errorCode == APIResponseStatus.TokenMissing)
    {
        await Logout(userId);
        ErrorAction.redirectUrl = '/AppLogin';
        ErrorAction.searchParam = '?reason=session_invalid';
    }
    else {
        ErrorAction.Message = response.Errors[0].Message;
    }

    return ErrorAction;
}
export const GetAxiosErrorAction = async (ex, userId) => {

    let ErrorAction = { redirectUrl: null, searchParam: null, Message: '' };

    if (ex.response != null && ex.response != undefined &&
        ex.response.headers != null && ex.response.headers != undefined)
    {
        if (ex.response.headers.aobauthstatus != null && ex.response.headers.aobauthstatus != undefined)
        {
            if (parseInt(ex.response.headers.aobauthstatus) == APIResponseStatus.TokenMissing ||
                parseInt(ex.response.headers.aobauthstatus) == APIResponseStatus.TokenExpired ||
                parseInt(ex.response.headers.aobauthstatus) == APIResponseStatus.InvalidToken)
            {
                await Logout(userId);
                ErrorAction.redirectUrl = '/AppLogin';
                ErrorAction.searchParam = '?reason=session_notfound';

                return ErrorAction;
            }
            else if (parseInt(ex.response.headers.aobauthstatus) == APIResponseStatus.SessionMismatch)
            {
                await Logout(userId);
                ErrorAction.redirectUrl = '/AppLogin';
                ErrorAction.searchParam = '?reason=session_invalid';

                return ErrorAction;
            }
        }
        else if (ex.response.status == 404 && ex.response.headers.aobmessage == 'File not found')
        {
            ErrorAction.Message = '';
            return ErrorAction;
        }
    }

    ErrorAction.Message = ErrorMessages.UnknownError;
    return ErrorAction;
}
export function RemoveLoginInfoFromSession(userId) {

    localStorage.setItem(userId + '_AOBIDENTITYA', '');
    localStorage.setItem(userId + '_AOBIDENTITYAV', '');

    localStorage.setItem(userId + '_AOBIDENTITYR', '');
    localStorage.setItem(userId + '_AOBIDENTITYRV', '');

    localStorage.setItem(userId + '_LoginUserEmail', '');
    localStorage.setItem(userId + '_UserName', '');
    localStorage.setItem(userId + '_AccessControl', '');
    localStorage.setItem(userId + '_SessionID', '');
    localStorage.setItem(userId + '_LoginTime', '');
    localStorage.setItem(userId + '_ProspectUser', '');

    let loginUserId = localStorage.getItem('LoginUserId');
    if (loginUserId != null && loginUserId != undefined)
    {
        if (parseInt(loginUserId) == parseInt(userId)) 
        {
            localStorage.setItem('LoginUserId', '');
        }
    }
}