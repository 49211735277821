import React, { useState, useRef, useEffect, } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { useFormik } from 'formik';
import * as yup from 'yup';
import { Typography, Link, Stack, Toolbar, Box, Alert, FormControl, TextField } from '@mui/material';
import Container from '@mui/material/Container';
import { Paper } from '@mui/material';
import OverlaySpinner from './Controls/OverlaySpinner';
import Button from '@mui/material/Button';
import { Footer } from './Footer';
import Grid from '@mui/material/Grid';
//import pwdimg from '../assets/images/mbr-13-816x544.jpg';
//import Image from 'material-ui-image'
import AlertControl from './Controls/AlertControl';
import { ErrorMessages } from '../common/ErrorMessages';
import { PreLoginMenu } from './PreLoginMenu';
//import { decode as base64_decode, encode as base64_encode } from 'base-64';
import { CallServerAPI, IsError, AddLoginInfoInSession } from '../global/APIUtils';
//import { useDispatch } from 'react-redux';
//import { ReactComponent as ReactLogo } from '../assets/images/ssb-horizontal-new.svg';
import { ServerAPICall, AddToSession } from '../global/Api';

const VerifyResetCode = (props) => {

    const location = useLocation();

    const [isLoading, setIsLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [blurEmail, setBlurEmail] = useState('');
    const [counter, setCounter] = useState(120);
    const [runTimer, setRunTimer] = useState(true);
    const [resendTextDisp, setResendTextDisp] = useState('block');
    const [resendButtonDisp, setResendButtonDisp] = useState('none');
    const [disSubmitBtn, setDisSubmitBtn] = useState(false);

    const emailAddress = useRef(location.state.emailAddress);

    let navigate = useNavigate();
    //const dispatch = useDispatch();

    //const id = setInterval(() => {
    //    setResetCounter();
    //}, 1000);

    const resetCodeForm = useFormik({
        initialValues: {
            ResetCode: ''
        },
        validationSchema: yup.object({
            ResetCode: yup.string()
                .max(30, "Verification code - must be a string with a maximum length of 30")
                .required('Verification code - required.'),
        }),
        onSubmit: values => {
            //setEmail(values.ResetCode)
            handleSubmit(values);
        }
    });

    const handleSubmit = async (resetValues) => {

        let loginData = {
            EmailAddress: emailAddress.current,
            ResetPasswordCode: resetValues.ResetCode
        };


        setErrorMsg('');

        try {

            setIsLoading(true);

            const apiUrl = new URL(
                process.env.REACT_APP_VERIFY_RESET_CODE,
                process.env.REACT_APP_BASE_URL,
            );

            //let endpoint = process.env.REACT_APP_BASE_URL + process.env.REACT_APP_VERIFY_RESET_CODE;

            //let response = await CallServerAPI({ url: apiUrl.href, method: 'POST', headers: headers, form: loginData });
            let response = await ServerAPICall({ url: apiUrl.href, method: 'POST', payload: loginData, contentType: 'application/json', responseType: '' });

            if (response != null && response != undefined) {

                let error = IsError(response);

                if (error != 0) {
                    setErrorMsg(response.Errors[0].Message);
                    return;
                }

                if (response["data"][0]["Access_Token"] !== null && typeof (response["data"][0]["Access_Token"]) !== "undefined") {

                    let userId = parseInt(response["data"][0]["UserID"]);
                    let sessionId = response["data"][0]["SessionID"];

                    AddToSession(response, emailAddress.current);
                    navigate('/ChangePasswordOption', { state: { userId: userId, sessionId: sessionId }, replace: true  });
                }
            }
        }
        catch (err) {
            setErrorMsg(ErrorMessages.UnknownError);
        }
        finally {
            setIsLoading(false);
        }
    }

    function setResetCounter() {
        if (runTimer) {
            if (parseInt(counter) == 0) {
                setRunTimer(false);
                setResendTextDisp('none');
                setResendButtonDisp('block');
                setDisSubmitBtn(true);
                return;
            }

            setCounter(counter - 1);
        }
    }

    useEffect(() => {

        if (emailAddress.current) {
            let arr = emailAddress.current.split("@");
            let len = arr[0].length;
            setBlurEmail(arr[0][0] + '*****' + (len > 1 ? arr[0][len - 2] : '') + arr[0][len - 1] + '@' + arr[1]);
        }

    }, []);

    useEffect(() => {
        const id = setInterval(() => {
            setResetCounter();
        }, 1000);

        return () => {
            clearInterval(id);
        };
    });

    const login = (event) => {
        event.preventDefault();
        navigate('/AppLogin', { state: {}, replace: true });
    };

    const ResendCode = async (event) => {

        event.preventDefault();

        let resetPwdModel = {
            EmailAddress: emailAddress.current
        };

        setErrorMsg('');

        try {

            setIsLoading(true);

            const apiUrl = new URL(
                process.env.REACT_APP_RESEND_PASSWORD_RESET_CODE,
                process.env.REACT_APP_BASE_URL,
            );

            let response = await ServerAPICall({ url: apiUrl.href, method: 'POST', payload: resetPwdModel, contentType: 'application/json', responseType: '' });

            if (response != null && response != undefined) {

                let error = IsError(response);

                if (error != 0) {
                    setErrorMsg(response.Errors[0].Message);
                }

                setCounter(120);
                setRunTimer(true);
                setResendTextDisp('block');
                setResendButtonDisp('none');
                setDisSubmitBtn(false);
            }
        }
        catch (err) {
            setErrorMsg(ErrorMessages.UnknownError);
        }
        finally {
            setIsLoading(false);
        }
    }

    return (
        <React.Fragment>
            <Container maxWidth="xl">
                <OverlaySpinner disappear={isLoading} />
                <PreLoginMenu />
                <Paper
                    sx={{
                        position: 'relative',
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        pb: { xs: 7, sm: 10 },
                    }}
                >
                    {/*<Toolbar sx={{ borderBottom: 1, borderColor: 'divider', justifyContent: 'space-between', backgroundColor: '#fff', pt: 0, pb: 0 }}>*/}

                    {/*    <Box sx={{ display: 'flex', alignItems: 'center', ml: 1, mt: 4, mb: 1 }}>*/}
                    {/*        <ReactLogo />*/}
                    {/*    </Box>*/}
                    {/*</Toolbar>*/}
                    <Grid container justifyContent="center" spacing={5} sx={{ pt: 7 }}>
                        <Grid item md={6} sx={{ pr: 2, }} justifyContent="center">
                            <Box sx={{ mx: 'auto', border: 0, borderColor: '#16191f', borderRadius: '6px', pt: 4, pb: 4 }}>
                                <Typography variant="h4" color="text.primary" sx={{ fontWeight: 400, mb: 3 }}>Enter verification code</Typography>
                                <AlertControl Message={errorMsg} severity="error" color="error" icon={true} />
                                <Typography variant="body1" color="text.primary" sx={{ pt: 1, fontWeight: 500 }}>
                                    sent to your email {blurEmail}
                                </Typography>
                                <Box sx={{ pt: 3, pr: 5 }}>
                                    <form onSubmit={resetCodeForm.handleSubmit} id="resetCodeForm" >
                                        <Paper elevation={0}>
                                            <label htmlFor="ResetCode" className="form-label">Verification Code*</label>
                                            <input type="text" id="ResetCode" name="ResetCode" autoComplete="off" placeholder="Enter code here" className="form-control" value={resetCodeForm.values.ResetCode} onChange={resetCodeForm.handleChange} />
                                            {
                                                resetCodeForm.touched.ResetCode && resetCodeForm.errors.ResetCode ? (<p className="text-danger">{resetCodeForm.errors.ResetCode}</p>) : null
                                            }
                                        </Paper>
                                        <Typography variant="body1" color="text.primary" sx={{pt: 1, display: resendTextDisp}}>
                                            Resend code in {counter}
                                        </Typography>
                                        <Box sx={{ pt: 1, display: resendButtonDisp }}>
                                            <Button sx={{ textTransform: 'none' }} onClick={(e) => ResendCode(e)}>
                                                <Typography variant="body1" sx={{color: '#002d72'}}>
                                                    Resend code
                                                </Typography>
                                            </Button>
                                        </Box>
                                        <Box sx={{ mt: 4 }}>
                                            <Button type="submit" variant="outlined" size="small" sx={{ padding: '0.4rem 3rem', border: 2, borderColor: '#616161' }}>
                                                <Typography variant="body2" color="#212121" sx={{ textTransform: 'none', fontWeight: 500 }} >Verify</Typography>
                                            </Button>
                                        </Box>
                                        </form>
                                </Box>
                                <Box sx={{pt: 3}}>
                                    <Button onClick={login} sx={{ textTransform: 'none', color: '#002d72' }}>Back to Login</Button>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item md={2}>
                        </Grid>
                    </Grid>
                </Paper>
                <Footer />
            </Container>
        </React.Fragment>
    );
}
export default VerifyResetCode;