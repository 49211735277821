
import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import { Typography } from '@mui/material';

export function ConfirmDialog(props) {
    const { onClose, params, open, ...other } = props;

    const handleCancel = () => {
        onClose(null, null);
    };

    const handleOk = () => {
        onClose(props.nvalue, props.svalue);
    };

    return (
        <Dialog
            sx={{ '& .MuiDialog-paper': { width: '100%', maxHeight: 435 } }}
            maxWidth="xs"
            open={open}
            {...other}
        >
            <DialogTitle sx={{ml: 0, mr: 2}}>
                {props.alertheader}
            </DialogTitle>
            <DialogContent sx={{ ml: 0, mr: 2, }}>
                <Typography variant="body2" color="text.primary" sx={{}}>{props.bmsgline1}</Typography>
            </DialogContent>
            <DialogActions sx={{ pr: 2, pb: 1 }}>
                <Button autoFocus variant="outlined" onClick={handleCancel} size="small" sx={{ mr: 1, mt: 0, mb: 2, padding: '5px 40px', border: '1px solid rgba(0,0,0,0.34)' }}>
                    <Typography variant="body1" color="text.primary" sx={{ textTransform: 'none' }} >No</Typography>
                </Button>
                <Button variant="outlined" onClick={handleOk} size="small" sx={{ mt: 0, mb: 2, padding: '5px 40px', border: '1px solid rgba(0,0,0,0.34)' }}>
                    <Typography variant="body1" color="text.primary" sx={{ textTransform: 'none' }} >Yes</Typography>
                </Button>
            </DialogActions>
        </Dialog>
    );
}
