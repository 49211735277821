import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { Button, Link, Typography, Box, Stack } from '@mui/material';
import { Paper } from '@mui/material';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import CallIcon from '@mui/icons-material/Call';
import clientlogo from '../assets/images/banklogo.jpg';
import productlogo from '../assets/images/Settlement4.jpg';

export function PreLoginMenu(props)
{
    const sections = [
        { title: 'Find Branch', url: '#' },
        { title: 'Contact Us', url: '#' },
        { title: 'Help', url: '#' },
        { title: 'Chat', url: '#' },
    ];

    let navigate = useNavigate();

    const gotoHomePath = (event) => {
        event.preventDefault();
        navigate.push({ pathname: '/' });
    }

    const Login = (event) => {
        event.preventDefault();
        navigate('/AppLogin', { state: {}, replace: true });
    }

    const FAQ = (event) => {
        event.preventDefault();
        navigate('/FAQ', { state: {}, replace: true });
    }

    const KeyDates = (event) => {
        event.preventDefault();
        navigate('/KeyDates', { state: {}, replace: true });
    }

    return (
        <React.Fragment>
            <Toolbar
                component="nav"
                variant="dense"
                sx={{ pl: 0, ml: 0, pt: 5, justifyContent: 'space-between', overflowX: 'auto', backgroundColor: '#4C4C4C', border: 0, minHeight: '40px'}}
            >
 
                {/*<Typography sx={{ ml: 0 }} variant="body2" color="#fff"> <CallIcon sx={{ color: '#fff', fontSize: '20px'}} />&nbsp;123-456-7890 </Typography>*/}
                <Box>
                    {/*<Box sx={{ ml: 1, pt: 1, pb: 1 }}>*/}
                    {/*    <img src={clientlogo} height="40" loading="lazy" />*/}
                    {/*</Box>*/}
{/*                {sections.map((section) => (*/}
                    {/*<Link color="#fff" underline="hover" noWrap key={0} variant="body1" href="#" sx={{ pl: 4, pr: 0, flexShrink: 0, fontWeight: 400, letterSpacing: '1px' }}>*/}
                    {/*    Find Branch*/}
                    {/*</Link>*/}
                    {/*<Link color="#fff" noWrap key={1} variant="body2" href="#" sx={{ pl: 2, pr: 0, flexShrink: 0 }}>*/}
                    {/*    Contact Us*/}
                    {/*</Link>*/}
                    {/*<Link color="#fff" underline="hover" noWrap key={2} variant="body1" href="#" sx={{ pl: 4, pr: 0, flexShrink: 0, letterSpacing: '1px' }}>*/}
                    {/*    Help*/}
                    {/*</Link>*/}
                    {/*<Link color="#fff" noWrap key={3} variant="body2" href="#" sx={{ pl: 2, pr: 0, flexShrink: 0 }}>*/}
                    {/*    Chat*/}
                    {/*</Link>*/}

{/*                ))}*/}
                    </Box>
            </Toolbar>
            <Toolbar sx={{ borderBottom: 1, borderColor: 'rgba(0,0,0,.3)', justifyContent: 'space-between', backgroundColor: '#fff', pt: 0, pb: 0, mb: 1}}>
                <Box sx={{ ml: 0, pt: 2, pb: 2 }}>
                    <img src={productlogo} height="72" loading="lazy" />
                </Box>
                <Box alignItems="center" sx={{ mr: 0 }}>
                    <Button size="small" variant="outlined" style={{ boxShadow: 'none', border: 0, textTransform: 'none' }} onClick={(e) => FAQ(e)}>
                        <Typography variant="body1" color="text.secondary" sx={{ pl: 1, fontWeight: 400, letterSpacing: '0px' }} >FAQs</Typography>
                    </Button>
                    <Button size="small" variant="outlined" style={{ boxShadow: 'none', border: 0, textTransform: 'none' }} onClick={(e) => KeyDates(e)}>
                        <Typography variant="body1" color="text.secondary" sx={{ fontWeight: 400, letterSpacing: '0px' }} >Key Dates</Typography>
                    </Button>
                    <Button size="small" variant="outlined" style={{ boxShadow: 'none', border: 0, textTransform: 'none' }} onClick={(e) => Login(e)}>
                        <Typography variant="body1" color="text.secondary" sx={{ fontWeight: 400, letterSpacing: '0px' }} >Login</Typography>
                    </Button>
                </Box>
            </Toolbar>

        </React.Fragment>
    );
}
