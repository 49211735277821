import React, { useCallback, useState, useEffect, useMemo, useRef, } from 'react';
import { renderToString } from 'react-dom/server';
import { useNavigate, useLocation } from "react-router-dom";
import PostLoginMenu from './PostLoginMenu'
import OverlaySpinner from './Controls/OverlaySpinner';
import { Footer } from './Footer';
//import { useSelector, useDispatch } from 'react-redux';
import { CallAPI, IsError } from '../global/APIUtils';
import { FormControlLabel, Checkbox, Stack, Paper, FormControl, Grid, InputLabel, Select, TextField, IconButton, Box, Tooltip, Menu, Typography, MenuItem, InputBase, Card, CardContent, bull, CardActions, Button } from '@mui/material';
import { CardMedia, CardHeader } from '@mui/material';
import { ErrorMessages } from '../common/ErrorMessages';
import { goToTop, LoadProfilePic, CanApprove, CanSubmitForEnrollment } from '../global/Helpers';
import { AlertDialog } from '../global/AlertDialog';
import { FormDialog } from '../global/FormDialog';
import { DrawerAppBar } from './AppNavbar'
//import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { deepOrange } from '@mui/material/colors';
import AlertControl from './Controls/AlertControl';
import AlertWithTitle from './Controls/AlertWithTitle';
import Divider from '@mui/material/Divider';
import Container from '@mui/material/Container';
import EnrollmentHeader from './EnrollmentHeader'
import profimg from '../assets/images/profimg.jpg';
import { GetApplicantImage } from '../global/EnrollmentHelper';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
//import pwdimg from '../assets/images/45325.png';
//import Image from 'material-ui-image'
import { useFormik } from 'formik';
import * as yup from 'yup';
import dayjs from 'dayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import { APICall } from '../global/Api';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import { styled } from '@mui/material/styles';

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: 0,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
}));

const User = () => {

    //const dispatch = useDispatch();
    //let sessions = useSelector((state) => state.sessionMgmnt);
    const location = useLocation();

    const [expanded, setExpanded] = useState(true);
    const [errorMsg, setErrorMsg] = useState(null);
    const [successMsg, setSuccessMsg] = useState(null);
    const [successMsgTitle, setSuccessMsgTitle] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState(null);
    const [titleData, setTitleData] = useState(null);
    const [roleData, setRoleData] = useState(null);
    const [imageUrl, setImageUrl] = useState(null);
    const [disActiveUser, setDisActiveUser] = useState(false);
    const [disUserLocked, setDisUserLocked] = useState(false);
    const [disNotActivatedAfterReg, setDisNotActivatedAfterReg] = useState(false);
    const [userId, setUserId] = useState(parseInt(location.state.userid) || -1);
    const [userEmail, setUserEmail] = useState(location.state.userEmail || '');
    const [userStatusId, setUserStatusId] = useState(parseInt(location.state.userStatusId) || 36);
    const [tabValue, setTabValue] = React.useState(0);
    const [disableControl, setdisableControl] = React.useState(false);
    const [disableTab, setDisableTab] = React.useState(true);

    const [loggedInUserId, setLoggedInUserId] = useState(location.state.loggedInUserId || -1);
    const [sessionId, setSessionId] = useState(location.state.sessionId || '');

    let navigate = useNavigate();

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
    const today = dayjs();
    const cntCtId = useRef(null);
    const pwdRegExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/;

    const tabStyles = makeStyles({
        indicator: {
            border: "1px solid #16191f"
        },
    });

    const tabClasses = tabStyles();


    const userForm = useFormik({
        enableReinitialize: true,

        initialValues: {
            Password: data ? "Aa1*******" : "",
            RenteredPassword: data ? "Aa1*******" : "",
            FirstName: data ? data["FirstName"] : "",
            LastName: data ? data["LastName"] : "",
            email: data ? data["EmailAddress"] : "",
            Phone: data ? data["PhoneNbr"] : "",
            Mobile: data ? data["MobileNbr"] : "",
            Title: data ? data["Title"] : "",
            Gender: data ? data["Gender"] : "",
            userrole: data ? data["RoleTypeId"] : "",
            chkActiveUser: data ? data["ActiveInd"] : true,
            chkUserLocked: data ? data["UserStatus"] === 37 ? true : false : false,
            //IsActive: false,
            UserStatus: data ? data["UserStatus"] : 36,
            DOB: data ? data["DOB"] && data["DOB"] != "" ? data["DOB"] : '' : ''
        },

        validationSchema: yup.object({
            Password: yup.string()
                .max(20, "Password - must be a string with a minimum length of '10' and a maximum length of '20'.")
                .min(10, "Password - must be a string with a minimum length of '10' and a maximum length of '20'.")
                .matches(pwdRegExp,
                    "Password - must be contain '10' characters, one uppercase, one lowercase, one number and one special case character."
                )
                .required('Password - required.'),
            RenteredPassword: yup.string()
                .max(20, "Confirm Password - must be a string with a minimum length of '10' and a maximum length of '20'.")
                .min(10, "Confirm Password - must be a string with a minimum length of '10' and a maximum length of '20'.")
                .matches(pwdRegExp,
                    "Confirm Password - must be contain '10' characters, one uppercase, one lowercase, one number and one special case character."
                )
                //.when("Password", {
                //    is: psswrd => (psswrd && psswrd.length > 0 ? true : false),
                //    then: yup.string().oneOf(
                //        [yup.ref("Password")],
                //        "Both password need to be the same.")
                //})
                .required('Confirm Password - required.'),

            FirstName: yup.string()
                .max(40, "First Name - must be a string with a maximum length of '40'.")
                .trim('First Name - cannot contain leading and trailing spaces')
                .required('First Name - required.'),
            LastName: yup.string()
                .max(40, "Last Name - must be a string with a maximum length of '40'.")
                .trim('Last Name - cannot contain leading and trailing spaces')
                .required('Last Name - required.'),
            email: yup.string()
                .max(80, "Email Address - must be a string with a maximum length of 80")
                .email('Email Address - Invalid email address')
                .required('Email Address - required'),
            Mobile: yup.string()
                .required('Mobile Number - required.'),
            userrole: yup.number()
                .required("Role - required.")
                .min(1, "Role - required."),
            Title: yup.number(),
            Gender: yup.number(),
            DOB: yup
                .date()
                .nullable()
                //.required("Date Of Birth - required")
        //        .max(today, "Date Of Birth that you entered is invalid")
        //        .min("1900-01-01", "Date is too early"),
        }),
        onSubmit: async values => {
            submitClick(values);
        },
    });

    const submitClick = async (values) => {

        setErrorMsg(null);
        setSuccessMsgTitle(null);
        setSuccessMsg(null);

        var birthDate = values.DOB !== '' ? moment(values.DOB, 'YYYY-MM-DD') : null;

        let inputJson = {
            UserId: parseInt(userId),
            Title: parseInt(values.Title),
            FirstName: values.FirstName,
            LastName: values.LastName,
            DOB: birthDate !== null ? birthDate.format('MM/DD/YYYY') : null,
            GenderId: parseInt(values.Gender),
            EmailAddress: values.email,
            PhoneNbr: values.Phone,
            MobileNbr: values.Mobile,
            RoleTypeId: parseInt(values.userrole),
            Password: values.Password,
            CPassword: values.RenteredPassword
        };

        let endPoint;
        if (userId != -1)
            endPoint = process.env.REACT_APP_MODIFY_USER;
        else
            endPoint = process.env.REACT_APP_CREATE_USER_PROFILE;

        const apiUrl = new URL(
            endPoint,
            process.env.REACT_APP_BASE_URL,
        );

        try {
            setIsLoading(true);

            let response = await APICall({ url: apiUrl.href, method: 'POST', payload: inputJson, contentType: 'application/json', responseType: '' }, loggedInUserId, sessionId);

            if (response !== null && response !== undefined) {

                setIsLoading(false);

                let error = IsError(response);

                if (error != 0) {
                    setErrorMsg(response.Errors[0].Message);
                    goToTop();
                    return;
                }

                if (response.meta.UserUpdated == "Yes") {

                    setUserId(parseInt(response.meta.NewContactId));
                    setUserEmail(values.email);
                    setUserStatusId(36);

                    setdisableControl(true);
                    setDisableTab(false);

                    setSuccessMsgTitle("User profile saved");
                    setSuccessMsg('User profile information saved successfuly.');
                }
                else
                    setErrorMsg(ErrorMessages.UnknownError);
            }
        }
        catch (err) {
            setErrorMsg(ErrorMessages.UnknownError);
            setIsLoading(false);
        }
        finally {
            
        }

        goToTop();
    }

    const userPwdForm = useFormik({
        enableReinitialize: true,

        initialValues: {
            NewPassword: "",
            ConfirmPassword: ""
        },
        validationSchema: yup.object({
            NewPassword: yup.string()
                .max(20, "New Password - must be a string with a minimum length of '10' and a maximum length of '20'.")
                .min(10, "New Password - must be a string with a minimum length of '10' and a maximum length of '20'.")
                .matches(pwdRegExp,
                    "New Password - must be contain '10' characters, one uppercase, one lowercase, one number and one special case character."
                )
                .required('Password - required.'),
            ConfirmPassword: yup.string()
                .max(20, "Confirm Password - must be a string with a minimum length of '10' and a maximum length of '20'.")
                .min(10, "Confirm Password - must be a string with a minimum length of '10' and a maximum length of '20'.")
                .matches(pwdRegExp,
                    "Confirm Password - must be contain '10' characters, one uppercase, one lowercase, one number and one special case character."
                )
                .when("NewPassword", {
                    is: psswrd => (psswrd && psswrd.length > 0 ? true : false),
                    then: yup.string().oneOf(
                        [yup.ref("NewPassword")],
                        "Both password need to be the same.")
                })
                .required('Confirm Password - required.')
        }),
        onSubmit: async values => {
            userPwdFormSubmitClick(values);
        },
    });

    const userPwdFormSubmitClick = async (values) => {

        setErrorMsg(null);
        setSuccessMsgTitle(null);
        setSuccessMsg(null);

        let inputJson = {
            EmailAddress: userEmail,
            NPassword: values.NewPassword,
            CPassword: values.ConfirmPassword,
            UserId: parseInt(userId)
        };

        const apiUrl = new URL(
            process.env.REACT_APP_CREATE_PASSWORD,
            process.env.REACT_APP_BASE_URL,
        );

        try {
            setIsLoading(true);

            let response = await APICall({ url: apiUrl.href, method: 'POST', payload: inputJson, contentType: 'application/json', responseType: '' }, loggedInUserId, sessionId);

            if (response !== null && response !== undefined) {

                setIsLoading(false);

                let error = IsError(response);

                if (error != 0) {
                    setErrorMsg(response.Errors[0].Message);
                    return;
                }
                if (response.meta.Success == "Yes") {

                    setdisableControl(true);
                    setDisableTab(false);

                    await loadFormData();
                    setSuccessMsgTitle("Password updated");
                    setSuccessMsg('Password has been reset successfully.');
                }
                else
                    setErrorMsg(ErrorMessages.UnknownError);
            }
        }
        catch (err) {
            setErrorMsg(ErrorMessages.UnknownError);
            setIsLoading(false);
        }
        finally {
            //setIsLoading(false);
        }
    };

    const userOtherParamForm = useFormik({
        enableReinitialize: true,

        initialValues: {
            chkActiveUser: data ? data["ActiveInd"] : true,
            chkUserLocked: data ? data["UserStatus"] === 37 ? true : false : false,
            chkActivationPending: data ? data["UserStatus"] === 72 ? true : false : false,
        },
        onSubmit: async values => {
            UserOtherParamFormSubmitClick(values);
        },
    });

    const UserOtherParamFormSubmitClick = async (values) => {

        setErrorMsg(null);
        setSuccessMsgTitle(null);
        setSuccessMsg(null);

        let userStatus = userStatusId;

        if (!values.chkUserLocked) {
            if (userStatusId === 37)
                userStatus = 36;
        }

        if (!values.chkActivationPending) {
            if (userStatusId === 72)
                userStatus = 36;
        }

        if (values.chkUserLocked) {
            userStatus = 37;
        }

        let inputJson = {
            ActiveInd: values.chkActiveUser,
            UserStatus: userStatus,
            UserId: parseInt(userId)
        };

        const apiUrl = new URL(
            process.env.REACT_APP_SAVE_USER_OTHER_INFO,
            process.env.REACT_APP_BASE_URL,
        );

        try {
            setIsLoading(true);

            let response = await APICall({ url: apiUrl.href, method: 'POST', payload: inputJson, contentType: 'application/json', responseType: '' }, loggedInUserId, sessionId);

            if (response !== null && response !== undefined) {

                setIsLoading(false);

                let error = IsError(response);

                if (error != 0) {
                    setErrorMsg(response.Errors[0].Message);
                    return;
                }
                if (response.meta.UserUpdated == "Yes") {
                    if (userStatus != 72) {
                        setDisUserLocked(false);
                        setDisNotActivatedAfterReg(true);
                    }

                    setSuccessMsgTitle("User updated");
                    setSuccessMsg('User information updated successfully.');
                }
                else
                    setErrorMsg(ErrorMessages.UnknownError);
            }
        }
        catch (err) {
            setErrorMsg(ErrorMessages.UnknownError);
            setIsLoading(false);
        }
        finally {
        }
    };

    const loadFormData = async () => {

        //let apiUrl = process.env.REACT_APP_BASE_URL + process.env.REACT_APP_GET_USER_DETAIL + (cntCtId.current);
        
        try {

            setIsLoading(true);

            let apiUrl;
            let response;

            apiUrl = new URL(
                process.env.REACT_APP_USER_TITLES,
                process.env.REACT_APP_BASE_URL,
            );

            response = await APICall({ url: apiUrl.href, method: 'GET', contentType: 'application/json', responseType: '' }, loggedInUserId, sessionId);

            if (response !== undefined && response !== null) {
                setTitleData(response["data"]);
            }


            apiUrl = new URL(
                process.env.REACT_APP_GET_ALL_USERROLES,
                process.env.REACT_APP_BASE_URL,
            );

            response = await APICall({ url: apiUrl.href, method: 'GET', contentType: 'application/json', responseType: '' }, loggedInUserId, sessionId);

            if (response !== undefined && response !== null) {
                setRoleData(response["data"]);
            }

            if (userId != -1) {

                setdisableControl(true);
                setDisableTab(false);

                apiUrl = new URL(
                    process.env.REACT_APP_GET_USER_DETAIL,
                    process.env.REACT_APP_BASE_URL,
                );

                apiUrl.searchParams.set('CntCtId', `${parseInt(userId)}`);

                response = await APICall({ url: apiUrl.href, method: 'GET', contentType: 'application/json', responseType: '' }, loggedInUserId, sessionId);

                if (response !== undefined && response !== null) {

                    setIsLoading(false);

                    setData(response["data"][0]);
                    if (parseInt(response["data"][0].UserStatus) === 72)
                        setDisUserLocked(true);

                    if (parseInt(response["data"][0].UserStatus) !== 72) {
                        setDisNotActivatedAfterReg(true);
                    }
                }
            }
            else {
                setIsLoading(false);
            }

            //let imageURL = await LoadProfilePic(cntCtId.current, sessions, dispatch);
            //setImageUrl(imageURL);
        }
        catch (err) {
            setErrorMsg(ErrorMessages.UnknownError);
        }
        finally {
            
        }
    };

    useEffect(() => {
        loadFormData();
    }, []);

    const handleAccordianChange = () => {
        if (expanded) {
            setExpanded(false);
        }
        else {
            setExpanded(true);
        }
    }

    const handleBackClick = () => {
        navigate('/UserManagement', { state: { userId: loggedInUserId, sessionId: sessionId }, replace: true });
    }

    return (
        <React.Fragment>
            <Container maxWidth="xl" sx={{}}>
                <OverlaySpinner disappear={isLoading} />
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <PostLoginMenu userId={loggedInUserId} sessionId={sessionId} />
                    <Paper className=""
                        sx={{
                            position: 'relative',
                            backgroundColor: '#fff',
                            backgroundSize: 'cover',
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'center',
                            pb: 12
                        }}
                    >
                        <Grid item>
                            <Box sx={{
                                pr: { xs: 3, md: 2 },
                                pl: { xs: 3, md: 2 }
                            }}>
                                <Accordion spacing={0} elevation={0} expanded={expanded} onChange={handleAccordianChange} sx={{ p: 0, m: 0, width: '100%' }}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header" sx={{ mr: 2 }}>
                                    </AccordionSummary>
                                    <AccordionDetails sx={{ pr: 2, pl: 0, pb: 0 }}>
                                        {/*<EnrollmentHeader />*/}
                                        <DrawerAppBar userId={loggedInUserId} sessionId={sessionId} />
                                    </AccordionDetails>
                                </Accordion>
                            </Box>
                        </Grid>
                        <Grid item sm={12}>
                            <Box sx={{ ml: 6, mr: 6, border: '0px solid #000', pt: 1 }}>
                                <Typography variant="h3" color="text.primary" sx={{ borderBottom: '1px solid rgba(0,0,0,0.1)', lineHeight: 2.2, fontWeight: 500, letterSpacing: '-1px', textAlign: 'center' }} >Manage Participant</Typography>
                            </Box>
                        </Grid>
                        <Grid item sm={10} sx={{ pl: 14, pr: 14, pt: 5 }} justifyContent="center" >
                            <Stack direction="row" alignItems="center">
                                <Tooltip arrow title="Back" onClick={() => handleBackClick()}>
                                    <span>
                                        <IconButton>
                                            <KeyboardBackspaceIcon sx={{ fontSize: 36 }} color="#9e9e9e" />
                                        </IconButton>
                                    </span>
                                </Tooltip>
                                <Typography variant="h5" color="text.secondary" sx={{ ml: 1, mb: 0, fontWeight: 400 }}>
                                    {userId === -1 ? 'User details - New User' : 'User details - Edit User'}
                                </Typography>
                            </Stack>
                        </Grid>
                        <Grid item sm={12}>
                            <Grid item xs container direction="row" spacing={4} justifyContent="center" sx={{ pl: 20, pr: 14, pt: 1 }}>
                                <Grid item md={8}>
                                    <Box sx={{ width: '100%', pb: 0  }}>
                                        <AlertControl Message={errorMsg} severity="error" color="error" icon={true} />
                                        <AlertWithTitle Message={successMsg} variant="body1" severity="success" color="success" icon={true} Title={successMsgTitle} />
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item sm={12}>
                            <Grid item xs container direction="row" spacing={0} sx={{ pt: 0, pl: 20, pr: 14}}>
                                <Grid item md={10}>
                                    <Box sx={{ borderTop: '0px solid #eaeded', }}>
                                        <Box sx={{ borderBottom: 0, borderColor: 'rgba(0, 0, 0, 0.19)' }}>
                                            <Tabs value={tabValue} classes={{ indicator: tabClasses.indicator }} onChange={handleTabChange} aria-label="basic tabs example" sx={{ height: '60px', background: '#fafafa', borderBottom: '1px solid #eaeded'}}>
                                                <Tab label="User Profile" sx={{ height: '60px', textTransform: 'none'}} />
                                                <Tab label="Password" sx={{ textTransform: 'none' }}  disabled={disableTab} />
                                                <Tab label="Others" sx={{ textTransform: 'none' }}  disabled={disableTab} />
                                            </Tabs>
                                        </Box>
                                        {tabValue === 0 && (
                                            <Grid item container sm={12} direction="row" sx={{ pt: 4, pb: 7, pl: 6, pr: 6 }}>
                                                <Box sx={{ width: '100%', pb: 3, pt: 2 }}>
                                                    <Typography sx={{fontWeight: 500}} variant="h6" color="text.secondary">
                                                        Profile Information
                                                    </Typography>
                                                </Box>
                                                <form onSubmit={userForm.handleSubmit} id="frmUser">
                                                    <Box>
                                                        <Grid item container direction="row" spacing={2} sx={{}}>
                                                            <Grid item sm={8} sx={{}}>
                                                                <Paper elevation={0}>
                                                                    <label htmlFor="Title" className="form-label">Title</label>
                                                                    <select id="Title" className="form-select" name="Title" value={userForm.values.Title} onChange={userForm.handleChange} placeholder="Title">
                                                                        <option key={-1} value={-1}>Select</option>
                                                                        {
                                                                            titleData && titleData.map((e) => (
                                                                                <option key={e.TitleId} value={e.TitleId}>{e.TitleName}</option>
                                                                            ))
                                                                        }
                                                                    </select>
                                                                    {
                                                                        userForm.touched.Title && userForm.errors.Title ? (<p className="text-danger">{userForm.errors.Title}</p>) : null
                                                                    }
                                                                </Paper>
                                                            </Grid>
                                                            <Grid item container xs={12} direction="row" spacing={0} sx={{ marginTop: '6px'}}>
                                                                <Grid item sm={6} sx={{ pr: 1 }} >
                                                                    <Paper elevation={0}>
                                                                        <label htmlFor="FirstName" className="form-label">First Name*</label>
                                                                        <input type="text" id="FirstName" name="FirstName" placeholder="Enter first name here" className="form-control" value={userForm.values.FirstName} onChange={userForm.handleChange} />
                                                                        {
                                                                            userForm.touched.FirstName && userForm.errors.FirstName ? (<p className="text-danger">{userForm.errors.FirstName}</p>) : null
                                                                        }
                                                                    </Paper>
                                                                </Grid>
                                                                <Grid item sm={6} sx={{ pl: 1 }} >
                                                                    <Paper elevation={0}>
                                                                        <label htmlFor="LastName" className="form-label">Last Name*</label>
                                                                        <input type="text" id="LastName" name="LastName" placeholder="Enter last name here" className="form-control" value={userForm.values.LastName} onChange={userForm.handleChange} />
                                                                        {
                                                                            userForm.touched.LastName && userForm.errors.LastName ? (<p className="text-danger">{userForm.errors.LastName}</p>) : null
                                                                        }
                                                                    </Paper>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid item container xs={12} direction="row" spacing={0} sx={{marginTop: '10px'}}>
                                                                <Grid item sm={6} sx={{ pr: 1 }} >
                                                                    <Paper elevation={0}>
                                                                        <label htmlFor="Gender" className="form-label">Gender</label>
                                                                        <select className="form-select" id="Gender" name="Gender" value={userForm.values.Gender} placeholder={'Gender *'} onChange={userForm.handleChange} >
                                                                            <option key={-1} value={-1}>Select</option>
                                                                            <option value="70">Male</option>
                                                                            <option value="71">Female</option>
                                                                            <option value="74">Other</option>
                                                                        </select>
                                                                        {
                                                                            userForm.touched.Gender && userForm.errors.Gender ? (<p className="text-danger">{userForm.errors.Gender}</p>) : null
                                                                        }
                                                                    </Paper>
                                                                </Grid>
                                                                <Grid item sm={6} sx={{ pl: 1 }} >

                                                                    <Paper elevation={0}>
                                                                        <label htmlFor="DOB" className="form-label">Date Of Birth*</label>
                                                                        <input type="date" className="form-control" id="DOB" name="DOB" value={userForm.values.DOB} onChange={userForm.handleChange} />
                                                                        {
                                                                            userForm.touched.DOB && userForm.errors.DOB ? (<p className="text-danger">{userForm.errors.DOB}</p>) : null
                                                                        }
                                                                    </Paper>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid item sm={8} sx={{}} >
                                                                <Paper elevation={0}>
                                                                    <label htmlFor="email" className="form-label">Email Address*</label>
                                                                    <input type="email" id="email" disabled={disableControl} name="email" placeholder="Enter email here" className="form-control" value={userForm.values.email} onChange={userForm.handleChange} />
                                                                    {
                                                                        userForm.touched.email && userForm.errors.email ? (<p className="text-danger">{userForm.errors.email}</p>) : null
                                                                    }
                                                                </Paper>
                                                            </Grid>
                                                            <Grid item container xs={12} direction="row" spacing={0} sx={{ }}>
                                                                <Grid item sm={6} sx={{ pr: 1 }} >
                                                                    <Paper elevation={0}>
                                                                        <label htmlFor="Mobile" className="form-label">Mobile Number*</label>
                                                                        <input type="text" name="Mobile" id="Mobile" placeholder="Enter mobile number here" className="form-control" value={userForm.values.Mobile} onChange={userForm.handleChange} />
                                                                        {
                                                                            userForm.touched.Mobile && userForm.errors.Mobile ? (<p className="text-danger">{userForm.errors.Mobile}</p>) : null
                                                                        }
                                                                    </Paper>
                                                                </Grid>
                                                                <Grid item sm={6} sx={{ pl: 1 }} >
                                                                    <Paper elevation={0}>
                                                                        <label htmlFor="Phone" className="form-label">Phone Number</label>
                                                                        <input type="tel" id="Phone" name="Phone" placeholder="Enter phone number here" className="form-control" value={userForm.values.Phone} onChange={userForm.handleChange} />
                                                                        {
                                                                            userForm.touched.Phone && userForm.errors.Phone ? (<p className="text-danger">{userForm.errors.Phone}</p>) : null
                                                                        }
                                                                    </Paper>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid item container xs={12} direction="row" spacing={0} sx={{}}>
                                                                <Grid item sm={8} sx={{ pr: 1 }} >

                                                                    <Paper elevation={0}>
                                                                        <label htmlFor="userrole" className="form-label">Role*</label>
                                                                        <select id="userrole" className="form-select" name="userrole" value={userForm.values.userrole} onChange={userForm.handleChange} placeholder="Title">
                                                                            <option key={-1} value={-1}>Select</option>
                                                                            {
                                                                                roleData && roleData.map((e) => (
                                                                                    <option key={e.RoleTypeId} value={e.RoleTypeId}>{e.RoleName}</option>
                                                                                ))
                                                                            }
                                                                        </select>
                                                                        {
                                                                            userForm.touched.userrole && userForm.errors.userrole ? (<p className="text-danger">{userForm.errors.userrole}</p>) : null
                                                                        }
                                                                    </Paper>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid item container xs={12} direction="row" spacing={0} sx={{}}>
                                                                <Grid item sm={8} sx={{ pr: 1 }} >
                                                                    <Paper elevation={0}>
                                                                        <label htmlFor="Password" className="form-label">Password*</label>
                                                                        <input type="password" autoComplete="off" disabled={disableControl} id="Password" name="Password" placeholder="Enter password" className="form-control" value={userForm.values.Password} onChange={userForm.handleChange} />
                                                                        {
                                                                            userForm.touched.Password && userForm.errors.Password ? (<p className="text-danger">{userForm.errors.Password}</p>) : null
                                                                        }
                                                                    </Paper>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid item container xs={12} direction="row" spacing={0} sx={{}}>
                                                                <Grid item sm={8} sx={{ pr: 1 }} >
                                                                    <Paper elevation={0}>
                                                                        <label htmlFor="RenteredPassword" className="form-label">Confirm Password*</label>
                                                                        <input type="password" autoComplete="off" disabled={disableControl} id="RenteredPassword" name="RenteredPassword" placeholder="Re-enter password" className="form-control" value={userForm.values.RenteredPassword} onChange={userForm.handleChange} />
                                                                        {
                                                                            userForm.touched.RenteredPassword && userForm.errors.RenteredPassword ? (<p className="text-danger">{userForm.errors.RenteredPassword}</p>) : null
                                                                        }
                                                                    </Paper>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid item sm={12} sx={{}} >
                                                                <Box sx={{ pt: 2 }}>
                                                                    <Button type="submit" variant="outlined" size="small" sx={{ padding: '0.4rem 3rem', border: 2, borderColor: '#616161' }}>
                                                                        <Typography variant="body2" color="#212121" sx={{ textTransform: 'none', fontWeight: 500 }} >Save</Typography>
                                                                    </Button>
                                                                </Box>
                                                            </Grid>
                                                        </Grid>
                                                    </Box>
                                                </form>
                                            </Grid>
                                        )}
                                        {tabValue === 1 && (
                                            <Grid item container sm={12} direction="column" sx={{ pt: 5, pb: 5, pl: 6, pr: 6 }}>
                                                <form onSubmit={userPwdForm.handleSubmit} id="userPwdForm">
                                                    <Box sx={{ minHeight: '300px' }}>
                                                        <Box sx={{ width: '100%', pb: 1 }}>
                                                            <Typography sx={{ fontWeight: 500 }} variant="h6" color="text.secondary">
                                                                Reset Password
                                                            </Typography>
                                                        </Box>
                                                        <Grid item sm={12} container direction="row" spacing={2} sx={{}}>
                                                            <Grid item sm={8}>
                                                                <Paper elevation={0} sx={{pt: 2}}>
                                                                    <label htmlFor="NewPassword" className="form-label">New Password*</label>
                                                                    <input type="password" id="NewPassword" autoComplete="off" name="NewPassword" placeholder="Enter password" className="form-control" value={userPwdForm.values.NewPassword} onChange={userPwdForm.handleChange} />
                                                                    {
                                                                        userPwdForm.touched.NewPassword && userPwdForm.errors.NewPassword ? (<p className="text-danger">{userPwdForm.errors.NewPassword}</p>) : null
                                                                    }
                                                                </Paper>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid item container direction="row" spacing={0} sx={{}}>
                                                            <Grid item sm={8} sx={{}} >
                                                                <Paper elevation={0} sx={{pt: 2}}>
                                                                    <label htmlFor="ConfirmPassword" className="form-label">Confirm Password*</label>
                                                                    <input type="password" id="ConfirmPassword" autoComplete="off" name="ConfirmPassword" placeholder="Enter password" className="form-control" value={userPwdForm.values.ConfirmPassword} onChange={userPwdForm.handleChange} />
                                                                    {
                                                                        userPwdForm.touched.ConfirmPassword && userPwdForm.errors.ConfirmPassword ? (<p className="text-danger">{userPwdForm.errors.ConfirmPassword}</p>) : null
                                                                    }
                                                                </Paper>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid item sm={12} sx={{pt: 4}} >
                                                            <Box sx={{ pt: 0 }}>
                                                                <Button type="submit" variant="outlined" size="small" sx={{ padding: '0.4rem 2.5rem', border: 2, borderColor: '#616161' }}>
                                                                    <Typography variant="body2" color="#212121" sx={{ textTransform: 'none', fontWeight: 500 }} >Save</Typography>
                                                                </Button>
                                                            </Box>
                                                        </Grid>
                                                    </Box>
                                                </form>
                                            </Grid>
                                        )}
                                        {tabValue === 2 && (
                                            <Grid item container direction="row" sx={{ pt: 5, pb: 5, pl: 6, pr: 6 }}>
                                                <form onSubmit={userOtherParamForm.handleSubmit} id="frmUser">
                                                    <Box sx={{ minHeight: '300px' }}>
                                                        <Grid item container direction="row" spacing={2} sx={{}}>
                                                            <Grid item sm={12} sx={{}} >
                                                                <Stack direction="column">
                                                                    <FormControl variant="outlined" sx={{ width: '100%' }} disabled={disActiveUser}>
                                                                        <FormControlLabel color="text.secondary" control={<Checkbox name="chkActiveUser" id="chkActiveUser" checked={userOtherParamForm.values.chkActiveUser} onChange={userOtherParamForm.handleChange} />} label="Active user" />
                                                                    </FormControl>
                                                                    {
                                                                        userOtherParamForm.touched.chkActiveUser && userOtherParamForm.errors.chkActiveUser ? (<p className="text-danger">{userOtherParamForm.errors.chkActiveUser}</p>) : null
                                                                    }
                                                                    <FormControl variant="outlined" sx={{ width: '100%' }} disabled={disUserLocked}>
                                                                        <FormControlLabel color="text.secondary" control={<Checkbox name="chkUserLocked" id="chkUserLocked" checked={userOtherParamForm.values.chkUserLocked} onChange={userOtherParamForm.handleChange} />} label="User locked" />
                                                                    </FormControl>
                                                                    {
                                                                        userOtherParamForm.touched.chkUserLocked && userOtherParamForm.errors.chkUserLocked ? (<p className="text-danger">{userOtherParamForm.errors.chkUserLocked}</p>) : null
                                                                    }
                                                                    <FormControl variant="outlined" sx={{ width: '100%' }} disabled={disNotActivatedAfterReg}>
                                                                        <FormControlLabel color="text.secondary" control={<Checkbox name="chkActivationPending" id="chkActivationPending" checked={userOtherParamForm.values.chkActivationPending} onChange={userOtherParamForm.handleChange} />} label="Pending Email activation after registration" />
                                                                    </FormControl>
                                                                    {
                                                                        userOtherParamForm.touched.chkActivationPending && userOtherParamForm.errors.chkActivationPending ? (<p className="text-danger">{userOtherParamForm.errors.chkActivationPending}</p>) : null
                                                                    }
                                                                </Stack>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid item sm={12} sx={{}} >
                                                            <Box sx={{ pt: 3 }}>
                                                                <Button type="submit" variant="outlined" size="small" sx={{ padding: '0.4rem 2.5rem', border: 2, borderColor: '#616161' }}>
                                                                    <Typography variant="body2" color="#212121" sx={{ textTransform: 'none', fontWeight: 500 }} >Save</Typography>
                                                                </Button>
                                                            </Box>
                                                        </Grid>
                                                    </Box>
                                                </form>
                                            </Grid>
                                        )}
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={2} sx={{}}>
                                    <Box sx={{ pl: 3 }}>
                                        <Typography variant="body1" color="text.secondary" sx={{ mt: 2, fontWeight: 500 }}>
                                            {data ? 'Last login' : null}
                                        </Typography>
                                        <Typography variant="caption" color="text.secondary" sx={{ mt: 0, }}>
                                            {
                                                data ? data.LastLogin : null
                                            }
                                        </Typography>
                                        <Typography variant="body1" color="text.secondary" sx={{ mt: 2, fontWeight: 500}}>
                                            {data ? 'Last password changed' : null}
                                        </Typography>
                                        <Typography variant="caption" color="text.secondary" sx={{ mt: 0,}}>
                                            {
                                                data ? data.LastPasswordChanged : null
                                            }
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item md={1}>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                    <Footer />
                </LocalizationProvider>
            </Container>
        </React.Fragment>
    );
};

function UserBreadcrumb() {
    return (
        <React.Fragment >
            <Box sx={{ borderLeft: '4px solid #9e9e9e', mt: 0, pt: 0, pb: 2, pl: 1, backgroundColor: '#fff', borderTop: '0px solid #dee2e6' }}>
                <Typography variant="h5" sx={{ fontWeight: 400, lineHeight: 1.1, pl: 2, textTransform: 'none' }} color="text.primary">System User Details</Typography>
            </Box>
            {/*<Box sx={{ mt: 1, pt: 3, pb: 3, pl: 1, backgroundColor: '#f7f7fa' }}>*/}
            {/*    <Typography variant="h5" sx={{ fontWeight: 400, lineHeight: 1.1, pl: 1, textTransform: 'uppercase' }} color="#616161">SYSTEM USER DETAILS</Typography>*/}
            {/*</Box>*/}
        </React.Fragment>
    );
}
export default User;