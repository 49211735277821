import React, { useEffect, useState } from 'react';
import { CallAPI, UploadUserFiles, CallAPIUsingAxios } from '../global/APIUtils';
import { DownloadFileHelper, GetFileSizeStr } from '../global/Helpers';
import { Typography, Stack } from '@mui/material';
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
import { APICall } from '../global/Api';

export async function GetAttachedFile(endpoint, filename, userId, sessionId) {

    let response = await APICall({ url: endpoint, method: 'GET', responseType: 'blob' }, userId, sessionId);

    if (response) {
        let contentType = response.type;
        await DownloadFileHelper(response, filename, contentType);

        return true;
    }

    return false;
}

export function CreateFileLinks(cntrlNm, fileNm, filesize, DownloadAttachment, DeleteAttachment) {

    var dot = fileNm.lastIndexOf(".");
    var extension = dot >= 0 ? fileNm.substr(dot, fileNm.length) : '';

    let iconFile = '<svg x="0px" y="0px" class="me-1" width="24" height="24" viewBox="0 0 102.55 122.88" style="enable-background:new 0 0 102.55 122.88"><g><path class="st0" d="M102.55,122.88H0V0h77.66l24.89,26.44L102.55,122.88L102.55,122.88z M29.82,45.51c4.33,0,7.84,3.5,7.84,7.83 c0,4.32-3.51,7.83-7.84,7.83c-4.32,0-7.83-3.51-7.83-7.83C21.99,49.01,25.5,45.51,29.82,45.51L29.82,45.51z M62.71,75.27 l14.02-24.24l2.07-3.57l1.52,3.84l9.45,44.84h-75.3v-9.12l2.87-0.1l5.26-0.27l6.06-14.81l3.77,0.2l2.84,9.98l6.92,0l8-20.59 l1.43-3.69l2.12,3.33L62.71,75.27L62.71,75.27L62.71,75.27z M96.13,115.98V32.36H73.45V5.91H6.51v110.07H96.13L96.13,115.98 L96.13,115.98z"/></g></svg>';
    if (extension === '.pdf')
        iconFile = '<svg x="0px" y="0px" class="me-1" viewBox="0 0 102.55 122.88" width="24" height="24"><g><path class="st0" d="M102.55,122.88H0V0h77.66l24.89,26.43V122.88L102.55,122.88z M87.01,69.83c-1.48-1.46-4.75-2.22-9.74-2.29 c-3.37-0.03-7.43,0.27-11.7,0.86c-1.91-1.1-3.88-2.31-5.43-3.75c-4.16-3.89-7.64-9.28-9.8-15.22c0.14-0.56,0.26-1.04,0.37-1.54 c0,0,2.35-13.32,1.73-17.82c-0.08-0.61-0.14-0.8-0.3-1.27l-0.2-0.53c-0.64-1.47-1.89-3.03-3.85-2.94l-1.18-0.03 c-2.19,0-3.97,1.12-4.43,2.79c-1.42,5.24,0.05,13.08,2.7,23.24l-0.68,1.65c-1.9,4.64-4.29,9.32-6.39,13.44l-0.28,0.53 c-2.22,4.34-4.23,8.01-6.05,11.13l-1.88,1c-0.14,0.07-3.36,1.78-4.12,2.24c-6.41,3.83-10.66,8.17-11.37,11.62 c-0.22,1.1-0.05,2.51,1.08,3.16L17.32,97c0.79,0.4,1.62,0.6,2.47,0.6c4.56,0,9.87-5.69,17.18-18.44 c8.44-2.74,18.04-5.03,26.45-6.29c6.42,3.61,14.3,6.12,19.28,6.12c0.89,0,1.65-0.08,2.27-0.25c0.95-0.26,1.76-0.8,2.25-1.54 c0.96-1.46,1.16-3.46,0.9-5.51c-0.08-0.61-0.56-1.36-1.09-1.88L87.01,69.83L87.01,69.83z M18.79,94.13 c0.83-2.28,4.13-6.78,9.01-10.78c0.3-0.25,1.06-0.95,1.75-1.61C24.46,89.87,21.04,93.11,18.79,94.13L18.79,94.13L18.79,94.13z M47.67,27.64c1.47,0,2.31,3.7,2.38,7.17c0.07,3.47-0.74,5.91-1.75,7.71c-0.83-2.67-1.24-6.87-1.24-9.62 C47.06,32.89,47,27.64,47.67,27.64L47.67,27.64L47.67,27.64z M39.05,75.02c1.03-1.83,2.08-3.76,3.17-5.81 c2.65-5.02,4.32-8.93,5.57-12.15c2.48,4.51,5.57,8.35,9.2,11.42c0.45,0.38,0.93,0.77,1.44,1.15 C51.05,71.09,44.67,72.86,39.05,75.02L39.05,75.02L39.05,75.02L39.05,75.02z M85.6,74.61c-0.45,0.28-1.74,0.44-2.56,0.44 c-2.67,0-5.98-1.22-10.62-3.22c1.78-0.13,3.41-0.2,4.88-0.2c2.68,0,3.48-0.01,6.09,0.66C86.01,72.96,86.05,74.32,85.6,74.61 L85.6,74.61L85.6,74.61L85.6,74.61z M96.12,115.98V30.45H73.44V5.91H6.51v110.07H96.12L96.12,115.98z"/></g></svg>'

    const dwnldLink = document.createElement('a');
    dwnldLink.name = 'href_' + cntrlNm;
    dwnldLink.id = 'href_' + cntrlNm;
    dwnldLink.setAttribute('controlname', cntrlNm);
    dwnldLink.setAttribute('filename', fileNm);
    dwnldLink.innerHTML = iconFile + '&nbsp;' + (fileNm.replace(extension, '').length > 12 ? fileNm.substr(0, 11) + '...' + extension : fileNm) + (filesize && filesize !== undefined ? '(' + GetFileSizeStr(filesize) + ')' : '');
    //dwnldLink.style = "width:20px;"
    dwnldLink.title = 'Download ' + fileNm;
    dwnldLink.addEventListener("click", e => {
        DownloadAttachment(e);
    });

    //iconFile = '<svg width="24" height="24" viewBox="0 0 105.16 122.88"><path d="M11.17,37.16H94.65a8.4,8.4,0,0,1,2,.16,5.93,5.93,0,0,1,2.88,1.56,5.43,5.43,0,0,1,1.64,3.34,7.65,7.65,0,0,1-.06,1.44L94,117.31v0l0,.13,0,.28v0a7.06,7.06,0,0,1-.2.9v0l0,.06v0a5.89,5.89,0,0,1-5.47,4.07H17.32a6.17,6.17,0,0,1-1.25-.19,6.17,6.17,0,0,1-1.16-.48h0a6.18,6.18,0,0,1-3.08-4.88l-7-73.49a7.69,7.69,0,0,1-.06-1.66,5.37,5.37,0,0,1,1.63-3.29,6,6,0,0,1,3-1.58,8.94,8.94,0,0,1,1.79-.13ZM5.65,8.8H37.12V6h0a2.44,2.44,0,0,1,0-.27,6,6,0,0,1,1.76-4h0A6,6,0,0,1,43.09,0H62.46l.3,0a6,6,0,0,1,5.7,6V6h0V8.8h32l.39,0a4.7,4.7,0,0,1,4.31,4.43c0,.18,0,.32,0,.5v9.86a2.59,2.59,0,0,1-2.59,2.59H2.59A2.59,2.59,0,0,1,0,23.62V13.53H0a1.56,1.56,0,0,1,0-.31v0A4.72,4.72,0,0,1,3.88,8.88,10.4,10.4,0,0,1,5.65,8.8Zm42.1,52.7a4.77,4.77,0,0,1,9.49,0v37a4.77,4.77,0,0,1-9.49,0v-37Zm23.73-.2a4.58,4.58,0,0,1,5-4.06,4.47,4.47,0,0,1,4.51,4.46l-2,37a4.57,4.57,0,0,1-5,4.06,4.47,4.47,0,0,1-4.51-4.46l2-37ZM25,61.7a4.46,4.46,0,0,1,4.5-4.46,4.58,4.58,0,0,1,5,4.06l2,37a4.47,4.47,0,0,1-4.51,4.46,4.57,4.57,0,0,1-5-4.06l-2-37Z" /></svg>';
    const delLink = document.createElement('a');
    delLink.name = 'href2_' + cntrlNm;
    delLink.id = 'href2_' + cntrlNm;
    delLink.setAttribute('controlname', cntrlNm);
    delLink.setAttribute('filename', fileNm);
    delLink.innerHTML = '<i class="fa fa-trash fa-lg ms-2" controlname="' + cntrlNm + '" filename="' + fileNm + '"></i>';
    delLink.title = 'Delete ' + fileNm;
    delLink.addEventListener("click", e => {
        DeleteAttachment(e);
    });


    const div = document.createElement('div');
    div.id = 'div' + cntrlNm;
    div.name = 'div' + cntrlNm;
    div.style.cssText = 'padding-top: 10px';

    div.appendChild(dwnldLink);
    div.appendChild(delLink);

    var parentElemnt = document.getElementById(cntrlNm).parentElement;
    parentElemnt.appendChild(div);

    return;
}

export async function GetApplicantImage(endpoint, userId, SessionId) {

    try {

        let response = await APICall({ url: endpoint, method: 'GET', responseType: 'blob' }, userId, SessionId);

        if (response) {
            if (response.type == 'image/jpeg') {
                return response;
            }
            return null;
        }
    } catch (ex) {
        console.log(ex);
        throw ex;
    }

    return null;
}


