import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import PostLoginMenu from './PostLoginMenu'
import EnrollmentHeader from './EnrollmentHeader'
import { Footer } from './Footer';
import { DrawerAppBar } from './AppNavbar'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Typography, Paper, Stack, Button, IconButton, Tooltip} from '@mui/material';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import { styled } from '@mui/material/styles';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import CallIcon from '@mui/icons-material/Call';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { GetLoggedInUserId, GetLoggedInUserEmail, GetLoggedInUserName, CallAPI } from '../global/APIUtils';
import { APICall } from '../global/Api';
import AlertControl from '../components/Controls/AlertControl';
import AlertWithTitle from './Controls/AlertWithTitle';
import { ErrorMessages } from '../common/ErrorMessages';

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: 0,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
}));

const ContactUs = () => {

    const location = useLocation();
    const [errorMsg, setErrorMsg] = useState(null);
    const [successMsg, setSuccessMsg] = useState(null);
    const [successMsgTitle, setSuccessMsgTitle] = useState(null);
    const [userId, setUserId] = useState(location.state.userId || -1);
    const [sessionId, setSessionId] = useState(location.state.sessionId || '');
    const [expanded, setExpanded] = useState(true);
    const [issue, setIssue] = useState('');
    const [query, setQuery] = useState('');

    const handleAccordianChange = () => {
        if (expanded) {
            setExpanded(false);
        }
        else {
            setExpanded(true);
        }
    };

    const handleIssueChange = (e) => {       
        setIssue(e.target.options[e.target.selectedIndex].text)
    };

    const handleQueryChange = (e) => {
        setQuery(e.target.value)
    };

    const SendRequest = async (event) => {
        try {

            if (query == '' || query == undefined)
                return;

            setSuccessMsgTitle("");
            setSuccessMsg("");

            event.preventDefault();

            let userEmail = GetLoggedInUserEmail(userId);
            let userName = GetLoggedInUserName(userId);
            

            let inputJson = {
                "UserEmail": userEmail,
                "Issue": issue,
                "Query": query
            }

            const apiUrl = new URL(
                process.env.REACT_APP_CONTACTUS_SENDQUERY,
                process.env.REACT_APP_BASE_URL,
            );

            let response = await APICall({ url: apiUrl.href, method: 'POST', payload: inputJson, contentType: 'application/json', responseType: '' }, userId, sessionId);

            if (response !== null && response !== undefined) {

                if (response.Errors.length > 0) {
                    setErrorMsg(response.Errors[0].Message);
                }
                else if (response.meta.EmailSent === "Yes") {
                    setQuery('');
                    setSuccessMsgTitle("Email Sent");
                    setSuccessMsg("Query sent successfully.");
                }
                else {
                    setErrorMsg("Unable to sent email.");
                }                
            }
        }
        catch (err) {
            setErrorMsg(ErrorMessages.UnknownError);
        }
        finally {
            /*setIsDataLoading(false);*/
        }
    }


    return (

        <React.Fragment>
            <Container maxWidth="xl" sx={{}}>
                <PostLoginMenu userId={userId} sessionId={sessionId} />
                <Paper className=""
                    sx={{
                        position: 'relative',
                        backgroundColor: '#fff',
                        color: '#fff',
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        pb: 0
                    }}
                >
                    <Grid item>
                        <Box sx={{
                            pr: { xs: 3, md: 2 },
                            pl: { xs: 3, md: 2 }
                        }}>
                            <Accordion spacing={0} elevation={0} expanded={expanded} onChange={handleAccordianChange} sx={{ p: 0, m: 0, width: '100%' }}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header" sx={{ mr: 2 }}>
                                </AccordionSummary>
                                <AccordionDetails sx={{ pr: 3, pl: 3, pb: 0 }}>
                                    {/*<EnrollmentHeader />*/}
                                    <DrawerAppBar userId={userId} sessionId={sessionId} />
                                </AccordionDetails>
                            </Accordion>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box sx={{ ml: 15, mr: 15, border: '0px solid #000', pt: 0 }}>
                            <Typography variant="h3" color="#000" sx={{ borderBottom: '1px solid rgba(0,0,0,0.1)', lineHeight: 2.2, fontWeight: 500, letterSpacing: '-1px', textAlign: 'center' }} >Contact Us</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box sx={{ border: '0px solid #000', pt: 1 }}>
                            <Grid item xs container direction="row" spacing={10} sx={{ pr: 10, pt: 0, pl: 2 }}>
                                <Grid item xs={12} md={1}>
                                </Grid>
                                <Grid item xs={12} md={10}>
                                    {/*<Box sx={{ pt: 3 }}>*/}
                                    {/*    <AlertControl Message={errorMsg} severity="error" color="error" icon={true} />*/}
                                    {/*    <AlertWithTitle Message={successMsg} variant="body1" severity="success" color="success" icon={true} Title={successMsgTitle} />*/}
                                    {/*</Box>*/}
                                    <Paper elevation={0} sx={{}}>
                                        <AlertControl Message={errorMsg} severity="error" color="error" icon={true} />
                                    </Paper>
                                    <Paper elevation={0}>
                                        <AlertWithTitle Message={successMsg} variant="body1" severity="success" color="success" icon={true} Title={successMsgTitle} />
                                    </Paper>
                                    <Box sx={{ pt: 2, pb: 3, borderBottom: '1px solid rgba(0,0,0,0.1)' }}>
                                        <Typography variant="h5" color="text.secondary" sx={{ fontWeight: 500 }} >Need Some Help?</Typography>
                                        <Typography variant="h6" color="text.secondary" sx={{ pt: 3, fontWeight: 400, lineHeight: 1.3, pb: 2, borderBottom: '1px solid rgba(0,0,0,0.1)' }} >Please give us a call or send an email to our helpdesk representative. Our helpdesk is happy to assist you in any way between 9 AM to 5 PM EST and 5 business days a week.</Typography>
                                        {/*<Typography variant="h6" color="text.secondary" sx={{ pl: 1, fontWeight: 400, pb: 4 }} >Our helpdesk is happy to assist you in any way between 9 AM to 5 PM EST and 5 business days a week. </Typography>*/}
                                        <Box sx={{ pt: 2 }}>
                                            <Stack direction="row">
                                                <CallIcon sx={{ color: '#0175bd', fontSize: '30px' }} />
                                                <Typography sx={{ ml: 1, fontWeight: 500 }} variant="h6" color="text.secondary">123-456-7890</Typography>
                                            </Stack>
                                            <Stack sx={{ mt: 1 }} direction="row">
                                                <ForwardToInboxIcon sx={{ color: '#0175bd', fontSize: '30px' }} />
                                                <Typography sx={{ ml: 1, fontWeight: 500 }} variant="h6" color="text.secondary">settlementadmin@fi-tek.com</Typography>
                                            </Stack>
                                        </Box>
                                    </Box>
                                    <form id="frmLogin">
                                        <Typography variant="h6" color="text.secondary" sx={{ pt: 3, pb: 2, fontWeight: 400 }} >Fill this form and we'll get back to you soon.</Typography>
                                        <Paper elevation={0} sx={{width: '52%'}}>
                                            <label htmlFor="userid" className="form-label">Issue*</label>
                                            <select className="form-select" id="cboIssue" name="cboIssue" placeholder={'Issue *'} onChange={handleIssueChange}>
                                                <option key={-1} value={-1}>Select</option>
                                                <option value="1">What is My Claim Number</option>
                                                <option value="2">General Questions</option>
                                                <option value="3">Change of Address</option>
                                                <option value="3">Help with Claim Form</option>
                                                <option value="3">Mail Me an Exteded Claims Period Claim Form</option>
                                            </select>
                                        {/*    {*/}
                                        {/*        loginForm.touched.userid && loginForm.errors.userid ? (<p className="text-danger">{loginForm.errors.userid}</p>) : null*/}
                                        {/*    }*/}
                                        </Paper>
                                        <Paper elevation={0} sx={{ pt: 2, width: '80%' }}>
                                            <label htmlFor="password" className="form-label">Questions/comments</label>
                                            <textarea className="form-control" style={{ height: '100%' }} rows={6} placeholder="Limit 1000 characters" maxLength={1000} onChange={handleQueryChange} value={query} required></textarea>
                                        </Paper>
                                        {/*    {*/}
                                        {/*        loginForm.touched.password && loginForm.errors.password ? (<p className="text-danger">{loginForm.errors.password}</p>) : null*/}
                                        {/*    }*/}
                                        
                                        {/*<Box sx={{ pt: 0, pb: 0 }}>*/}
                                        {/*    <Tooltip title="Add Attachment">*/}
                                        {/*        <span>*/}
                                        {/*            <Stack direction="row" alignItems="center">*/}
                                        {/*                <Typography color="text.secondary" variant="body1" sx={{ }}>Attach File</Typography>*/}
                                        {/*                <IconButton sx={{ border: 0, borderColor: '#235ec9', p: 1 }} >*/}
                                        {/*                    <MoreHorizIcon sx={{ fontSize: 32 }} />*/}
                                        {/*                </IconButton>*/}
                                        {/*            </Stack>*/}
                                        {/*        </span>*/}
                                        {/*    </Tooltip>*/}
                                        {/*</Box>*/}
                                        <Box sx={{ pt: 1, pb: 0 }}>
                                            <Button type="submit" variant="outlined" size="large" style={{ boxShadow: 'none', border: '2px solid #0972d3', borderRadius: '40px', padding: '0.4rem 2.5rem', textTransform: 'none', }} onClick={(e) => SendRequest(e)}>
                                                <Typography variant="body2" color="#0972d3" sx={{ fontWeight: 600 }} >Submit</Typography>
                                            </Button>
                                        </Box>
                                    </form>
                                </Grid>
                                <Grid item xs={12} md={0}>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                </Paper>
                <Footer />
            </Container>
        </React.Fragment>
    );
}

export default ContactUs;