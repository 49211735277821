import React, { Component } from 'react';
import { Route, Routes } from 'react-router';
import { Layout } from './components/Layout';
import { AppLogin } from './components/AppLogin';
import AppLogout from './components/AppLogout';
import OTPVerification from './components/OTPVerification';
import FAQ from './components/FAQ';
import FAQP from './components/FAQP';
import KeyDates from './components/KeyDates';
import KeyDatesP from './components/KeyDatesP';
import ContactUs from './components/ContactUs';
import UserHome from './components/Home';
import ProspectDashboard from './components/ProspectDashboard';
import { ApplicationEnrollment } from './components/ApplicationEnrollment';
import UserRegistration from './components/UserRegistration';
import ForgetPassword from './components/ForgetPassword';
import ChangePassword from './components/ChangePassword';
import VerifyResetCode from './components/VerifyResetCode';
import ChangePasswordOption from './components/ChangePasswordOption';
import CreatePassword from './components/CreatePassword';
import UserManagement from './components/UserManagement';
import RoleManagement from './components/RoleManagement';
import RoleDefinition from './components/RoleDefinition';
import User from './components/User';
import UserProfileSetting from './components/UserProfileSetting';
import TemplateDesigner from './components/TemplateDesigner';
import ApplicationDashboard from './components/ApplicationDashboard';
import TemplateList from './components/TemplateList';
import { Logout } from './global/Logout';
import { ThemeProvider, createTheme, responsiveFontSizes } from '@mui/material/styles';
import ActivateRegistration from './components/ActivateRegistration';

import './assets/css/bootstrap.min.css';
import './assets/css/AOBStyle.css';
import { red } from '@mui/material/colors';

let theme = createTheme({
    typography: {
        fontFamily: [
            'Roboto',
            'sans-serif'
        ].join(','),
        fontSize: 16,
    },
    palette: {
        text: {
            primary: '#383d3f',
            secondary: '#000',
        },
        //primary: '#0069EC',
        //primary: {
        //    main: '#0069aa',
        //},
        //secondary: {
        //    light: '#862633',
        //    main: '#862633',
        //    contrastText: '#862633',
        //},

        //custom: {
        //    light: '#ffa726',
        //    main: '#f57c00',
        //    dark: '#ef6c00',
        //    contrastText: 'rgba(0, 0, 0, 0.87)',
        //},
        //contrastThreshold: 3,
        //tonalOffset: 0.2,
    },
});

theme.typography.h1 = {
    fontSize: '3.75rem', //60px
    //'@media (max-width:600px)': {
    //    fontSize: '1.5rem',
    //},
    //[theme.breakpoints.up('md')]: {
    //    fontSize: '3.5rem',
    //},
};
theme.typography.h2 = {
    fontSize: '2.5rem', //48px
    fontWeight: 500,
    lineHeight: 1.4,
    //'@media (max-width:600px)': {
    //    fontSize: '1.5rem',
    //},
    //[theme.breakpoints.up('md')]: {
    //    fontSize: '3.5rem',
    //},
};
theme.typography.h3 = {
    fontSize: '2.25rem', //36px
    fontWeight: 500,
    lineHeight: 1.5,
    //'@media (max-width:600px)': {
    //    fontSize: '1.5rem',
    //},
    //[theme.breakpoints.up('md')]: {
    //    fontSize: '3.5rem',
    //},
};
theme.typography.h4 = {
    fontSize: '1.875rem', //30px
    lineHeight: 1.5,
    fontWeight: 400,
    //'@media (max-width:600px)': {
    //    fontSize: '1.5rem',
    //},
    //[theme.breakpoints.up('md')]: {
    //    fontSize: '3.5rem',
    //},
};
theme.typography.h5 = {
    fontSize: '1.5rem', //24px
    lineHeight: 1.3333,
    //fontWeight: 500,
    //'@media (max-width:600px)': {
    //    fontSize: '1.5rem',
    //},
    //[theme.breakpoints.up('md')]: {
    //    fontSize: '3.5rem',
    //},
};
theme.typography.h6 = {
    fontSize: '1.125rem', //18px
    lineHeight: 1.7,
    //'@media (max-width:600px)': {
    //    fontSize: '1.5rem',
    //},
    //[theme.breakpoints.up('md')]: {
    //    fontSize: '3.5rem',
    //},
};
theme.typography.subtitle1 = {
    fontSize: '1rem',
    lineHeight: 1.5,
    fontWeight: 500,
    //'@media (max-width:600px)': {
    //    fontSize: '1.5rem',
    //},
    //[theme.breakpoints.up('md')]: {
    //    fontSize: '3.5rem',
    //},
};
theme.typography.subtitle2 = {
    fontSize: '0.875rem',
    lineHeight: 1.4286,
    //'@media (max-width:600px)': {
    //    fontSize: '1.5rem',
    //},
    //[theme.breakpoints.up('md')]: {
    //    fontSize: '3.5rem',
    //},
};
theme.typography.body1 = {
    fontSize: '1rem',
    lineHeight: 1.6,
    //'@media (max-width:600px)': {
    //    fontSize: '1.5rem',
    //},
    //[theme.breakpoints.up('md')]: {
    //    fontSize: '3.5rem',
    //},
};
theme.typography.body2 = {
    fontSize: '0.875rem',
    lineHeight: 1.5,
    //'@media (max-width:600px)': {
    //    fontSize: '1.5rem',
    //},
    //[theme.breakpoints.up('md')]: {
    //    fontSize: '3.5rem',
    //},
};
//theme = responsiveFontSizes(theme);

class App extends Component {  

    render() {
        return (
            <ThemeProvider theme={theme}>
                <Layout>
                    <Routes>
                        <Route path='/' exact element={<AppLogin />} />
                        <Route path='/AppLogin' exact element={<AppLogin />} />
                        <Route path='/OTPVerification' exact element={<OTPVerification />} />
                        <Route path='/AppLogout' exact element={<AppLogout />} />
                        <Route path='/FAQ' exact element={<FAQ />} />
                        <Route path='/FAQP' exact element={<FAQP />} />
                        <Route path='/KeyDates' exact element={<KeyDates />} />
                        <Route path='/KeyDatesP' exact element={<KeyDatesP />} />
                        <Route path='/ContactUs' exact element={<ContactUs />} />
                        <Route path='/ActivateRegistration' exact element={<ActivateRegistration />} />
                        <Route path='/ProspectDashboard' exact element={<ProspectDashboard />} />
                        <Route path='/UserRegistration' exact element={<UserRegistration />} />
                        <Route path='/Enrollment' exact element={<ApplicationEnrollment />} />
                        <Route path='/UserProfileSetting' exact element={<UserProfileSetting />} />
                        <Route path='/ApplicationDashboard' exact element={<ApplicationDashboard />} />
                        <Route path='/TemplateList' exact element={<TemplateList />} />
                        <Route path='/TemplateDesigner' exact element={<TemplateDesigner />} />
                        <Route path='/ForgetPassword' exact element={<ForgetPassword />} />
                        <Route path='/VerifyResetCode' exact element={<VerifyResetCode />} />
                        <Route path='/ChangePasswordOption' exact element={<ChangePasswordOption />} />
                        <Route path='/CreatePassword' exact element={<CreatePassword />} />
                        <Route path='/UserManagement' exact element={<UserManagement />} />
                        <Route path='/RoleManagement' exact element={<RoleManagement />} />
                        <Route path='/RoleDefinition' exact element={<RoleDefinition />} />
                        <Route path='/User' exact element={<User />} />
                        <Route path='/UserHome' exact element={<UserHome />} />
                    </Routes>
                </Layout>
            </ThemeProvider>
        );
    }
}

export default App;
