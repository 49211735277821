import React from 'react';

export const LoginStatus = {

    Failed : 61,
    Locked : 62,
    InvalidCredential : 63,
    InactiveUser : 64,
    AccountNotActivated : 65,
    AllreadyLoggedIn : 66,
    Success : 67,
    PasswordExpired : 68,
    PasswordExpiredWarning: 69

}

export const APIResponseStatus = {

    Success : 1,
    NotAuthorized : 2,
    TokenMissing : 3,
    InvalidToken : 4,
    TokenExpired : 5,
    TokenOld: 6,
    SessionMismatch: 7,
    SessonExisted: 8,
    SessonExpired: 9
}

export const TemplateStatus = {

    InProgress: 33,
    Published: 34
}

export const EnrollmentStatus = {
    SubmitApplication: 19,
    CancelApplication: 20,
    UnderReview: 21,
    RejectApplication: 22,
    RequestCancellation: 23,
    ReviewPass: 24,
    ReviewFail: 25,
    KYCAMLInitiated: 26,
    KYCAMLPassed: 27,
    KYCAMLFailed: 28,
    SubmittedEnrollment: 29,
    DocuSignRequired: 30,
    DocuSignCompleted: 31,
    AccountActivated: 32,
    InComplete: 35,
    Complete: 56
}
export const EnrollmentStatusText = {
    SubmitApplication: "Submitted",
    CancelApplication: "Application Cancelled",
    UnderReview: "Under Review",
    RejectApplication: "Application Rejected",
    RequestCancellation: "Request for Cancellation",
    ReviewPass: "Review Passed",
    ReviewFail: "Review Failed",
    KYCAMLInitiated: "KYC/AML Initiated",
    KYCAMLPassed: "KYC/AML Passed",
    KYCAMLFailed: "KYC/AML Failed",
    SubmittedEnrollment: "Submitted for Enrollment",
    DocuSignRequired: "DocuSign Required",
    DocuSignCompleted: "DocuSign Completed",
    AccountActivated: "Account Activated",
    InComplete: "Incomplete",
    Complete: "Complete"
}

export const TemplateSortAction = {
    Newest: 1,
    Oldest: 2,
    TemplateName: 3,
    TemplateDesc: 4,
    TemplateOwner: 5,
    TemplateStatus: 6
}

export const ApplicationSortAction = {
    Newest: 1,
    Oldest: 2,
    Owner: 3,
    AccountType: 4,
    ApplName: 5,
    Status: 6,
    ModUser: 7
}

export const UserListSortAction = {
    Newest: 1,
    Oldest: 2,
    FirstName: 3,
    LastName: 4,
    Email: 5,
    RoleName: 6
}
export const RegistrationResponseStatus = {

    DuplicateEntry: 10,
    NotExists: 12
}
export const DocuSignStatus = {

    Completed: 57,
    Cancelled: 58,
    Declined: 59,
    Initiated: 60,
    Timeout: 61
}
export const AOBSystemProcess = {
    ApplicationMaintenance: 1,
    ViewApplication: 2,
    ExportApplication: 3,
    ApproveApplication: 4,
    PublishApplication: 5,
    DocuSignDocUpload: 6,
    TemplateMaintenance: 7,
    PublishTemplate: 8,
    UserMaintenance: 9,
    RoleMaintenance: 10,
    SystemParamMaintenance: 11,
    ViewActivityLog: 12,
    ViewErrorLog: 13,
    ViewTraceLog: 14,
    ViewAuditTrailReport: 15,
    SystemMessage: 16,
    Prospects: 17,
    AllProcess: 18,
    DeleteApplication: 19,
    DeleteTemplate: 20,
    ViewAllTemplates: 21,
    DeleteUser: 22
}
export const ContentType = {
    'png': 'image/png',
    'tif': 'image/tiff',
    'ico': 'image/x-icon',
    'jng': 'image/x-jng',
    'bmp': 'image/x-ms-bmp',
    'svg': 'image/svg+xml',
    'jpeg': 'image/jpeg',
    'jpg': 'image/jpeg',
    'pdf': 'application/pdf'
}
