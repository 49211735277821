import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import { Typography, Box, Stack, Paper} from '@mui/material'; 
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

export default function KeyDate() {

    return (
        <React.Fragment>
            <Box sx={{ ml: 6, mr: 6, border: '0px solid #000', pt: 1 }}>
                <Typography variant="h3" color="text.primary" sx={{ borderBottom: '1px solid rgba(0,0,0,0.1)', lineHeight: 2.2, fontWeight: 500, letterSpacing: '-1px', textAlign: 'center' }} >Key Dates</Typography>
            </Box>
            <Box sx={{ ml: 15, mr: 15, mt: 6 }}>
                <TableContainer component={Paper} elevation={0} sx={{ borderTop: '1px solid #dee2e6' }} >
                    <Table sx={{}} size="small" aria-label="a dense table">
                        <TableHead sx={{}} >
                            <TableRow sx={{ border: '2px solid ccc' }}>
                                <TableCell sx={{ width: '230px', }}>
                                    <Typography variant="h6" color="text.primary" sx={{ fontWeight: 700, textDecoration: 'underline' }}>Date</Typography>
                                </TableCell>
                                <TableCell><Typography variant="h6" color="text.primary" sx={{ fontWeight: 700, textDecoration: 'underline' }}>Event</Typography>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow key={1} sx={{ borderTop: '2px solid #dee2e6' }}>
                                <TableCell scope="row">
                                    <Typography color="text.secondary" variant="h6" >07/22/2019</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography variant="body1" color="text.secondary" sx={{ lineHeight: 1.3 }}><b>Preliminary Approval Date.</b>  The Court entered an Order approving notice to the individuals who were impacted by the 2017 data breach, or “class members” in the lawsuit.</Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow key={2} sx={{ borderTop: '1px solid #dee2e6' }}>
                                <TableCell scope="row">
                                    <Stack direction="row">
                                        <Typography variant="h6" color="text.secondary" >11/19/2019 </Typography>
                                        <Typography variant="body1" sx={{ ml: .5, color: '#58b764' }} ><b>(Passed)</b></Typography>
                                    </Stack>
                                </TableCell>
                                <TableCell>
                                    <Typography variant="body1" color="text.secondary" sx={{ lineHeight: 1.3 }}><b>Deadline to Exclude Yourself or Opt-Out of the Settlement.</b> The deadline to exclude yourself from the Settlement has passed.</Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow key={3} sx={{ borderTop: '1px solid #dee2e6' }}>
                                <TableCell scope="row">
                                    <Stack direction="row">
                                        <Typography variant="h6" color="text.secondary" >11/19/2019</Typography>
                                        <Typography variant="body1" sx={{ ml: .5, color: '#58b764' }} ><b>(Passed)</b></Typography>
                                    </Stack>
                                </TableCell>
                                <TableCell>
                                    <Typography variant="body1" color="text.secondary" sx={{ lineHeight: 1.3 }}><b>Deadline to Object to the Settlement.</b> The deadline to object to the Settlement has passed. </Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow key={4} sx={{ borderTop: '1px solid #dee2e6' }}>
                                <TableCell scope="row">
                                    <Stack direction="row">
                                        <Typography variant="h6" color="text.secondary"  >12/19/2019</Typography>
                                        <Typography variant="body1" sx={{ ml: .5, color: '#58b764' }} ><b>(Approved)</b></Typography>
                                    </Stack>
                                </TableCell>
                                <TableCell>
                                    <Typography variant="body1" color="text.secondary" sx={{ lineHeight: 1.3 }}><b>Final Approval Hearing.</b> This was the date of the Court’s final approval hearing where it considered whether to approve the Settlement.  Please note that no Settlement benefits will be distributed or available until the Settlement becomes effective.</Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow key={5} sx={{ borderTop: '1px solid #dee2e6' }}>
                                <TableCell scope="row">
                                    <Stack direction="row">
                                        <Typography variant="h6" color="text.secondary" >1/22/2020</Typography>
                                        <Typography variant="body1" sx={{ ml: .5, color: '#58b764' }} ><b>(Passed)</b></Typography>
                                    </Stack>
                                </TableCell>
                                <TableCell>
                                    <Typography variant="body1" color="text.secondary" sx={{ lineHeight: 1.3 }}><b>Initial Claims Period Deadline.</b> This was the deadline for the Initial Claims Period.  You must have filed a claim by this deadline in order to receive the Free Credit Monitoring Services offered under the Settlement, or Alternative Reimbursement Compensation up to $125, and for any out-of-pocket losses or time spent before this deadline.</Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow key={6} sx={{ borderTop: '1px solid #dee2e6' }}>
                                <TableCell scope="row">
                                    <Stack direction="row">
                                        <Typography variant="h6" color="text.secondary" >1/22/2024</Typography>
                                    </Stack>
                                </TableCell>
                                <TableCell>
                                    <Typography variant="body1" color="text.secondary" sx={{ lineHeight: 1.3 }}><b>Extended Claims Period Deadline.</b> You must file a claim by this deadline in order to receive benefits for Out-of-Pocket Losses or Time Spent (excluding losses of money and time associated with freezing or unfreezing credit reports or purchasing credit monitoring or identity theft protection) that occur between January 23, 2020 and January 22, 2024.</Typography>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </React.Fragment>
    );
}