import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import moment from "moment";
import { AddEditEnrollment } from '../dialogs/AddEditEnrollment';
import AlertControl from './Controls/AlertControl';
import PostLoginMenu from './PostLoginMenu'
import EnrollmentHeader from './EnrollmentHeader'
import { Footer } from './Footer';
import { CallAPI } from '../global/APIUtils';
import { ErrorMessages } from '../common/ErrorMessages';
import OverlaySpinner from './Controls/OverlaySpinner';
import { EnrollmentStatus } from '../common/AOBEnum';
import { ApplicationAllDocumentDownload } from './ApplicationDocumentDownload';
import { DrawerAppBar } from './AppNavbar'
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Typography, Stack, Button, Badge, Paper, Alert, AlertTitle, Divider, IconButton, Link, Tooltip, Menu, MenuItem, Stepper, Step, StepLabel } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DoneIcon from '@mui/icons-material/Done';
import Avatar from '@mui/material/Avatar';
import { deepOrange, deepPurple } from '@mui/material/colors';
import MessageAlert from '../global/MessageAlert';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import { styled } from '@mui/material/styles';
//import MuiAccordion from '@mui/material/Accordion';
import pwdimg from '../assets/images/bg_3.jpg';
import placeimg from '../assets/images/2345.jpg';
//import Image from 'material-ui-image'
import ListItemIcon from '@mui/material/ListItemIcon';
import CheckIcon from '@mui/icons-material/Check';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import DownloadIcon from '@mui/icons-material/Download';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import AvTimerIcon from '@mui/icons-material/AvTimer';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { grey } from '@mui/material/colors';
import GradingIcon from '@mui/icons-material/Grading';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';
import PrintIcon from '@mui/icons-material/Print';
import ReplyOutlinedIcon from '@mui/icons-material/ReplyOutlined';
import DescriptionIcon from '@mui/icons-material/Description';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import { ConfirmDialog } from '../global/ConfirmDialog';
import { GetAttachedFile } from '../global/EnrollmentHelper';
import AlertWithTitle from './Controls/AlertWithTitle';
import { IsError, GetLoggedInUserId, GetLoggedInUserEmail, GetLoggedInUserName } from '../global/APIUtils';
import AppViewDlg from '../dialogs/ApplViewDlg';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import profimg from '../assets/images/profimg.jpg';
import { GetApplicantImage } from '../global/EnrollmentHelper';
import { APICall } from '../global/Api';
import BLogo from '../assets/images/ssb-logo.png';
import { goToTop } from '../global/Helpers'
import ProspectDocListDlg from '../dialogs/ProspectDocListDlg';
import Snackbar from '@mui/material/Snackbar';
import CloseIcon from '@mui/icons-material/Close';
import MuiAlert from '@mui/material/Alert';
import AutoLagoutTimer from '../global/IdleMonitor';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: 0,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
}));

const ProspectDashboard = () => {

    //var moment = require('moment-timezone');
    //var platform = require('platform');

    const location = useLocation();

    const [modalShow, setModalShow] = useState(false);
    const [reviewModalShow, setReviewModalShow] = useState(false);
    const [alertMsg, setAlertMsg] = useState('');
    const [alertSucStatus, setAlertSucStatus] = useState(false);
    const [modalTitle, setModalTitle] = useState('');
    const [expanded, setExpanded] = useState(true);
    const [appCount, setAppCount] = useState(0);

    const [disCancelMenu, setDisCancelMenu] = useState(false);
    const [disReqCancelMenu, setDisReqCancelMenu] = useState(false);
    const [disDelMenu, setDisDelMenu] = useState(false);

    const [errorMsg, setErrorMsg] = useState('');
    const [infoMsg, setInfoMsg] = useState('');
    const [showDocListDlg, setShowDocListDlg] = useState(false);
    const [popWin, setPopWin] = useState(false);

    const [userId, setUserID] = useState(location.state.userId || -1);
    const [sessionId, setSessionId] = useState(location.state.sessionId || '');

    const [state, setState] = useState({
        enrmntId: 0,
        templateId: 0,
        name: '',
        templateNm: '',
        ownerId: -1
    })
    const [data, setData] = useState(null);
    const [signingDocdata, setSigningDocData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const selectedEnrollment = useRef(null);
    const selectedEnrollmentNm = useRef(null);
    const selectedTemplateNm = useRef(null);
    const selectedEnrollmentData = useRef(null);
    const selectedOwnerNm = useRef(null);
    const selectedOwnerEmail = useRef(null);
    const selectedOwnerMobile = useRef(null);

    const [openConfirmDlg, setOpenConfirmDlg] = useState(false);
    const [openCancelConfirmDlg, setOpenCancelConfirmDlg] = useState(false);
    const [confirmDlgNValue, setConfirmDlgNValue] = useState(null);
    const [confirmDlgSValue, setConfirmDlgSValue] = useState(null);
    const [openSnackBar, setOpenSnackBar] = React.useState(false);

    const [openReqCancelConfirmDlg, setOpenReqCancelConfirmDlg] = useState(false);

    const [successMsg, setSuccessMsg] = useState(null);
    const [successMsgTitle, setSuccessMsgTitle] = useState(null);

    const [showAppViewDlg, setShowAppViewDlg] = useState(false);

    const steps = ['New', 'Submit', 'Admin Review',];
    const [activeStep, setActiveStep] = React.useState(0);

    const steps2 = ['Your Review', 'Payment', 'Completed',];
    const [activeStep2, setActiveStep2] = React.useState(-1);
    const [imageUrl, setImageUrl] = useState(null);

    //const userId = useRef(-1);
    const loginUserEmail = useRef('');
    const loginUserName = useRef('');

    const confirmDlgHdr = useRef('');
    const confirmDlgBLine1 = useRef('');

    //const dispatch = useDispatch();

    //let sessions = useSelector((state) => state.sessionMgmnt);
    let history = useNavigate();
    
    const [applMoreMenuAnchorEl, setApplMoreMenuAnchorEl] = useState(null);
    const openApplMoreMenu = Boolean(applMoreMenuAnchorEl);

    const handleSnackBarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenSnackBar(false);
    };
    const SnackAlert = React.forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });
    const snackBarAction = (
        <React.Fragment>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleSnackBarClose}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </React.Fragment>
    );


    const closeAppViewDlg = () => {
        setShowAppViewDlg(false);
    }

    const closeDocListDlg = () => {
        setShowDocListDlg(false);
    }

    const GetActiveState1 = (enrmntStatusId) => {

        switch (enrmntStatusId) {
            case EnrollmentStatus.InComplete:
                return 0;
            case EnrollmentStatus.Complete:
                return 0;
            case EnrollmentStatus.SubmitApplication:
                return 1;
            case EnrollmentStatus.UnderReview:
                return 2;
            case EnrollmentStatus.ReviewPass:
                return 2;
            case EnrollmentStatus.ReviewFail:
                return 2;
            default:
                return 4;
        }
    }

    const GetActiveState2 = (enrmntStatusId) => {

        switch (enrmntStatusId) {
            case EnrollmentStatus.KYCAMLInitiated:
                return 3;
            case EnrollmentStatus.KYCAMLPassed:
                return 3;
            case EnrollmentStatus.KYCAMLFailed:
                return 3;
            case EnrollmentStatus.DocuSignRequired:
                return 4;
            case EnrollmentStatus.DocuSignCompleted:
                return 4;
            case EnrollmentStatus.AccountActivated:
                return 6;
            default:
                return -1;
        }
    }

    const isStepCompleted1 = (stepLabel, enrmntStatusId) => {
        if (stepLabel === 'New') {
            //if (enrmntStatusId)
        }
    }

    const handleApplMoreMenuClick = (event, enrmntId, enrmntStatusId, enrmntNm, templateNm) => {

        setApplMoreMenuAnchorEl(event.currentTarget);

        selectedEnrollment.current = enrmntId;
        selectedEnrollmentNm.current = enrmntNm;
        selectedTemplateNm.current = templateNm;

        if (enrmntStatusId == EnrollmentStatus.Complete || enrmntStatusId == EnrollmentStatus.InComplete
            || enrmntStatusId == EnrollmentStatus.CancelApplication || enrmntStatusId == EnrollmentStatus.RejectApplication
            || enrmntStatusId == EnrollmentStatus.ReviewFail || enrmntStatusId == EnrollmentStatus.KYCAMLFailed) {
            setDisDelMenu(false);
        }
        else {
            setDisDelMenu(true);
        }

        if (enrmntStatusId == EnrollmentStatus.Complete || enrmntStatusId == EnrollmentStatus.InComplete) {
            setDisCancelMenu(false);
        }
        else {
            setDisCancelMenu(true);
        }

        if (enrmntStatusId == EnrollmentStatus.SubmitApplication || enrmntStatusId == EnrollmentStatus.UnderReview
            || enrmntStatusId == EnrollmentStatus.ReviewPass || enrmntStatusId == EnrollmentStatus.KYCAMLInitiated
            || enrmntStatusId == EnrollmentStatus.KYCAMLPassed || enrmntStatusId == EnrollmentStatus.DocuSignRequired
            || enrmntStatusId == EnrollmentStatus.DocuSignCompleted) {
            setDisReqCancelMenu(false);
        }
        else {
            setDisReqCancelMenu(true);
        }
    };
    const handleApplMoreMenuClose = () => {
        setApplMoreMenuAnchorEl(null);
    };

    const handleApplViewMenuClick = (event) => {
        setApplMoreMenuAnchorEl(null);
    }

    const handleDelMenuClick = (event) => {
        setApplMoreMenuAnchorEl(null);

        confirmDlgHdr.current = 'Delete Application';
        confirmDlgBLine1.current = "Do you want to delete the application named as '" + selectedEnrollmentNm.current + "' ?"
        setOpenConfirmDlg(true);
    }
    const handleConfirmDelDialogClose = async (nvalue, svalue) => {

        setErrorMsg('');
        setSuccessMsgTitle('');
        setSuccessMsg('');

        setOpenConfirmDlg(false);

        if (nvalue == null)
            return;

        try {

            setIsLoading(true);

            const apiUrl = new URL(
                process.env.REACT_APP_DELETE_ENROLLMENT,
                process.env.REACT_APP_BASE_URL,
            );

            const inputJson = {
                enrmntId: parseInt(selectedEnrollment.current),
            };

            //let response = await CallAPI({ url: apiUrl.href, method: 'POST', body: inputJson }, userId, sessionId);
            let response = await APICall({ url: apiUrl.href, method: 'POST', payload: inputJson, contentType: 'application/json', responseType: '' }, userId, sessionId);

            if (response !== null && response !== undefined) {

                let error = IsError(response);

                if (error != 0) {
                    setErrorMsg(response.Errors[0].Message);
                    return;
                }
                setSuccessMsgTitle("Application deleted");
                setSuccessMsg('Your application named \'' + selectedEnrollmentNm.current + '\' deleted successfully.');
                loadUserEnrollments();
            }
        }
        catch (err) {
            setErrorMsg(ErrorMessages.UnknownError);
        }
        finally {
            setIsLoading(false);
        }

        goToTop();
    };

    const handleCancelApplication = async (event) => {

        setApplMoreMenuAnchorEl(null);

        confirmDlgHdr.current = 'Application cancellation';
        confirmDlgBLine1.current = "Do you want to cancel the application named as '" + selectedEnrollmentNm.current + "' ?"

        setOpenCancelConfirmDlg(true);
    }

    const loadApplicantImage = async () => {

        const apiUrl = new URL(
            process.env.REACT_APP_GET_APPLICANT_IMAGE,
            process.env.REACT_APP_BASE_URL,
        );

        apiUrl.searchParams.set('enrmntId', `${parseInt(selectedEnrollment.current)}`);
        apiUrl.searchParams.set('tag', 'ApplicantImage');

        let imageURL = await GetApplicantImage(apiUrl.href, userId, sessionId);

        setImageUrl(imageURL);

    }

    const makeCancelRequest = async (event) => {

        setApplMoreMenuAnchorEl(null);

        confirmDlgHdr.current = 'Cancellation Request';
        confirmDlgBLine1.current = "Do you want to make a request to cancel the application named as '" + selectedEnrollmentNm.current + "' ?"

        setOpenReqCancelConfirmDlg(true);
    }

    const reqCancelConfirmDlgClose = async (nvalue, svalue) => {

        setErrorMsg('');
        setSuccessMsgTitle('');
        setSuccessMsg('');

        setOpenReqCancelConfirmDlg(false);

        if (nvalue == null)
            return;

        try {

            let apiUrl = new URL(
                process.env.REACT_APP_MAKE_ENROLMENT_CANCEL_REQUEST,
                process.env.REACT_APP_BASE_URL,
            );

            const inputJson = {
                enrmntId: parseInt(selectedEnrollment.current),
                enrmntStatusId: EnrollmentStatus.CancelApplication
            };

            setIsLoading(true);

            //let response = await CallAPI({ url: apiUrl.href, method: 'POST', headers: {}, body: inputJson }, userId, sessionId);
            let response = await APICall({ url: apiUrl.href, method: 'POST', payload: inputJson, contentType: 'application/json', responseType: '' }, userId, sessionId);

            if (response !== null && response !== undefined) {

                if (response.Errors.length > 0) {
                    setErrorMsg(response.Errors[0].Message);
                }
                else if (response.meta.Updated === "1") {
                    setSuccessMsgTitle('Request For Cancellation');
                    setSuccessMsg("You application cancellation request is received. You can track the status of your cancellation request in the following application list.");
                    loadUserEnrollments();
                }
            }
        }
        catch (err) {
            setErrorMsg(ErrorMessages.UnknownError);
        }
        finally {
            setIsLoading(false);
        }

        goToTop();
    };

    const handleViewApplication = async (event, enrmntId) => {

        try {

            selectedEnrollment.current = enrmntId;
            setIsLoading(true);

            setApplMoreMenuAnchorEl(null);

            let result = await loadEnrollment();
            await loadApplicantImage();

            if (result) {
                setShowAppViewDlg(true);
            }
        }
        catch (err) {
            console.error(err.message);
            setErrorMsg(ErrorMessages.UnknownError);
        }
        finally {
            setIsLoading(false);
        }
        goToTop();
    }

    const loadEnrollment = async () => {

        const apiUrl = new URL(
            process.env.REACT_APP_GET_ENROLLMENT_BY_ID,
            process.env.REACT_APP_BASE_URL,
        );
        apiUrl.searchParams.set('enrmntId', `${parseInt(selectedEnrollment.current)}`);

        let response = await APICall({ url: apiUrl.href, method: 'GET', contentType: 'application/json', responseType: '' }, userId, sessionId);

        if (response !== null && response !== undefined) {

            let error = IsError(response);

            if (error != 0) {
                setErrorMsg(response.Errors[0].Message);
                return false;
            }

            selectedEnrollmentData.current = response.data[0].enrmntData;
            selectedTemplateNm.current = response.data[0].templateNm;

            selectedOwnerNm.current = response.data[0].ownerName;
            selectedOwnerEmail.current = response.data[0].ownerEmail;
            selectedOwnerMobile.current = response.data[0].mobileNbr;

            return true;
        }

        return false;
    }

    const handleCancelConfirmDialogClose = async (nvalue, svalue) => {

        setErrorMsg('');
        setSuccessMsgTitle('');
        setSuccessMsg('');

        setOpenCancelConfirmDlg(false);

        if (nvalue == null)
            return;

        try {

            let apiUrl = new URL(
                process.env.REACT_APP_UPDATE_USER_ENROLMENT_STATUS,
                process.env.REACT_APP_BASE_URL,
            );

            const inputJson = {
                enrmntId: parseInt(selectedEnrollment.current),
                enrmntStatusId: EnrollmentStatus.CancelApplication
            };

            setIsLoading(true);

            //let response = await CallAPI({ url: apiUrl.href, method: 'POST', headers: {}, body: inputJson }, userId, sessionId);
            let response = await APICall({ url: apiUrl.href, method: 'POST', payload: inputJson, contentType: 'application/json', responseType: '' }, userId, sessionId);

            if (response !== null && response !== undefined) {

                if (response.Errors.length > 0) {
                    setErrorMsg(response.Errors[0].Message);
                }
                else if (response.meta.Updated === "1") {
                    setSuccessMsgTitle('Application cancelled');
                    setSuccessMsg("You application has been cancelled. We will not be moving your application forward.");
                    loadUserEnrollments();
                }
            }
        }
        catch (err) {
            setErrorMsg(ErrorMessages.UnknownError);
        }
        finally {
            setIsLoading(false);
        }

        goToTop();
    };
    //const handleMoreMenuClick = (event) => {
    //    setMoreMenuAnchorEl(event.currentTarget);
    //};

    const loadUserEnrollments = async () => {

        const apiUrl = new URL(
            process.env.REACT_APP_GET_ENROLLMENTS,
            process.env.REACT_APP_BASE_URL,
        );

        //let apiUrl = process.env.REACT_APP_GET_ENROLLMENTS;

        try {

            setIsLoading(true);

            //let response = await CallAPI({ url: apiUrl.href, method: 'GET', headers: {} }, userId, sessionId);
            let response = await APICall({ url: apiUrl.href, method: 'GET', contentType: 'application/json', responseType: '' }, userId, sessionId);

            if (response !== null && response !== undefined) {

                let enrollmentData = response["data"];
                let apiErrors = response["Errors"];

                if (apiErrors.length > 0)
                    setErrorMsg(apiErrors[0].Message);

                if (enrollmentData)
                    setAppCount(enrollmentData.length);

                //if (enrollmentData.length > 0)
                setData(enrollmentData)

                let docuSignStatus = enrollmentData.filter(el => {
                    return el['enrmntStatusId'] === EnrollmentStatus.DocuSignRequired;
                });

                if (docuSignStatus && docuSignStatus.length > 0) {
                    setAlertMsg('Your document signing is pending. Please complete your document signing process.');
                    setAlertSucStatus(false);
                }
            }
        }
        catch (err) {
            setErrorMsg(ErrorMessages.UnknownError);
        }
        finally {
            setIsLoading(false);
        }
        return;
    }

    const onPageClick = (e) => {
        if (typeof (e.target.text) !== "undefined") {
        }
    }

    //const delHandelClick = (enrmntId, templateId) => {
    //    let inputJson = {
    //        "enrmntId": enrmntId,
    //        "templateId": templateId,
    //        "isDeleted": true
    //    };

    //    let headers = {
    //        'Content-Type': 'application/json'
    //    };

    //    let userprompt = false;
    //    userprompt = window.confirm("Do you want to remove the application?");
    //    if (userprompt) {
    //        ApiHelper({
    //            url: process.env.REACT_APP_CREATE_MODIFY_ENROLLMENT_DATA, method: 'POST', headers: headers, body: inputJson, sessions: sessions, dispatch: dispatch
    //        })
    //            .then(resposnse => {
    //                if (resposnse !== null && resposnse != "undefined") {
    //                    alert('Application removed successfully.');
    //                    loadUserEnrollments();
    //                }
    //            });
    //    }
    //}


    const handleClick = (enrmntId, templateId, name, templateNm, ownerId) => {

        if (enrmntId !== null && enrmntId !== 'undefined') {
            setState({
                enrmntId: enrmntId,
                templateId: templateId,
                name: name,
                templateNm: templateNm,
                ownerId: ownerId
            })
            setModalTitle("Edit your case");
            setModalShow(true)
        }
    };

    const OpenReadOnly = (enrmntId, templateId, name, templateNm, ownerId, templatenm) => {
        history('/Enrollment', { state: { templateid: parseInt(templateId), name: name, enrmntid: enrmntId, ownerid: ownerId, templatenm: templatenm, appreadonly: true } })

    };
    const openAccountClick = (event) => {
        event.preventDefault();
        setState({
            enrmntId: 0,
            templateId: 0,
            name: null,
            templateNm: ''
        })
        setModalTitle("File a new case");
        setModalShow(true);
    };

    const signDocumentClick = async (enrmntid, attachmentid) => {

        const apiUrl = new URL(
            process.env.REACT_APP_GET_SIGNING_URL,
            process.env.REACT_APP_BASE_URL,
        );
        apiUrl.searchParams.set('enrmntId', `${parseInt(enrmntid)}`);
        apiUrl.searchParams.set('attchmentid', `${parseInt(attachmentid)}`);
        apiUrl.searchParams.set('signerUserId', `${parseInt(userId)}`);
        apiUrl.searchParams.set('signerName', loginUserName.current);
        apiUrl.searchParams.set('signerEmail', loginUserEmail.current);

        try {
            setIsLoading(true);

            //let response = await CallAPI({ url: apiUrl.href, method: 'GET', headers: {} }, userId, sessionId);
            let response = await APICall({ url: apiUrl.href, method: 'GET', contentType: 'application/json', responseType: '' }, userId, sessionId);

            if (response !== null && response !== undefined) {
                if (response.data.length > 0) {

                    let width = window.screen.width - 10;
                    let height = window.screen.height - 10;
                    window.open(response.data[0].SigningURL, "_self")
                    //let p = window.open(response.data[0].SigningURL, "_blank", "toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=" + width + ",height=" + height);
                    //p.onunload = function () { console.log('Child window closed'); };
                    //setPopWin(p);
                }
                else if (response.meta.Completed === "1")
                {
                    //await loadUserEnrollments();
                    setSigningDocData(null);
                    setOpenSnackBar(true);

                }
                else {
                    setErrorMsg(ErrorMessages.UnknownError);
                    goToTop();
                }
            }
        }
        catch (err) {
            setErrorMsg(ErrorMessages.UnknownError);
            goToTop();
        }
        finally {
            setIsLoading(false);
        }
    }

    const documenthandleClick = (enrmntId, enrmntStatusId) => {
        history('/Document', { state: { enrmntId: enrmntId, isProspect: true, loginUsrId: 0, loginEmail: '', loginUsrNm: '', applicationStatus: enrmntStatusId } })
    };

    const reviewhandleClick = (enrmntId, templateId, name, templateNm) => {
        if (enrmntId !== null && enrmntId !== 'undefined') {
            setState({
                enrmntId: enrmntId,
                templateId: templateId,
                name: name,
                templateNm: templateNm
            })
            setReviewModalShow(true);
        }
    };

    const handleDownloadClick = async (event) => {

        try {

            setIsLoading(true);
            setApplMoreMenuAnchorEl(null);

            let result = await loadEnrollment();
            await loadApplicantImage();

            let userInfo = {
                ApplicantsName: selectedOwnerNm.current,
                ApplicantEmail: selectedOwnerEmail.current,
                ApplicantsImg: imageUrl,
                AvtarImage: profimg,
                BankLogo: BLogo
            }

            let errorMsg = await ApplicationAllDocumentDownload(selectedEnrollment.current, userId, sessionId, userInfo);
            if (errorMsg !== '')
                setErrorMsg(errorMsg);
        }
        catch (err) {
            setErrorMsg(ErrorMessages.UnknownError);
            goToTop();
        }
        finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {

        //let timeZone = moment.tz.guess(Boolean);

        //timeZone = moment.tz.guess(Boolean);
        //if (timeZone == 'Asia/Calcutta')
        //    timeZone = 'Asia/Kolkata';

        //Object.keys(zones).forEach(z => {
        //    timeZoneToCountry[z] = countries[zones[z].countries[0]].name;
        //});


        //let country = countries[zones[timeZone].countries[0]].name

        //Object.keys(zones).forEach(z => {
        //    timeZoneToCountry[z] = countries[zones[z].countries[0]].name;
        //});

        //console.log(JSON.stringify(timeZoneToCountry, null, 2))
        //let x = window.navigator.userAgent;
        //let y = Intl.DateTimeFormat().resolvedOptions().timeZone;

        loginUserEmail.current = GetLoggedInUserEmail(userId, sessionId);
        loginUserName.current = GetLoggedInUserName(userId, sessionId);

        loadUserEnrollments();

    }, [openSnackBar]);

    const onReceiveDocuSignEvent = (e) => {

        loadUserEnrollments();
    }

    const handleAccordianChange = () => {
        if (expanded) {
            setExpanded(false);
        }
        else {
            setExpanded(true);
        }
    };
    const getApplInfoMsg = (enrmntStatusId) => {

        if (enrmntStatusId == EnrollmentStatus.InComplete) {
            return 'Complete and submit your claim';
        }

        if (enrmntStatusId == EnrollmentStatus.Complete) {
            return 'Submit your claim';
        }

        if (enrmntStatusId == EnrollmentStatus.ReviewFail) {
            return 'Review of your claim failed. See reason below:';
        }

        if (enrmntStatusId == EnrollmentStatus.KYCAMLInitiated) {
            return 'Conducting mandatory background check. The status of the application will change once done.';
        }

        if (enrmntStatusId == EnrollmentStatus.KYCAMLPassed) {
            return 'Mandatory background check completed.';
        }

        if (enrmntStatusId == EnrollmentStatus.KYCAMLFailed) {
            return 'Mandatory background check failed. See reason below:';
        }

        return 'Processing of your claim is in progress. Check the status of your claim here.';
    };

    const getApplInfoAlertVariant = (enrmntStatusId) => {

        if (enrmntStatusId == EnrollmentStatus.InComplete) {
            return 'info';
        }

        if (enrmntStatusId == EnrollmentStatus.ReviewFail || enrmntStatusId == EnrollmentStatus.KYCAMLFailed) {
            return 'error';
        }

        return 'info';
    };

    const getApplStatAlertVariant = (enrmntStatusId) => {

        if (enrmntStatusId == EnrollmentStatus.InComplete) {
            return 'info';
        }

        if (enrmntStatusId == EnrollmentStatus.ReviewFail || enrmntStatusId == EnrollmentStatus.KYCAMLFailed) {
            return 'error';
        }

        return 'info';
    };

    const getStatusTextColor = (enrmntStatusId) => {

        if (enrmntStatusId == EnrollmentStatus.ReviewFail || enrmntStatusId == EnrollmentStatus.KYCAMLFailed) {
            return '#ef5350';
        }

        return 'text.primary';
    };

    const IsDisplayReason = (enrmntStatusId) => {

        if (enrmntStatusId == EnrollmentStatus.ReviewFail || enrmntStatusId == EnrollmentStatus.KYCAMLFailed) {
            return 'block';
        }

        return 'none';
    };

    const GetSigningDocs = async (enrmntid) => {

        try {

            setIsLoading(true);

            const apiUrl = new URL(
                process.env.REACT_APP_GET_SIGNING_DOCS,
                process.env.REACT_APP_BASE_URL,
            );

            apiUrl.searchParams.set('enrmntId', `${parseInt(enrmntid)}`);

            //let response = await CallAPI({ url: apiUrl.href, method: 'GET', headers: {} }, userId, sessionId);
            let response = await APICall({ url: apiUrl.href, method: 'GET', contentType: 'application/json', responseType: '' }, userId, sessionId);

            if (response !== null && response !== undefined) {

                let error = IsError(response);

                if (error != 0) {
                    setErrorMsg(response.Errors[0].Message);
                    return;
                }

                setSigningDocData(response.data[0])
            }
        }
        catch (err) {
            setErrorMsg(ErrorMessages.UnknownError);
        }
        finally {
            setIsLoading(false);
        }
    }

    const downloadAttachment = async (enrmntid, attachmentId, fileNm) => {
        
        try {

            setIsLoading(true);

            const apiUrl = new URL(
                process.env.REACT_APP_DOWNLOAD_SIGNED_DOCUMENT,
                process.env.REACT_APP_BASE_URL,
            );
            apiUrl.searchParams.set('enrmntId', `${parseInt(enrmntid)}`);
            apiUrl.searchParams.set('attachmentId', `${parseInt(attachmentId)}`);
            apiUrl.searchParams.set('fname', fileNm);
            apiUrl.searchParams.set('tag', 'None');

            await GetAttachedFile(apiUrl.href, fileNm, userId, sessionId);
        }
        catch (ex) {
            //setErrorMsg(ErrorMessages.UnknownError);
        }
        finally {
            setIsLoading(false);
        }
    }

    const handleViewDocClick = async () => {

        setApplMoreMenuAnchorEl(null);
        setShowDocListDlg(true);
    };

    return (

        <React.Fragment>
            <Container maxWidth="xl" sx={{}}>
                <OverlaySpinner disappear={isLoading} />
                {/*<AutoLagoutTimer userId={userId} sessionId={sessionId} />*/}
                <PostLoginMenu userId={userId} sessionId={sessionId} />
                <Paper className=""
                    sx={{
                        position: 'relative',
                        backgroundColor: '#fff',
                        color: '#fff',
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        pb: 12
                    }}
                >
                    {/*<Grid container sx={{ height: '100%' }}>*/}
                        <Grid item>
                            <Box sx={{
                                pr: { xs: 3, md: 2 },
                                pl: { xs: 3, md: 2 }
                            }}>
                                <Accordion spacing={0} elevation={0} expanded={expanded} onChange={handleAccordianChange} sx={{ p: 0, m: 0, width: '100%' }}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header" sx={{ mr: 2 }}>
                                    </AccordionSummary>
                                    <AccordionDetails sx={{ pr: 2, pl: 0, pb: 0 }}>
                                        {/*<EnrollmentHeader />*/}
                                        <DrawerAppBar userId={userId} sessionId={sessionId} />
                                    </AccordionDetails>
                                </Accordion>
                            </Box>
                    </Grid>
                    <Grid item xs={12} sx={{ pt: 0 }}>
                        <Box sx={{ ml: 6, mr: 6, border: '0px solid #000', pt: 1 }}>
                            <Typography variant="h3" color="text.primary" sx={{ borderBottom: '1px solid rgba(0,0,0,0.1)', lineHeight: 2.2, fontWeight: 500, letterSpacing: '-1px', textAlign: 'center' }} >Register A Claim</Typography>
                        </Box>
                    </Grid>
                        <Grid item xs={12}>
                            <Box sx={{ pr: 4, pl: 4 }}>
                            <Grid item xs container direction="row" spacing={2} sx={{ pt: 0, pb: 0 }}>
                                <Grid item md={12} sx={{}} justifyContent="center">
                                    {/*<Box sx={{ pt: 0, pr: 2, ml: 10, mr: 10 }}>*/}
                                    {/*    <Typography variant="h3" color="text.primary" sx={{ borderBottom: '2px solid rgba(0,0,0,0.1)', lineHeight: 2.2, fontWeight: 500, letterSpacing: '-1px', textAlign: 'center' }} >Register A Claim</Typography>*/}
                                    {/*</Box>*/}
                                    <Box sx={{ mt: 6, pt: 0, pr: 2, ml: 10, mr: 10, border: '1px solid rgba(0,0,0,0.4)' }}>
                                        <Typography variant="h4" color="#424242" sx={{ lineHeight: 1.8, fontWeight: 500, letterSpacing: '-1px', textAlign: 'center' }} >Important Updates:</Typography>
                                        <Typography sx={{ pl: 10, pr: 8, mt: 1, pb: 3, fontWeight: 400, lineHeight: 1.3 }} variant="h6" color="text.secondary">
                                            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur unde suscipit, quam beatae rerum inventore consectetur, neque doloribus, cupiditate numquam dignissimos laborum fugiat deleniti? Eum quasi quidem quibusdam.
                                        </Typography>
                                        <Typography sx={{ pl: 10, pr: 8, mb: 4, fontWeight: 400, lineHeight: 1.3 }} variant="h6" color="text.secondary">
                                            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur unde suscipit, quam beatae rerum inventore consectetur, neque doloribus, cupiditate numquam dignissimos laborum fugiat deleniti? Eum quasi quidem quibusdam.
                                        </Typography>
                                        <Typography sx={{ pl: 10, pr: 8, mb: 4, fontWeight: 400, lineHeight: 1.3 }} variant="h6" color="text.secondary">
                                            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur unde suscipit, quam beatae rerum inventore consectetur, neque doloribus, cupiditate numquam dignissimos laborum fugiat deleniti? Eum quasi quidem quibusdam.
                                        </Typography>
                                    </Box>
                                </Grid>
                                    <Grid item md={12} sx={{ }} justifyContent="center">
                                        <Box sx={{ pt: 0, pr: 2, ml: 10, mr: 10 }}>
                                            
                                            <Typography sx={{ mt: 2, pb: 3, fontWeight: 400, lineHeight: 1.3 }} variant="h6" color="text.secondary">
                                                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur unde suscipit, quam beatae rerum inventore consectetur, neque doloribus, cupiditate numquam dignissimos laborum fugiat deleniti? Eum quasi quidem quibusdam.
                                            </Typography>
                                            <Typography sx={{ mb: 4, fontWeight: 400, lineHeight: 1.3 }} variant="h6" color="text.secondary">
                                                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur unde suscipit, quam beatae rerum inventore consectetur, neque doloribus, cupiditate numquam dignissimos laborum fugiat deleniti? Eum quasi quidem quibusdam.
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    {/*<Grid item md={5}>*/}
                                    {/*    <Grid container>*/}
                                    {/*        <Grid item md={12} sx={{ pl: 0, pt: 1, background: '#f4f4f4' }} >*/}
                                    {/*            <img src={pwdimg} height="420" loading="lazy" placeholdersrc={placeimg} />*/}
                                    {/*        </Grid>*/}
                                    {/*    </Grid>*/}
                                    {/*</Grid>*/}
                                </Grid>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Box sx={{ ml: { xs: 3, md: 14 }, mr: { xs: 3, md: 14 }, mt: 2, backgroundColor: '#f4f4f4', border: 1, borderColor: 'transparent'}}>
                                <Stack direction="row" alignItems="center" sx={{ pl: 3, pt: 2, pb: 2, fontWeight: 700,  }}>
                                    <Typography className="" variant="h4" sx={{ fontWeight: 300, pt: 0 }} color="#002d72">Take the next step</Typography>
                                <Button type="button" variant="contained" size="small" sx={{ padding: '0.7rem 1rem', ml: 3, textTransform: 'none', background: '#0972d3' }} onClick={(e) => openAccountClick(e)}>
                                    <Typography variant="body1">Register a new claim</Typography>
                                </Button>
                                </Stack>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sx={{ ml: { xs: 3, md: 14 }, mr: { xs: 3, md: 14 }, mt: 4 }}>
                            <Box>
                                <AlertControl Message={errorMsg} severity="error" color="error" icon={true} />
                                <AlertWithTitle Message={successMsg} variant="body1" severity="success" color="success" icon={true} Title={successMsgTitle} />
                            </Box>
                            {/*<Stack className="" direction="row" spacing={3} sx={{ ml: { xs: 3, md: 6 }, mr: { xs: 3, md: 6 }, pt: 3, pl: 1, borderBottom: 1, borderColor: 'divider', pb: 3}}>*/}
                            {/*<Badge badgeContent={appCount} color="success" size="medium" sx={{ml: 0, }}>*/}
                            {/*    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="48" height="48" stroke="#002d72" fill="#002d72">*/}
                            {/*        <path fill="#002d72" d="M3.5 3.75a.25.25 0 01.25-.25h13.5a.25.25 0 01.25.25v10a.75.75 0 001.5 0v-10A1.75 1.75 0 0017.25 2H3.75A1.75 1.75 0 002 3.75v16.5c0 .966.784 1.75 1.75 1.75h7a.75.75 0 000-1.5h-7a.25.25 0 01-.25-.25V3.75z"></path><path d="M6.25 7a.75.75 0 000 1.5h8.5a.75.75 0 000-1.5h-8.5zm-.75 4.75a.75.75 0 01.75-.75h4.5a.75.75 0 010 1.5h-4.5a.75.75 0 01-.75-.75zm16.28 4.53a.75.75 0 10-1.06-1.06l-4.97 4.97-1.97-1.97a.75.75 0 10-1.06 1.06l2.5 2.5a.75.75 0 001.06 0l5.5-5.5z"></path>*/}
                            {/*    </svg>*/}
                            {/*</Badge>*/}
                            <Typography variant="h5" sx={{ fontWeight: 500, pt: 2, textTransform: 'none', pb: 2 }} color="text.primary">Existing Claims</Typography>
                           {/* </Stack>*/}
                            <Divider sx={{ background: '#757575', pl: 7, pr: 7 }} />
                            {/*<hr className="hr-block bg-blue-500 mt-2" />*/}
                            <Typography variant="body1" color="text.secondary" sx={{ mt: 3, mb: 4, }}>
                                You have <strong>{data == null ? 0 : data.length}</strong> claim(s).
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                        <Grid item xs container direction="row" spacing={10} sx={{ pl: 14, pr: 14, pt: 0, }}>
                                {
                                    (data != null) ? data.map((item, index) => (
                                        <Grid item xs={12} md={6} key={index}>
                                            <Paper elevation={0} className="" sx={{ background: '#f4f4f4', borderRadius: 6, pl: 2, pb: 1, pr: 2}}>
                                            <Box>
                                                <Typography sx={{ pl: 4, pt: 6, pb: 0 }} variant="body1" color="text.secondary">Registered claim</Typography>
                                                <Box display="flex" justifyContent="space-between" alignItems="center" sx={{pr: 2}}>
                                                    <Link component="button" href="#" sx={{}} underline="hover" onClick={(e) => OpenReadOnly(item.enrmntId, item.templateId, item.name, item.templateNm, item.ownerId, item.templateNm)}>
                                                            <Typography variant="h5" color="text.secondary" sx={{ fontWeight: 400, pl: 4, mt: 0 }}>Claim No. {item.enrmntId}</Typography>
                                                    </Link>
                                                    <Tooltip arrow title="More options">
                                                        <span>
                                                                <IconButton onClick={(e) => handleApplMoreMenuClick(e, item.enrmntId, item.enrmntStatusId, item.name, item.templateNm)}>
                                                                <MoreHorizIcon sx={{ color: grey[600], fontSize: 48 }} />
                                                            </IconButton>
                                                        </span>
                                                    </Tooltip>
                                                </Box>
                                                {/*<Typography sx={{ pl: 4, pt: 0 }} variant="h6" color="text.secondary"><strong>Application name: </strong>{item.name}</Typography>*/}
                                                {/*<Typography sx={{ pl: 4, mt: 0 }} variant="h6" color="text.secondary"><strong>Owner: </strong>{item.ownerName}</Typography>*/}
                                                <Stack sx={{ mt: 0, pl: 4 }} direction="row" spacing={0} alignItems="center">
                                                    <AvTimerIcon />
                                                    <Typography className="" sx={{ pl: 1 }} variant="body2" color="text.secondary">
                                                        {item.lapse}
                                                    </Typography>
                                                </Stack>
                                                <Box sx={{ ml: 4, mr: 5, mt: 3 }}>
                                                    <Divider sx={{ background: '#9e9e9e' }} />
                                                    <MessageAlert sx={{pt: 1, pb: 1}} severity={getApplInfoAlertVariant(item.enrmntStatusId)}
                                                        Message={getApplInfoMsg(item.enrmntStatusId)} />
                                                    <Divider sx={{ background: '#9e9e9e' }} />
                                                </Box>
                                                <Box sx={{ ml: 4, mr: 5, mt: 2 }}>
                                                    <MessageAlert Message={item.stsReason} severity="error" color="error" />
                                                </Box>
                                                    <Stepper activeStep={GetActiveState1(item.enrmntStatusId)} sx={{ pl: 2, pr: 2, pt: 2 }}>
                                                        {steps.map((label, ind) => {
                                                            const stepProps = {};
                                                            const labelProps = {};
                                                            if (ind == 2 && item.enrmntStatusId == EnrollmentStatus.ReviewFail) {
                                                                labelProps.optional = (
                                                                    <Typography variant="caption" color="error">
                                                                        Failed
                                                                    </Typography>
                                                                );

                                                                labelProps.error = true;
                                                            }
                                                            return (
                                                                <Step key={ind} {...stepProps}>
                                                                    <StepLabel {...labelProps}>{label}</StepLabel>
                                                                </Step>
                                                            );
                                                        })}
                                                    </Stepper>
                                                    <Stepper activeStep={GetActiveState2(item.enrmntStatusId)} sx={{pl: 2, pr: 2, pt: 3, pb: 4}}>
                                                        {steps2.map((label, index) => {
                                                            const step2Props = {};
                                                            const label2Props = {};
                                                            if (index == 0 && item.enrmntStatusId == EnrollmentStatus.KYCAMLFailed) {
                                                                label2Props.optional = (
                                                                    <Typography variant="caption" color="error">
                                                                        Failed
                                                                    </Typography>
                                                                );

                                                                label2Props.error = true;
                                                            }
                                                            //stepProps.completed = true;
                                                            return (
                                                                <Step index={index+3} key={index} {...step2Props}>
                                                                    <StepLabel {...label2Props}>{label}</StepLabel>
                                                                </Step>
                                                            );
                                                        })}
                                                    </Stepper>
                                                <Typography sx={{ ml: 4, fontWeight: 400, mb: 2, mt: 0 }} variant="h6" color="text.secondary">Claim Status</Typography>
                                                    <Box sx={{ mb: 4, ml: 4, mr: 5 }}>
                                                        <Divider sx={{ background: '#757575' }} />
                                                        <Alert severity={getApplStatAlertVariant(item.enrmntStatusId)} color="warning" >
                                                            <Stack direction="row" alignItems="center">
                                                                <Typography className="caption" variant="body1" sx={{fontWeight: 600}} color={getStatusTextColor(item.enrmntStatusId)}>{item.enrmntStatus + (item.CancelRequest === true ? ' (Requested For Cancellation)' : '')}</Typography>
                                                            {
                                                                    item.enrmntStatusId == EnrollmentStatus.DocuSignRequired || item.enrmntStatusId == EnrollmentStatus.DocuSignCompleted
                                                                        ? <Link component="button" href="#" sx={{}} onClick={(e) => GetSigningDocs(item.enrmntId)}>
                                                                            <Typography variant="body1" sx={{ fontWeight: 400, pl: 4 }}>{item.enrmntStatusId == EnrollmentStatus.DocuSignRequired ? 'Sign document' : 'Signed document(s)'}</Typography>
                                                                    </Link>
                                                                    : ''
                                                            }
                                                            </Stack>
                                                        </Alert>

                                                        {
                                                            signingDocdata && signingDocdata.length > 0 && signingDocdata[0].EnrmntId == item.enrmntId && (item.enrmntStatusId == EnrollmentStatus.DocuSignRequired || EnrollmentStatus.DocuSignCompleted) ?
                                                                <Box sx={{ pt: 2 }}>
                                                                    <Table aria-label="simple table" sx={{ border: '1px solid #CCCCCC' }} elevation={0}>
                                                                        <TableBody>
                                                                            {
                                                                                signingDocdata.map((row) => (
                                                                                    <TableRow
                                                                                        key={row.AttchmntId}
                                                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                                                    >
                                                                                        <TableCell component="th" scope="row" sx={{width: '50%'}}>
                                                                                            <Link component="button" href="#" sx={{}} onClick={(e) => downloadAttachment(item.enrmntId, row.AttchmntId, row.FileName)}>
                                                                                                <Typography variant="body1" sx={{ fontWeight: 400, pl: 1 }}>{row.FileName}</Typography>
                                                                                            </Link>
                                                                                            {/*<Typography className="" variant="body2" color="text.primary">*/}
                                                                                            {/*    {row.FileName}*/}
                                                                                            {/*</Typography>*/}
                                                                                        </TableCell>
                                                                                        <TableCell component="th" scope="row" sx={{ width: '30%' }}>
                                                                                            <Typography className="" variant="body2" color="text.primary">
                                                                                                {row.SignStatus}
                                                                                            </Typography>
                                                                                        </TableCell>
                                                                                        <TableCell align="right" color="text.primary" sx={{ opacity: '0.7' }} sx={{ width: '20%' }}>
                                                                                            <Button variant="contained" disabled={row.IsSignCompleted} size="small" sx={{ width: '100%', padding: '0.2rem 1rem', }} onClick={(e) => signDocumentClick(row.EnrmntId, row.AttchmntId)}>
                                                                                                <Typography variant="body2" sx={{ textTransform: 'none' }} >Sign</Typography>
                                                                                            </Button>
                                                                                        </TableCell>
                                                                                    </TableRow>
                                                                                ))}
                                                                        </TableBody>
                                                                    </Table>
                                                                </Box>
                                                                : ''
                                                        }
                                                        <Divider sx={{ background: '#757575' }} />
                                                    </Box>
                                                    <Stack sx={{ mt: 5, ml: 4, mr: 5, mb: 5 }} direction="row" spacing={2} color="primary">
                                                        <Button variant="outlined" size="small" startIcon={<EditIcon />} sx={{ pr: 1, fontSize: '1rem', color: '#212121', padding: '0.5rem 3rem', border: 2, borderColor: '#616161', fontWeight: 500, textTransform: 'none' }} disabled={item.enrmntStatusId == EnrollmentStatus.InComplete || item.enrmntStatusId == EnrollmentStatus.Complete || item.enrmntStatusId == EnrollmentStatus.ReviewFail ? false : true} onClick={() => handleClick(item.enrmntId, item.templateId, item.name, item.templateNm, item.ownerId)} >
                                                            Edit
                                                        </Button>
                                                        <Button variant="outlined" size="small" startIcon={<DescriptionIcon />} sx={{ fontSize: '1rem', color: '#212121', padding: '0.5rem 3rem', border: 2, borderColor: '#616161', fontWeight: 500, textTransform: 'none' }} onClick={(event) => handleViewApplication(event, item.enrmntId)}>
                                                            View
                                                        </Button>

                                                        {/*<Button variant="outlined" startIcon={<EditIcon />} sx={{ fontWeight: '700', fontSize: '1.125rem', borderWidth: 2, borderColor: '#0076d4', padding: '0.5rem 3rem', textTransform: 'none' }} onClick={() => handleClick(item.enrmntId, item.templateId, item.name, item.templateNm, item.ownerId)} disabled={item.enrmntStatusId == EnrollmentStatus.InComplete || item.enrmntStatusId == EnrollmentStatus.Complete || item.enrmntStatusId == EnrollmentStatus.ReviewFail ? false : true}>Edit</Button>*/}
                                                        {/*<Button variant="outlined" startIcon={<DescriptionIcon />} sx={{ fontWeight: '700', borderWidth: 2, borderColor: '#0076d4', padding: '0.5rem 3rem', textTransform: 'none' }} onClick={(event) => handleViewApplication(event, item.enrmntId)}>View</Button>*/}
                                                    </Stack>

                                                </Box>
                                            </Paper>
                                        </Grid>
                                    )) : null
                                }
                                <Menu 
                                    id="basic-menu"
                                    anchorEl={applMoreMenuAnchorEl}
                                    open={openApplMoreMenu}
                                    onClose={handleApplMoreMenuClose}
                                    MenuListProps={{
                                        'aria-labelledby': 'basic-button',
                                    }}
                                >
                                    {/*<MenuItem sx={{ ml: 2, mt: 1, mb: 1 }} onClick={(event) => handleViewApplication(event)}>*/}
                                    {/*    <ListItemIcon sx={{}}>*/}
                                    {/*        <DescriptionIcon width="24px" height="24px" color="#ccc" />*/}
                                    {/*    </ListItemIcon>*/}
                                    {/*    <Typography className="me-5" variant="body2" sx={{ textTransform: 'none' }} color="text.primary">View</Typography>*/}
                                    {/*</MenuItem>*/}
                                    {/*<Divider />*/}
                                    <MenuItem sx={{ ml: 2 }} onClick={(event) => handleDownloadClick(event)}>
                                        <ListItemIcon>
                                        <DownloadIcon width="24px" height="24px" color="#ccc" />
                                        </ListItemIcon>
                                        <Typography className="me-5" variant="body2" sx={{ textTransform: 'none' }} color="text.primary">Download</Typography>
                                    </MenuItem>
                                    <Divider />
                                    <MenuItem sx={{ ml: 2 }} onClick={() => handleViewDocClick()}>
                                        <ListItemIcon>
                                        <FileCopyOutlinedIcon width="24px" height="24px" color="#ccc" />
                                        </ListItemIcon>
                                        <Typography className="me-5" variant="body2" sx={{ textTransform: 'none' }} color="text.primary">Attached Documents</Typography>
                                    </MenuItem>
                                    <Divider />
                                    <MenuItem sx={{ ml: 2 }} disabled={disCancelMenu} onClick={(event) => handleCancelApplication(event)}>
                                        <ListItemIcon>
                                        <CancelPresentationIcon width="24px" height="24px" color="#ccc" />
                                        </ListItemIcon>
                                        <Typography className="me-5" variant="body2" sx={{ textTransform: 'none' }} color="text.primary">Cancel</Typography>
                                    </MenuItem>
                                    <MenuItem sx={{ ml: 2 }} disabled={disReqCancelMenu} onClick={(event) => makeCancelRequest(event)}>
                                        <ListItemIcon>
                                            <ReplyOutlinedIcon width="24px" height="24px" color="#ccc" />
                                        </ListItemIcon>
                                        <Typography className="me-5" variant="body2" sx={{ textTransform: 'none' }} color="text.primary">Request for cancellation</Typography>
                                    </MenuItem>
                                    <Divider />
                                    <MenuItem sx={{ ml: 2 }} disabled={disDelMenu} onClick={(event) => handleDelMenuClick(event)}>
                                        <ListItemIcon>
                                        <DeleteForeverIcon width="24px" height="24px" color="#ccc" />
                                        </ListItemIcon>
                                        <Typography className="me-5" variant="body2" sx={{ textTransform: 'none' }} color="text.primary">Delete</Typography>
                                    </MenuItem>
                                </Menu>
                            <ConfirmDialog
                                keepMounted
                                open={openConfirmDlg}
                                onClose={handleConfirmDelDialogClose}
                                alertheader={confirmDlgHdr.current}
                                bmsgline1={confirmDlgBLine1.current}
                                nvalue={selectedEnrollment.current} // {delFileControl.current}
                                svalue={selectedEnrollmentNm.current}
                            />
                            <ConfirmDialog
                                keepMounted
                                open={openCancelConfirmDlg}
                                onClose={handleCancelConfirmDialogClose}
                                alertheader={confirmDlgHdr.current}
                                bmsgline1={confirmDlgBLine1.current}
                                nvalue={selectedEnrollment.current}
                                svalue={selectedEnrollmentNm.current}
                            />
                            <ConfirmDialog
                                keepMounted
                                open={openReqCancelConfirmDlg}
                                onClose={reqCancelConfirmDlgClose}
                                alertheader={confirmDlgHdr.current}
                                bmsgline1={confirmDlgBLine1.current}
                                nvalue={selectedEnrollment.current}
                                svalue={selectedEnrollmentNm.current}
                            />
                            </Grid>
                        </Grid>
                    {/*</Grid>*/}
                </Paper>
                {
                    openSnackBar ? 
                    <Snackbar
                        open={openSnackBar}
                        autoHideDuration={6000}
                        onClose={handleSnackBarClose}
                        message="Note archived"
                        action={snackBarAction}
                        >
                            <SnackAlert onClose={handleSnackBarClose} severity="success" sx={{ width: '100%' }}>
                                Document signing is completed.
                            </SnackAlert>
                    </Snackbar>
                    : null
                }
                {
                    modalShow ?
                        <AddEditEnrollment
                            key={state.enrmntId}
                            show={modalShow}
                            backdrop="static"
                            keyboard={false}
                            onHide={() => setModalShow(false)}
                            title={modalTitle}
                            templateid={state.templateId}
                            enrmntid={state.enrmntId}
                            name={state.name}
                            templatenm={state.templateNm}
                            userId={userId}
                            sessionId={sessionId}
                        />
                        : <div />
                }
                {showAppViewDlg ?
                    <AppViewDlg open={showAppViewDlg} onClose={closeAppViewDlg} enrollData={selectedEnrollmentData.current} enrmntid={selectedEnrollment.current} acctType={selectedTemplateNm.current} avtarImage={profimg} applicantImage={imageUrl} applicantName={selectedOwnerNm.current} applicantEmail={selectedOwnerEmail.current} applicantMobile={selectedOwnerMobile.current} userId={userId} sessionId={sessionId} />
                    : null
                }
                {showDocListDlg ?
                    <ProspectDocListDlg open={showDocListDlg} onClose={closeDocListDlg} enrmntid={selectedEnrollment.current} ownerName={selectedOwnerNm.current} enrolNm={selectedEnrollmentNm.current} templateNm={selectedTemplateNm.current} userId={userId} sessionId={sessionId} />
                    : null
                }
                <Footer />
            </Container>
        </React.Fragment>
    );
}

function ProspectDashboardBreadcrumb() {
    return (
        <React.Fragment >
            <Box sx={{ borderLeft: '4px solid #9e9e9e', mt: 0, pt: 0, pb: 2, pl: 1, backgroundColor: '#fff', borderTop: '0px solid #dee2e6' }}>
                <Typography variant="h4" sx={{ fontWeight: 400, lineHeight: 1.1, pl: 2, textTransform: 'none' }} color="text.primary">File a Case</Typography>
            </Box>
        </React.Fragment>
    );
}

export default ProspectDashboard;