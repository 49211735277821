import React, { useState, useEffect } from 'react';
import { CallAPI, IsError } from '../global/APIUtils';
import OverlaySpinner from './Controls/OverlaySpinner';
import { Grid, Box, Typography, Link, Stack, Switch, Divider, FormControlLabel, FormControl, Checkbox, IconButton } from '@mui/material';
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';
import HealthAndSafetyOutlinedIcon from '@mui/icons-material/HealthAndSafetyOutlined';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import AodIcon from '@mui/icons-material/Aod';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import ErrorIcon from '@mui/icons-material/Error';
import RecoveryEmailDlg from '../dialogs/RecoveryEmailDlg';
import TwoStepVerifyConfigDlg from '../dialogs/TwoStepVerifyConfigDlg';
import { APICall } from '../global/Api';
import AlertControl from '../components/Controls/AlertControl';
import { ErrorMessages } from '../common/ErrorMessages';

const ProfilePreferences = (props) => {

    const [isLoading, setIsLoading] = useState(false);
    const [showRecoveryEmailDlg, setShowRecoveryEmailDlg] = useState(false);
    const [showTwoStepConfigDlg, setShowTwoStepConfigDlg] = useState(false);
    const [twoStepStatus, setTwoStepStatus] = useState('Off');
    const [recoveryEmail, setRecoveryEmail] = useState('Add an email address');
    const [errorMsg, setErrorMsg] = useState('');

    const [userId, setUserId] = useState(props.userId);
    const [sessionId, setSessionId] = useState(props.sessionId);
    const [email, setEmail] = useState(props.emailadrs);

    //const [checked, setChecked] = React.useState(true);

    //const handleChange = (evt) => {
    //    setChecked(evt.target.checked);
    //};

    const closeRecoveryEmailDlg = () => {
        setShowRecoveryEmailDlg(false);
    }

    const closeTwoStepConfigDlg = () => {
        setShowTwoStepConfigDlg(false);
    }

    const onEmailAddHandle = async () => {
        loadForm();
    }

    const onTwoStepConfigChange = async () => {
        loadForm();
    }

    const handleAddRecoveryEmail = () => {
        setShowRecoveryEmailDlg(true);
    }

    const handleTwoStepClick = async () => {
        setShowTwoStepConfigDlg(true);
    }

    const loadForm = async () => {

        const apiUrl = new URL(
            process.env.REACT_APP_GET_USER_PREFERENCE,
            process.env.REACT_APP_BASE_URL,
        );

        try {

            setIsLoading(true);

            let response = await APICall({ url: apiUrl.href, method: 'GET', contentType: 'application/json', responseType: '' }, userId, sessionId);

            if (response !== null && response !== undefined) {
                if (response.data.length > 0) {
                    if (response.data[0].IsTwoStepVerifySet) {
                        setTwoStepStatus('On');
                    }
                    else {
                        setTwoStepStatus('Off');
                    }
                    setRecoveryEmail(response.data[0].RecoveryEmail);
                }
            }
        }
        catch (err) {
            setErrorMsg(ErrorMessages.UnknownError);
        }
        finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        loadForm();
    }, []);

    return (
        <React.Fragment>
            <OverlaySpinner disappear={isLoading} />
            <Grid item xs container direction="row" sx={{ borderLeft: 1, borderColor: 'divider', pl: 7, pr: 4 }}>
                <Grid item md={9} sx={{ pr: 10, }} justifyContent="center">
                    <Typography gutterBottom variant="h5" color="text.secondary" sx={{ mr: 5, lineHeight: 2.5, fontWeight: 500, borderBottom: '1px solid rgba(0,0,0,0.1)' }}>
                        Your Preferences
                    </Typography>
                    <Typography variant="body1" color="text.secondary" sx={{ mt: 4, mb: 2, fontWeight: 500 }}>
                        Settings of your account.
                    </Typography>

                    {/*<Typography sx={{ mb: 4, borderBottom: 1, borderColor: '#e0e0e0', pb: 3 }} variant="body1" color="text.secondary">*/}
                    {/*    Settings of your account*/}
                    {/*</Typography>*/}
                    <AlertControl Message={errorMsg} severity="error" color="error" icon={true} />
                </Grid>
                {/*<Grid item md={3}>*/}
                {/*    <Grid container>*/}
                {/*        <Grid item md={10} sx={{}} >*/}
                {/*            <span className="">*/}
                {/*                <svg viewBox="0 0 500 500" width="128" height="128">*/}
                {/*                    <title></title>*/}
                {/*                    <g id="Layer_1" dataname="Layer 1">*/}
                {/*                        <rect x="57.97" y="55.07" width="21.4" height="388.55" fill="#3fe2fd"></rect>*/}
                {/*                        <path d="M223,189.09s-61.38,42.23-40,103,109.81,50.12,109.81,50.12l-30.41,12.67,2.53,24.49-38,.85-3.38-25.34-22.8-8.45-20.28,15.21L156,337.19l10.13-18.58-3.37-25.34-22.81-1.69-5.07-38,20.27-5.07,11.83-22.81L155.1,213l26.19-35.47L199,195.28Z" fill="#3fe2fd"></path>*/}
                {/*                    </g>*/}
                {/*                    <g id="Layer_5" dataname="Layer 5">*/}
                {/*                        <path d="M447.5,448.52a7,7,0,0,0,7-7V57.69c0-6-4.49-10.9-10-10.9H55.5c-5.51,0-10,4.89-10,10.9V441.52a7,7,0,0,0,7,7Zm-7-387.73V114H59.5V60.79ZM59.5,434.52V128h381V434.52Z" fill="#251ac7"></path>*/}
                {/*                        <path d="M357.77,242.78h-19.1a96,96,0,0,0-6.88-17.47L345,212.06a7,7,0,0,0,0-9.9l-30.28-30.28a7,7,0,0,0-9.9,0l-12.67,12.66a96.42,96.42,0,0,0-18.05-7.64V159.15a7,7,0,0,0-7-7H224.31a7,7,0,0,0-7,7V176.9a95.33,95.33,0,0,0-18.07,7.65l-12.67-12.67a7,7,0,0,0-9.9,0l-30.28,30.28a7,7,0,0,0,0,9.9l13.26,13.25a96.15,96.15,0,0,0-6.89,17.47h-19.1a7,7,0,0,0-7,7v42.83a7,7,0,0,0,7,7h20.27a96.51,96.51,0,0,0,7,16.16l-14.58,14.57a7,7,0,0,0,0,9.9l30.28,30.28a7,7,0,0,0,9.9,0l15.17-15.16a95.27,95.27,0,0,0,15.57,6.32v21.57a7,7,0,0,0,7,7h42.83a7,7,0,0,0,7-7V361.68a95.89,95.89,0,0,0,15.56-6.32l15.16,15.16a7,7,0,0,0,9.9,0L345,340.24a7,7,0,0,0,0-9.9l-14.58-14.57a95.8,95.8,0,0,0,7-16.16h20.27a7,7,0,0,0,7-7V249.78A7,7,0,0,0,357.77,242.78Zm-7,42.83H332.29a7,7,0,0,0-6.76,5.19,82.24,82.24,0,0,1-9.77,22.36,7,7,0,0,0,1,8.68l13.45,13.45-20.38,20.38-13.93-13.92a7,7,0,0,0-8.49-1.09,81.75,81.75,0,0,1-21.92,8.89,7,7,0,0,0-5.33,6.8v19.9H231.31v-19.9a7,7,0,0,0-5.34-6.8A81.62,81.62,0,0,1,204,340.66a7,7,0,0,0-8.49,1.09l-13.93,13.92-20.38-20.38,13.46-13.45a7,7,0,0,0,1-8.67,82.08,82.08,0,0,1-9.78-22.37,7,7,0,0,0-6.76-5.19H140.66V256.78h17.55A7,7,0,0,0,165,251.3a82.05,82.05,0,0,1,9.31-23.69,7,7,0,0,0-1.09-8.49l-12-12,20.38-20.38,11.55,11.54a7,7,0,0,0,8.67,1A82.17,82.17,0,0,1,226,189a7,7,0,0,0,5.34-6.8V166.15h28.83v16.08a7,7,0,0,0,5.33,6.8,81.89,81.89,0,0,1,24.12,10.22,7,7,0,0,0,8.67-1l11.55-11.54,20.38,20.38-12,12a7,7,0,0,0-1.09,8.49,81.63,81.63,0,0,1,9.3,23.69,7,7,0,0,0,6.84,5.48h17.54Z" fill="#251ac7"></path><path d="M245.72,213.92A54.3,54.3,0,1,0,300,268.21,54.35,54.35,0,0,0,245.72,213.92Zm0,94.59A40.3,40.3,0,1,1,286,268.21,40.34,40.34,0,0,1,245.72,308.51Z" fill="#251ac7"></path><path d="M65.18,88.2a21.6,21.6,0,1,0,21.6-21.6A21.62,21.62,0,0,0,65.18,88.2Zm29.2,0a7.6,7.6,0,1,1-7.6-7.6A7.61,7.61,0,0,1,94.38,88.2Z" fill="#251ac7"></path>*/}
                {/*                    </g>*/}
                {/*                </svg>*/}
                {/*            </span>*/}
                {/*        </Grid>*/}
                {/*    </Grid>                    */}
                {/*</Grid>*/}
                <Grid item xs={11} sx={{ pr: 10, pt: 2,  }} justifyContent="center">
                    <Box sx={{ borderRadius: 4, border: 1, borderColor: 'rgba(0,0,0,0.1)', pl: 4, pr: 4, pt: 2, pb: 6 }}>
                        <Typography gutterBottom variant="h5" color="text.secondary" sx={{fontWeight: 500, pt: 2, mb: 1}}>
                            Options
                        </Typography>
                        <Typography sx={{ mb: 4 }} variant="h6" color="text.secondary">
                            See and manage changes made to how you sign in to your account.
                        </Typography>
                        <Grid container>

                            {/*<Grid item md={5} sx={{}} >*/}
                            {/*    <Stack spacing={1} direction="row">*/}
                            {/*        <PlaceOutlinedIcon color="action" sx={{ fontSize: 24 }} />*/}
                            {/*        <Typography gutterBottom variant="body1" color="text.secondary" sx={{}}>*/}
                            {/*            Location History*/}
                            {/*        </Typography>*/}
                            {/*    </Stack>*/}
                            {/*</Grid>*/}
                            {/*<Grid item md={5} sx={{}} >*/}
                            {/*    <Typography gutterBottom variant="body1" color="text.secondary" sx={{}}>*/}
                            {/*        Off*/}
                            {/*    </Typography>*/}
                            {/*</Grid>*/}
                            {/*<Grid item container justifyContent="flex-end" md={2} sx={{}}>*/}
                            {/*    <Switch />*/}
                            {/*</Grid>*/}
                            {/*<Grid item md={12} sx={{pt: 1, pb: 2}} >*/}
                            {/*    <Divider component="div" sx={{ background: '#eeeeee' }} />*/}
                            {/*</Grid>*/}
                            <Grid item md={5} sx={{}} >
                                <Stack spacing={1} direction="row">
                                    <HealthAndSafetyOutlinedIcon color="action" sx={{ fontSize: 24 }} />
                                    <Typography gutterBottom variant="body1" color="text.secondary" sx={{}}>
                                        2-Step Verification
                                    </Typography>
                                </Stack>
                            </Grid>
                            <Grid item md={5} sx={{}} >
                                <Typography gutterBottom variant="body1" color="text.secondary" sx={{}}>
                                    {twoStepStatus}
                                </Typography>
                            </Grid>
                            <Grid item container justifyContent="flex-end" md={2} sx={{}}>
                                <IconButton size="small" sx={{ border: 0, borderColor: '#9e9e9e', borderRadius: '5px', mb: 1}} onClick={() => handleTwoStepClick()}>
                                    <ArrowForwardIosOutlinedIcon color="action" sx={{ fontSize: 24, mr: 1}} />
                                </IconButton>                               
                                {/*<Switch sx={{}} checked={checked} onChange={handleChange} />*/}
                            </Grid>
                            <Grid item md={12} sx={{ pt: 0, pb: 2, pl: 4 }} >
                                <Typography gutterBottom variant="body1" color="text.secondary" sx={{}}>
                                    Code will be sent to your email address
                                </Typography>
                            </Grid>
                            <Grid item md={12} sx={{ pt: 1, pb: 2 }} >
                                <Divider component="div" sx={{ background: '#eeeeee' }} />
                            </Grid>
                            {/*<Grid item md={5} sx={{}} >*/}
                            {/*    <Stack spacing={1} direction="row">*/}
                            {/*        <AodIcon color="action" sx={{ fontSize: 24 }} />*/}
                            {/*        <Typography gutterBottom variant="body1" color="text.secondary" sx={{}}>*/}
                            {/*            Recovery phone*/}
                            {/*        </Typography>*/}
                            {/*    </Stack>*/}
                            {/*</Grid>*/}
                            {/*<Grid item md={5} sx={{}} >*/}
                            {/*    <Typography gutterBottom variant="body1" color="text.secondary" sx={{}}>*/}
                            {/*        123-456-7890*/}
                            {/*    </Typography>*/}
                            {/*</Grid>*/}
                            {/*<Grid item container justifyContent="flex-end" md={2} sx={{}}>*/}
                            {/*    <ArrowForwardIosOutlinedIcon color="action" sx={{ fontSize: 24, mr: 2 }} />*/}
                            {/*</Grid>*/}
                            {/*<Grid item md={12} sx={{ pt: 1, pb: 2 }} >*/}
                            {/*    <Divider component="div" sx={{ background: '#eeeeee' }} />*/}
                            {/*</Grid>*/}
                            <Grid item md={5} sx={{}} >
                                <Stack spacing={1} direction="row">
                                    <MailOutlineIcon color="action" sx={{ fontSize: 24 }} />
                                    <Typography gutterBottom variant="body1" color="text.secondary" sx={{}}>
                                        Recovery email
                                    </Typography>
                                </Stack>
                            </Grid>
                            <Grid item md={5} sx={{}} >
                                <Stack spacing={1} direction="row">
                                    <ErrorIcon color="action" sx={{ fontSize: 24, color: '#f57c00' }} />
                                    <Link underline="hover" variant="body2" sx={{}} color="text.secondary" onClick={() => handleAddRecoveryEmail()}>
                                        {recoveryEmail}
                                    </Link>
                                </Stack>
                            </Grid>
                            <Grid item container justifyContent="flex-end" md={2} sx={{}}>
                                <IconButton size="small" sx={{ border: 0, borderColor: '#9e9e9e', borderRadius: '5px', mb: 1 }} onClick={() => handleAddRecoveryEmail()}>
                                    <ArrowForwardIosOutlinedIcon color="action" sx={{ fontSize: 24, mr: 1 }} />
                                </IconButton>
                                {/*<ArrowForwardIosOutlinedIcon color="action" sx={{ fontSize: 24, mr: 2 }} />*/}
                            </Grid>
                            <Grid item md={12} sx={{ pt: 1, pb: 2 }} >
                                <Divider component="div" />
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
            </Grid>

            {showRecoveryEmailDlg ?
                <RecoveryEmailDlg open={showRecoveryEmailDlg} onClose={closeRecoveryEmailDlg} onChange={onEmailAddHandle} emailadrs={email} userId={userId} sessionId={sessionId} />
                : null
            }

            {showTwoStepConfigDlg ?
                <TwoStepVerifyConfigDlg open={showTwoStepConfigDlg} onClose={closeTwoStepConfigDlg} onChange={onTwoStepConfigChange} userId={userId} sessionId={sessionId} />
                : null
            }
        </React.Fragment>
    );
}

export default ProfilePreferences;