import React from 'react';
//import { useNavigate } from "react-router-dom";
//import '../assets/css/bootstrap.min.css';
//import '../assets/css/AOBStyle.css';
import { Typography, Paper, Grid, Link, Stack, Box, Divider } from '@mui/material';

export function Footer(props) {

    return (
        <React.Fragment>

            {/*<Paper className=""*/}
            {/*    sx={{*/}
            {/*        color: '#002d72', borderTop: 1, borderColor: '#7d8998',*/}
            {/*    }}*/}
            {/*>*/}
            {/*    <Grid container sx={{ pl: 10, pr: 10, pt: 6, pb: 6}}>*/}
            {/*        <Grid item md={3} sx={{pl: 0}}>*/}
            {/*            <Typography component="h2" variant="h6" color="inherit" sx={{ mb: 0, pb: 1, }}>*/}
            {/*                <strong>About Us</strong>*/}
            {/*            </Typography>*/}
            {/*            <Typography className="w-75" component="h2" variant="body2" color="inherit" sx={{ mb: 1, pb: 0, pt: 0 }}>*/}
            {/*                Careers*/}
            {/*            </Typography>*/}
            {/*            <Typography className="w-75" component="h2" variant="body2" color="inherit" sx={{ mb: 1, pb: 0, pt: 0 }}>*/}
            {/*                Investor Relations*/}
            {/*            </Typography>*/}
            {/*        </Grid>*/}
            {/*        <Grid item md={3} sx={{ pl: 0 }}>*/}
            {/*            <Typography component="h2" variant="h6" color="inherit" sx={{ mb: 0, pb: 1, }}>*/}
            {/*                <strong>Onboarding Help</strong>*/}
            {/*            </Typography>*/}
            {/*            <Typography className="w-75" component="h2" variant="body2" color="inherit" sx={{ mb: 1, pb: 0, pt: 0 }}>*/}
            {/*                Knowledge Center*/}
            {/*            </Typography>*/}
            {/*            <Typography className="w-75" component="h2" variant="body2" color="inherit" sx={{ mb: 1, pb: 0, pt: 0 }}>*/}
            {/*                FAQs*/}
            {/*            </Typography>*/}
            {/*        </Grid>*/}
            {/*        <Grid item md={3} sx={{ pl: 0 }}>*/}
            {/*            <Typography component="h2" variant="h6" color="inherit" sx={{ mb: 0, pb: 1, }}>*/}
            {/*                <strong>Security</strong>*/}
            {/*            </Typography>*/}
            {/*            <Typography className="w-75" component="h2" variant="body2" color="inherit" sx={{ mb: 1, pb: 0, pt: 0 }}>*/}
            {/*                Security & Privacy*/}
            {/*            </Typography>*/}
            {/*        </Grid>*/}
            {/*        <Grid item md={3} sx={{}}>*/}
            {/*            <Typography component="h2" variant="h6" color="inherit" sx={{ mb: 0, pb: 1, }}>*/}
            {/*                <strong>Connect with Us</strong>*/}
            {/*            </Typography>*/}
            {/*            <Typography className="w-75" component="h2" variant="body2" color="inherit" sx={{ mb: 1, pb: 0, pt: 0 }}>*/}
            {/*                (800) 277-2175*/}
            {/*            </Typography>*/}
            {/*            <Typography className="w-75" component="h2" variant="body2" color="inherit" sx={{ mb: 1, pb: 0, pt: 0 }}>*/}
            {/*                Contact Us*/}
            {/*            </Typography>*/}
            {/*            <div className="d-inline-flex flex-wrap">*/}
            {/*                <a href="#" className="p-1 text-white" aria-label="facebook">*/}
            {/*                    <svg viewBox="0 0 24 24" fill="#002d72" width="20" height="20">*/}
            {/*                        <path d="M14 13.5h2.5l1-4H14v-2c0-1.03 0-2 2-2h1.5V2.14c-.326-.043-1.557-.14-2.857-.14C11.928 2 10 3.657 10 6.7v2.8H7v4h3V22h4v-8.5z" />*/}
            {/*                    </svg>*/}
            {/*                </a>*/}
            {/*                <a href="#" className="p-1 text-white" aria-label="instagram">*/}
            {/*                    <svg viewBox="0 0 24 24" fill="#002d72" width="20" height="20">*/}
            {/*                        <path d="M12 2c2.717 0 3.056.01 4.122.06 1.065.05 1.79.217 2.428.465.66.254 1.216.598 1.772 1.153a4.908 4.908 0 0 1 1.153 1.772c.247.637.415 1.363.465 2.428.047 1.066.06 1.405.06 4.122 0 2.717-.01 3.056-.06 4.122-.05 1.065-.218 1.79-.465 2.428a4.883 4.883 0 0 1-1.153 1.772 4.915 4.915 0 0 1-1.772 1.153c-.637.247-1.363.415-2.428.465-1.066.047-1.405.06-4.122.06-2.717 0-3.056-.01-4.122-.06-1.065-.05-1.79-.218-2.428-.465a4.89 4.89 0 0 1-1.772-1.153 4.904 4.904 0 0 1-1.153-1.772c-.248-.637-.415-1.363-.465-2.428C2.013 15.056 2 14.717 2 12c0-2.717.01-3.056.06-4.122.05-1.066.217-1.79.465-2.428a4.88 4.88 0 0 1 1.153-1.772A4.897 4.897 0 0 1 5.45 2.525c.638-.248 1.362-.415 2.428-.465C8.944 2.013 9.283 2 12 2zm0 5a5 5 0 1 0 0 10 5 5 0 0 0 0-10zm6.5-.25a1.25 1.25 0 0 0-2.5 0 1.25 1.25 0 0 0 2.5 0zM12 9a3 3 0 1 1 0 6 3 3 0 0 1 0-6z" />*/}
            {/*                    </svg>*/}
            {/*                </a>*/}
            {/*                <a href="#" className="p-1 text-white" aria-label="linkedin">*/}
            {/*                    <svg viewBox="0 0 24 24" fill="#002d72" width="20" height="20">*/}
            {/*                        <path d="M6.94 5a2 2 0 1 1-4-.002 2 2 0 0 1 4 .002zM7 8.48H3V21h4V8.48zm6.32 0H9.34V21h3.94v-6.57c0-3.66 4.77-4 4.77 0V21H22v-7.93c0-6.17-7.06-5.94-8.72-2.91l.04-1.68z" />*/}
            {/*                    </svg>*/}
            {/*                </a>*/}
            {/*                <a href="#" className="p-1 text-white" aria-label="twitter">*/}
            {/*                    <svg viewBox="0 0 24 24" fill="#002d72" width="20" height="20">*/}
            {/*                        <path d="M22.162 5.656a8.384 8.384 0 0 1-2.402.658A4.196 4.196 0 0 0 21.6 4c-.82.488-1.719.83-2.656 1.015a4.182 4.182 0 0 0-7.126 3.814 11.874 11.874 0 0 1-8.62-4.37 4.168 4.168 0 0 0-.566 2.103c0 1.45.738 2.731 1.86 3.481a4.168 4.168 0 0 1-1.894-.523v.052a4.185 4.185 0 0 0 3.355 4.101 4.21 4.21 0 0 1-1.89.072A4.185 4.185 0 0 0 7.97 16.65a8.394 8.394 0 0 1-6.191 1.732 11.83 11.83 0 0 0 6.41 1.88c7.693 0 11.9-6.373 11.9-11.9 0-.18-.005-.362-.013-.54a8.496 8.496 0 0 0 2.087-2.165z" />*/}
            {/*                    </svg>*/}
            {/*                </a>*/}
            {/*            </div>*/}
            {/*        </Grid>*/}
            {/*    </Grid>*/}
            {/*</Paper>*/}
            <Paper className="" elevation={0}
                sx={{
                    backgroundColor: '#fff',
                    color: '#fff',
                    mt: 1,
                    borderTop: '1px solid rgba(0,0,0,.2)'
                }}
            >
                {/*<Box sx={{ height: '150px', background: '#f4f4f4'}}>*/}
                {/*</Box>*/}
                <Grid sx={{ pt: 4, pb: 10 }} container>
                    <Grid item sx={{ pl: 6 }}>
                        <Stack direction="row">
                            <Stack direction="column">
                                <Stack direction="row">
                                    <Typography variant="body2" color="#002d72" sx={{ mb: 0, pb: 0, mr: 1 }}>
                                        Consumer Privacy Notice
                                    </Typography>
                                    <div className="vertical-line" sx={{ height: '15px'}}></div>
                                    <Typography variant="body2" color="#002d72" sx={{ mb: 0, pb: 0, ml: 1, mr: 1}}>
                                        Online Privacy Policy
                                    </Typography>
                                    <div className="vertical-line" sx={{ height: '15px' }}></div>
                                    <Typography variant="body2" color="#002d72" sx={{ mb: 0, pb: 0, ml: 1 }}>
                                        Terms of Use
                                    </Typography>
                                </Stack>
                                <Typography variant="caption" color="text.secondary" sx={{ mt: 1, mb: 0, pb: 0, ml: 0 }}>
                                    © 2023 Demo Bank, NY.
                                </Typography>
                            </Stack>
                        </Stack>
                    </Grid>
                </Grid>
            </Paper>
        </React.Fragment >
    );
}