import React, { useState, useEffect } from 'react';
import { ErrorMessage, useFormik } from 'formik';
import { useNavigate } from "react-router-dom";
import moment from "moment";
import * as yup from 'yup';
//import { ApiHelper } from '../customFunctions/apiHelper';
import { PreLoginMenu } from './PreLoginMenu';
//import { ModalMessage } from './ModalMessage';
//import AlertText from './Controls/AlertText';
import { Footer } from './Footer';
import regimg from '../assets/images/bg_2.jpg';
//import banklogo from '../assets/images/ssb-horizontal-new.svg';
//import { ReactComponent as ReactLogo } from '../assets/images/ssb-horizontal-new.svg';
//import ldrimg from '../assets/images/loader.gif';
import LoaderControl from './Controls/LoaderControl';
import { Typography, Link, Stack, Toolbar, Box, MenuItem, FormControl, InputLabel, TextField, FormControlLabel, Checkbox, Alert, AlertTitle } from '@mui/material';
import Button from '@mui/material/Button';
//import ImageLoader from 'react-loading-image';
import { CallServerAPI, IsError } from '../global/APIUtils';
import { ErrorMessages } from '../common/ErrorMessages';
import AlertControl from './Controls/AlertControl';
import AlertWithTitle from './Controls/AlertWithTitle';
import Container from '@mui/material/Container';
import { Paper } from '@mui/material';
import Grid from '@mui/material/Grid';
//import Image from 'material-ui-image'
import Select from '@mui/material/Select';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import OverlaySpinner from './Controls/OverlaySpinner';
import dayjs from 'dayjs';
import { goToTop } from '../global/Helpers'

const UserRegistration = () => {
    const [submit, setSubmit] = useState(false);
    const [regSuccessStatus, setRegSuccessStatus] = useState(false);
    const [regMsg, setRegMsg] = useState('');
    const [errorMsg, setErrorMsg] = useState('');
    const [titleData, setTitleData] = useState(null);
    const [gender, setGender] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [DOBValue, setDOBValue] = React.useState(null);
    const [successMsg, setSuccessMsg] = useState('');

    const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
    const pwdRegExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/;
    let navigate = useNavigate();

    const today = dayjs();

    const registrationForm = useFormik({
        initialValues: {
            FirstName: '',
            LastName: '',
            Email: '',
            Phone: '',
            Mobile: '',
            Password: '',
            ConfirmPassword: '',
            chkConfirm: false,
            Title:  "-1",
            Gender: "-1",
            DOB: ''
        },
        validationSchema: yup.object({
            FirstName: yup.string()
                .max(80, "First Name - must be a string with a maximum length of 40")
                .trim('First Name - cannot contain leading and trailing spaces')
                .required('First Name - required'),
            LastName: yup.string()
                .max(80, "Last Name - must be a string with a maximum length of 40")
                .trim('Last Name - cannot contain leading and trailing spaces')
                .required('Last Name - required'),
            Email: yup.string()
                .max(80, "Email - must be a string with a maximum length of 80")
                .email('Email Address - Invalid email address')
                .required('Email Address - required'),
            Phone: yup.string()
                .max(24, "Phone Number - length too long"),
            Mobile: yup.string()
                .max(24, "Mobile Number - length too long")
                .required('Mobile Number - required'),
            Password: yup.string()
                .max(20, "Password - weak")
                .min(10, "Password - weak")
                .matches(pwdRegExp,
                    "Password - weak"
                )
                .required('Password - required'),
            ConfirmPassword: yup.string()
                .max(20, "Password - weak")
                .min(10, "Password - weak")
                .matches(pwdRegExp,
                    "Password - weak"
                )
                .when("Password", {
                    is: psswrd => (psswrd && psswrd.length > 0 ? true : false),
                    then: yup.string().oneOf(
                        [yup.ref("Password")],
                        "Both password need to be the same")
                })
                .required('Confirm Password - required'),
            Title: yup.number(),
            Gender: yup.string(),
            DOB: yup.date()
                .nullable()
                .required("Date Of Birth - required"),
            //    .max(today, "Date Of Birth that you entered is invalid")
            //    .min("01/01/1901", "Date is too early"),
            chkConfirm: yup.boolean()
                .oneOf([true], 'Please confirm all the information is accurate')
        }),
        onSubmit: values => {
            submitClick(values);
        }
    });

    const submitClick = async (values) => {
        setSubmit(true); //Disabled
        setErrorMsg('');
        setSuccessMsg('');

        var birthDate = moment(values.DOB, 'YYYY-MM-DD');

        let regModel = {
            FirstName: values.FirstName,
            LastName: values.LastName,
            EmailAddress: values.Email,
            PhoneNbr: values.Phone,
            MobileNbr: values.Mobile,
            Password: values.Password,
            CPassword: values.ConfirmPassword,
            Title: parseInt(values.Title),
            GenderId: parseInt(values.Gender),
            DOB: birthDate.format('MM/DD/YYYY')
        };

        let headers = {
            'Content-Type': 'application/json'
        };

        try {

            setIsLoading(true);

            const apiUrl = new URL(
                process.env.REACT_APP_USER_REGISTER,
                process.env.REACT_APP_BASE_URL,
            );

            //let endpoint = process.env.REACT_APP_BASE_URL + process.env.REACT_APP_USER_REGISTER;

            let response = await CallServerAPI({ url: apiUrl.href, method: 'POST', headers: headers, body: regModel });

            if (response != null && response != undefined) {

                let error = IsError(response);

                if (error != 0) {
                    setErrorMsg(response.Errors[0].Message);
                }
                else {
                    if (response.meta.EmailSent === "Yes") {
                        setSuccessMsg('A verification mail has been sent to ' + values.Email + '. Please click on the link provided in the email to complete your registration. This may take upto 10 minutes to arrive.');
                        registrationForm.resetForm();
                    }
                    else {
                        setErrorMsg(ErrorMessages.UnknownError);
                    }
                }

                goToTop();

            }
        }
        catch (err) {
            setErrorMsg(ErrorMessages.UnknownError);
            goToTop();
        }
        finally {
            setSubmit(false);//Enabled
            setIsLoading(false);
        }
    }

    const loadForm = async () => {

        try {

            setIsLoading(true);

            const apiUrl = new URL(
                process.env.REACT_APP_USER_TITLES,
                process.env.REACT_APP_BASE_URL,
            );

            //let endpoint = process.env.REACT_APP_BASE_URL + process.env.REACT_APP_USER_TITLES;
            let response = await CallServerAPI({ url: apiUrl.href, method: 'GET', headers: {} });

            if (response !== undefined && response !== null) {

                if (response.data.length == 0) {
                    setErrorMsg(ErrorMessages.UnknownError);
                }

                setTitleData(response["data"]);
            }
            else {
                setErrorMsg(ErrorMessages.UnknownError);
            }

        }
        catch (err) {
            setErrorMsg(ErrorMessages.UnknownError);
        }
        finally {
            setIsLoading(false);
        }
    };

    const login = (event) => {
        event.preventDefault();
        navigate('/AppLogin');
    }

    useEffect(() => {
        loadForm();
    }, []);

    return (
        <React.Fragment>
            <OverlaySpinner disappear={isLoading} />
            <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Container maxWidth="xl">
                <OverlaySpinner disappear={isLoading} />
                <PreLoginMenu />
                <Paper 
                    sx={{
                        position: 'relative',
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        pb: 10,
                    }}
                    >
                        <Box
                            sx={{
                                position: 'absolute',
                                top: 0,
                                bottom: 0,
                                right: 0,
                                left: 0,
                                backgroundColor: 'rgba(238, 238, 238, 0.08)',
                            }}
                        />
                        <Grid sx={{ pb: 5, pt: 2 }} container>
                            <Grid item justifyContent="center">
                                <Box sx={{ ml: 6, mr: 6, border: '0px solid #000', pt: 1 }}>
                                    <Typography variant="h3" color="text.primary" sx={{ borderBottom: '1px solid rgba(0,0,0,0.1)', lineHeight: 2.2, fontWeight: 500, letterSpacing: '-1px', textAlign: 'center' }} >Registration</Typography>
                                </Box>
                                <Stack spacing={1} direction="row" sx={{ mb: 3, ml: 20, mr: 20, mt: 5 }}>
                                    <Typography variant="h6" color="text.secondary">If you already have an account, you can <Link className="" href="#" underline="hover" variant="body1" color="text.info" sx={{ fontWeight: 700, color: '#002d72' }}>Log in</Link> using your account.</Typography>
                                </Stack>
                                <Typography variant="h6" color="text.secondary" sx={{ mb: 2, fontWeight: 500, ml: 20, mr: 20, lineHeight: 1.3 }}>
                                    Please enter the account information below. The email address you enter on this form must be a valid address that is owned by you.
                                    We’ll use your email address to send you a verification email.
                                </Typography>
                                
                                <Box sx={{ background: '#f9f9f9', p: 2, ml: 20, mr: 20, mt: 3, border: '1px solid #eaeded' }}>
                                    <Typography variant="h6" color="text.secondary" sx={{ mb: 0, fontWeight: 400, lineHeight: 1.3 }}>
                                        <strong>Note:</strong> Once you have created an account, you cannot change your username or email address. In case you need to change your email or user name, please do not hesitate to contact us.
                                    </Typography>
                                </Box>
                                    <form onSubmit={registrationForm.handleSubmit} id="frmSignup">
                                    <Box sx={{ ml: 20, mr: 20 }}>
                                        <AlertControl Message={errorMsg} severity="error" color="error" icon={true} />
                                        <AlertWithTitle Message={successMsg} severity="success" color="success" icon={true} Title="Email verification" />
                                        <Typography variant="body2" display="block" color="text.secondary" sx={{ textAlign: 'right', fontStyle: 'italic', mb: 0, pt: 1 }}>
                                            * indiactes a required field
                                        </Typography>
                                        <Grid container sm={9} spacing={2}>
                                                <Grid item sm={8} sx={{}}>
                                                <Paper elevation={0}>
                                                    <label htmlFor="title" className="form-label">Title</label>
                                                        <select id="title" className="form-select" name="Title" value={registrationForm.values.Title} onChange={registrationForm.handleChange} placeholder="Title">
                                                            <option key={-1} value={-1}>Select</option>
                                                            {
                                                                titleData && titleData.map((e) => (
                                                                    <option key={e.TitleId} value={e.TitleId}>{e.TitleName}</option>
                                                                ))
                                                            }
                                                        </select>
                                                        {
                                                            registrationForm.touched.Title && registrationForm.errors.Title ? (<p className="text-danger">{registrationForm.errors.Title}</p>) : null
                                                        }

                                                </Paper>
                                            </Grid>
                                                <Grid item sm={6} sx={{}} >
                                                <Paper elevation={0}>
                                                    <label htmlFor="FirstName" className="form-label">First Name*</label>
                                                    <input type="text" id="FirstName" name="FirstName" placeholder="Enter your first name here" className="form-control" value={registrationForm.values.FirstName} onChange={registrationForm.handleChange} />
                                                    {
                                                        registrationForm.touched.FirstName && registrationForm.errors.FirstName ? (<p className="text-danger">{registrationForm.errors.FirstName}</p>) : null
                                                    }
                                                </Paper>
                                            </Grid>
                                            <Grid item sm={6} sx={{}} justifyContent="flex-end">
                                                <Paper elevation={0}>
                                                    <label htmlFor="LastName" className="form-label">Last Name*</label>
                                                    <input type="text" id="LastName" name="LastName" placeholder="Enter your last name here" className="form-control" value={registrationForm.values.LastName} onChange={registrationForm.handleChange} />
                                                    {
                                                        registrationForm.touched.LastName && registrationForm.errors.LastName ? (<p className="text-danger">{registrationForm.errors.LastName}</p>) : null
                                                    }
                                                </Paper>
                                            </Grid>
                                            <Grid item sm={6} sx={{}} >
                                                <Paper elevation={0}>
                                                    <label htmlFor="Gender" className="form-label">Gender</label>
                                                    <select className="form-select" id="Gender" name="Gender" value={registrationForm.values.Gender} placeholder={'Gender *'} onChange={registrationForm.handleChange} >
                                                        <option key={-1} value={-1}>Select</option>
                                                        <option value="70">Male</option>
                                                        <option value="71">Female</option>
                                                        <option value="74">Other</option>
                                                    </select>
                                                    {
                                                        registrationForm.touched.Gender && registrationForm.errors.Gender ? (<p className="text-danger">{registrationForm.errors.Gender}</p>) : null
                                                    }
                                                </Paper>
                                            </Grid>
                                            <Grid item sm={6} sx={{}} >
                                                <Paper elevation={0}>
                                                    <label htmlFor="DOB" className="form-label">Date Of Birth*</label>
                                                    <input type="date" min="1900-01-01" max={today.format('YYYY-MM-DD')} className="form-control" id="DOB" name="DOB" width="0" value={registrationForm.values.DOB} onChange={registrationForm.handleChange} />
                                                    {
                                                        registrationForm.touched.DOB && registrationForm.errors.DOB ? (<p className="text-danger">{registrationForm.errors.DOB}</p>) : null
                                                    }
                                                </Paper>
                                            </Grid>
                                            <Grid item sm={6} sx={{}} >
                                                <Paper elevation={0}>
                                                    <label htmlFor="Mobile" className="form-label">Mobile Number*</label>
                                                    <input type="text" name="Mobile" id="Mobile" placeholder="Enter your mobile number here" className="form-control" value={registrationForm.values.Mobile} onChange={registrationForm.handleChange} />
                                                    {
                                                        registrationForm.touched.Mobile && registrationForm.errors.Mobile ? (<p className="text-danger">{registrationForm.errors.Mobile}</p>) : null
                                                    }
                                                </Paper>
                                            </Grid>
                                            <Grid item sm={6} sx={{}} >
                                                <Paper elevation={0}>
                                                    <label htmlFor="Phone" className="form-label">Phone Number</label>
                                                    <input type="tel" id="Phone" name="Phone" placeholder="Enter your phone number here" className="form-control" value={registrationForm.values.Phone} onChange={registrationForm.handleChange} />
                                                    {
                                                        registrationForm.touched.Phone && registrationForm.errors.Phone ? (<p className="text-danger">{registrationForm.errors.Phone}</p>) : null
                                                    }
                                                </Paper>
                                            </Grid>
                                            <Grid item sm={12} sx={{}} >
                                                <Paper elevation={0}>
                                                    <label htmlFor="Email" className="form-label">Email Address*</label>
                                                    <input type="email" id="Email" name="Email" placeholder="Enter your email here" className="form-control" value={registrationForm.values.Email} onChange={registrationForm.handleChange} />
                                                    {
                                                        registrationForm.touched.Email && registrationForm.errors.Email ? (<p className="text-danger">{registrationForm.errors.Email}</p>) : null
                                                    }
                                                </Paper>
                                            </Grid>
                                            <Grid item sm={12} sx={{}} >
                                                <Paper elevation={0}>
                                                    <label htmlFor="Password" className="form-label">Password*</label>
                                                    <input type="password" id="Password" name="Password" placeholder="Enter password" className="form-control" value={registrationForm.values.Password} onChange={registrationForm.handleChange} />
                                                    {
                                                        registrationForm.touched.Password && registrationForm.errors.Password ? (<p className="text-danger">{registrationForm.errors.Password}</p>) : null
                                                    }
                                                </Paper>
                                            </Grid>
                                            <Grid item sm={12} sx={{}} >
                                                <Paper elevation={0}>
                                                    <label htmlFor="ConfirmPassword" className="form-label">Password*</label>
                                                    <input type="password" id="ConfirmPassword" name="ConfirmPassword" placeholder="Re-enter password *" className="form-control" value={registrationForm.values.ConfirmPassword} onChange={registrationForm.handleChange} />
                                                    {
                                                        registrationForm.touched.ConfirmPassword && registrationForm.errors.ConfirmPassword ? (<p className="text-danger">{registrationForm.errors.ConfirmPassword}</p>) : null
                                                    }
                                                </Paper>
                                            </Grid>
                                            <Grid item sm={12} sx={{}} >
                                                <FormControl variant="outlined" sx={{ width: '100%' }}>
                                                    <FormControlLabel control={<Checkbox name="chkConfirm" id="chkConfirm" checked={registrationForm.values.chkConfirm} onChange={registrationForm.handleChange} />} label="I confirm all the info is accurate" />
                                                </FormControl>
                                                {
                                                    registrationForm.touched.chkConfirm && registrationForm.errors.chkConfirm ? (<p className="text-danger">{registrationForm.errors.chkConfirm}</p>) : null
                                                }
                                            </Grid>
                                            <Grid item sm={12} sx={{}} >
                                                <Box sx={{pt: 1}}>
                                                    <Button type="submit" variant="outlined" size="small" sx={{ padding: '0.4rem 2.5rem', border: 2, borderColor: '#616161' }}>
                                                        <Typography variant="body2" color="#212121" sx={{ textTransform: 'none', fontWeight: 500 }} >Register</Typography>
                                                    </Button>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </form>
                            </Grid>
                            <Grid item md={2}>
                            </Grid>
                    </Grid>
                </Paper>
                <Footer />
                </Container>
            </LocalizationProvider>
        </React.Fragment>
    );
}

export default UserRegistration;