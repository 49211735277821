import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from "react-router-dom";
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import MailRoundedIcon from '@mui/icons-material/MailRounded';
import Badge from '@mui/material/Badge';
import DescriptionRoundedIcon from '@mui/icons-material/DescriptionRounded';
import ConstructionRoundedIcon from '@mui/icons-material/ConstructionRounded';
import ExpandCircleDownOutlinedIcon from '@mui/icons-material/ExpandCircleDownOutlined';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import PersonAdd from '@mui/icons-material/PersonAdd';
import Settings from '@mui/icons-material/Settings';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import DashboardCustomizeOutlinedIcon from '@mui/icons-material/DashboardCustomizeOutlined';
import PeopleOutlinedIcon from '@mui/icons-material/PeopleOutlined';
import SettingsSuggestOutlinedIcon from '@mui/icons-material/SettingsSuggestOutlined';
import MessageOutlinedIcon from '@mui/icons-material/MessageOutlined';
import SummarizeOutlinedIcon from '@mui/icons-material/SummarizeOutlined';
import RecentActorsOutlinedIcon from '@mui/icons-material/RecentActorsOutlined';
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';
import TravelExploreOutlinedIcon from '@mui/icons-material/TravelExploreOutlined';
import { AOBSystemProcess } from '../common/AOBEnum';
import { GetAccessControl, IsLoggedInUserProspectUser } from '../global/APIUtils';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { GetLoggedInUserId, GetLoggedInUserEmail, GetLoggedInUserName } from '../global/APIUtils';
import productlogo from '../assets/images/Settlement4.jpg';
//import Avatar from '@mui/material/Avatar';
import { deepOrange, deepPurple } from '@mui/material/colors';
import Tooltip from '@mui/material/Tooltip';
import Logout from '@mui/icons-material/Logout';
import ListAltIcon from '@mui/icons-material/ListAlt';

const drawerWidth = 240;
const navItems = ['Home', 'About', 'Contact'];

export function DrawerAppBar(props) {

    const x = 'inline-block';

    const [prospectDashboardMenuDisp, setProspectDashboardMenuDisp] = useState('none');
    const [applDashboardMenuDisp, setApplDashboardMenuDisp] = useState('none');
    const [templateMenuDisp, setTemplateMenuDisp] = useState('none');
    const [FAQMenuDisp, setFAQMenuDisp] = useState('none');
    const [keyDatesMenuDisp, setKeyDatesMenuDisp] = useState('none');
    const [contactUsMenuDisp, setContactUsMenuDisp] = useState('none');

    const [disUsersMenu, setDisUsersMenu] = useState(true);
    const [disRolesMenu, setDisRolesMenu] = useState(true);
    const [disActLogMenu, setDisActLogMenu] = useState(true);
    const [disSysOptMenu, setDisSysOptMenu] = useState(true);
    const [disSysMsgMenu, setDisSysMsgMenu] = useState(true);
    const [disAuditTrailRptMenu, setDisAuditTrailRptMenu] = useState(true);
    const [disErrLogMenu, setDisErrLogMenu] = useState(true);
    const [disTraceLogMenu, setDisTraceLogMenu] = useState(true);
    const [moreMenuDisp, setMoreMenuDisp] = useState('none');

    const [userId, setUserId] = useState((!props.userId || props.userId == -1) ? (GetLoggedInUserId() != null && GetLoggedInUserId() != undefined) ? GetLoggedInUserId() : -1 : props.userId );
    const [sessionId, setSessionId] = useState(props.sessionId);
    const email = useRef(null);
    const userName = useRef(null);

    const { window } = props;
    const [mobileOpen, setMobileOpen] = React.useState(false);

    const [accountAnchorEl, setAccountAnchorEl] = React.useState(null);
    const openAccountMenu = Boolean(accountAnchorEl);
    const handleAccountMenuClick = (event) => {
        setAccountAnchorEl(event.currentTarget);
    };
    const handleAccountMenuClose = () => {
        setAccountAnchorEl(null);
    };

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleUsersMenuClick = () => {
        navigate('/UserManagement', { state: { userId: userId, sessionId: sessionId }, replace: true });
        setAnchorEl(null);
    };
    const handleRolesMenuClick = () => {
        navigate('/RoleManagement', { state: { userId: userId, sessionId: sessionId }, replace: true });
        setAnchorEl(null);
    };

    const handleSystemOptionsMenuClick = () => {
        navigate('/SystemParameters1', { state: { userId: userId, sessionId: sessionId }, replace: true });
        setAnchorEl(null);
    };

    let navigate = useNavigate();

    const handleDrawerToggle = () => {
        setMobileOpen((prevState) => !prevState);
    };

    //let sessions = useSelector((state) => state.sessionMgmnt);

    useEffect(() => {

        if (!userId || userId == -1) {
            navigate('/AppLogin', { state: {}, replace: true });
            return;
        }

        email.current = GetLoggedInUserEmail(userId);
        email.current = email.current.length > 25 ? email.current.substring(0, 25) + '...' : email.current;

        userName.current = GetLoggedInUserName(userId);
        userName.current = userName.current.length > 25 ? userName.current.substring(0, 25) + '...' : userName.current;

        let accessControl = GetAccessControl(userId, sessionId);
        let isProspectUser = IsLoggedInUserProspectUser(userId, sessionId);

        let accessControlArray = accessControl.split("|");

        SetProspectDashboardMenuDisplay(accessControlArray, isProspectUser);
        SetApplDashboardMenuDisplay(accessControlArray, isProspectUser);
        SetTemplateMenuDisplay(accessControlArray, isProspectUser);
        SetUsersMenuDisplay(accessControlArray, isProspectUser);
        SetRoleMenuDisplay(accessControlArray, isProspectUser);
        SetActivityLogMenuDisplay(accessControlArray, isProspectUser);
        SetSystemOptionMenuDisplay(accessControlArray, isProspectUser);
        SetSystemMessageMenuDisplay(accessControlArray, isProspectUser);
        SetAuditTrailRptMenuDisplay(accessControlArray, isProspectUser);
        SetErrorLogMenuDisplay(accessControlArray, isProspectUser);
        SetTraceLogMenuDisplay(accessControlArray, isProspectUser);
        SetMoreMenuDisplay(accessControlArray, isProspectUser);
        SetFAQMenuDisplay(isProspectUser);
        SetKeyDatesMenuDisplay(isProspectUser);
        SetContactUsMenuDisplay(isProspectUser);

    }, []);

    const SetFAQMenuDisplay = (isProspectUser) => {

        if (isProspectUser)
            setFAQMenuDisp('inline-block');
        else
            setFAQMenuDisp('none');
    }

    const SetKeyDatesMenuDisplay = (isProspectUser) => {

        //if (isProspectUser)
            setKeyDatesMenuDisp('inline-block');
        //else
        //    setKeyDatesMenuDisp('none');
    }

    const SetContactUsMenuDisplay = (isProspectUser) => {

        if (isProspectUser)
            setContactUsMenuDisp('inline-block');
        else
            setContactUsMenuDisp('none');
    }

    const SetProspectDashboardMenuDisplay = (accessControlArray, isProspectUser) => {

        if (isProspectUser)
            setProspectDashboardMenuDisp('inline-block');
        else
            setProspectDashboardMenuDisp('none');
    }

    const SetApplDashboardMenuDisplay = (accessControlArray, isProspectUser) => {

        if (isProspectUser) {
            setApplDashboardMenuDisp('none');
            return;
        }

        const found = accessControlArray.filter(element => element == AOBSystemProcess.ApplicationMaintenance
            || element == AOBSystemProcess.ViewApplication || element == AOBSystemProcess.ExportApplication
            || element == AOBSystemProcess.ApproveApplication || element == AOBSystemProcess.PublishApplication
            || element == AOBSystemProcess.DocuSignDocUpload || element == AOBSystemProcess.AllProcess);

        if (found !== undefined && found.length > 0)
            setApplDashboardMenuDisp('inline-block');
        else
            setApplDashboardMenuDisp('none');
    }

    const SetTemplateMenuDisplay = (accessControlArray, isProspectUser) => {

        if (isProspectUser) {
            setTemplateMenuDisp('none');
            return;
        }

        const found = accessControlArray.filter(element => element == AOBSystemProcess.TemplateMaintenance || AOBSystemProcess.PublishTemplate);

        if (found !== undefined && found.length > 0)
            setTemplateMenuDisp('inline-block');
        else
            setTemplateMenuDisp('none');
    }

    const SetUsersMenuDisplay = (accessControlArray, isProspectUser) => {

        const found = accessControlArray.filter(element => element == AOBSystemProcess.UserMaintenance);

        if (found !== undefined && found.length > 0)
            setDisUsersMenu(false);
        else
            setDisUsersMenu(true);
    }

    const SetRoleMenuDisplay = (accessControlArray, isProspectUser) => {

        const found = accessControlArray.filter(element => element == AOBSystemProcess.RoleMaintenance);

        if (found !== undefined && found.length > 0)
            setDisRolesMenu(false);
        else
            setDisRolesMenu(true);
    }

    const SetActivityLogMenuDisplay = (accessControlArray, isProspectUser) => {

        //const found = accessControlArray.filter(element => element == AOBSystemProcess.ViewActivityLog);

        //if (found !== undefined && found.length > 0) {
        //    setDisActLogMenu(false);
        //}
        //else {
        //    setDisActLogMenu(true);
        //}
    }

    const SetSystemOptionMenuDisplay = (accessControlArray, isProspectUser) => {
        const found = accessControlArray.filter(element => element == AOBSystemProcess.SystemParamMaintenance || element == AOBSystemProcess.AllProcess);
        //if (found !== undefined && found.length > 0)
        //    setDisSysOptMenu(false);
        //else
        //    setDisSysOptMenu(true);
    }

    const SetSystemMessageMenuDisplay = (accessControlArray, isProspectUser) => {

        //const found = accessControlArray.filter(element => element == AOBSystemProcess.SystemMessage);

        //if (found !== undefined && found.length > 0)
        //    setDisSysMsgMenu(false);
        //else
        //    setDisSysMsgMenu(true);
    }

    const SetAuditTrailRptMenuDisplay = (accessControlArray, isProspectUser) => {

        //const found = accessControlArray.filter(element => element == AOBSystemProcess.ViewAuditTrailReport);

        //if (found !== undefined && found.length > 0)
        //    setDisAuditTrailRptMenu(false);
        //else
        //    setDisAuditTrailRptMenu(true);
    }

    const SetErrorLogMenuDisplay = (accessControlArray, isProspectUser) => {

        //const found = accessControlArray.filter(element => element == AOBSystemProcess.ViewErrorLog);

        //if (found !== undefined && found.length > 0)
        //    setDisErrLogMenu(false);
        //else
        //    setDisErrLogMenu(true);
    }

    const SetTraceLogMenuDisplay = (accessControlArray, isProspectUser) => {

        //const found = accessControlArray.filter(element => element == AOBSystemProcess.ViewTraceLog);

        //if (found !== undefined && found.length > 0)
        //    setDisTraceLogMenu(false);
        //else
        //    setDisTraceLogMenu(true);
    }

    const SetMoreMenuDisplay = (accessControlArray, isProspectUser) => {

        const found = accessControlArray.filter(element => element == AOBSystemProcess.UserMaintenance
            || element == AOBSystemProcess.RoleMaintenance || element == AOBSystemProcess.SystemParamMaintenance
            || element == AOBSystemProcess.ViewActivityLog || element == AOBSystemProcess.ViewErrorLog
            || element == AOBSystemProcess.ViewTraceLog || element == AOBSystemProcess.ViewAuditTrailReport
            || element == AOBSystemProcess.SystemMessage);

        if (found !== undefined && found.length > 0)
            setMoreMenuDisp('inline-block');
        else
            setMoreMenuDisp('none');
    }

    const SetProspectDasboardMenuClick = () => {
        navigate('/ProspectDashboard', { state: { userId: userId, sessionId: sessionId }, replace: true });
    };

    const ApplMenuClick = () => {
        navigate('/ApplicationDashboard', { state: { userId: userId, sessionId: sessionId }, replace: true });
    };

    const EmailMenuClick = () => {
        navigate('/EmailMessages', { state: { userId: userId, sessionId: sessionId }, replace: true });
    };

    const ProfileMenuClick = () => {
        setAccountAnchorEl(null);
        navigate('/UserProfileSetting', { state: { userId: userId, sessionId: sessionId }, replace: true });
    };

    const FAQMenuClick = () => {
        navigate('/FAQP', { state: { userId: userId, sessionId: sessionId }, replace: true });
    };

    const ContactUsMenuClick = () => {
        navigate('/ContactUs', { state: { userId: userId, sessionId: sessionId }, replace: true });
    };

    const KeyDatesMenuClick = () => {
        navigate('/KeyDatesP', { state: { userId: userId, sessionId: sessionId }, replace: true });
    };

    const TemplateMenuClick = () => {
        setAccountAnchorEl(null);
        navigate('/TemplateList', { state: { userId: userId, sessionId: sessionId }, replace: true });
    };

    const LogoutMenuClick = () => {
        navigate('/AppLogout', { state: { userId: userId, sessionId: sessionId }, replace: true });
    };

    const drawer = (
        <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
            <Typography variant="h6" sx={{ my: 2 }}>
                MUI
            </Typography>
            <Divider />
            <List>
                {navItems.map((item) => (
                    <ListItem key={item} disablePadding>
                        <ListItemButton sx={{ textAlign: 'center' }}>
                            <ListItemText primary={item} />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
        </Box>
    );

    const container = window !== undefined ? () => window().document.body : undefined;

    return (
        <>
            <Box sx={{ display: 'flex' }}>
                <AppBar component="nav" position="relative" color="transparent" elevation={0} sx={{ p: 0, m: 0 }}>
                    <Toolbar disableGutters={true} sx={{ borderBottom: 1, borderColor: '#d5dbdb', pl: 2, justifyContent: 'space-between', pb: 1, mb: 4 }} >
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            edge="start"
                            onClick={handleDrawerToggle}
                            sx={{ mr: 2, display: { sm: 'none' } }}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Box sx={{ display: 'flex', alignItems: 'center', pr: 0 }}>
                            <img src={productlogo} height="70" loading="lazy" />
                        </Box>
                        {/*<Stack direction="column" sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}>*/}
                        {/*    <Typography variant="h4" sx={{ fontWeight: 500, lineHeight: 1.1 }} color="text.primary">Account Onboarding</Typography>*/}
                        {/*    <Typography variant="body2" className="mt-3" color="text.secondary">Smarter. And so straightforward. Start saving for something great.</Typography>*/}
                        {/*</Stack>*/}
                        <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                            <Button sx={{ ml: 0, mr: 0, display: prospectDashboardMenuDisp }} onClick={() => SetProspectDasboardMenuClick()}>
                                <Typography className="" variant="body1" sx={{ textTransform: 'none', fontWeight: 400 }} color="text.secondary">View Cases</Typography>
                            </Button>
                            <Button sx={{ ml: 0, mr: 0, display: applDashboardMenuDisp }} onClick={() => ApplMenuClick()}>
                                <Typography className="" variant="body1" sx={{ textTransform: 'none', fontWeight: 400 }} color="text.secondary">Claim Settlement</Typography>
                            </Button>
                            {/*<Button sx={{ ml: 0, mr: 3, display: templateMenuDisp }} onClick={() => TemplateMenuClick()}>*/}
                            {/*    <Typography className="" variant="body1" sx={{ textTransform: 'none', fontWeight: 400 }} color="text.secondary">Claim Form</Typography>*/}
                            {/*</Button>*/}
                            {/*<Button sx={{ ml: 0, mr: 3, display: 'inline-block' }} onClick={() => EmailMenuClick()}>*/}
                            {/*    <Typography className="" variant="body1" sx={{ textTransform: 'none', fontWeight: 500}} color="text.secondary">Email</Typography>*/}
                            {/*</Button>*/}
                            <Button sx={{ ml: 0, mr: 0, display: FAQMenuDisp }} onClick={() => FAQMenuClick()}>
                                <Typography className="" variant="body1" sx={{ textTransform: 'none', fontWeight: 400 }} color="text.secondary">FAQs</Typography>
                            </Button>
                            <Button sx={{ ml: 0, mr: 0, display: keyDatesMenuDisp }} onClick={() => KeyDatesMenuClick()}>
                                <Typography className="" variant="body1" sx={{ textTransform: 'none', fontWeight: 400 }} color="text.secondary">Key Dates</Typography>
                            </Button>
                            {/*<Button sx={{ ml: 0, mr: 0, display: contactUsMenuDisp }} onClick={() => ContactUsMenuClick()}>*/}
                            {/*    <Typography className="" variant="body1" sx={{ textTransform: 'none', fontWeight: 400 }} color="text.secondary">Contact Us</Typography>*/}
                            {/*</Button>*/}
                            {/*<Button sx={{ ml: 0, mr: 0, display: 'inline-block' }} onClick={() => ProfileMenuClick()}>*/}
                            {/*    <Typography className="" variant="body1" sx={{ textTransform: 'none', fontWeight: 400 }} color="text.secondary">Profile</Typography>*/}
                            {/*</Button>*/}
                            <Button sx={{ ml: 0, mr: 0, display: moreMenuDisp }} onClick={handleClick}>
                                <Stack direction="row" alignItems="center">
                                    <Typography className="" variant="body1" sx={{ textTransform: 'none', fontWeight: 400 }} color="text.secondary">I Would Like To ..</Typography>
                                    <KeyboardArrowDownIcon sx={{ fontSize: 28, color: '#9e9e9e' }} />
                                </Stack>
                            </Button>
                            <Tooltip title="Account settings">
                                <IconButton
                                    onClick={handleAccountMenuClick}
                                    size="small"
                                    sx={{ ml: 0 }}
                                    aria-controls={open ? 'account-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={open ? 'true' : undefined}
                                >
                                    <Avatar sx={{ width: 28, height: 28 }}>
                                        <Typography variant="caption">A</Typography>
                                    </Avatar>
                                </IconButton>
                            </Tooltip>
                            {/*<Button sx={{ ml: 0, mr: 0, display: 'inline-block' }} onClick={() => LogoutMenuClick()}>*/}
                            {/*    <Avatar sx={{ width: 28, height: 28, bgcolor: deepOrange[500] }}>*/}
                            {/*        <Typography variant="caption">A</Typography>*/}
                            {/*    </Avatar>*/}
                            {/*</Button>*/}
                        </Box>
                    </Toolbar>
                </AppBar>
                <Box component="nav">
                    <Drawer
                        container={container}
                        variant="temporary"
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                        ModalProps={{
                            keepMounted: true,
                        }}
                        sx={{
                            display: { xs: 'block', sm: 'none' },
                            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                        }}
                    >
                        {drawer}
                    </Drawer>
                </Box>

            </Box>
            <Menu
                anchorEl={anchorEl}
                id="more-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <MenuItem onClick={TemplateMenuClick} sx={{ pl: 4, pr: 1, pt: 2 }}>
                    <ListItemIcon>
                        <ListAltIcon sx={{ color: '#757575', fontSize: '24px' }} />
                    </ListItemIcon>
                    <Typography variant="body2" sx={{ textTransform: 'none', mr: 3 }} color="text.primary">View Claim Form</Typography>
                </MenuItem>
                <Divider />
                <MenuItem onClick={handleUsersMenuClick} disabled={disUsersMenu} sx={{ pl: 4, pr: 1 }}>
                    <ListItemIcon>
                        <PeopleOutlinedIcon sx={{ color: '#757575', fontSize: '24px' }} />
                    </ListItemIcon>
                    <Typography variant="body2" sx={{ textTransform: 'none', mr: 3 }} color="text.primary">View Participants </Typography>
                </MenuItem>
                <MenuItem onClick={handleRolesMenuClick} disabled={disRolesMenu} sx={{ pl: 4, pr: 1 }}>
                    <ListItemIcon sx={{pb: 3}}>
                        <ManageAccountsOutlinedIcon sx={{ color: '#757575', fontSize: '24px' }} />
                    </ListItemIcon>
                    <Typography variant="body2" sx={{ textTransform: 'none', pb: 3, mr: 3 }} color="text.primary">View Roles</Typography>
                </MenuItem>
            </Menu>
            <Menu
                anchorEl={accountAnchorEl}
                id="account-menu"
                open={openAccountMenu}
                onClose={handleAccountMenuClose}
                onClick={handleAccountMenuClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <MenuItem onClick={ProfileMenuClick} sx={{ pb: 2, pt: 2, minWidth: '280px', background: 'rgb(245, 246, 247)'}}>
                    <Avatar />
                    <Stack direction="column" sx={{}}>
                        <Typography variant="h6" sx={{ pl: 1, lineHeight: 1.3, fontWeight: 500}}>Profile</Typography>
                        <Typography variant="body1" sx={{ pl: 1, lineHeight: 1.3 }}>{userName.current}</Typography>
                        <Typography variant="caption" sx={{ pl: 1, lineHeight: 1.3 }}>{email.current}</Typography>
                    </Stack>
                </MenuItem>
                <Divider />
                <MenuItem onClick={LogoutMenuClick}>
                    <ListItemIcon>
                        <Logout fontSize="small" />
                    </ListItemIcon>
                    <Typography variant="body1" sx={{ pl: 1, lineHeight: 1.3 }}>Logout</Typography>
                </MenuItem>
            </Menu>
        </>
    );
}

