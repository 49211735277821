import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';


export function FormDialog(props) {
    const { onClose, value: valueProp, open, ...other } = props;
    const [note, setNote] = useState('');

    const handleCancel = () => {
        onClose();
        setNote('');
    };

    const handleOk = () => {
        if (note) {
            onClose(props.nvalue, note);
            setNote('');
        }
    };

    const noteChangeHandler = (event) => {
        /*setTemplateInput({ ...statusHistInput, note: event.target.value })*/
        setNote(event.target.value);
    };

    return (
        <Dialog
            sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 535 } }}
            maxWidth="xs"
            open={open}
            {...other}>
            <DialogTitle>
                <Typography variant="body1" color="text.primary" sx={{ fontWeight: 600 }}>{props.alertheader}</Typography>
            </DialogTitle>
            {/*<DialogContent dividers>*/}
                
            {/*</DialogContent>*/}
            <DialogContent dividers>
                <Typography variant="body2" color="text.secondary" sx={{}}>{props.bmsgline1}</Typography>
                <Typography variant="body2" color="text.secondary" sx={{}}>{props.bmsgline2}</Typography>
                <Typography variant="body2" color="text.secondary" sx={{}}>{props.bmsgline3}</Typography>
                <Typography variant="body2" color="text.secondary" sx={{}}>{props.bmsgline4}</Typography>
                <Typography className="mt-4 mb-1" variant="body2" color="text.secondary" sx={{}}>{props.bmsgline5}</Typography>

                <TextField
                    id="standard-multiline-static"
                    autoFocus
                    margin="dense"
                    label="Reason"
                    multiline
                    rows={4}
                    fullWidth
                    required={true}
                    onChange={noteChangeHandler}
                />
            </DialogContent>
            <DialogActions>
                <Button autoFocus variant="contained" onClick={handleCancel} size="small" sx={{ backgroundColor: '#0099f6', margin: '4px 8px', padding: '5px 40px' }}>
                    &nbsp;Cancel
                </Button>
                <Button variant="contained" onClick={handleOk} size="small" sx={{ backgroundColor: '#0099f6', margin: '4px 10px 4px 0px', padding: '5px 40px' }} disabled={!note || note === undefined}>Ok</Button>
            </DialogActions>
        </Dialog>
    );
}

FormDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    nvalue: PropTypes.number,
    svalue: PropTypes.string,
    alertheader: PropTypes.string.isRequired,
    bmsgline1: PropTypes.string.isRequired,
    bmsgline2: PropTypes.string,
    bmsgline3: PropTypes.string,
    bmsgline4: PropTypes.string,
    bmsgline5: PropTypes.string,
};