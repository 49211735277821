import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { PreLoginMenu } from './PreLoginMenu';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { loadCaptchaEnginge, LoadCanvasTemplate, LoadCanvasTemplateNoReload, validateCaptcha } from 'react-simple-captcha';
import { Footer } from './Footer';
import OverlaySpinner from './Controls/OverlaySpinner';
import { IsError } from '../global/APIUtils';
import { authenticate, AddToSession } from '../global/Api';
import { RedirectToHome, GetUserCurrentDate, GetOSPlatform, GetBrowserName, GetCountryOfUser } from '../global/Helpers';
import { LoginStatus } from '../common/AOBEnum';
import { ErrorMessages } from '../common/ErrorMessages';
import Button from '@mui/material/Button';
import { Typography, Link, card, CardContent, Box, FormControl, Toolbar, Stack } from '@mui/material';
import { Paper } from '@mui/material';
import AlertControl from './Controls/AlertControl';
import { AOBSystemProcess } from '../common/AOBEnum';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
//import clientlogo from '../assets/images/SettlementPortal.jpg';
import { APICall } from '../global/Api';

export function AppLogin() {

    const [isSubmitting, setIsSubmitting] = useState(false);

    const [isLoading, setIsLoading] = useState(false);

    const [showCaptcha, setShowCaptcha] = useState(parseInt(process.env.REACT_APP_DIAPLAY_CAPTCHA_AFTER));
    const [failedLoginCount, setFailedLoginCount] = useState(1);
    const [isForceLogin, setIsForceLogin] = useState(false);
    const [loginStatusMsg, setLoginStatusMsg] = useState('');

    let navigate = useNavigate();

    const search = useLocation().search;
    let logoutReason = new URLSearchParams(search).get("reason");

    useEffect(() => {

        if (failedLoginCount > showCaptcha) { loadCaptchaEnginge(6); }
    }, [failedLoginCount]);

    useEffect(() => {

        if (logoutReason != null) {

            if (logoutReason.toUpperCase() == 'SESSION_NOTFOUND') {
                setLoginStatusMsg('Session has expired or could not be found. Please try login again.');
            }
            else if (logoutReason.toUpperCase() == 'SESSION_INVALID') {
                setLoginStatusMsg('You are logged on from some other place using the same login which prevents you using this site. Please try Log in again.');
            }
            else if (logoutReason.toUpperCase() == 'SESSION_EXISTED') {
                setLoginStatusMsg('You were logged in from some other place using the same login which prevents you using this site. Please try Log in again.');
            }
        }

    }, []);

    const handleLoginError = (responseMsg) => {

        setIsForceLogin(false);

        if (responseMsg.data[0].UserLoginStatus === LoginStatus.AccountNotActivated) {
            setFailedLoginCount(0);
            setLoginStatusMsg(ErrorMessages.AccountNotActivated);
        }
        else if (responseMsg.data[0].UserLoginStatus === LoginStatus.AllreadyLoggedIn) {
            setFailedLoginCount(0);
            setIsForceLogin(true);
            setLoginStatusMsg(ErrorMessages.MultipleLoginMsg);
        }
        else if (responseMsg.data[0].UserLoginStatus === LoginStatus.Locked) {
            setFailedLoginCount(0);
            setLoginStatusMsg(ErrorMessages.AccountLocked);
        }
        else if (responseMsg.data[0].UserLoginStatus === LoginStatus.InvalidCredential) {
            setFailedLoginCount(responseMsg.data[0].NoOfTry);
            setLoginStatusMsg(ErrorMessages.InvalidCredential);
        }
        else if (responseMsg.data[0].UserLoginStatus === LoginStatus.InactiveUser) {
            setFailedLoginCount(0);
            setLoginStatusMsg(ErrorMessages.InactiveUser);
        }
        else {
            setFailedLoginCount(failedLoginCount + 1);
            setLoginStatusMsg(ErrorMessages.UnknownError);
        }
    }

    const isTwoFactorEnabled = async (userId, sessionId, atoken) => {

        const apiUrl = new URL(
            process.env.REACT_APP_GET_USER_PREFERENCE,
            process.env.REACT_APP_BASE_URL,
        );

        try {

            setIsLoading(true);

            let response = await APICall({ url: apiUrl.href, method: 'GET', contentType: 'application/json', responseType: '' }, userId, sessionId, atoken);

            if (response !== null && response !== undefined) {
                if (response.data.length > 0) {
                    if (response.data[0].IsTwoStepVerifySet) {
                        return true;
                    }
                }
            }
        }
        catch (err) {
            setLoginStatusMsg(ErrorMessages.UnknownError);
        }
        finally {
            setIsLoading(false);
        }

        return false;
    }

    const validateLogin = async (loginValues) => {

        try {

            setLoginStatusMsg("");
            setIsLoading(true);

            let response = await authenticate(loginValues.userid, loginValues.password, isForceLogin);

            if (response !== null && response != undefined) {
                if (response.data[0].Access_Token !== null && response.data[0].Access_Token !== undefined) {

                    let TFEnabled = await isTwoFactorEnabled(response.data[0].UserID, response.data[0].SessionID, response.data[0].Access_Token)
                    if (TFEnabled) {
                        sendOTP(response.data[0].UserID, response.data[0].SessionID, response.data[0].Access_Token, response.data[0].Refresh_Token);
                    }
                    else {
                        AddToSession(response, loginForm.values.userid);
                        navigate('/UserHome', { state: { userId: response.data[0].UserID, sessionId: response.data[0].SessionID }, replace: true });
                    }
                }
                else {
                    handleLoginError(response);
                }
            }
        }
        catch (err) {
            setLoginStatusMsg(ErrorMessages.UnknownError);
        }
        finally {
            setIsLoading(false);
        }
    }

    const sendOTP = async (userId, sessionId, atoken, rtoken) => {

        let browserName = GetBrowserName();
        let country = GetCountryOfUser();
        let os = GetOSPlatform();
        let currentDate = GetUserCurrentDate();

        let inputJson = {
            EmailAddress: loginForm.values.userid,
            UserBrowserName: browserName,
            UserOS: os,
            UserLocationCountry: country,
            UserLocationDate: currentDate
        };

        setLoginStatusMsg('');

        const apiUrl = new URL(
            process.env.REACT_APP_SEND_LOGIN_OTP,
            process.env.REACT_APP_BASE_URL,
        );

        try {
            setIsLoading(true);

            let response = await APICall({ url: apiUrl.href, method: 'POST', payload: inputJson, contentType: 'application/json', responseType: '' }, userId, sessionId, atoken);

            if (response != null && response != undefined) {

                let error = IsError(response);

                if (error != 0) {
                    setLoginStatusMsg(response.Errors[0].Message);
                }
                else {
                    if (response.meta.EmailSent === "Yes") {
                        let otpRef = response.data[0];
                        navigate('/OTPVerification', { state: { emailAddress: loginForm.values.userid, userId: userId, sessionId: sessionId, otpRef: otpRef, atoken: atoken, rtoken: rtoken }, replace: true });
                    }
                    else {
                        setLoginStatusMsg(ErrorMessages.UnknownError);
                    }
                }
            }
        }
        catch (err) {
            setLoginStatusMsg(ErrorMessages.UnknownError);
        }
        finally {
            setIsLoading(false);
        }
    }
    const loginForm = useFormik({
        initialValues: {
            userid: '',
            password: ''
        },
        validationSchema: yup.object({
            userid: yup.string()
                .email('Invalid Email address')
                .required('Email - Required'),
            password: yup.string()
                .required('Password - Required')
        }),
        onSubmit: values => {

            let user_captcha = '';

            if (failedLoginCount > 1 && failedLoginCount > showCaptcha) {
                user_captcha = document.getElementById('user_captcha_input').value;

                if (validateCaptcha(user_captcha, false) === true) {
                    validateLogin(values);
                }
                else {
                    setLoginStatusMsg('Captcha does not match.');
                    document.getElementById('user_captcha_input').value = "";
                }
            }
            else {
                validateLogin(values);
            }
        },
    });

    const forgetPassword = (event) => {
        event.preventDefault();
        navigate('/ForgetPassword', { state: {}, replace: true });
    }

    const Register = (event) => {
        event.preventDefault();
        navigate('/UserRegistration', { state: {}, replace: true });
    }

    const FAQ = (event) => {
        event.preventDefault();
        navigate('/FAQ', { state: {}, replace: true });
    }

    const KeyDates = (event) => {
        event.preventDefault();
        navigate('/KeyDates', { state: {}, replace: true });
    }

    return (
        <React.Fragment>
            <Container maxWidth="xl" sx={{}} >
                <OverlaySpinner disappear={isLoading} />
                <PreLoginMenu />
                <Paper elevation={0}
                    sx={{
                        position: 'relative',
                        //backgroundColor: 'grey.800',
                        color: '#fff',
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        pb: { xs: 7, sm: 0 },
                    }}
                >
                    <Box
                        sx={{
                            position: 'absolute',
                            top: 0,
                            bottom: 0,
                            right: 0,
                            left: 0,
                            backgroundColor: 'rgba(238, 238, 238, 0.08)',
                        }}
                    />
                    {/*<Toolbar sx={{ borderBottom: 1, borderColor: '#ccc', justifyContent: 'space-between', backgroundColor: '#fff', pt: 0, pb: 0, }}>*/}
                    {/*    <Box sx={{ ml: 1, pt: 1, pb: 1 }}>*/}
                    {/*        <img src={clientlogo} height="70" loading="lazy" />*/}
                    {/*    </Box>*/}
                    {/*    <Box alignItems="center" sx={{ mr: 1 }}>*/}
                    {/*        <Button size="small" variant="outlined" style={{ boxShadow: 'none', border: 0, textTransform: 'none' }} onClick={(e) => FAQ(e)}>*/}
                    {/*            <Typography variant="body1" color="#002d72" sx={{ pl: 1, fontWeight: 400, letterSpacing: '2px' }} >FAQs</Typography>*/}
                    {/*        </Button> */}
                    {/*        <Button size="small" variant="outlined" style={{ boxShadow: 'none', border: 0, textTransform: 'none' }} onClick={(e) => KeyDates(e)}>*/}
                    {/*            <Typography variant="body1" color="#002d72" sx={{ fontWeight: 400, letterSpacing: '2px' }} >Key Dates</Typography>*/}
                    {/*        </Button>*/}
                    {/*        <Button size="small" variant="outlined" style={{ boxShadow: 'none', border: 0, textTransform: 'none' }} onClick={(e) => Register(e)}>*/}
                    {/*            <Typography variant="body1" color="#002d72" sx={{ fontWeight: 400, letterSpacing: '2px' }} >Login</Typography>*/}
                    {/*        </Button>*/}
                    {/*    </Box>*/}
                    {/*</Toolbar>*/}

                    <Grid sx={{ pb: 6}} container>
                        <Grid item xs={12} sm={7}>
                            <Box sx={{ ml: 9, border: '0px solid #000', pt: 4, mr: 1}}>
                                <Typography variant="h2" color="text.primary" sx={{ lineHeight: 2.2, fontWeight: 400, letterSpacing: '-1px', textAlign: 'center', borderBottom: '1px solid rgba(0,0,0,.1)' }} >Welcome To The Settlement Portal</Typography>
                                <Typography variant="h6" color="text.secondary" sx={{ fontWeight: 400, mt: 4, lineHeight: 1.3,  }} >
                                    In October of 2019, AcmeCorp disclosed a data incident that affected the personal details of nearly 160 million individuals. In response to this incident, a class action Settlement has been agreed upon in a federal court, addressing consumer lawsuits that followed the data incident. AcmeCorp has maintained that it did not engage in any wrongdoing, and there has been no court ruling or finding that establishes any wrongdoing on their part.
                                </Typography>
                                <Typography variant="h6" color="text.secondary" sx={{ fontWeight: 400, mt: 3,lineHeight: 1.3, }} >
                                    For those who are identified as Class Members, the deadline to submit Initial Claims Period claim(s) for complimentary credit monitoring or up to $135 cash compensation, as well as other cash reimbursements, has passed as of February 20, 2021.
                                </Typography>
                                <Typography variant="h6" color="text.secondary" sx={{ fontWeight: 400, mt: 3, lineHeight: 1.3, }} >
                                    The Settlement is now in effect. If you applied for a cash benefit during the Initial Claims Period, the amount you are eligible to receive might be notably decreased depending on the quantity of valid claims submitted by other class members. Considering the current number of potentially valid claims received, payments for time spent and alternative compensation of up to $135 are expected to be significantly lowered and will be distributed proportionally once the Settlement is implemented. Depending on the volume of valid claims submitted, the amount you receive might represent only a fraction of your original claim.
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={5} sx={{}}>
                            <Grid container>
                                <Grid item sm={12} sx={{ pl: 6, pr: 18, pt: 1 }}>
                                    <Box sx={{ borderLeft: '1px solid rgba(0,0,0,.3)', backgroundColor: '#fff', pl: 7, pr: 0, pt: 1, pb: 6, mt: { xs: 1, sm: 8 }, mb: { xs: 1, sm: 1 }, position: 'relative', borderRadius: '0px' }}>
                                        {/*<Typography variant="h6" color="text.primary" sx={{ mb: 0, fontWeight: 400, textTransform: 'none', lineHeight: 1.5 }}>*/}
                                        {/*    Digital*/}
                                        {/*</Typography>*/}
                                        {/*<Typography variant="h3" color="text.primary" sx={{ mb: 0, fontWeight: 500, textTransform: 'none', lineHeight: 1.5 }}>*/}
                                        {/*    Account Onboarding*/}
                                        {/*</Typography>*/}
                                        <Typography variant="h4" color="text.primary" sx={{ mt: 2, mb: 3, letterSpacing: '0px', fontWeight: 400, textTransform: 'none' }}>
                                            Sign in to your account
                                        </Typography>
                                        <form onSubmit={loginForm.handleSubmit} id="frmLogin">
                                            <AlertControl Message={loginStatusMsg} severity="error" color="error" icon={true} />

                                            <Paper elevation={0}>
                                                <label htmlFor="userid" className="form-label">Email Address*</label>
                                                <input type="text" id="userid" name="userid" autoComplete="off" placeholder="Enter your email address here" className="form-control" value={loginForm.values.userid} onChange={loginForm.handleChange} />
                                                {
                                                    loginForm.touched.userid && loginForm.errors.userid ? (<p className="text-danger">{loginForm.errors.userid}</p>) : null
                                                }
                                            </Paper>
                                            <Paper elevation={0} sx={{ pt: 2 }}>
                                                <label htmlFor="password" className="form-label">Password*</label>
                                                <input type="password" id="password" name="password" autoComplete="off" placeholder="Enter your password here" className="form-control" value={loginForm.values.password} onChange={loginForm.handleChange} />
                                                {
                                                    loginForm.touched.password && loginForm.errors.password ? (<p className="text-danger">{loginForm.errors.password}</p>) : null
                                                }
                                            </Paper>
                                            {failedLoginCount > showCaptcha ? (
                                                <Box sx={{ mt: 2 }}>
                                                    <Box className="input-group">
                                                        <Stack direction="row">
                                                            <LoadCanvasTemplate />
                                                            <input placeholder="Captcha Value" id="user_captcha_input" className="form-control" name="user_captcha_input" type="text" ></input>
                                                        </Stack>
                                                    </Box>
                                                </Box>)
                                                : null
                                            }
                                            <Box sx={{ pt: 3, pb: 4 }}>
                                                <Button type="submit" variant="outlined" size="large" style={{ boxShadow: 'none', border: '2px solid #0972d3', borderRadius: '40px', padding: '0.4rem 2.5rem', textTransform: 'none', }}>
                                                    <Typography variant="body2" color="#0972d3" sx={{fontWeight: 600}} >Log in</Typography>
                                                </Button>
                                            </Box>
                                            <Stack direction="row" justifyContent="space-between">
                                                <Link className="" href="#" underline="hover" variant="body1" sx={{ mb: 2, }} onClick={(e) => forgetPassword(e)}>Forgot password?</Link>
                                                <Link className="" href="#" underline="hover" variant="body1" sx={{ mb: 2, }} >Forgot username?</Link>
                                            </Stack>
                                        </form>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>
                {/*<LoginFeature />*/}
                <Footer />
            </Container>
        </React.Fragment>
    );
}