import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { Typography, Link, Stack, Toolbar, Box, Alert } from '@mui/material';
import Container from '@mui/material/Container';
import { Paper } from '@mui/material';
import OverlaySpinner from './Controls/OverlaySpinner';
import { PreLoginMenu } from './PreLoginMenu';
import Button from '@mui/material/Button';
import { Footer } from './Footer';
import Grid from '@mui/material/Grid';
import { CallServerAPI, IsError } from '../global/APIUtils';
import { ErrorMessages } from '../common/ErrorMessages';
import { RegistrationResponseStatus } from '../common/AOBEnum';
//import { ReactComponent as ReactLogo } from '../assets/images/ssb-horizontal-new.svg';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import NewReleasesOutlinedIcon from '@mui/icons-material/NewReleasesOutlined';

const ActivateRegistration = () => {

    const [isLoading, setIsLoading] = useState(false);
    const [statusMsg, setStatusMsg] = useState('');
    const [statusDetailMsg, setStatusDetailMsg] = useState('');
    const [loginButtonDisplay, setLoginButtonDisplay] = useState('none');
    const [registerButtonDisplay, setRegisterButtonDisplay] = useState('none');
    const [resendButtonDisplay, setResendButtonDisplay] = useState('none');
    const [successIconDisplay, setSuccessIconDisplay] = useState('none');
    const [failIconDisplay, setFailIconDisplay] = useState('none');

    let navigate = useNavigate();

    const search = useLocation().search;
    let searchParm = new URLSearchParams(search);
    let email = searchParm.get("email");
    let regAuthCode = searchParm.get("code");

    const verifyRegistration = async () => {

        let regModel = {
            EmailAddress: email,
            RegAuthCode: regAuthCode
        };

        let headers = {
            'Content-Type': 'application/json'
        };

        try {

            setIsLoading(true);

            //let endpoint = process.env.REACT_APP_BASE_URL  + process.env.REACT_APP_VERIFY_REGISTRATION;

            const apiUrl = new URL(
                process.env.REACT_APP_VERIFY_REGISTRATION,
                process.env.REACT_APP_BASE_URL,
            );

            let response = await CallServerAPI({ url: apiUrl.href, method: 'POST', headers: headers, body: regModel });

            if (response != null && response != undefined) {

                if (response.meta.RegistrationStatus === "1") { // Activated/Success
                    setSuccessIconDisplay('block');
                    setStatusMsg('Email verification successfully done!');//Disabled
                    setStatusDetailMsg('Welcome to the Account Onboarding Application! – Thanks for joining us. Please login to the website.');
                    setLoginButtonDisplay('block');
                }
                else if (response.meta.RegistrationStatus === "2") { // AlreadyRegistered
                    setSuccessIconDisplay('block');
                    setStatusMsg('Your registration is completed!');//Disabled
                    setStatusDetailMsg('Please login to the website.');
                    setLoginButtonDisplay('block');
                }
                else if (response.meta.RegistrationStatus === "3" // InvalidLink
                    || response.meta.RegistrationStatus === "4" // InvalidEmail
                    || response.meta.RegistrationStatus === "5")  // InvalidRegCode
                {
                    setFailIconDisplay('block');
                    setStatusMsg('User verification problem!');
                    setStatusDetailMsg('The verification link is not valid or relevant. Please verify your account by clicking on the link contained in the verification email we\'ve already sent to you.');
                    setRegisterButtonDisplay('block');
                }
                //else if (response.meta.RegistrationStatus === "6" && parseInt(response.meta.ElapsedTime) > 24 ) { // RegCodeExpired too long
                //    setFailIconDisplay('block');
                //    setStatusMsg('User verification problem!');
                //    setStatusDetailMsg('The verification link is not valid or relevant. Sign-up again by clicking on the sign-up button below.');
                //    setRegisterButtonDisplay('block');
                //}
                else if (response.meta.RegistrationStatus === "6") { // RegCodeExpired
                    setFailIconDisplay('block');
                    setStatusMsg('User verification problem!');
                    setStatusDetailMsg('The verification link has expired, because you didn\'t click it within 1 hour. Sign-up again by clicking on the sign-up button below.');
                    setRegisterButtonDisplay('block');
                }
            }
        }
        catch (err) {
            setFailIconDisplay('block');
            setStatusMsg('An Unexpected Error Occurred!');
            setStatusDetailMsg(ErrorMessages.UnknownError);
        }
        finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {

        if (email == null || regAuthCode == null) {
            setFailIconDisplay('block');
            setStatusMsg('User verification problem!');
            setRegisterButtonDisplay('block');
            setStatusDetailMsg('The user verification link is not valid or relevant.');
            return;
        }

        verifyRegistration();

        //if (logoutReason != null) {

        //    if (logoutReason.toUpperCase() == 'SESSION_NOTFOUND') {
        //        setLoginStatusMsg('Session has expired or could not be found. Please login again.');
        //    }
        //    else if (logoutReason.toUpperCase() == 'LOGIN_AGAIN') {
        //        setLoginStatusMsg('Please login again.');
        //    }
        //}

    }, []);

    const Register = (event) => {
        event.preventDefault();
        navigate('/UserRegistration');
    }

    const Login = (event) => {
        event.preventDefault();
        navigate('/AppLogin');
    }

    const ResendAuthCode = async (event) => {
        let regModel = {
            EmailAddress: email,
            RegAuthCode: regAuthCode
        };

        let headers = {
            'Content-Type': 'application/json'
        };

        try {

            setIsLoading(true);
            //let endpoint = process.env.REACT_APP_BASE_URL + process.env.REACT_APP_RESEND_REG_AUTHCODE;

            let apiUrl = new URL(
                process.env.REACT_APP_RESEND_REG_AUTHCODE,
                process.env.REACT_APP_BASE_URL,
            );

            let response = await CallServerAPI({ url: apiUrl.href, method: 'POST', headers: headers, body: regModel });

            if (response != null && response != undefined) {

                let error = IsError(response);

                if (error != 0) {
                    if (response.Error.Code == RegistrationResponseStatus.DuplicateEntry) {
                        setFailIconDisplay('block');
                        setStatusMsg('Resend Error!');
                        setStatusDetailMsg(ErrorMessages.DuplicateEmail);
                        setLoginButtonDisplay('block');
                    }
                    else if (response.Error.Code == RegistrationResponseStatus.NotExists) {
                        setFailIconDisplay('block');
                        setStatusMsg('Resend Error!');
                        setStatusDetailMsg("Your email is not used during registration.");
                        setRegisterButtonDisplay('block');
                    }
                }
                else {
                    if (response.meta.EmailSent === "Yes") {
                        setSuccessIconDisplay('block');
                        setStatusMsg('Email verification mail sent!');//Disabled
                        setStatusDetailMsg('A verification mail has been sent to ' + email + '. Please click on the link provided in the email to complete your registration. This may take upto 10 minutes to arrive.');
                    }
                    else {
                        setStatusMsg(ErrorMessages.UnknownError);
                    }
                }
            }
        }
        catch (err) {
            setFailIconDisplay('block');
            setStatusMsg('An Unexpected Error Occurred!');
            setStatusDetailMsg(ErrorMessages.UnknownError);
        }
        finally {
            setIsLoading(false);
        }
    }

    return (
        <React.Fragment>
            <Container maxWidth="xl">
                <OverlaySpinner disappear={isLoading} />
                <PreLoginMenu />
                <Paper
                    sx={{
                        position: 'relative',
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        pb: { xs: 7, sm: 10 },
                    }}
                >
                    <Box
                        sx={{
                            position: 'absolute',
                            top: 0,
                            bottom: 0,
                            right: 0,
                            left: 0,
                            backgroundColor: 'rgba(238, 238, 238, 0.08)',
                        }}
                    />
                    <Grid sx={{ pb: 5, pt: 2 }} container>
                        <Grid item xs={12}>
                            <Box sx={{ ml: 6, mr: 6, border: '0px solid #000', pt: 1 }}>
                                <Typography variant="h3" color="text.primary" sx={{ borderBottom: '1px solid rgba(0,0,0,0.1)', lineHeight: 2.2, fontWeight: 500, letterSpacing: '-1px', textAlign: 'center' }} >Registration</Typography>
                            </Box>
                            <Box textAlign='center' sx={{ pt: 8, pb: 1, display: failIconDisplay }}>
                                <NewReleasesOutlinedIcon sx={{fontSize: 60}} />
                            </Box>
                            <Box textAlign='center' sx={{ pt: 5, pb: 1, display: successIconDisplay }}>
                                <DoneAllIcon sx={{ fontSize: 40, color: '#757575' }} />
                            </Box>
                            <Typography component="h2" variant="h5" color="text.primary" align="center"  sx={{ pt: 0, fontWeight: 700 }} >
                                {statusMsg}
                            </Typography>
                            <Typography component="h2" variant="body1" color="text.primary" sx={{ pt: 1, textAlign: 'center' }} >
                                {statusDetailMsg}
                                {/*The user verification link is not valid or relevant. */}
                                {/*The user verification link is not valid or relevant. Please verify your account by clicking on the link contained in the verification email we've already sent to you.*/}
                                {/*Welcome to the Account Onboarding Application! – Thanks for joining us. Please login to the website.*/}
                            </Typography>
                            <Box textAlign='center' sx={{ pt: 3, pb: 3, display: loginButtonDisplay }}>
                                <Button variant="outlined" size="large" onClick={(e) => Login(e)} style={{ boxShadow: 'none', border: '2px solid #0972d3', borderRadius: '40px', padding: '0.4rem 2.5rem', textTransform: 'none', }}>
                                    <Typography variant="body2" color="#0972d3" sx={{ fontWeight: 600 }} >Log in</Typography>
                                </Button>
                                {/*<Button type="submit" variant="outlined" size="small" onClick={(e) => Login(e)} sx={{ padding: '0.4rem 3rem', border: 2, borderColor: '#616161' }}>*/}
                                {/*    <Typography variant="body2" color="#212121" sx={{ textTransform: 'none', fontWeight: 500 }} >Log In</Typography>*/}
                                {/*</Button>*/}
                                {/*<Button type="submit" variant="contained" size="large" style={{ padding: '0.6rem 3.5rem', textTransform: 'none', }} onClick={(e) => Login(e)}>*/}
                                {/*    <Typography variant="body1">LOG IN</Typography>*/}
                                {/*</Button>*/}
                            </Box>
                            <Box textAlign='center' sx={{ pt: 3, pb: 3, display: registerButtonDisplay }}>
                                <Button type="submit" onClick={(e) => Register(e)} variant="outlined" size="small" sx={{ padding: '0.4rem 3rem', border: 2, borderColor: '#616161' }}>
                                    <Typography variant="body2" color="#212121" sx={{ textTransform: 'none', fontWeight: 500 }} >Sign-up</Typography>
                                </Button>
                                {/*<Button type="submit" variant="contained" size="large" style={{ padding: '0.6rem 3.5rem', }} onClick={(e) => Register(e)}>*/}
                                {/*    <Typography variant="body1">Sign-up</Typography>*/}
                                {/*</Button>*/}
                            </Box>
                            <Box textAlign='center' sx={{ pt: 3, pb: 3, display: resendButtonDisplay }}>
                                <Button type="submit" variant="contained" size="large" style={{ padding: '0.6rem 3rem', textTransform: 'none', }} onClick={(e) => ResendAuthCode(e)}>
                                    <Typography variant="body1">Resend</Typography>
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                </Paper>
                <Footer />
            </Container>
        </React.Fragment>
    );
}

export default ActivateRegistration;
