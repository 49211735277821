import React, { useEffect, useState, useRef } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, IconButton, Grid, FormControl, Box, FormGroup, FormControlLabel } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import AlertControl from '../components/Controls/AlertControl';
//import { useDispatch, useSelector } from 'react-redux';
import { ErrorMessages } from '../common/ErrorMessages';
import { LoadProfilePic } from '../global/Helpers';
import profimg from '../assets/images/profimg.jpg';
import { CallAPIUsingAxios, IsAxiosError } from '../global/APIUtils';
import LoadingOverlay from 'react-loading-overlay';
import RiseLoader from 'react-spinners/RiseLoader'
import { GetApplicantImage } from '../global/EnrollmentHelper';
import Checkbox from '@mui/material/Checkbox';
import { APICall } from '../global/Api';

LoadingOverlay.propTypes = undefined;

const DocUpldDlg = (props) => {

    const { onClose, open, onChange, enrmntid } = props;
    const [invalidDoc, setInvalidDoc] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [uploadedDoc, setUploadedDoc] = useState(null);
    const [isOkBtnEnabled, setIsOkBtnEnabled] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [fileName, setFileName] = useState(null);
    const [isSignReq, setIsSignReq] = useState(true);
    const [checked, setChecked] = React.useState(true);

    const [userId, setUserId] = useState(props.userId);
    const [sessionId, setSessionId] = useState(props.sessionId);

    //const sessions = useSelector((state) => state.sessionMgmnt);
    //const dispatch = useDispatch();

    const handleClose = (event) => {
        event.preventDefault();
        onClose();
    };

    const handleCheckChange = (event) => {
        setChecked(event.target.checked);
    };

    const handleOk = async (event) => {
        event.preventDefault();

        if (!invalidDoc) {

            try {

                setIsLoading(true);

                const apiUrl = new URL(
                    process.env.REACT_APP_SAVE_ENROLLMENT_DOC,
                    process.env.REACT_APP_BASE_URL,
                );

                const fileFormData = new FormData();
                fileFormData.append("enrmntId", parseInt(props.enrmntid));
                fileFormData.append("DocumentFile", uploadedDoc);
                fileFormData.append("FileName", fileName);
                fileFormData.append("IsSignReq", checked);

                //let response = await CallAPIUsingAxios({ url: apiUrl.href, method: 'POST', formData: fileFormData }, userId, sessionId);

                let response = await APICall({ url: apiUrl.href, method: 'POST', payload: fileFormData, contentType: 'multipart/form-data', responseType: '' }, userId, sessionId);

                if (response !== null && response !== undefined) {

                    setIsLoading(false);

                    let error = IsAxiosError(response);

                    if (error != 0) {
                        setErrorMsg(response.data.Errors[0].Message);
                        return false;
                    }

                    onChange();
                }
            }
            catch (err) {

                setErrorMsg(ErrorMessages.UnknownError);
                setIsLoading(false);
            }
            finally {
            }
        }
        else if (!invalidDoc) {
            setErrorMsg('Please select a valid file.');
        }
    };

    const onChangeFile = (event) => {
        const uploadedFile = event.target.files[0];

        const reader = new FileReader();

        setErrorMsg('');
        if (!uploadedFile) {
            setErrorMsg("Please select a file.");
            setInvalidDoc(true);
            return false;
        }

        if (!uploadedFile.name.match(/\.(pdf)$/)) {
            setErrorMsg("Please select a valid PDF file.");
            setInvalidDoc(true);
            return false;
        }

        if (uploadedFile.type !== 'application/pdf') {
            setErrorMsg("Please select a valid PDF file.");
            setInvalidDoc(true);
            return false;
        }

        if (Math.floor(uploadedFile.size / 1048576) > 10) {   /* 2MB */
            setErrorMsg("File size exceeds the limit allowed (10MB) and cannot be uploaded.");
            setInvalidDoc(true);
            event.preventDefault();
            return false;
        }

        //reader.onload = (e) => {
        //    const img = new Image();
        //    img.onload = () => {
        //        setPicUpload(imageFile);
        //        setInvalidImage(false);
        //    };
        //    img.onerror = () => {
        //        setErrorMsg("Invalid image content.");
        //        setInvalidImage(true);
        //        return false;
        //    };

        //    setImageUrl(e.target.result);
        //    img.src = e.target.result;
        //};
        reader.readAsDataURL(uploadedFile);
        setUploadedDoc(uploadedFile);
        setFileName(uploadedFile.name);
        setIsOkBtnEnabled(true);
    }

    return (
        <Dialog onClose={handleClose} open={open} keepMounted>
            <LoadingOverlay active={isLoading} spinner={<RiseLoader color={'#1D50AF'} />} >
                <DialogTitle>
                    Upload document
                    <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent dividers sx={{ ml: 2, mr: 2 }}>
                    <AlertControl Message={errorMsg} severity="error" color="error" icon={true} />
                    <Grid container>
                        <Grid item md={11} sx={{}}>
                            <Typography sx={{ fontWeight: 400 }} gutterBottom variant="body1" color="text.primary">
                                Owner: {props.ownerName}
                            </Typography>
                            <Typography sx={{ fontWeight: 400 }} gutterBottom variant="body1" color="text.primary">
                                Application name: {props.enrolNm}
                            </Typography>
                            <Typography sx={{ fontWeight: 400 }} gutterBottom variant="body1" color="text.primary">
                                Applied for: {props.templateNm}
                            </Typography>
                        </Grid>
                        <Grid item md={11} sx={{pt: 4}}>
                            <Typography sx={{ fontWeight: 400 }} gutterBottom variant="h6" color="text.primary">
                                Please choose a file that’s less than 10MB size, and format should be PDF
                            </Typography>
                        </Grid>
                        <Grid item md={11} sx={{ pt: 2 }}>
                            <Typography sx={{ mb: 1 }} variant="h6" color="text.primary"><strong>Document</strong></Typography>
                        </Grid>
                        {/*<Grid item md={11} sx={{ pt: 1 }}>*/}
                        {/*    {imageUrl ?*/}
                        {/*        <img src={imageUrl} alt="Prifile Picture" className="prof-pic-prvw" />*/}
                        {/*        :*/}
                        {/*        <img src={profimg} alt="Prifile Picture" className="prof-pic-prvw" />*/}
                        {/*    }*/}
                        {/*</Grid>*/}
                        <Grid item md={11} sx={{ pt: 1, pb: 2 }}>
                            <FormControl variant="outlined">
                                <input type="file" className="prof-pic-upld-ctrl" id="prof-pic-upld" onChange={onChangeFile} accept="application/pdf" />
                            </FormControl>
                            <Box sx={{pt: 1}}>
                                <FormGroup>
                                    <FormControlLabel control={<Checkbox checked={checked} onChange={handleCheckChange} />} label="Requires DocuSign" />
                                </FormGroup>
                            </Box>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions sx={{ mr: 2 }}>
                    <Button autoFocus onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button onClick={handleOk} disabled={!isOkBtnEnabled}>Upload</Button>
                </DialogActions>
            </LoadingOverlay>
        </Dialog>
    );
}

export default DocUpldDlg;
