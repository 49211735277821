import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useIdleTimer } from 'react-idle-timer'
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

const AutoLagoutTimer = (props) => {

    const [open, setOpen] = React.useState(false);
    const [remaining, setRemaining] = useState(0);
    const [userId, setUserId] = useState(props.userId);
    const [sessionId, setSessionId] = useState(props.sessionId);

    const SESSION_IDLE_MINUTES = parseInt(process.env.REACT_APP_SESSION_TIMEOUT);
    const SESSION_IDLE_WARNING_MINUTES = SESSION_IDLE_MINUTES - 2;

    let navigate = useNavigate();

    const handleStayLogin = () => {
        activate();
        setOpen(false);
    };

    const handleLogoff = () => {
        setOpen(false);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const onActive = () => {
        activate();
        setOpen(false);
    }

    const handleOnIdle = (event) => {
        navigate('/AppLogout/?reason=SESSION_NOTFOUND', { state: { userId: userId, sessionId: sessionId }, replace: true });
    }

    const handleOnPrompt = () => {
        setOpen(true)
    }
    const { getLastActiveTime, getRemainingTime, activate } = useIdleTimer({
        timeout: 1000 * 60 * SESSION_IDLE_MINUTES,
        onIdle: handleOnIdle,
        onPrompt: handleOnPrompt,
        events: [
            'mousemove',
            'keydown',
            'wheel',
            'DOMMouseScroll',
            'mousewheel',
            'mousedown',
            'touchstart',
            'touchmove',
            'MSPointerDown',
            'MSPointerMove',
            'visibilitychange',
            'focus'
        ],
        debounce: 0,
        throttle: 500,
        promptBeforeIdle: 1000 * 60 * SESSION_IDLE_WARNING_MINUTES,
        eventsThrottle: 200,
        element: document,
        name: 'idle-timer',
        startOnMount: true,
    })

    useEffect(() => {
        const interval = setInterval(() => {
            setRemaining(Math.ceil(getRemainingTime() / 1000))
        }, 500)

        return () => {
            clearInterval(interval)
        }
    })

    const action = (
        <React.Fragment>
            <Button color="secondary" size="small" onClick={handleClose}>
                UNDO
            </Button>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleClose}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </React.Fragment>
    );

    return (
        <React.Fragment >

            <Snackbar
                open={open}
                autoHideDuration={306000}
                onClose={handleClose}
                message="Note archived"
                action={action}
            />

            {/*<Dialog keepMounted*/}
            {/*    open={open}*/}
            {/*    aria-labelledby="alert-dialog-title"*/}
            {/*    aria-describedby="alert-dialog-description"*/}
            {/*>*/}
            {/*    <DialogTitle id="alert-dialog-title">*/}
            {/*        {"Timeout warning"}*/}
            {/*    </DialogTitle>*/}
            {/*    <DialogContent dividers>*/}
            {/*        <DialogContentText id="alert-dialog-description">*/}
            {/*            You will automatically be logged off in {remaining} seconds.*/}
            {/*        </DialogContentText>*/}
            {/*    </DialogContent>*/}
            {/*    <DialogActions>*/}
            {/*        <Button onClick={handleLogoff}>Log off</Button>*/}
            {/*        <Button onClick={handleStayLogin} autoFocus>*/}
            {/*            Stay logged on*/}
            {/*        </Button>*/}
            {/*    </DialogActions>*/}
            {/*</Dialog>*/}
        </React.Fragment >

    );
}

export default AutoLagoutTimer;

//const IdleMonitor = () => {
//    const [idleModal, setIdleModal] = useState(false);

//    let idleTimeout = 1000 * 60 * 1;  //1 minute
//    let idleLogout = 1000 * 60 * 2; //2 Minutes
//    let idleEvent;
//    let idleLogoutEvent;

//    const events = [
//        'click',
//        'keypress',
//        'scroll',
//        'load',
//        'keydown'
//    ];


//    const sessionTimeout = () => {
//        if (!!idleEvent) clearTimeout(idleEvent);
//        if (!!idleLogoutEvent) clearTimeout(idleLogoutEvent);

//        idleEvent = setTimeout(() => setIdleModal(true), idleTimeout); //show session warning modal.
//        idleLogoutEvent = setTimeout(() => logOut, idleLogout); //Call logged out on session expire.
//    };


//    const extendSession = () => {
//        console.log('user wants to stay logged in');
//    }


//    const logOut = () => {
//        console.log('logging out');
//    }

//    useEffect(() => {
//        for (let e in events) {
//            window.addEventListener(events[e], sessionTimeout);
//        }

//        return () => {
//            for (let e in events) {
//                window.removeEventListener(events[e], sessionTimeout);
//            }
//        }
//    }, []);


//    return (

//        <Modal isOpen={idleModal} toggle={() => setIdleModal(false)}>
//            <ModalHeader toggle={() => setIdleModal(false)}>
//                Session expire warning
//            </ModalHeader>
//            <ModalBody>
//                your session will expire in {idleLogout / 60 / 1000} minutes. Do you want to extend the session?
//            </ModalBody>
//            <ModalFooter>
//                <button className="btn btn-info" onClick={() => logOut()}>Logout</button>
//                <button className="btn btn-success" onClick={() => extendSession()}>Extend session</button>

//            </ModalFooter>
//        </Modal>
//    )

//}

//export default IdleMonitor;
