import React from 'react';
import { Alert, AlertTitle, Box } from '@mui/material';
import { Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';

const MessageAlert = (props) => {

    const [open, setOpen] = React.useState(true);

    return (
        <>
            {
                (props.Message && props.Message !== '') ?
                    props.icon ?

                        <Alert className={props.className} severity={props.severity} color={props.color}>
                            <AlertTitle>{props.Title}</AlertTitle>
                            {props.Message}
                        </Alert>
                        :
                        <Alert className={props.className} severity={props.severity} color={props.color} icon={false} >
                            <AlertTitle>{props.Title}</AlertTitle>
                            {props.Message}
                        </Alert>
                : <p></p>
            }
        </>
    );
}

export default MessageAlert;