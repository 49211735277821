import React, { useState, useEffect, useRef } from 'react';
//import Modal from 'react-bootstrap/Modal';
import LoaderControl from '../components/Controls/LoaderControl';
import { useNavigate } from "react-router-dom";
//import { ArrowRight } from 'react-bootstrap-icons';
//import { ApiHelper } from '../../../customFunctions/apiHelper';
import { CallAPI, IsError, IsLoggedInUserProspectUser, GetLoggedInUserName, GetLoggedInUserId } from '../global/APIUtils';
import { ErrorMessages } from '../common/ErrorMessages';
import AlertControl from '../components/Controls/AlertControl';
import LoadingOverlay from 'react-loading-overlay';
//import styled from 'styled-components';
import RiseLoader from 'react-spinners/RiseLoader'
import { EnrollmentStatus } from '../common/AOBEnum';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Box from '@mui/material/Box';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import OverlaySpinner from '../components/Controls/OverlaySpinner';
import { Grid, TextField, FormControl, Select, InputLabel, MenuItem } from '@mui/material';
import { APICall } from '../global/Api';

function PaperComponent(props) {
    return (
        <Draggable
            handle="#draggable-dialog-title"
            cancel={'[class*="MuiDialogContent-root"]'}
        >
            <Paper {...props} />
        </Draggable>
    );
}

export function AddEditEnrollment(props) {

    //const sessions = useSelector((state) => state.sessionMgmnt);
    //const dispatch = useDispatch();

    const [data, setData] = useState([]);
    const [dataOwner, setDataOwner] = useState([]);
    const [loading, setLoading] = useState(false);
    //const [show, setShow] = useState(true);
    const [msgModalShow, setMsgModalShow] = useState(true);
    const [isReadOnly, setIsReadOnly] = useState(props.name != null && props.name.trim().length === 0);
    const [TemplateId, setTemplateId] = useState(props.templateid || -1);
    const [name, setName] = useState(props.name || '');
    const [isContinueBtnEnabled, setIsContinueBtnEnabled] = useState(false);
    const [templateName, setTemplateName] = useState('');
    const [templateDesc, setTemplateDesc] = useState('');
    const [ownerId, setOwnerId] = useState(props.ownerid || -1);
    let history = useNavigate();
    const [CntCtId, setCntCtId] = useState(-1);
    const [CntCtNm, setCntCtNm] = useState('');
    const [roleId, setRoleId] = useState(-1);
    const [errorMsg, setErrorMsg] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const [userId, setUserId] = useState(props.userId || -1);
    const [sessionId, setSessionId] = useState(props.sessionId || '');

    const enrmntId = useRef(props.enrmntid || -1);
    const enrollmentDetail = useRef('');
    const enrollmentStatusId = useRef(EnrollmentStatus.InComplete);

    const handleClose = () => {
        props.onHide();
    };

    const [isProspectUser, setProspect] = useState(0);

    LoadingOverlay.propTypes = undefined;

    useEffect(() => {

        setProspect(IsLoggedInUserProspectUser(userId, sessionId) ? 1 : 0);
        setCntCtNm(GetLoggedInUserName(userId, sessionId));
        setCntCtId(userId);
        loadTemplateOwner();

    }, []);


    const loadTemplateOwner = async () => {

        try {


            setIsLoading(true);

            let apiUrl = new URL(
                process.env.REACT_APP_GET_PUBLISHED_TEMPLATES,
                process.env.REACT_APP_BASE_URL,
            );

            //let response = await CallAPI({ url: apiUrl.href, method: 'GET', headers: {} }, userId, sessionId);
            let response = await APICall({ url: apiUrl.href, method: 'GET', contentType: 'application/json', responseType: '' }, userId, sessionId);

            if (response !== null && response !== undefined) {
                let templateData = response["data"];
                if (templateData == null || templateData == undefined) {
                    setErrorMsg(ErrorMessages.UnknownError);
                }
                else {
                    setTemplateId(templateData[0].TemplateId);
                    setData(templateData);
                }
            }

            apiUrl = new URL(
                process.env.REACT_APP_GET_PROSPECTS,
                process.env.REACT_APP_BASE_URL,
            );

            //response = await CallAPI({ url: apiUrl.href, method: 'GET', headers: {} }, userId, sessionId);
            response = await APICall({ url: apiUrl.href, method: 'GET', contentType: 'application/json', responseType: '' }, userId, sessionId);

            if (response !== null && response !== undefined) {
                let userData = response["data"];
                if (userData == null || userData == undefined) {
                    setErrorMsg(ErrorMessages.UnknownError);
                }
                else {
                    setDataOwner(userData);
                }
            }

            if (enrmntId.current != -1) {

                apiUrl = new URL(
                    process.env.REACT_APP_GET_ENROLLMENT_BY_ID,
                    process.env.REACT_APP_BASE_URL,
                );
                apiUrl.searchParams.set('enrmntId', `${parseInt(enrmntId.current)}`);

                //response = await CallAPI({ url: apiUrl.href, method: 'GET', headers: {} }, userId, sessionId);
                response = await APICall({ url: apiUrl.href, method: 'GET', contentType: 'application/json', responseType: '' }, userId, sessionId);

                if (response !== null && response !== undefined) {
                    enrollmentDetail.current = response["data"][0].enrmntData;
                    setOwnerId(response["data"][0].ownerId);
                    setName(response["data"][0].name);
                    enrollmentStatusId.current = response["data"][0].enrmntStatusId;
                    setIsContinueBtnEnabled(true);
                }
            }
        }
        catch (err) {
            console.error(err.message);
            setErrorMsg(ErrorMessages.UnknownError);
        }
        finally {
            setIsLoading(false);
        }
    }

    const nameChangeHandler = (nm) => {
        setName(nm)
        setIsContinueBtnEnabled(nm.trim().length > 0 && TemplateId > 0)
    }

    const changeCboTemplate = (tmpltId) => {

        if (enrmntId.current > 0 && tmpltId !== TemplateId) {
            alert('Application Type changing not allow.')
            return;
        }

        setTemplateId(tmpltId)
        setTemplateDesc('Select Application Type')

        var desc = data.filter(item => item.TemplateId == tmpltId)
        if (desc.length > 0) {
            setTemplateName(desc[0].TemplateName)
            setTemplateDesc(desc[0].templateDesc)
        }

        setIsReadOnly(tmpltId <= 0);
        setIsContinueBtnEnabled(name.trim().length > 0 && tmpltId > 0)
    }
    const changeCboOwner = (CntCtId) => {
        setOwnerId(CntCtId)
    }


    //const handleClose = () => setShow(false);

    const handleContinue = async () => {

        let tmpltId = TemplateId || props.templateid;
        let selOwnerId = ((isProspectUser === 1) ? CntCtId : ownerId);

        if (selOwnerId == -1) {
            setErrorMsg('Please select at least one of the owner when creating an application.');
            return;
        }

        let inputJson;
        let apiUrl;

        try {

            setIsLoading(true);

            let apiUrl = null;


            if (enrmntId.current == -1) {

                apiUrl = new URL(
                    process.env.REACT_APP_ADD_ENROLLMENT,
                    process.env.REACT_APP_BASE_URL,
                );

                inputJson = {
                    ownerId: parseInt(selOwnerId),
                    templateId: parseInt(tmpltId),
                    name: name
                };
            }
            else {

                apiUrl = new URL(
                    process.env.REACT_APP_MODIFY_ENROLLMENT,
                    process.env.REACT_APP_BASE_URL,
                );

                inputJson = {
                    enrmntId: parseInt(enrmntId.current),
                    ownerId: parseInt(selOwnerId),
                    templateId: parseInt(tmpltId),
                    enrmntData: enrollmentDetail.current,
                    mntStatusId: parseInt(null),
                    enrmntStatusId: enrollmentStatusId.current,
                    name: name
                };
            }

            //let response = await CallAPI({ url: apiUrl.href, method: 'POST', headers: {}, body: inputJson }, userId, sessionId);
            let response = await APICall({ url: apiUrl.href, method: 'POST', payload: inputJson, contentType: 'application/json', responseType: '' }, userId, sessionId);

            if (response !== null && response !== undefined) {

                setIsLoading(false);
                let error = IsError(response);

                if (error != 0) {
                    setErrorMsg(response.Errors[0].Message);
                    return;
                }
                enrmntId.current = response["data"][0].EnrolmentID;
                handleClose();
                history('/Enrollment', { state: { templateid: parseInt(tmpltId), name: name, enrmntid: enrmntId.current, ownerid: selOwnerId, templatenm: (templateName || props.templatenm), userId: userId, sessionId: sessionId }, replace: true })
            }
        }
        catch (err) {
            setErrorMsg(ErrorMessages.UnknownError);
            setIsLoading(false);
        }
        finally {
            
        }
    };

        return (
            <>
                
                    <Dialog
                    open={props.show}
                    PaperComponent={PaperComponent}
                    aria-labelledby="draggable-dialog-title"
                    keepMounted
                    sx={{}}
                >
                    <LoadingOverlay active={isLoading} spinner={<RiseLoader color={'#1D50AF'} />} >
                    <DialogTitle style={{ cursor: 'move' }} sx={{ ml: 3, mr: 12, mt: 1, mb: 1 }} id="draggable-dialog-title">
                        {props.title}
                        <IconButton
                            aria-label="close"
                            onClick={handleClose}
                            sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                                color: (theme) => theme.palette.grey[500],
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                        <DialogContent dividers sx={{ ml: 3, mr: 2 }}>
                            <Grid container>
                            <AlertControl Message={errorMsg} severity="error" color="error" icon={true} />
                                <Grid item md={11} sx={{pt: 1}}>
                                    <Typography sx={{ mb: 1 }} variant="body1" color="text.primary">Before you start, make sure you have your supporting documents ready to upload. You will need the following documents to file a case: </Typography>
                                    <ul>
                                        {/*<li>Identification Document - Passport, Driver's License or State issued ID card.</li>*/}
                                        <li>W-9 Form.</li>
                                        <li>Opt-In Agreement.</li>
                                    </ul>
                            </Grid>
                            {
                                (isProspectUser === 1) ? null
                                    :
                                    <Grid item md={11} sx={{ mb: 2 }}>
                                            <Typography sx={{ mb: 2, mt: 2 }} variant="body1" color="text.primary">Choose owner *</Typography>
                                            {/*{*/}
                                            <FormControl variant="outlined" sx={{ width: '100%' }}>
                                                <InputLabel id="id-owner-label">Owner</InputLabel>
                                                <Select
                                                    labelId="id-owner-label"
                                                    id="cboOwner"
                                                    name="cboOwner"
                                                    value={ownerId}
                                                    onChange={(event) => changeCboOwner(event.target.value)}
                                                    label="Owner"
                                                    disabled={(isProspectUser === 1 || (enrollmentStatusId.current != EnrollmentStatus.InComplete && enrollmentStatusId.current != EnrollmentStatus.Complete))  ? true : false}
                                                >
                                                    {
                                                        dataOwner && dataOwner.map(item => (
                                                            <MenuItem key={item.UserId} value={item.UserId}>{item.FullName}</MenuItem>
                                                        ))
                                                    }
                                                </Select>
                                            </FormControl>
                                    </Grid>
                            }
                        </Grid>
                    </DialogContent>
                        <DialogActions sx={{mr: 2}}>
                        <Button autoFocus onClick={handleClose}>
                            Cancel
                        </Button>
                        <Button onClick={handleContinue} >Proceed</Button>
                        </DialogActions>
                    </LoadingOverlay>
                </Dialog>                
            </>
        );
};

