import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { APICall, RemoveSessionInfo } from '../global/Api';
import { Container } from '@mui/material';
import OverlaySpinner from './Controls/OverlaySpinner';
import PostLoginMenu from './PostLoginMenu';

const AppLogout = () => {

    const location = useLocation();
    let navigate = useNavigate();
    const search = useLocation().search;
    const params = new URLSearchParams(search);
    let logoutReason = params.get("reason");
    let qUserId = params.get("diu");
    let qSessionId = params.get("dis");

    const [userId, setUserId] = useState(location.state ? location.state.userId : qUserId);
    const [sessionId, setSessionId] = useState(location.state ? location.state.sessionId : qSessionId);

    useEffect(() => {

        clearSession();

    }, []);

    const clearSession = async () => {
        try {

            if (userId === null || userId === undefined) {
                return;
            }

            if (sessionId === null || sessionId === undefined) {
                return;
            }

            let inputJson = {
                CntctId: parseInt(userId),
                SessionId: sessionId
            }

            const apiUrl = new URL(
                process.env.REACT_APP_LOGOUTUSER,
                process.env.REACT_APP_BASE_URL,
            );

            let response = await APICall({ url: apiUrl.href, method: 'POST', payload: inputJson, contentType: 'application/json', responseType: '' }, userId, sessionId);

            RemoveSessionInfo(userId, sessionId);

            if (logoutReason) {
                navigate('/AppLogin/?reason=' + `${logoutReason}`, { state: {}, replace: true });
            }
            else {
                navigate('/AppLogin', { state: {}, replace: true });
            }
        }
        catch (err) {
            console.error(err.message);
            return false;
        }
    }

    return (
        <React.Fragment>
            <Container maxWidth="xl" sx={{}}>
                <OverlaySpinner disappear={true} />
                <PostLoginMenu userId={userId} sessionId={sessionId} />
            </Container>
        </React.Fragment>
    );
}
export default AppLogout;