import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import { Typography, Link, card, CardContent, Box, FormControl, Toolbar, Stack, Paper, InputBase, IconButton } from '@mui/material';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, {
    AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import AddIcon from '@mui/icons-material/Add';
import { Search } from 'react-bootstrap-icons';
import RemoveIcon from '@mui/icons-material/Remove';

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: 0,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
}));

const CustomExpandIcon = () => {
    return (
        <Box
            sx={{
                '.Mui-expanded & > .collapsIconWrapper': {
                    display: 'none',
                },
                '.expandIconWrapper': {
                    display: 'none',
                },
                '.Mui-expanded & > .expandIconWrapper': {
                    display: 'block',
                },
            }}
        >
            <div className="expandIconWrapper">
                <RemoveIcon sx={{ fontSize: '1rem' }} />
            </div>
            <div className="collapsIconWrapper">
                <AddIcon sx={{ fontSize: '1.5rem' }} />
            </div>
        </Box>
    )
}

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        expandIcon={<CustomExpandIcon />}
        {...props}
    />
))(({ theme }) => ({
    //backgroundColor:
    //    theme.palette.mode === 'dark'
    //        ? 'rgba(255, 255, 255, .05)'
    //        : 'rgba(0, 0, 0, .03)',
    border: `1px solid #ccc`,
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        //transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    //borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

export default function FAQS() {

    const [expanded, setExpanded] = React.useState('');

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    const handleSearchChange = (event) => {
        //setSearchText(event.target.value);
    };

    const handleSearchClick = (event) => {
    };

    return (
        <React.Fragment>
            <Box sx={{ ml: 10, mr: 10, border: '0px solid #000', pt: 1 }}>
                <Typography variant="h3" color="text.primary" sx={{ borderBottom: '1px solid rgba(0,0,0,0.1)', lineHeight: 2.2, fontWeight: 500, letterSpacing: '-1px', textAlign: 'center' }} >Frequently Asked Questions</Typography>
                <Paper elevation={0}
                    component="form"
                    sx={{ mt: 2, ml: 5, mr: 5, display: 'flex', alignItems: 'center', border: 1, borderColor: '#d5dbdb', }}
                >
                    <InputBase className="form-control" sx={{ boxShadow: 'none !important', border: 'none !important', padding: '0px 1rem !important', font: 'unset' }}
                        placeholder="Search..." onChange={handleSearchChange}
                    />
                    <IconButton type="button" sx={{ p: '10px' }} aria-label="search" onClick={(event) => handleSearchClick(event)}>
                        <Search />
                    </IconButton>
                </Paper>
                <Box sx={{ mt: 5, ml: 4 }}>
                    <Button size="small" variant="outlined" style={{ boxShadow: 'none', border: 0, textTransform: 'none' }}>
                        <Typography variant="body1" color="text.secondary" sx={{ pl: 1, fontWeight: 400 }} >Expand/Collapse All</Typography>
                    </Button>
                </Box>
                <Typography variant="h5" color="text.primary" sx={{ fontWeight: 500, textAlign: 'center', mt: 3, lineHeight: 1.5 }} >Basic Information and Overview</Typography>
                <Box sx={{ mt: 1, ml: 5, mr: 5 }}>
                    <Accordion>
                        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                            <Typography variant="h6" color="text.primary" sx={{ fontWeight: 600 }} >1. I received a Notice of a Class Action Settlement. Why?</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography variant="h6" color="text.secondary" sx={{ lineHeight: 1.3 }}>
                                A Court authorized the notice to inform you how you may be affected by this Settlement. The notice describes the lawsuit, the general terms of the Settlement and what it may mean to you. The notice also explains how to participate in, or how you could have excluded yourself from, the Settlement if you were impacted by the Data Breach.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                </Box>
                <Box sx={{ mt: 1, ml: 5, mr: 5 }}>
                    <Accordion>
                        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                            <Typography variant="h6" color="text.primary" sx={{ fontWeight: 600 }} >2. What is this lawsuit about?</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography variant="h6" color="text.secondary" sx={{ lineHeight: 1.3 }}>
                                In September 2017, Equifax announced that it had been the victim of a criminal cyberattack on its systems. The attackers gained unauthorized access to the personal information of approximately 147 million U.S. consumers. This information included people’s names, Social Security numbers, birth dates, addresses, and in some instances driver’s license numbers, credit card numbers, or other personal information.
                            </Typography>
                            <Typography variant="h6" sx={{ mt: 2, lineHeight: 1.3 }} color="text.secondary" >
                                Numerous lawsuits were brought on behalf of consumers whose personal information was impacted as a result of the Data Breach. Chief Judge Thomas W. Thrash Jr. of the U.S. District Court for the Northern District of Georgia is overseeing these lawsuits. These lawsuits are known as In re: Equifax Inc. Customer Data Security Breach Litigation, Case No. 1:17-md-2800-TWT. The consumers who sued are called the “Plaintiffs.” Equifax, Inc., and two of its subsidiaries are the “Defendants.” Plaintiffs claimed that Equifax did not adequately protect consumers’ personal information and that Equifax delayed in providing notice of the data breach. The most recent version of the lawsuit, which describes the specific legal claims alleged by the Plaintiffs, is available here. Equifax denies any wrongdoing, and no court or other judicial entity has made any judgment or other determination of any wrongdoing.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                </Box>
                <Box sx={{ mt: 1, ml: 5, mr: 5 }}>
                    <Accordion>
                        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                            <Typography variant="h6" color="text.primary" sx={{ fontWeight: 600 }} >3. Why is this a class action?</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography variant="h6" color="text.secondary" sx={{ lineHeight: 1.3 }}>
                                In a class action, one or more people called “class representatives” sue on behalf of themselves and other people with similar claims. All of these people together are the “class” or “class members.” Because this is a class action, even persons who did not file their own lawsuit can obtain relief from harm that may have been caused by the Data Breach, except for those individuals who excluded themselves from the Settlement class by the deadline.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                </Box>
                <Box sx={{ mt: 1, ml: 5, mr: 5 }}>
                    <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                        <AccordionSummary>
                            <Typography variant="h6" color="text.primary" sx={{ fontWeight: 600 }} >4. Why is there a Settlement?</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography variant="h6" color="text.secondary" sx={{ lineHeight: 1.3 }}>
                                The Court has not decided in favor of Plaintiffs or Equifax. Instead, both sides agreed to a Settlement after a lengthy mediation process overseen by a retired federal judge. Settlements avoid the costs and uncertainty of a trial and related appeals, while more quickly providing benefits to members of the Settlement class. The class representatives appointed to represent the class and the attorneys for the Settlement class (“Class Counsel,” see FAQ 27) believe that the Settlement is in the best interests of the Settlement Class Members.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                </Box>
                <Typography variant="h5" color="text.primary" sx={{ fontWeight: 500, textAlign: 'center', mt: 2, lineHeight: 1.5 }} >Who Is Part of the Settlement</Typography>
                <Box sx={{ mt: 1, ml: 5, mr: 5 }}>
                    <Accordion>
                        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                            <Typography variant="h6" color="text.primary" sx={{ fontWeight: 600 }} >5. How do I know if I am part of the Settlement?</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography variant="h6" sx={{ lineHeight: 1.3 }} color="text.secondary">
                                You are a Settlement Class Member if you are among the approximately 147 million U.S. consumers identified by Equifax whose personal information was impacted by the Equifax Data Breach.
                            </Typography>
                            <Typography variant="h6" sx={{ mt: 2, lineHeight: 1.3, mb: 2 }} color="text.secondary">
                                You can confirm you are a Settlement Class Member, and eligible for benefits, by:
                            </Typography>
                            <ul>
                                <li>Going <b>here;</b> or</li>
                                <li>Calling the Settlement Administrator toll-free at 1-833-759-2982.</li>
                            </ul>
                            <Typography variant="h6" sx={{ mt: -1, lineHeight: 1.3 }} color="text.secondary">
                                When using the link above, if you have gone by more than one last name (for example, a maiden name and a married name), make sure that you check all of your last names. If you received a message that your information was impacted, please use your last name that received the “impacted” message when submitting your claim form.
                            </Typography>
                            <Typography variant="h6" sx={{ mt: 2, lineHeight: 1.3, mb: 2 }} color="text.secondary">
                                Excluded from the Settlement are:
                            </Typography>
                            <ul>
                                <li>Officers and directors of Equifax;</li>
                                <li>The presiding judge and any judicial staff involved in the lawsuit; and</li>
                                <li>Any Class Member who validly opted-out (see <b>FAQ 30</b>).</li>
                            </ul>
                        </AccordionDetails>
                    </Accordion>
                </Box>
            </Box>
        </React.Fragment>
    );
}